import React from 'react';
import { connect } from 'react-redux';

import { navigationHistory } from 'store/slices';

import Subtotal from '../FinishYourPurchaseStep/Subtotal';
import { Text } from 'components-v2/shared/Elements';

import './no-pet-plans.css';
import { handleKeyUp } from 'utils';

// eslint-disable-next-line class-methods-use-this
const NoPetPlans = (props) => (
  <div>
    <div className="EmptyPetCard">
      <div className="EmptyPetCard__Item">
        <div className="EmptyPetCard__PetInfo">
          <Text content="You have no pet plans selected." />
          <div
            className="EmptyPetCard__ExplorePlans"
            role="button"
            tabIndex="0"
            onClick={() => props.dispatch(navigationHistory.actions.goToSellScreen())}
            onKeyUp={(e) => handleKeyUp(e, () => props.dispatch(navigationHistory.actions.goToSellScreen()))}
          >
            Explore Membership Plans
          </div>
        </div>
      </div>
    </div>

    <div className="EmptyPetCard__Item">
      <Subtotal value={0} />
    </div>
  </div>
);

export default connect()(NoPetPlans);

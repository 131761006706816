import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { Button } from 'components-v2/shared/Button-v1';
import { Text } from 'components-v2/shared/Elements';

import {
  loadingCount,
} from 'store/slices';

import { benefitConsts } from 'utils/constBenefits';
import './confirmation-modal.css';
import Icon from 'components-v2/shared/Icon/Icon';

const ConfirmationModal = ({
  show, benefit, onHide, isLoading, coupons = [], redeemBenefit,
}) => {
  const dispatch = useDispatch();
  const [needToConfirm, setNeedToConfirm] = useState(true);

  const onConfirm = () => {
    setNeedToConfirm(false);
    redeemBenefit();
  };

  useEffect(() => {
    const localNeed = !benefit || benefit.allotmentFrequency !== benefitConsts.UNLIMITED_ALLOTMENT;
    setNeedToConfirm(localNeed);
  }, [benefit, setNeedToConfirm]);

  useEffect(() => {
    const delayCouponsPresentation = async () => {
      let maxDelay = 0;

      if (coupons?.length && coupons[0]?.delaySeconds) {
        maxDelay = coupons[0].delaySeconds;
      }

      if (maxDelay !== 0) {
        dispatch(loadingCount.actions.increment());
        await new Promise((resolve) => setTimeout(resolve, maxDelay * 1000));
        dispatch(loadingCount.actions.decrement());
      }
    };

    delayCouponsPresentation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupons]);

  if (!benefit) return null;
  if (!needToConfirm && !coupons) return null;

  return needToConfirm
    && (
      <Modal className="membership-modalV2" show={show} onHide={onHide} backdrop="static" keyboard={false}>
        <div data-test-id="BarcodeModalWrapper-v2" className="BarcodeModalWrapper-v2">
          <div className="modal-title-wrapper">
            <Modal.Title role="heading" aria-level="3" id="v2-modal-title">
              Are you sure?
            </Modal.Title>
            <Icon
              name="closeBig" 
              className="v2-close-icon" 
              onClick={onHide}
            />
          </div>
          <Modal.Body>
            <Text id="v2-upper-text">Once used, this benefit will disappear.</Text>
            <Text>
              Please make sure you are physically at the store before you redeem.
            </Text>
          </Modal.Body>
          <Modal.Footer id="v2-modal-footer">
            <Button
              onClick={onConfirm}
              label="YES, REDEEM NOW"
              disabled={isLoading}
              className="confirmButton"
            />
            <Button
              className="secondary"
              onClick={onHide}
              disabled={isLoading}
              label="NEVERMIND, CANCEL"
            />
          </Modal.Footer>
        </div>
      </Modal>
    );
};

export default ConfirmationModal;

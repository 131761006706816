import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'components-v2/shared/Button-v1';
import { handleKeyUp } from 'utils';
import './SelectPaymentPlanModal.css';
import Icon from 'components-v2/shared/Icon/Icon';


const SelectPaymentPlanModal = (props) => {
  const {
    show, title, onHide, children, onAction, subTitle,
  } = props;

  return (
    <Modal show={show} onHide={onHide} className="customModal SelectPaymentPlanModal">
      <div className="SelectPaymentPlanModal">
        <Modal.Title className="SelectPaymentPlanTitle">
          {title}
        </Modal.Title>
        <Modal.Title className="SelectPaymentPlanSubTitle">
          {subTitle}
        </Modal.Title>

        <div
          className="CloseButton"
          role="button"
          tabIndex="0"
          aria-label="Close"
          onClick={onHide}
          onKeyUp={(e) => handleKeyUp(e, onHide)}
        >
          <span><Icon name="closeBig" className="SelectPaymentPlanCloseIcon" /></span>
        </div>

        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => onAction() && onHide()}
            label="CONTINUE"
            className="confirmButton"
          />
          <Button
            onClick={onHide}
            label="Back"
            className="change-plan-status-button"
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default SelectPaymentPlanModal;

import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { DateTime, Info } from 'luxon';
import * as Yup from 'yup';

import { trackEvent } from 'utils';

import { Button } from 'components-v2/shared/Button-v1';
import ComboBox from 'components-v2/shared/ComboBox/ComboBox';
import Container from 'components-v2/shared/Container';
import Effect from 'components-v2/formik-effect';
import Error from 'components-v2/shared/Error';
import { Title } from 'components-v2/shared/Elements';

import './birthday-selection.css';


const BirthdaySelection = ({
  petName, setPetBirthdate,
}) => {
  const getYearList = (showCurrentYear = true) => [...Array(20).keys()]
    .map((y) => DateTime.now().year + (showCurrentYear ? 0 : -1) - y);

  const getBirthdate = (selectedMonth, year) => DateTime.fromObject({ year, month: selectedMonth, day: 1 }).toISODate();

  const isMonthBeforeYear = (birthdate) => DateTime.fromISO(birthdate) < DateTime.now();

  const years = getYearList();
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    trackEvent({
      formname: 'Membership Signup',
      formstepname: 'Add or select a pet',
      formstep: 4,
      subformname: 'Add a pet',
      subformstepdetails: 'Pet birthday',
    });
  }, []);

  const getErrorClassName = (className, name, errors, touched) => {
    if (errors[name] && touched[name]) {
      return `${className} error`;
    }

    return className;
  };

  const handleSubmit = (values) => {
    trackEvent({
      formname: 'Membership Signup',
      formstepname: 'Add or select a pet',
      formstep: 4,
      subformname: 'Add a pet',
      subformstepdetails: 'Pet birthday',
      eventname: 'Create pet',
    });

    const birthdate = getBirthdate(values.month, values.year);
    setPetBirthdate(birthdate);
  };

  const isFormValid = (values) => {
    const { month, year } = values;
    const nextMonthName = DateTime.now().plus({ months: 1 }).toLocaleString({ month: 'long' });
    const currentYearString = DateTime.now().year.toString();
    const invalidBirthdateMessage = `Birthdate must be prior to ${nextMonthName} ${currentYearString}`;
    const invalidBirthdateErrors = { year: invalidBirthdateMessage };

    if (month && year) {
      const birthdate = getBirthdate(month, year);
      const monthIsBeforeYear = isMonthBeforeYear(birthdate);
      return monthIsBeforeYear ? true : invalidBirthdateErrors;
    } else return false;
  };

  /*
    CAPTRAY: We need to discuss the usage of formik-effect, as there is a serious chance of creating an 
    infinite loop here, and possibly race conditions between validation and change handlers. This should 
    really be emitted back to a data store and then broadcast back down into the components, OR just keep this 
    simply and in the UI prior to submission. There is an overuse of external dependencies here that should 
    be simplified. 
  */
  const handleChange = (_, nextFormikState) => {
    const { month, year } = nextFormikState.values;

    if (month) {
      const parsedMonth = parseInt(month, 10);
      setCurrentMonth(parsedMonth);
    }

    if (year) {
      const parsedYear = parseInt(year, 10);
      setCurrentYear(parsedYear);
    }
  };

  const monthPlaceholder = 'Select Month';
  const yearPlaceholder = 'Select Year';
  const months = Info.months('long');

  return (
    <div className="BirthdaySelectionPage">
      <Container>
        <Formik
          initialValues={{
            ...({
              month: currentMonth,
              year: currentYear,
            }),
          }}
          enableReinitialize={false}
          validateOnChange
          validationSchema={
            Yup.object().shape({
              month: Yup.string().required(),
              year: Yup.string().required(),
            })
          }
          validate={isFormValid}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Effect onChange={(currentFormikState, nextFormikState) => {
                handleChange(currentFormikState, nextFormikState);
              }}
              />

              <fieldset>
                <Title>
                  When is {petName}'s birthday?
                </Title>

                <div>
                  <div className="dropdownWrapper">
                    <div className={getErrorClassName('col-6 form-group', 'month', props.errors, props.touched)}>
                      <label htmlFor="month">*Month</label>

                      <ComboBox
                        id="month"
                        ariaLabel="month"
                        name="month"
                        text={monthPlaceholder}
                        onChange={(key, value) => {
                          props.setFieldTouched(key);
                          props.setFieldValue(key, value);
                        }}
                        selectedOption={currentMonth}
                        options={months.map((month, monthNumber) => ({
                          key: monthNumber + 1,
                          value: monthNumber + 1,
                          label: month,
                        }))}
                      />

                      {!!props.errors.month && (
                        <Error name="month" role="alert" aria-label={`${props.error}`}>
                          {props.error}
                        </Error>
                      )}
                    </div>
                  </div>

                  <div className="dropdownWrapper">
                    <div className={getErrorClassName('col-6 form-group', 'year', props.errors, props.touched)}>
                      <label htmlFor="year">*Year</label>

                      <ComboBox
                        isDisabled={!props.touched.month && !currentMonth}
                        id="year"
                        ariaLabel="year"
                        name="year"
                        text={yearPlaceholder}
                        onChange={(key, value) => {
                          props.setFieldTouched(key, value);
                          props.setFieldValue(key, value);
                        }}
                        options={years.map((year) => ({
                          key: year,
                          value: year,
                          label: year,
                        }))}
                        selectedOption={currentYear}
                      />

                      {!!props.errors.year && (
                        <Error name="year">
                          {props.error}
                        </Error>
                      )}
                    </div>
                  </div>

                  <br />
                  <div className={getErrorClassName('col-6 form-group', 'month', props.errors, props.touched)}>
                    <Button
                      id="next-button"
                      data-testid="next-button"
                      type="submit"
                      className={props.isValid ? 'button-active' : ''}
                      disabled={!props.isValid ? 'disabled' : ''}
                      label="Create Pet"
                    />
                  </div>
                </div>
              </fieldset>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default BirthdaySelection;

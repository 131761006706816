import { webSocketStatusType } from 'utils/constGlobal';
import store from '../store';
import { fatalError, navigationHistory, registrationInfo } from '../store/slices';


const webSocketConnection = () => {
  const wsConnection = new WebSocket(`${process.env.REACT_APP_API_URL_ARCHWEBSOCKET}`);
  wsConnection.onopen = (event) => {
    console.log('Found Websocket Connection Open', event);
    const response = wsConnection.send('hello');
  };

  const getSocketData = (data) => {
    if (data.status_code === 201 || data.status_code === 500) {
      const getData = {
        source: data?.source,
        message: data?.message,
        status_code: data?.status_code,
        wsConnectionId: data?.wsConnectionId,
        data: [data?.data],
      };
      return getData;
    }
  };

  wsConnection.onmessage = (event) => {
    const socketResponse = JSON.parse(event.data);
    const connectionId = socketResponse.message === 'Forbidden' ? socketResponse.connectionId : '';
    store.dispatch(registrationInfo.actions.setSelectedWSConnectionId(connectionId));
    console.log('Found Connection id', connectionId, event.data);
    const socketData = event.data && getSocketData(socketResponse);
    switch (socketData && socketData.status_code) {
      case webSocketStatusType.SUCCESS:
        store.dispatch(registrationInfo.actions.setSelectedNotification(
          socketData,
        ));
        // Web socket connection close
        wsConnection.close(1000, 'Process Completed');
        console.log(wsConnection.close, 'Found websocket connection close');
        break;
      case webSocketStatusType.ERROR:
        store.dispatch(registrationInfo.actions.setSelectedNotification(
          socketData,
        ));
        break;
      case webSocketStatusType.DENIEDERROR:
        store.dispatch(registrationInfo.actions.setSelectedNotification(
          socketData,
        ));
        break;
      default:
        break;
    }
  };

  wsConnection.onerror = (event) => {
    console.log('Found Error occured', event);
    store.dispatch(fatalError.actions.set(true));
    store.dispatch(navigationHistory.actions.goToInitialDecision());
  };

  wsConnection.onclose = (event) => {
    if (event.code !== 1000 && event.code !== 1005) {
      console.log('Found Websocket connection closed', event);
      store.dispatch(fatalError.actions.set(true));
      store.dispatch(navigationHistory.actions.goToInitialDecision());
    }
  };
};

export default webSocketConnection;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getRegistrationInfoInitialValues = () => ({
  membership: {
    selectedMembershipId: '',
    annual: true,
  },
  enrolledPets: [],
  paymentMethod: null,
  selectedTenureName: '',
  createdMembershipPlans: [],
  addressInformedByCustomer: null,
  ncrIframe: {
    SessionId: null,
    RequestURL: null,
  },
  createdPets: null,
  taxAndDiscountInfo: null,
  onMembershipCreateError: {
    onError: false,
    generalMessage: null,
    errors: [],
  },
  onPetNameRetry: {
    onError: false,
    message: null,
  },
  selectedWSConnectionId: '',
  listenedNotification: {

  },
  onMembershipPaymentMethodError: { occurred: false, message: null, statusCode: '' },
  onMembershipPaymentAuthCC: { isTotalPrice: false, authCCResponse: {} },
});

// FYI fetchAllDiscounts and fetchAllTaxes are storing into the same part of this info
// They are both putting that data into key taxAndDiscountInfoi j
// The responses are different but there is a shared subset of the API response we use
// Those keys are subtotal, tax, total, discoutns and petCosts. The rest are not guaranteed to work if accessed
const registrationInfo = createSlice({
  name: 'registrationInfo',
  initialState: getRegistrationInfoInitialValues(),
  reducers: {
    setOnMembershipCreateError: (state, action) => {
      state.onMembershipCreateError = action.payload;
    },
    setSelectedMembership: (state, action) => {
      state.membership.selectedMembershipId = action.payload;
    },
    setAnnual: (state, action) => {
      state.membership.annual = action.payload;
    },
    setCreatedPets: (state, action) => {
      // need to think of a better name
      state.createdPets = action.payload;
    },
    setSelectedBillingAddres: (state, action) => {
      state.paymentMethod.address = action.payload;
    },
    setSelectedPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setSelectedTenureName: (state, action) => {
      state.selectedTenureName = action.payload;
    },
    setCreatedMembershipPlans: (state, action) => {
      state.createdMembershipPlans = action.payload;
    },
    setAddressInformedByCustomer: (state, action) => {
      state.addressInformedByCustomer = action.payload;
    },
    setNCRIframe: (state, action) => {
      state.ncrIframe = action.payload;
    },
    setSelectedPetsToEnroll: (state, action) => {
      state.enrolledPets = action.payload;
    },
    setTaxAndDiscountInfo: (state, action) => {
      state.taxAndDiscountInfo = action.payload;
    },
    clearRegistrationInfo: () => getRegistrationInfoInitialValues(),
    setOnPetNameRetry: (state, action) => {
      state.onPetNameRetry.onError = action.payload;
    },
    setPetNameErrorMessage: (state, action) => {
      state.onPetNameRetry.message = action.payload;
    },
    setSellScreenDetails: (state, action) => {
      state.sellScreenDetails = action.payload;
    },
    setSelectedWSConnectionId: (state, action) => {
      state.selectedWSConnectionId = action.payload;
    },
    setSelectedNotification: (state, action) => {
      state.listenedNotification = action.payload;
    },
    clearSelectedNotification: (state, action) => {
      state.listenedNotification = action.payload;
    },
    setOnMembershipPaymentMethodError: (state, action) => {
      state.onMembershipPaymentMethodError = action.payload;
    },
    clearOnMembershipPaymentMethodError: (state, action) => {
      state.onMembershipPaymentMethodError = action.payload;
    },
    setOnMembershipPaymentAuthCC: (state, action) => {
      state.onMembershipPaymentAuthCC = action.payload;
    },
    clearOnMembershipPaymentAuthCC: (state, action) => {
      state.onMembershipPaymentAuthCC = action.payload;
    },
  },
});

export default registrationInfo;

import { createSlice } from '@reduxjs/toolkit';

const loadingCount = createSlice({
  name: 'loadingCount',
  initialState: 0,
  reducers: {
    increment: (state) => state + 1,
    decrement: (state) => state - 1,
  },
});

export default loadingCount;

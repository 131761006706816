/* https://github.com/jaredpalmer/formik-effect/issues/4 */

import React, { useRef, useEffect } from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';

const Effect = ({ formik, onChange }) => {
  const previousFormikInstance = useRef(formik);

  useEffect(() => {
    if (previousFormikInstance.current === formik) return;

    onChange(previousFormikInstance.current, formik);

    previousFormikInstance.current = formik;
  }, [formik, onChange]);

  return <></>;
};

Effect.propTypes = {
  onChange: PropTypes.func,
  formik: PropTypes.shape,
};

Effect.defaultProps = {
  onChange: PropTypes.func,
  formik: {},
};

export default connect(Effect);

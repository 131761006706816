/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { affiliateReferralCode, navigationHistory } from 'store/slices';
import {
  formatDateRange, formatToTitleCase, sendMembershipSignUpAnalyticsData, trackEvent, getAgeInString, getMonth, getYear, getDay,
} from 'utils';
import { Button } from 'components-v2/shared/Button';
import Icon from 'components-v2/shared/Icon/Icon';
import { Title, Text } from 'components-v2/shared/Elements';
import { getPurchaseSuccessViewModel } from './viewModel';
import { adobeAnalyticsTags } from 'utils/constGlobal';
import './purchase-success.css';

const PurchaseSuccess = ({
  membershipTotalPrice,
  membershipTotalPriceWithoutTax,
  totalTax,
  analyticsDataObject,
  paymentTenure,
  toggles,
  uiText,
  enrolledPets,
}) => {
  const viewModel = useSelector((state) => getPurchaseSuccessViewModel(state));
  const dispatch = useDispatch();
  const {
    palsId, createdMembershipPlans, createdMembershipPlansIds, petInfo,
  } = viewModel;
  const [firstCreatedMembershipPlan] = createdMembershipPlans;
  const totalValue = createdMembershipPlans.reduce((acc, { priceAmount }) => acc + priceAmount, 0);
  const { transactionId } = firstCreatedMembershipPlan;
  const [fireEventMessage, setFireEventMessage] = useState(false);


  const adobeTrackerForSuccessScreenV2 = () => {
    const successTag = adobeAnalyticsTags?.SUCCESS_SCREEN_V2;
    successTag.serviceDetails.totalTransaction = membershipTotalPrice;
    successTag.serviceDetails.orderTaxAmount = totalTax?.toFixed(2);
    const successTagPetInfoTag = [];
    successTag.petInfo.splice(0, successTag.petInfo.length);
    enrolledPets?.map((pet) => successTagPetInfoTag.push({
      petName: pet?.name,
      orignalPrice: pet?.petCost?.preDiscountPrice,
      listPrice: pet?.petCost?.subtotal,
    }));
    successTag.petInfo = successTagPetInfoTag;
    trackEvent(successTag);
  };

  useEffect(() => {
    if (!fireEventMessage) {
      adobeTrackerForSuccessScreenV2();
      const orderConfirmationTag = adobeAnalyticsTags?.ORDER_CONFIRMATION_SCREEN_V2;
      const orderPetInfoTag = [];
      createdMembershipPlans.map((plan) => orderPetInfoTag.push({
        petName: petInfo[plan.pet].name,
        vitalcareId: plan.id,
        petSpecies: petInfo[plan.pet].petType,
        petBreed: petInfo[plan.pet].petBreed,
        petAge: {
          year: getYear(petInfo[plan.pet].birthdate),
          month: getMonth(petInfo[plan.pet].birthdate),
          day: getDay(petInfo[plan.pet].birthdate),
          age: getAgeInString(petInfo[plan.pet].birthdate),
        },
      }));

      orderConfirmationTag.petInfo = orderPetInfoTag;
      orderConfirmationTag.serviceDetails.orderId = transactionId || '';
      orderConfirmationTag.serviceDetails.orderStatus = 'success';
      orderConfirmationTag.serviceDetails.totalTransaction = membershipTotalPrice;
      orderConfirmationTag.serviceDetails.orderTaxAmount = totalTax?.toFixed(2);
      orderConfirmationTag.page.buildVersion = toggles?.FRONTEND_VERSION;
      setFireEventMessage(true);
      trackEvent(orderConfirmationTag);
    }
    dispatch(navigationHistory.actions.purgeRegistrationExceptCompletion());
    dispatch(affiliateReferralCode.actions.reset());
    window.parent.postMessage({ action: 'triggerMedallia' }, '*');
  }, [dispatch, membershipTotalPriceWithoutTax, totalTax, membershipTotalPrice, totalValue, transactionId, JSON.stringify(createdMembershipPlans), JSON.stringify(petInfo)]);

  const returnToDashboard = () => {
    if (viewModel.dashboardInHistory) {
      dispatch(navigationHistory.actions.goBackToRedemptionDashboard());
    } else {
      dispatch(navigationHistory.actions.goToRedemptionDashboard());
    }
  };

  const IconOfpet = (petType) => {
    if (petType === 'Dog') {
      return <Icon className="icon-dog" name="dog" />;
    } else if (petType === 'Cat') {
      return <Icon className="icon-cat" name="cat" />;
    } else if (petType === 'Fish') {
      return <Icon className="icon-fish" name="pet-selection-fish" />;
    } else if (petType === 'Bird') {
      return <Icon className="icon-bird" name="pet-selection-bird" />;
    } else if (petType === 'Reptile') {
      return <Icon className="icon-reptile" name="pet-selection-reptile" />;
    } else if (petType === 'Small Animal') {
      return <Icon className="icon-small-animal" name="pet-selection-small-pet" />;
    }
  };

  return (
    <div className="PurchaseSuccess-v2">
      <div className="Container Container--larger">
        {toggles?.ENABLE_UNIFIED_MEMBERSHIP
          ? (
            <Title className="main-title">
              <Icon name="successCheck-UM" alt="Success Icon" />
              {uiText?.purchaseSuccessHeading1}
            </Title>
          )
          : (
            <Title className="main-title">
              <Icon name="successCheck" alt="Success Icon" />
              {uiText?.purchaseSuccessHeading2}
            </Title>
          )}

        <Text className="main-intro">
          {uiText?.purchaseSuccessSubHeading}
        </Text>
        <div className={createdMembershipPlans.length === 1 ? 'CardPlan__List CardPlan__List--oneitem' : 'CardPlan__List'}>
          {createdMembershipPlans.map((plan) => (
            <div key={plan.pet} className="CardPlan pro-care">
              <div className="CardPlan__Body">
                <div className="CardPlan__PetInfo">
                  <div className="pet-name">
                    {IconOfpet(petInfo[plan.pet].petType)}
                    <Title>{petInfo[plan.pet].name}</Title>
                  </div>
                  {toggles?.ENABLE_UNIFIED_MEMBERSHIP
                    ? (
                      <Icon
                        name="petcoVitalCareUMLogo"
                        className="petcoVitalCareLogo um"
                      />
                    )
                    : (
                      <Icon
                        name="petcoVitalCareLogo"
                        className="petcoVitalCareLogo"
                      />
                    )}

                </div>
                <div className="CardPlan__ListItem" key={plan.id}>
                  <span>
                    {uiText?.cardAnnualCommitment} {toggles?.ANNUAL_PAID_UPFRONT_ONLINE ? formatToTitleCase(paymentTenure) : 'Monthly'}
                  </span>
                  <span data-testid="policy-number">
                    {`${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? `${uiText?.purchaseSuccessVcpUm}` : `${uiText?.purchaseSuccessVcpNonUm}`}`} {uiText?.purchaseSuccessPlanId}: {plan.id}
                  </span>
                  <span data-testid="plan-effective-date">
                    {uiText?.purchasePlanEffectiveDate}: {formatDateRange(plan.createDt, plan.endDate)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Button
          className="view-dashboard-button"
          data-testid="return-to-dashboard-button"
          label={`${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? `${uiText?.viewDashboardUmCta}` : `${uiText?.viewDashboardNonUmCta}`}`}
          onClick={() => returnToDashboard()}
        />
        {toggles?.ANNUAL_PAID_UPFRONT_ONLINE && (
          <div className="footer-text" dangerouslySetInnerHTML={{ __html: uiText?.californiaResidentsMsg }} />
        )}
      </div>
    </div>
  );
};

export default PurchaseSuccess;

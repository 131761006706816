import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { fetchNCRIframe, resetNCRIframe, createWallet } from 'store/actions';
import { globalConsts } from 'utils/constGlobal';
import viewModel from './viewModel';
import './ncr-frame.css';

const NCRFrame = ({ dismissNCRFrame, onCreditCardAddition, customerInfo }) => {
  const dispatch = useDispatch();
  const { ncrIframe: { SessionId, RequestURL } } = useSelector(viewModel);
  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));
  const [iFrameId, setiFrameId] = useState('add-card-iframe');
  const [showMessage, setShowMessage] = useState(false);

  // create a session string once, to identify that the NCRFrame component that's built on the screen pertains to a certain
  // enrollment run.
  // Later on when the customer taps the add new payment button we can use that constant to track to which run it belongs.
  const CUSTOMER_SESSION_GUID = Math.random().toString(36).substring(2, 15)
    + Math.random().toString(36).substring(2, 15);

  const sendDataToSentry = (message, event, walletId) => {
    Sentry.withScope((scope) => {
      const data = {
        SessionId, RequestURL, eventData: event ? event.data : undefined, walletId,
      };
      scope.setTag('ncr_session_guid', CUSTOMER_SESSION_GUID);
      // make it easy to find data on logs by looking at these tags
      scope.setTag('customer_email', customerInfo.email);
      scope.setTag('customer_id', customerInfo.id);

      scope.setExtras(data);
      Sentry.captureMessage(message);
    });
  };

  useEffect(() => {
    // when the user clicks on add new payment
    setTimeout(() => {
      setShowMessage(true);
    }, 30000);

    if (SessionId && RequestURL) {
      sendDataToSentry('NCR IFrame event message received');
    }
  }, [SessionId, RequestURL]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onNCRStatusCodeReceived = async (e) => {
      const SUCCESS_STATUS_CODE = '100';
      sendDataToSentry('General onNCRStatusCodeReceived', e);

      try {
        const { statusCode, sessionId } = JSON.parse(e.data);
        if (sessionId) {
          // log when the user clicked on done button within NCR iframe
          sendDataToSentry('Customer clicked on NCR done or cancel button, received sessionId from NCR IFrame', e);

          const isCreditCardCreated = statusCode === SUCCESS_STATUS_CODE;
          if (isCreditCardCreated) {
            await dispatch(createWallet(sessionId, (walletId) => {
              // explicitly logs if NCR responded with 100 status even though no wallet
              // was created.
              if (walletId) {
                sendDataToSentry('Under success on NCR IFrame card addition', e, walletId);
              } else {
                sendDataToSentry('NCR status code response 100 but without created wallet', e, walletId);
              }
              onCreditCardAddition(isCreditCardCreated, walletId);
            }));
          } else {
            alert(`Unable to add a new card to your wallet. Please try again later. Status code: ${statusCode}`);
            // we don't see a single result of this message in production as of Feb, 14, 2022. In QA we do,
            // meaning that this event is being catched but it seems to be extremely rare in production to
            // happen(no users clicking on cancel or no response from NCR with a status different from 100).
            sendDataToSentry(`Invalid status code from NCR: ${statusCode}`);
            onCreditCardAddition(isCreditCardCreated, null);
          }
          dispatch(resetNCRIframe());
        }
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setExtras(
            {
              eventData: e.data,
              errorMessage: `NCR CC addition failed for Customer session guid:
                ${CUSTOMER_SESSION_GUID}, SessionId: ${SessionId}, RequestURL: ${RequestURL}
              `,
            },
          );
          Sentry.captureException(error);
        });

        sendDataToSentry('Under catch error on NCRFrame', error, null);
        dismissNCRFrame();
      }
    };

    dispatch(fetchNCRIframe());
    window.addEventListener('message', onNCRStatusCodeReceived, false);
    return () => window.removeEventListener('message', onNCRStatusCodeReceived, false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMobile) {
      setiFrameId('add-card-iframe-mobile');
    } else {
      setiFrameId('add-card-iframe');
    }
  }, [isMobile, setiFrameId]);

  return (
    <div className="NRCFrameWrap">
      <iframe
        className="NRCFrame"
        id={iFrameId}
        title="New credit card"
        src={RequestURL}
      />
      {showMessage && isMobile && (
        <p className="NCR-help-text">
          Are you having issues adding payment? If so, please try adding your
          card to your wallet in the Account section then come back to the
          Vital Care section to finish sign up.
        </p>
      )}
    </div>
  );
};

export default NCRFrame;

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import CreditCardComboBox from '../../CreditCardSelectionNonModal/CreditCardComboBox';
import { customer } from 'store/slices';
import viewModel from '../../CreditCardSelectionNonModal/viewModel';
import * as Yup from 'yup';
import Error from '../../Error';
import { Button } from 'components-v2/shared/Button';
import { PaymentMethod } from '../../CreditCardSelectionNonModal/models/types';
import Icon from 'components-v2/shared/Icon/Icon';

interface SelectPaymentMethodComboBoxProps {
  paymentMethodUpdateErrorInfo: any;
  id?: string;
  name?: string;
  paymentMethods: PaymentMethod[];
  setShowAddNewForm: (showAddNewForm: boolean) => void;
  onShowMobileAddNewForm: () => void;
  onSelect: (paymentMethod: string) => void;
  onClose: () => void;
  selectOnChange: any;
  trackEvent: (paymentInfo: any) => void;
  adobeAnalyticsTags: any;
}


const SelectPaymentMethodComboBox = ({
  paymentMethodUpdateErrorInfo, id = 'paymentMethod',
  name = 'paymentMethod', paymentMethods, setShowAddNewForm, onShowMobileAddNewForm, onSelect, onClose, trackEvent, adobeAnalyticsTags, selectOnChange,
}: SelectPaymentMethodComboBoxProps) => {
  const dispatch = useDispatch();

  const {
    paymentMethodsError,
    isMobile,
    useNativeWallet,
    useAciPayment,
  } = useSelector(viewModel);

  return (
    <div className="SelectPaymentMethod">
      {paymentMethodUpdateErrorInfo?.errorOccurred ? (<span className="activationErrorMessage flashMessage"><span><Icon name="errorIcon" /></span>{paymentMethodUpdateErrorInfo?.modalTitle}</span>) : ''}
      {/* @ts-ignore */}
      <Formik
        initialValues={{ paymentMethod: '' }}
        validateOnMount
        validationSchema={Yup.object().shape({ paymentMethod: Yup.string().required('Required') })}
        enableReinitialize
      >
        {(props) => (
          <div>
            <Form>
              <CreditCardComboBox
                id={id}
                name={name}
                paymentMethods={paymentMethods}
                onSelectAddNewCard={() => {
                  if (isMobile && useNativeWallet && !useAciPayment) {
                    onShowMobileAddNewForm();
                  } else {
                    setShowAddNewForm(true);
                  }
                }}
                onChange={async (nameParam: any, value: any, paymentInfo: any) => {
                  dispatch(customer.actions.resetPaymentMethodError(null));
                  await props.setFieldValue(nameParam, value);
                  // @ts-ignore
                  await props.handleChange(nameParam, value);
                  await props.setFieldTouched(nameParam);
                  dispatch(customer.actions.setSelectedPayment(value));
                  if (selectOnChange && props.values.paymentMethod) {
                    onSelect(props.values.paymentMethod);
                    trackEvent(paymentInfo);
                  }
                }}
              />
              {props.errors.paymentMethod && props.touched.paymentMethod && (
                <Error name="paymentMethod">{props.errors}</Error>
              )}
              {
                props.errors.paymentMethod && props.touched.paymentMethod && props.errors !== undefined && trackEvent(adobeAnalyticsTags?.NEW_CARD_INFO_FAIL_V2)
              }
              {paymentMethodsError && (<small className="error">{paymentMethodsError}</small>) && trackEvent(adobeAnalyticsTags?.NEW_CARD_INFO_FAIL_V2)}


              {(!selectOnChange || isMobile) && (
                <Button
                  onClick={() => onSelect(props.values.paymentMethod)}
                  disabled={!props.isValid || Object.keys(props.errors).length > 0}
                  className={!props.isValid || Object.keys(props.errors).length > 0 ? '' : 'active-button'}
                >
                  Select Payment
                </Button>
              )}
              {onClose && (
                <Button
                  className="secondary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              )}
            </Form>

          </div>

        )}
      </Formik>
    </div>
  );
};

SelectPaymentMethodComboBox.defaultProps = {
  id: 'paymentMethod',
  name: 'paymentMethod',
};
export default SelectPaymentMethodComboBox;

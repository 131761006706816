import { createSlice } from '@reduxjs/toolkit';

export const authError = createSlice({
  name: 'authError',
  initialState: false,
  reducers: {
    setTrue: () => true,
  },
});

export default authError;

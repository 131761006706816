import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import PetListGrid from 'components-v2/shared/PetListGrid';
import Container from 'components-v2/shared/Container';
import { adobeAnalyticsTags, StatusPlan } from 'utils/constGlobal';

import './dashboard-header.css';
import { DEEPLINK_INDEX, deepLink, navigationHistory } from 'store/slices';
import { useDispatch } from 'react-redux';
import Icon from 'components-v2/shared/Icon/Icon';
import { trackEvent } from 'utils';
import { Button } from 'react-bootstrap';

function DashboardHeader({
  plansWithPets,
  selectedPlanWithPet,
  choosePet,
  planStatus,
  goToMembershipDetails,
  toggles,
  uiText,
  switchMembershipSuccessMessage,
  customerInfo,
  screenName,
}) {
  const dispatch = useDispatch();
  const [validPCCs, setValidPCCs] = useState([]);
  const [roverBenefit, setRoverBenefit] = useState([]);
  const THIRD_PARTY_CATEGORY_NAME = 'Your Third Party Benefits';

  const checkRoverTextInStorage = localStorage.getItem('roverBenefitSuccessMessage');
  const checkHideRoverBenefitForPlans = JSON.parse(localStorage.getItem('hideRoverBenefitForPlans'));

  // using this as a trick to update/re-render component tree once a user has viewed and interacted with the roverBanner
  // so that instantly the banner gets hidden for specific plan.
  const [_, forceUpdate] = useReducer(x => x + 1, 0);

  const checkPlanStatus = () => {
    if ((planStatus === StatusPlan.EXPIRED) || (selectedPlanWithPet?.plan?.preAuthFailed)) {
      return true;
    } else {
      return false;
    }
  };

  const handleRedeemBenefits = () => {
    handleBannerClose();
    trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_HERE_HYPERLINK_ON_DASHBOARD_PAGE_WITH_ROVER_BANNER);
    dispatch(navigationHistory.actions.goToRedeemBenefits());
  };

  const handleUpdatePayment = () => {
    if (toggles?.ENABLE_FIRST_GRACE_PERIOD_UI && planStatus === StatusPlan.EXPIRED) {
      dispatch(deepLink.actions.setDeepLinkDestination(DEEPLINK_INDEX.RENEW_MEMBERSHIP_PLAN.key));
      trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_UPDATE_YOUR_CARD_LINK_DASHBOARD_FIRST_GRACE_PERIOD);
    } else {
      trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_UPDATE_PAYMENT_METHOD);
      dispatch(deepLink.actions.setDeepLinkDestination(DEEPLINK_INDEX.EDIT_PAYMENT_METHOD.key));
    }
    goToMembershipDetails();
  };

  const handleReactivatePlan = () => {
    trackEvent(adobeAnalyticsTags?.USER_CLICK_REACTIVATE_YOUR_PLAN);
    dispatch(deepLink.actions.setDeepLinkDestination(DEEPLINK_INDEX.REACTIVATE_MEMBERSHIP_PLAN.key));
    goToMembershipDetails();
  };

  const calcCanceledString = (str) => {
    if (str) {
      const linkSplit = str.split('</link>');
      return linkSplit.map(msg => {
        const [text, link] = msg.split('<link>');
        return (
          <span key={msg}>{text}
            {
              link
                ? <button onClick={handleReactivatePlan} className="plan-status-button">{link}</button> : null
            }
          </span>
        );
      });
    }
  };

  const calcExpiredString = (str) => {
    if (str) {
      const linkSplit = str.split('</link>');
      return linkSplit.map(msg => {
        const [text, link] = msg.split('<link>');
        return (
          <span key={msg}>{text}
            {
              link
                ? <button onClick={handleUpdatePayment} className="plan-status-button">{link}</button> : null
            }
          </span>
        );
      });
    }
  };

  const calcSwitchSuccessString = (str) => {
    if (str) {
      const linkSplit = str.split('<br/>');
      const boldText = linkSplit[1].split('<b>');
      const boldTextData = <b>{boldText[1]}</b>;
      return <span key={linkSplit}>{linkSplit[0]} <br /> {boldText[0]}{boldTextData}{boldText[2]}</span>;
    }
  };

  useEffect(() => {
    if (customerInfo && toggles?.PILOT_PCC) {
      const pccArr = toggles?.PILOT_PCC.split(',')?.map((str) => str?.trim()); // used  trim as an edge case scenario to remove whitespaces from the string if any.
      setValidPCCs(pccArr);
    }
  }, [customerInfo, toggles?.PILOT_PCC]);

  const checkShowSwitchNowSuccessBanner = () => {
    if (validPCCs?.includes(customerInfo?.primaryPCC)) {
      return true;
    } else if (toggles?.ANNUAL_PAID_UPFRONT_ONLINE && toggles?.SWITCH_NOW_BANNER) {
      return true;
    } else {
      return false;
    }
  };

  const checkForRedeemedThirdPartyBenefit = useMemo(() => {
    for (let i = 0; i < roverBenefit.length; i++) {
      if (roverBenefit[i].isRedeemable && roverBenefit[i].redeemedCount === 0) {
        return true;
      }
    }
  }, [roverBenefit]);

  const checkRoverBenefitStatus = useMemo(() => {
    if (toggles?.ENABLE_ROVER_BANNER && (planStatus !== StatusPlan.CANCELED || selectedPlanWithPet?.plan?.preAuthFailed) && (selectedPlanWithPet?.pet?.petType === 'Dog' || selectedPlanWithPet?.pet?.petType === 'Cat') && checkForRedeemedThirdPartyBenefit && (checkRoverTextInStorage === null || checkRoverTextInStorage !== undefined) && (checkHideRoverBenefitForPlans === null || !checkHideRoverBenefitForPlans?.includes(selectedPlanWithPet?.plan?.id)) && screenName !== 'hide') {
      localStorage.setItem('roverBenefitSuccessMessage', uiText?.roverBannerMessage);
      return true;
    } else {
      return false;
    }
  }, [toggles?.ENABLE_ROVER_BANNER, planStatus, selectedPlanWithPet?.plan?.preAuthFailed, selectedPlanWithPet?.pet?.petType, roverBenefit[0]?.isRedeemable, roverBenefit[0]?.redeemedCount, checkRoverTextInStorage, checkHideRoverBenefitForPlans, selectedPlanWithPet?.plan?.id, screenName]);

  const roverBenefitBannerString = (str) => {
    if (str) {
      const linkSplit = str.split('</link>');
      return linkSplit.map(msg => {
        const [text, link] = msg.split('<link>');
        return (
          <span key={msg}>{text}
            {
              link
                ? <button onClick={handleRedeemBenefits} className="plan-status-button">{link}</button> : null
            }
          </span>
        );
      });
    }
  };

  const handleBannerClose = useCallback(() => {
    trackEvent(adobeAnalyticsTags?.USER_CLICKS_CLOSE_ICON_ON_DASHBOARD_PAGE_WITH_ROVER_BANNER);
    let plansArray = checkHideRoverBenefitForPlans || [];
    plansArray = [...plansArray, selectedPlanWithPet?.plan?.id];
    localStorage.setItem('hideRoverBenefitForPlans', JSON.stringify(plansArray));
    forceUpdate();
  }, [selectedPlanWithPet?.plan?.id, checkHideRoverBenefitForPlans]);

  useEffect(() => {
    if ((selectedPlanWithPet?.pet?.petType === 'Dog' || selectedPlanWithPet?.pet?.petType === 'Cat')) {
      const { membershipBenefits } = selectedPlanWithPet.plan || {};
      const filteredRoverBenefit = membershipBenefits.filter((benefit) => benefit.categoryName === THIRD_PARTY_CATEGORY_NAME);
      setRoverBenefit(filteredRoverBenefit);
    }
  }, [selectedPlanWithPet?.pet, selectedPlanWithPet?.pet?.petType, selectedPlanWithPet?.plan, selectedPlanWithPet?.plan?.id]);

  useEffect(() => {
    if (checkRoverTextInStorage && checkRoverTextInStorage !== uiText?.roverBannerMessage) {
      localStorage.removeItem('hideRoverBenefitForPlans');
    }
  }, []);

  return (
    <div className="DashboardHeader-v2">
      <Container>
        {plansWithPets.length > 0
          && (
            <PetListGrid
              plansWithPets={plansWithPets}
              selectedPlanWithPet={selectedPlanWithPet}
              choosePet={choosePet}
              toggles={toggles}
            />
          )}

        {toggles?.ENABLE_FIRST_GRACE_PERIOD_UI ? (
          checkPlanStatus()
          && (
            <div className="expiredErrorMessage flashMessage" data-testid="expiredErrorMessage">
              <span><Icon name="errorIcon" /></span>
              <span>
                {calcExpiredString(selectedPlanWithPet?.plan?.paymentUpdateErrorMessage || uiText?.paymentUpdateErrorMessage)}
              </span>
            </div>
          )
        ) : (
          checkPlanStatus()
          && (
            <p className="activationMessage" data-testid="activationMessage">
              Your pet's plan is tied to a payment method that is not working.
              Please <button className="change-plan-status-button" onClick={handleUpdatePayment}>update your card</button>.
            </p>
          ))}

        {toggles?.ENABLE_REACTIVATION_UI ? (
          planStatus === StatusPlan.CANCELED
          && (
            <div className="activationErrorMessage flashMessage" data-testid="activationErrorMessage">
              <span><Icon name="errorIcon" /></span>
              <span>
                {calcCanceledString(selectedPlanWithPet?.plan?.planCancelErrorMessage)}
              </span>
            </div>
          )
        ) : (
          planStatus === StatusPlan.CANCELED
          && (
            <p className="activationMessage" data-testid="activationMessage">
              Your pet's plan is canceled, and their benefits are
              no longer accessible.
              Please click <button onClick={handleReactivatePlan} className="change-plan-status-button">here</button> to reactivate your plan.
            </p>
          )
        )}

        {checkShowSwitchNowSuccessBanner && (
          switchMembershipSuccessMessage && (
            <div className="successMessage flashMessage">
              <span><Icon name="successMessageCheckMedium" /></span>
              {calcSwitchSuccessString(switchMembershipSuccessMessage)}
            </div>
          )
        )}

        {checkRoverBenefitStatus && (
          <div className="roverBannerContainer" data-testid="roverBannerMessage">
            <div className="rovertxtDescription">
              <span>{roverBenefitBannerString(uiText?.roverBannerMessage)}</span>
            </div>
            <div className="closeBtnContainer">
              <button
                className="CloseButton"
                tabIndex="0"
                aria-label="Close"
                onClick={e => handleBannerClose(true)}
              >
                <Icon name="roverClose" />
              </button>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default DashboardHeader;

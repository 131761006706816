import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { fetchStoreLocations } from 'store/actions';

import { trackEvent, handleKeyUp, sendMembershipSignUpAnalyticsData } from 'utils';
import Logger from 'utils/logger';
import { removeNonAlphaNumeric, validateZipCode } from 'utils/TextUtils';

import { Button } from 'components-v2/shared/Button';
import { Title, Text, Input } from 'components-v2/shared/Elements';
import Icon from 'components-v2/shared/Icon/Icon';
import { adobeAnalyticsTags } from 'utils/constGlobal';

import './store-locator.css';

import LinesEllipsis from 'react-lines-ellipsis';

const PAGINATION_PAGE_SIZE = 5;
const TELL_US_MESSAGE = 'Please tell us which store helped you learn about Vital Care.';
const TELL_US_MESSAGE_UM = 'Please tell us which store helped you learn about Vital Care Premier.';
const COULD_NOT_FIND_LOCATION_MESSAGE = 'Could not find location, please edit zip code to try a different search.';
const ERROR_MESSAGE_RESPONSE = "We're having trouble finding stores near you. Please skip this step.";

const LOGGER = Logger.getLogger('StoreLocator');
let lastIndexOfDisplayedStore = 0;

const StoreLocator = ({
  yourStoreIndex,
  showStep,
  stepNames,
  expandedSection,
  enrolledPets,
  stepsChecked,
  selectedStore,
  setSelectedStore,
  completeStep,
  ENABLE_UNIFIED_MEMBERSHIP,
}) => {
  const dispatch = useDispatch();
  const [storeList, setStoreList] = useState([]);
  const [storeListPage, setStoreListPage] = useState(1);
  const [storeNotFoundMessage, setStoreNotFoundMessage] = useState('');
  const [lastSearchedZipCode, setLastSearchedZipCode] = useState('');

  const buildResponse = (response) => {
    // Next is logic to loop trough the array and return only duplicate values and display the proper info
    const duplicatedStores = response
      .map(store => store.title)
      .filter((value, index, arr) => arr.indexOf(value) !== index);

    const enhancedStoresData = response.map((store) => {
      const {
        address: {
          street1, street2, city, state, zip,
        }, title,
      } = store;
      if (duplicatedStores.includes(title)) {
        const addressToDisplay = street2 ? `${street1}, ${street2}, ${city}, ${state}, ${zip}` : `${street1}, ${city}, ${state}, ${zip}`;
        return {
          ...store,
          distinguishedAddress: addressToDisplay,
        };
      } else {
        return store;
      }
    });
    return enhancedStoresData;
  };

  const checkSuccessResponse = (response, zipCode) => {
    try {
      if (Array.isArray(response) && response.length === 0) {
        setStoreNotFoundMessage(COULD_NOT_FIND_LOCATION_MESSAGE);
        setLastSearchedZipCode('');
        LOGGER.info(`No location found for zipcode [${zipCode}]`);
      }
      return buildResponse(response);
    } catch (error) {
      LOGGER.error(`Error checking response for zipcode [${zipCode}]`, error);
    }
  };

  const completeStoreSelectionStep = () => {
    completeStep(yourStoreIndex);
  };

  const errorHandler = (response, zipCode) => {
    LOGGER.info(`Error trying get location from zipcode[${zipCode}]`, response);
    setStoreNotFoundMessage(ERROR_MESSAGE_RESPONSE);
    setStoreList([]);
    setLastSearchedZipCode('');
  };

  const processResponse = (response, zipCode) => {
    if (!response || response.errors) {
      LOGGER.info(`Error trying get location from zipcode[${zipCode}]`, response && response.errors);
      setStoreNotFoundMessage(ERROR_MESSAGE_RESPONSE);
      setStoreList([]);
      setLastSearchedZipCode('');
    } else {
      const result = checkSuccessResponse(response, zipCode);
      setStoreList(result);
    }
  };

  const fetchStores = async (zipCode) => {
    setStoreNotFoundMessage(null);
    const response = await dispatch(fetchStoreLocations(zipCode, errorHandler));
    setLastSearchedZipCode(zipCode);
    processResponse(response, zipCode);
    trackEvent(adobeAnalyticsTags?.SEARCHED_STORE_V2);
  };

  const handleSubmit = async (values) => {
    const cleanZip = removeNonAlphaNumeric(values.zipCode);
    fetchStores(cleanZip);
  };

  const storeListDisplayed = useMemo(() => {
    try {
      const filterItemsToBeDisplayed = () => {
        const criteria = (index) => index < PAGINATION_PAGE_SIZE * storeListPage;
        return storeList.filter((_, index) => criteria(index));
      };

      lastIndexOfDisplayedStore = filterItemsToBeDisplayed().length - 1;
      return filterItemsToBeDisplayed();
    } catch (error) {
      LOGGER.error('Unable to filter storeList', error);
      return [];
    }
  }, [storeList, storeListPage, PAGINATION_PAGE_SIZE]);

  let firstElement;
  useEffect(() => {
    firstElement = document.getElementById(`Store-${lastIndexOfDisplayedStore}`);
  }, [lastIndexOfDisplayedStore]);

  return (
    <div className="StoreLocator-v2">
      <div className="Step-v2">
        <div
          className="Step__Header-v2"
          role="button"
          aria-expanded={expandedSection === yourStoreIndex ? 'true' : 'false'}
          tabIndex="0"
          onClick={() => { showStep(yourStoreIndex, expandedSection !== yourStoreIndex && enrolledPets.length > 0); trackEvent(adobeAnalyticsTags?.EDIT_SEARCHED_STORE_V2); }}
          onKeyUp={
            (e) => {
              handleKeyUp(e, () => {
                showStep(yourStoreIndex, expandedSection !== yourStoreIndex && enrolledPets.length > 0);
                trackEvent(adobeAnalyticsTags?.EDIT_SEARCHED_STORE_V2);
              });
            }
          }
        >
          <Title as="h3" className="no-padding">3. Your Store</Title>
          <Text id="optional" className="optional">Optional</Text>
          {stepsChecked[yourStoreIndex] && expandedSection !== yourStoreIndex
            && <div className="Step__Edit-v2">Edit</div>}
        </div>

        {expandedSection === yourStoreIndex && (
          <div className="Step__Content-v2">
            <div>
              <Formik
                initialValues={{ zipCode: selectedStore?.title || '' }}
                validateOnMount
                validationSchema={Yup.object().shape({
                  zipCode: Yup.string().length(5).required('Required'),
                })}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ setFieldTouched, setFieldValue }) => (
                  <Form>
                    <div>
                      {ENABLE_UNIFIED_MEMBERSHIP
                        ? <Text className="tell-text">{TELL_US_MESSAGE_UM}</Text>
                        : <Text className="tell-text">{TELL_US_MESSAGE}</Text>}
                      <div className="store-container">
                        <div className="store-card">
                          <div className="store-card-input" role="combobox" aria-haspopup="dialog" aria-expanded={storeList.length ? 'true' : 'false'}>
                            <Input
                              data-testid="zipCode"
                              name="zipCode"
                              placeholder="Enter your zip code"
                              className="zip-code"
                              onChange={async (e) => {
                                const cleanZip = removeNonAlphaNumeric(e.target.value);
                                setFieldTouched('zipCode');
                                setFieldValue('zipCode', cleanZip);

                                if (cleanZip !== lastSearchedZipCode && validateZipCode(cleanZip)) {
                                  setStoreListPage(1);
                                  fetchStores(cleanZip);
                                }
                              }}
                            />
                            <Icon name="icon-dropdown" className="icon-dropdown" size="25" />
                          </div>

                          {storeNotFoundMessage && <Text className="store-not-found-msg">{storeNotFoundMessage}</Text>}
                          {storeNotFoundMessage && <div role="alert" aria-label="We're having trouble finding stores near you. Please skip this step." />}
                          {storeList.length ? (
                            <div className="store-list">
                              {storeListDisplayed.map((store, index) => (
                                <>
                                  {storeList.length && <div role="alert" aria-label={`${storeListDisplayed.length} stores founded`} />}
                                  <div
                                    id={`Store-${index}`}
                                    key={store.id}
                                    className="store-list-item"
                                    role="button"
                                    tabIndex="0"
                                    onKeyUp={(e) => handleKeyUp(e, () => {
                                      setSelectedStore(store);
                                      trackEvent(adobeAnalyticsTags?.SEARCHED_STORE_V2);
                                      completeStoreSelectionStep('Continue to order total');
                                      window.parent.postMessage({ action: 'scrollTop' }, '*');
                                    })}
                                    onClick={() => {
                                      setSelectedStore(store);
                                      trackEvent(adobeAnalyticsTags?.SEARCHED_STORE_V2);
                                      completeStoreSelectionStep('Continue to order total');
                                      window.parent.postMessage({ action: 'scrollTop' }, '*');
                                    }}
                                  >
                                    <h4 className="store-list-title">{store.title}</h4>
                                    {(store.distinguishedAddress || store?.address?.distinguishedAddress) && (
                                      <LinesEllipsis
                                        className="distinguished-address"
                                        text={store.distinguishedAddress || store?.address?.distinguishedAddress}
                                        maxLine="2"
                                        ellipsis="..."
                                      />
                                    )}
                                    <span>{`${store.distance.toFixed(1)} mi`}</span>
                                  </div>
                                </>
                              ))}
                              {storeList.length > storeListDisplayed.length && (
                                <div className="store-list-actions">
                                  <div
                                    role="button"
                                    tabIndex="0"
                                    className="load-more-button"
                                    aria-label="load more stores"
                                    onClick={() => {
                                      setStoreListPage(storeListPage + 1);
                                      firstElement && firstElement.focus();
                                    }}
                                    onKeyUp={(e) => handleKeyUp(e, () => {
                                      setStoreListPage(storeListPage + 1);
                                      firstElement && firstElement.focus();
                                    })}
                                  >
                                    Load More
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : <></>}
                        </div>
                        <Button
                          className="secondary right"
                          type="button"
                          onClick={() => { trackEvent(adobeAnalyticsTags?.SEARCHED_STORE_SKIP_V2); completeStoreSelectionStep('Skip'); }}
                          label="Skip"
                          aria-label="Click here to skip searching for a store by zip code."
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreLocator;

/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import 'seamless/build/seamless.child';
import store from '../store';
import {
  tokenState, url, deepLink, toggles,
} from '../store/slices';

export const tokenCookie = 'PetCo_Membership_UserToken';
export const tokenWCS = 'PetCo_Membership_WCSToken';

export const setToken = ({ key = tokenCookie, value }) => {
  localStorage.setItem(key, value);
};

export const clearToken = (key = tokenCookie) => {
  localStorage.removeItem(key);
};

export const getToken = () => {
  const petcoToken = localStorage.getItem(tokenWCS);
  const authToken = localStorage.getItem(tokenCookie);
  let token = null;

  if (authToken) {
    token = authToken;
  } else if (petcoToken) {
    token = petcoToken;
  }

  return token;
};

let seamlessParent = null;

export function initSeamless() {
  console.log('initSeamless');
  seamlessParent = window.seamless.connect();
  seamlessParent.receive((data) => {
    console.log(data);
    const {
      uiVersion,
      petcoToken = null,
      authToken = null,
      urlLocationSearch = null,
      isRequestFromMobile,
      isRequestFromExternalPetcoLandingPage,
      deepLinkDestination,
      deepLinkPet,
      deepLinkPets,
      deepLinkWalletId,
      mobileFeature,
      companionAnimalFeature,
      apuKey,
      isOBO,
      affiliate,
      threeKey,
    } = data;
    if (petcoToken && petcoToken !== '') {
      setToken({ key: tokenWCS, value: petcoToken });
    }
    if (authToken && authToken !== '') {
      setToken({ key: tokenCookie, value: authToken });
    }

    // being explicit, we always true or false
    localStorage.setItem('Petco_Membership_isRequestFromMobile', isRequestFromMobile);

    if (uiVersion) {
      store.dispatch(url.actions.setUiVersion(uiVersion));
    }

    if (apuKey !== undefined) {
      store.dispatch(url.actions.setApuKey(apuKey));
    }

    if (affiliate !== undefined) {
      store.dispatch(url.actions.setAffiliate(affiliate));
    }

    if (threeKey !== undefined) {
      store.dispatch(url.actions.setThreeKey(threeKey));
    }

    store.dispatch(tokenState.actions.tokenSet());
    store.dispatch(url.actions.setLocationSearch(urlLocationSearch));
    store.dispatch(url.actions.setMobileFeature(mobileFeature));
    store.dispatch(url.actions.setIsRequestFromExternalPetcoLandingPage(isRequestFromExternalPetcoLandingPage));
    store.dispatch(url.actions.setOBO(isOBO));
    store.dispatch(deepLink.actions.setDeepLinkDestination(deepLinkDestination));
    store.dispatch(deepLink.actions.setDeepLinkPetId(deepLinkPet));
    store.dispatch(deepLink.actions.setDeepLinkPetIds(deepLinkPets?.split(',')));
    store.dispatch(deepLink.actions.setDeepLinkWalletId(deepLinkWalletId));
    if (companionAnimalFeature === 'true') {
      store.dispatch(url.actions.setCompanionAnimalFeature(true));
    }
  });
}

export function getSeamlessParent() {
  if (seamlessParent === null) {
    throw new Error('seamless not initialized yet');
  }
  return seamlessParent;
}

import React from 'react';

import { Modal } from 'react-bootstrap';

class ErrorModal extends React.Component {
  componentDidMount() {
    this.pageTopHeading = document.getElementById('modal-title');
    if (this.pageTopHeading !== null) {
      this.pageTopHeading.focus();
    }
  }

  render() {
    const {
      show,
      title,
      message,
      children,
    } = this.props;
    return (
      <Modal id="error-modal" backdrop="static" show={show}>
        <Modal.Header closeButton={false}>
          <div className="error-dog">
            <img src="../../../../img/error-dog.png" alt="" />
          </div>
          <Modal.Title
            tabIndex="0"
            id="modal-title"
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="find-a-location">
            <div className="row">
              <div className="col-12 text-center">
                <p>
                  {message}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {children}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ErrorModal;

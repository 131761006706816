/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

// contains the query string data
export const url = createSlice({
  name: 'url',
  initialState: {
    uiVersion: null,
    locationSearch: window.location.search,
    mobileFeature: '',
    isRequestFromExternalPetcoLandingPage: '',
    apuKey: '',
    affiliate: '',
    threeKey: '',
    isOBO: false,
  },
  reducers: {
    setLocationSearch: (state, action) => {
      state.locationSearch = action.payload;
    },
    setMobileFeature: (state, action) => {
      state.mobileFeature = action.payload;
    },
    setUiVersion: (state, action) => {
      state.uiVersion = action.payload;
    },
    setIsRequestFromExternalPetcoLandingPage: (state, action) => {
      state.isRequestFromExternalPetcoLandingPage = action.payload;
    },
    setCompanionAnimalFeature: (state, action) => {
      state.companionAnimalFeature = action.payload;
    },
    setOBO: (state, action) => {
      state.isOBO = action.payload;
    },
    setApuKey: (state, action) => {
      state.apuKey = action.payload;
    },
    setAffiliate: (state, action) => {
      state.affiliate = action.payload;
    },
    setThreeKey: (state, action) => {
      state.threeKey = action.payload;
    },
  },
});

export const MOBILE_FEATURE_FLAG_INDEX = {
  MOBILE_WALLET: { key: 'wallet' },
};

export const useMobileWalletSelector = createSelector(
  (state) => state.url,
  (urlParam) => urlParam.mobileFeature.includes(MOBILE_FEATURE_FLAG_INDEX.MOBILE_WALLET.key),
);

export default url;

import Icon from 'components-v2/shared/Icon/Icon';
import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import { PaymentMethod } from '../CreditCardSelectionNonModal/models/types';

interface CreditCardRadioRowProps {
  paymentMethod: PaymentMethod;
  selectRowRadioInput: (e: any, setFieldValue: any, setFieldTouched: any, handleChange: any) => void;
  i: number;
}

const CreditCardRadioRow = ({ paymentMethod, selectRowRadioInput, i }: CreditCardRadioRowProps) => {
  const {
    setFieldValue, handleChange, setFieldTouched,
  } = useFormikContext();

  const cardName = useRef<string>();

  const determineCardType = useCallback(() => {
    switch (paymentMethod.brand) {
      case 'Visa':
        cardName.current = 'visaCreditCard';
        break;
      case 'MasterCard':
        cardName.current = 'mastercardCreditCard';
        break;
      case 'AmericanExpress':
        cardName.current = 'amexCreditCard';
        break;
      case 'Discover':
        cardName.current = 'discoverCreditCard';
        break;
      default:
        cardName.current = 'defaultCreditCard';
        break;
    }
  }, [paymentMethod.brand]);

  useEffect(() => {
    determineCardType();
  }, [determineCardType]);

  return (
    <>
      <tr className="radioPaymentTableRow" onClick={(e) => selectRowRadioInput(e, setFieldValue, setFieldTouched, handleChange)}>
        <td className={i !== 0 ? 'radioPaymentTableData' : 'radioPaymentTableDataFirst'}>
          <div className="creditCardIconRow">
            <Field className="radioPaymentTableInput" type="radio" name="paymentMethod" value={paymentMethod.mbrPayinfoId} />
            <Icon name={cardName.current} className="creditCardRadioRowIcon" />
            <div className="creditCardRadioRowText">{` Ending in  ${paymentMethod.lastFour}`}</div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default CreditCardRadioRow;

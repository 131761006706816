import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { createWallet } from 'store/actions';
import { isMobile, trackEvent } from 'utils';
import Icon from '../Icon/Icon';
import { customer } from 'store/slices';
import { adobeAnalyticsTags } from 'utils/constGlobal';


const AciPayment = ({
  onCancel: _onCancel, onCreditCardAddition, errorAddingPayment, setErrorAddingPayment, uiText, inSignUp,
}) => {
  const [onLoading, setOnLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(uiText.declinedPaymentMethodMessageForACI);
  const dispatch = useDispatch();
  window.aci_handlers = {
    onCancel: () => {
      _onCancel();
    },
    onCardAdded: async () => {
      const paymentFrame = document.getElementById('filecontainer');
      const sessionId = paymentFrame.contentWindow.wpwl.checkout.id;
      setErrorAddingPayment(false);
      setTimeout(() => {
        setOnLoading(true);
        dispatch(createWallet(sessionId, (walletId, error) => {
          // explicitly logs if NCR responded with 100 status even though no wallet
          // was created.
          // if (walletId) {
          //   sendDataToSentry('Under success on NCR IFrame card addition', e, walletId);
          // } else {
          //   sendDataToSentry('NCR status code response 100 but without created wallet', e, walletId);
          // }
          if (error) {
            if (inSignUp) {
              adobeAnalyticsTags.USER_ADD_NEW_CARD_DURING_MEMBERSHIP_SIGNUP_ERROR.message.error = error;
              trackEvent(adobeAnalyticsTags.USER_ADD_NEW_CARD_DURING_MEMBERSHIP_SIGNUP_ERROR);
            } else {
              adobeAnalyticsTags.USER_ERROR_WHEN_ADDING_CREDIT_CARD.message.error = error;
              trackEvent(adobeAnalyticsTags.USER_ERROR_WHEN_ADDING_CREDIT_CARD);
            }
            setErrorMessage(error);
            setErrorAddingPayment(true);
            setOnLoading(false);
            return;
          }
          trackEvent(adobeAnalyticsTags.USER_ADD_NEW_CARD_DURING_MEMBERSHIP_SIGNUP_SUCCESS);
          dispatch(customer.actions.clearPaymentMethodError());
          onCreditCardAddition({ created: true, walletId });
        }));
      }, 2000);
    },
  };

  const [isPetcoAciPaymentLoaded, setIsPetcoAciPaymentLoaded] = useState(false);

  // useEffect(() => {
  //   if (!isPetcoAciPaymentLoaded) return ;
  //   // const callbackUrl = '/ncr_return.html';
  //   // const { addressInfo, completeSession } = props;

  //   // Save original href on top window
  //   // window.sessionStorage.setItem('rawUrl', window.top.location.href);

  //   // Clear querystring on URL
  //   // window.history.pushState({}, window.top.document.title, window.top.location.pathname);

  //   // setTimeout(() => {
  //   //     inspectUrl(completeSession, setOnLoading);
  //   // }, 500);
  // }, [isPetcoAciPaymentLoaded]);

  const ACIFormSrc = process.env.REACT_APP_ENV === 'prod' ? '/payment_form_prod.html' : '/payment_form.html';
  const mobileForm = '/payment_form_mobile.html';

  useEffect(() => {
    if (!isPetcoAciPaymentLoaded) return;
    setIsPetcoAciPaymentLoaded(true);
  }, [isPetcoAciPaymentLoaded]);

  return onLoading ? (
    <p>Loading...</p>
  ) : (
    <div>
      {errorAddingPayment ? <p className="activationErrorMessage flashMessage" style={{ marginLeft: 20, marginRight: 20 }}><span><Icon name="errorIcon" /></span>{errorMessage}</p> : null}
      <iframe id="filecontainer" className="aci-payment-frame" title=" " src={isMobile ? mobileForm : ACIFormSrc} sandbox="allow-same-origin allow-scripts allow-popups allow-forms" />
    </div>
  );
};

export default AciPayment;


// Found above -- may need later? No idea what this is.
// const sendDataToSentry = (message, event, walletId) => {
//     Sentry.withScope((scope) => {
//         const data = {
//             SessionId, RequestURL, eventData: event ? event.data : undefined, walletId,
//         };
//         scope.setTag('ncr_session_guid', CUSTOMER_SESSION_GUID);
//         // make it easy to find data on logs by looking at these tags
//         scope.setTag('customer_email', customerInfo.email);
//         scope.setTag('customer_id', customerInfo.id);

//         scope.setExtras(data);
//         Sentry.captureMessage(message);
//     });
// };

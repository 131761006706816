import React, { useEffect, useState } from 'react';
import CardPlan from 'components-v2/shared/CardPlan';
import { Title, Text } from 'components-v2/shared/Elements';
import PetListGrid from 'components-v2/shared/PetListGrid';
import { useDispatch } from 'react-redux';
import { getSellScreenDetails } from 'store/actions';

const SellScreenV2 = ({ sellScreenDetails, toggles, companionAnimalFeature }) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('Dog');
  const [displayPetTypes, setDisplayPetTypes] = useState(['Dog', 'Cat', 'Fish', 'Small Pet', 'Reptile', 'Bird']);

  useEffect(() => {
    dispatch(getSellScreenDetails());
  }, []);

  useEffect(() => {

  }, [selectedTab]);

  const chooseTab = (petType) => {
    setSelectedTab(petType);
  };

  useEffect(() => {
    if (companionAnimalFeature === true || toggles?.ENABLE_COMPANION_ANIMAL_UI) {
      setDisplayPetTypes(['Dog', 'Cat', 'Fish', 'Small Pet', 'Reptile', 'Bird']);
    } else {
      setDisplayPetTypes(['Dog', 'Cat']);
    }
  }, [companionAnimalFeature, toggles?.ENABLE_COMPANION_ANIMAL_UI]);

  return (
    <div className="CoreCareCardPlan">
      {sellScreenDetails?.sellScreenData?.filter((pet) => pet.pet_type === selectedTab).map((pet, index) => (
        <>
          <CardPlan key={index + 1}>
            <div className="CardPlan__Body noPadding">
              <div className="QuoteSelection">
                <div className="QuoteSelection__BenefitBanner">
                  <Title content={pet.sell_page_headline} tabIndex={0} />
                  <Text tabIndex={0}>{pet.sell_page_above_copy}</Text>
                  <Title content={pet.sell_page_headline_2} tabIndex={0} className="title-heading-2" />
                </div>
                <PetListGrid
                  screenName="Sell_Screen"
                  petTypes={displayPetTypes}
                  chooseTab={chooseTab}
                  selectedTab={selectedTab}
                  toggles={toggles}
                />
                <div className="QuoteSelection__BenefitHeader">
                  <div className="QuoteSelection__BenefitText" tabIndex={0}>{pet.plan_price_text}</div>
                </div>
                <table className="Table">
                  <thead />
                  <tbody className="Table__Body">
                    {pet?.benefits?.map((benefit) => (
                      <tr className="Table__Row" key={benefit.Title}>
                        <th tabIndex={0} scope="row" className="Table__Data Table__Data--Bold"><span className="Benefit__Title">{benefit.Title}</span></th>
                        <td tabIndex={0} className={toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'Table__Data Table__Data--ColouredBackground UM-Background' : 'Table__Data Table__Data--ColouredBackground'}>{benefit.Value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </CardPlan>
          <div className="MembershipInfoPage__AdditionalInfo" key={pet?.sell_page_below_copy}>
            <p tabIndex={0}>{pet?.sell_page_below_copy}</p>
          </div>
        </>
      ))}
    </div>
  );
};

export default SellScreenV2;

interface Address {
    addressId: string;
    street1: string;
    street2: string;
    city: string;
    statee: string;
    zip: string;
    country: string;
    email: string;
}

export interface PaymentMethod {
    lastFour: string;
    expirydate: string;
    mbrPayinfoId: string;
    repeatDelivery: string;
    avsRespCode: string;
    walletNickName: string;
    accountType: string;
    tempPccDefault: string;
    defaultInd: string;
    walletAddressId: string;
    cvvRespCode: string;
    name: string;
    markForDelete: string;
    tokenType: string;
    brand: string;
    tokenValue: string;
    account: string;
    walletId: string;
    userId: string;
    customerEmail: string;
    expiryMonth: string;
    expiryYear: string;
    channel: string;
    created: string;
    lastUpdated: string;
    usageType: any;
    lastName: string;
    country: string;
    city: string;
    addressNickName: string;
    emailId: string;
    addressId: string;
    zipcode: string;
    firstName: string;
    phone: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    state: string;
    fax: string;
    memberId: string;
    status: string;
    initialTransactionId: string;
    address: Address
}

export enum Flow {
  REACTIVATION = 'Reactivation',
  RENEW = 'Renew',
}

import Icon from 'components-v2/shared/Icon/Icon';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { trackEvent } from 'utils';
import { adobeAnalyticsTags } from 'utils/constGlobal';
import NCRFrame from './NCRFrame';
import viewModel from './viewModel';

import classnames from 'classnames';
import { fetchCustomerAddress } from 'store/actions';
import AciPayment from '../AciPayment';
import './credit-card-selection.css';
import SelectPaymentMethodTable from '../SelectPaymentMethod/SelectPaymentMethodTable';
import SelectPaymentMethodComboBox from '../SelectPaymentMethod/SelectPaymentMethodComboBox/SelectPaymentMethodComboBox';
import { customer } from 'store/slices';

const CreditCardSelectionNonModal = ({
  onSelect,
  onClose,
  selectOnChange,
  onShowMobileAddNewForm,
  showFrameInModal = false,
  paymentMethodUpdateErrorInfo,
  deepLinkInfo,
  errorAddingPayment,
  setErrorAddingPayment,
}) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const {
    fetchPaymentMethods,
    useAciPayment,
    customerInfo,
    useNativeWallet,
    uiText,
    isMobile,
    paymentMethods,
  } = useSelector(viewModel);

  const sortedPaymentMethods = [...paymentMethods].sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

  useEffect(() => {
    if (!(paymentMethods.length > 0) && !isLoaded) {
      dispatch(fetchPaymentMethods());
      setIsLoaded(true);
    }
  }, [paymentMethods, isLoaded, dispatch, fetchPaymentMethods]);

  const handleNCRModalDismissal = () => { setShowAddNewForm(false); };

  useEffect(() => {
    fetchCustomerAddress();
  }, [customerInfo]);

  useEffect(() => {
    if (showAddNewForm && showFrameInModal && !useAciPayment) {
      trackEvent(adobeAnalyticsTags?.ADD_NEW_PAYMENT_INFO_V2);
    }
  }, [showAddNewForm, showFrameInModal]);

  useEffect(() => {
    if (useAciPayment) {
      if (paymentMethods && paymentMethods.length === 0) {
        if (!useNativeWallet) {
          setShowAddNewForm(true);
        }
      }
    }
  }, [isMobile, onShowMobileAddNewForm, paymentMethods, useAciPayment, useNativeWallet]);

  const paymentForm = useAciPayment ? (
    <>
      <div className="PaymentModalHeader">{uiText?.paymentModalHeading}</div>
      <div className="ACIPaymentContainer">
        <AciPayment
          onCancel={(obj) => {
            trackEvent(adobeAnalyticsTags.USER_CLICKS_ON_CLOSE_ICON_BUTTON_ON_ADD_A_NEW_CREDIT_CARD_MODAL_DURING_SIGNUP);
            console.info(JSON.stringify(obj, null, 2));
            setErrorAddingPayment(false);
            dispatch(customer.actions.clearPaymentMethodError());
            handleNCRModalDismissal();
          }}
          // eslint-disable-next-line
          inSignUp={true}
          onCreditCardAddition={({ created, walletId }) => {
            // eslint-disable-next-line no-console
            console.info(JSON.stringify({ created, walletId }, null, 2));
            onSelect(walletId);
          }}
          errorAddingPayment={errorAddingPayment}
          setErrorAddingPayment={setErrorAddingPayment}
          uiText={uiText}
        />
      </div>
    </>
  ) : (
    <NCRFrame
      dismissNCRFrame={handleNCRModalDismissal}
      onCreditCardAddition={(created, walletId) => {
        if (created) {
          trackEvent(adobeAnalyticsTags?.NEW_CARD_INFO_V2);
        }
        if (created && selectOnChange) {
          onSelect(walletId);
        } else {
          setShowAddNewForm(false);
        }
      }}
      customerInfo={customerInfo}
    />
  );

  return (
    <>
      {showAddNewForm && (
        <>
          {showFrameInModal
            && (
              <Modal
                className={classnames('complete-your-purchase-modal', {
                  'aci-payment-modal': useAciPayment,
                })}
                show={showAddNewForm}
                data-testid="ncr-modal"
                backdrop="static"
                keyboard={false}
                centered
              >
                <button
                  className="ACIPaymentModalCloseButton"
                  onClick={() => {
                    if (showAddNewForm && useAciPayment) {
                      trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_CLOSE_ICON_BUTTON_ON_ADD_A_NEW_CREDIT_CARD_MODAL_DURING_SIGNUP);
                    }
                    setErrorAddingPayment(false);
                    dispatch(customer.actions.clearPaymentMethodError());
                    setShowAddNewForm(false);
                  }}
                > <Icon name="closeModal" />
                </button>
                <br />
                {paymentForm}
              </Modal>
            )}
          {!showFrameInModal && paymentForm}
        </>
      )}

      {!showAddNewForm && useAciPayment ? (
        <SelectPaymentMethodTable
          paymentMethodUpdateErrorInfo={paymentMethodUpdateErrorInfo}
          sortedPaymentMethods={sortedPaymentMethods}
          onShowMobileAddNewForm={onShowMobileAddNewForm}
          setShowAddNewForm={setShowAddNewForm}
          onSelect={onSelect}
          onClose={onClose}
          selectOnChange={selectOnChange}
        />
      )
        : !showAddNewForm && (
          <SelectPaymentMethodComboBox
            paymentMethods={paymentMethods}
            trackEvent={trackEvent}
            adobeAnalyticsTags={adobeAnalyticsTags}
            paymentMethodUpdateErrorInfo={paymentMethodUpdateErrorInfo}
            onSelect={onSelect}
            onClose={onClose}
            selectOnChange={selectOnChange}
            onShowMobileAddNewForm={onShowMobileAddNewForm}
            setShowAddNewForm={setShowAddNewForm}
          />
        )}
    </>
  );
};

export default CreditCardSelectionNonModal;

export const validatePetName = (name) => {
  if (name?.length > 0) {
    // Allow for spaces
    const specialCharactersPattern = new RegExp(/(?=\S)\W/);
    const containsNumericCharacters = new RegExp(/\d/).test(name);
    const triggerWordsPattern = /\b\s*(them|hair\s*cut|(no)*(un)*name(s|d|less)*|groom(s|er|ing)*|breed(s|er|ing)*|test(s|er|ing)*)\s*\b|breed|rescue|(\?!^foster$)foster|available|sponsor|various|undecided|adopt|zoom.*groom|rush.*rover|cocoa.*center|petco|petsmart/i;
    const containsOnlyAlphaNumericCharactersAndSpaces = !specialCharactersPattern.test(name) && !triggerWordsPattern.test(name);

    if (containsNumericCharacters) {
      const containsMoreThanTwoConsectuiveCharacters = new RegExp(/\d{3}/g);
      const containsAtMostTwoNumericCharacters = !containsMoreThanTwoConsectuiveCharacters.test(name);
      return containsOnlyAlphaNumericCharactersAndSpaces && containsAtMostTwoNumericCharacters;
    } else {
      return containsOnlyAlphaNumericCharactersAndSpaces;
    }
  }
};

export default validatePetName;

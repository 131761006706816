import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import store from './store';
import { initSeamless } from './helpers/authToken';
import ErrorBoundary from './components-v2/ErrorBoundary';
import VersionSelector from './version-selector';

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN,
    environment: process.env.REACT_APP_ENV,
  });
}

if (process?.env?.REACT_APP_ENV === 'local') {
  const fitness = require('utils/fitness');
  fitness.monitor();
}

initSeamless();

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <VersionSelector />
    </ErrorBoundary>
  </Provider>,
  rootElement,
);

if (module.hot) {
  module.hot.accept('./version-selector', () => {
    const NextVersionSelector = require('./version-selector');
    ReactDOM.render(
      <Provider store={store}>
        <NextVersionSelector />
      </Provider>,
      rootElement,
    );
  });
}

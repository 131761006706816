import Icon from 'components-v2/shared/Icon/Icon';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customer } from 'store/slices';
import {
  getCardInfoForAdobeTracking, trackEvent,
} from 'utils';
import { adobeAnalyticsTags } from 'utils/constGlobal';
import * as Yup from 'yup';
import { Button } from '../Button';
import Error from '../Error';
import { Flow, PaymentMethod } from '../CreditCardSelectionNonModal/models/types';
import viewModel from '../CreditCardSelectionNonModal/viewModel';
import CreditCardRadioRow from './CreditCardRadioRow';

interface SelectPaymentMethodTableProps {
  sortedPaymentMethods: PaymentMethod[];
  onShowMobileAddNewForm: () => void;
  setShowAddNewForm: (showAddNewForm: boolean) => void;
  onSelect: (e: any) => void;
  onClose: (e: any) => void;
  renderedInModal?: boolean;
  flow: Flow;
  errorModalTitle: string;
  paymentLinkFlow: string;
  flag: boolean;
  umMessageClass: string;
}

const SelectPaymentMethodTable = ({
  sortedPaymentMethods, onShowMobileAddNewForm, setShowAddNewForm, onSelect, onClose, renderedInModal, flow, errorModalTitle, paymentLinkFlow, umMessageClass, flag,
}: SelectPaymentMethodTableProps) => {
  const dispatch = useDispatch();
  const {
    paymentMethodsError,
    isMobile,
    useNativeWallet,
  } = useSelector(viewModel);

  const checkInputForRowClick = (e: any, setFieldValue: any, setFieldTouched: any, handleChange: any) => {
    const radioInput = e.currentTarget.querySelector('input');
    if (radioInput) {
      const cardInfo = getCardInfoForAdobeTracking(sortedPaymentMethods, radioInput.value);
      radioInput.checked = true;
      setFieldValue('paymentMethod', radioInput.value);
      handleChange('paymentMethod', radioInput.value);
      setFieldTouched('paymentMethod');
      if (paymentLinkFlow === 'edit_payment') {
        adobeAnalyticsTags.USER_SELECT_EXISTING_CREDIT_CARD_ON_EDIT_PAYMENT_METHOD.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
        trackEvent(adobeAnalyticsTags?.USER_SELECT_EXISTING_CREDIT_CARD_ON_EDIT_PAYMENT_METHOD);
      } else if (paymentLinkFlow === 'update_payment') {
        adobeAnalyticsTags.USER_SELECT_EXISTING_CREDIT_CARD_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
        trackEvent(adobeAnalyticsTags?.USER_SELECT_EXISTING_CREDIT_CARD_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD);
      } else if (paymentLinkFlow === 'reactivate_payment') {
        adobeAnalyticsTags.USER_SELECTS_EXISTING_CARD_DURING_REACTIVATION.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
        trackEvent(adobeAnalyticsTags.USER_SELECTS_EXISTING_CARD_DURING_REACTIVATION);
      }
    } else {
      const parentRadioInput = e.currentTarget.querySelector('input');
      const cardInfo = getCardInfoForAdobeTracking(sortedPaymentMethods, parentRadioInput.value);
      parentRadioInput.checked = true;
      setFieldValue('paymentMethod', parentRadioInput.value);
      handleChange('paymentMethod', parentRadioInput.value);
      setFieldTouched('paymentMethod');
      if (paymentLinkFlow === 'edit_payment') {
        adobeAnalyticsTags.USER_SELECT_EXISTING_CREDIT_CARD_ON_EDIT_PAYMENT_METHOD.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
        trackEvent(adobeAnalyticsTags?.USER_SELECT_EXISTING_CREDIT_CARD_ON_EDIT_PAYMENT_METHOD);
      }
    }
  };

  useEffect(() => {
    // To track default modal view event on edit payment method
    if (paymentLinkFlow === 'edit_payment') {
      trackEvent(adobeAnalyticsTags?.USER_VIEWS_ADD_CREDIT_CARD_MODAL_ON_EDIT_PAYMENT_METHOD);
    } else if (paymentLinkFlow === 'update_payment') {
      trackEvent(adobeAnalyticsTags?.USER_VIEWS_ADD_CREDIT_CARD_MODAL_ON_UPDATE_PAYMENT_METHOD);
    }
  }, []);

  return (
    <>
      <div className={renderedInModal ? 'SelectPaymentMethod SelectPaymentMethod-RedemptionModal' : 'SelectPaymentMethod'}>
        {(flow === Flow.REACTIVATION || Flow.RENEW) && errorModalTitle && <p className={`activationErrorMessage flashMessage ${umMessageClass}`}><span><Icon name="errorIcon" /></span>{errorModalTitle}</p>}
        <Formik
          initialValues={{ paymentMethod: sortedPaymentMethods[0]?.mbrPayinfoId }}
          validateOnMount
          validationSchema={Yup.object().shape({ paymentMethod: Yup.string().required('Required') })}
          enableReinitialize
          onSubmit={async (values) => {
            const cardInfo = getCardInfoForAdobeTracking(sortedPaymentMethods, values.paymentMethod);
            // Passing !renderedInModal in below if condition as the below adobe event wrapped
            // Under if because this event should triggered only during sign up
            if (!renderedInModal) {
              adobeAnalyticsTags.USER_SELECTS_EXISTING_CARD_AND_CLICK_ON_SAVE_UNDER_PAYMENT_STEP_DURING_SIGNUP.serviceDetails.orderPaymentType = cardInfo[0]?.brand;
              trackEvent(adobeAnalyticsTags?.USER_SELECTS_EXISTING_CARD_AND_CLICK_ON_SAVE_UNDER_PAYMENT_STEP_DURING_SIGNUP);
            }
            dispatch(customer.actions.setSelectedPayment(values.paymentMethod));
            onSelect(values.paymentMethod);
            if (paymentLinkFlow === 'edit_payment') {
              adobeAnalyticsTags.USER_SELECT_EXISTING_CREDIT_CARD_AND_SAVE_ON_EDIT_PAYMENT_METHOD.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
              trackEvent(adobeAnalyticsTags?.USER_SELECT_EXISTING_CREDIT_CARD_AND_SAVE_ON_EDIT_PAYMENT_METHOD);
            } else if (paymentLinkFlow === 'update_payment') {
              adobeAnalyticsTags.USER_SELECT_EXISTING_CREDIT_CARD_AND_SAVE_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
              trackEvent(adobeAnalyticsTags?.USER_SELECT_EXISTING_CREDIT_CARD_AND_SAVE_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD);
            } else if (paymentLinkFlow === 'reactivate_payment') {
              adobeAnalyticsTags.USER_SELECTS_EXISTING_CARD_AND_CLICK_ON_SAVE_DURING_REACTIVATION.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
              trackEvent(adobeAnalyticsTags.USER_SELECTS_EXISTING_CARD_AND_CLICK_ON_SAVE_DURING_REACTIVATION);
            }
          }}
        >
          {(props) => (
            <div style={{ paddingTop: '.5em' }}>
              <Form>
                <div className="paymentMethodTableContent disable-scrollbars">
                  <table cellSpacing={0} style={{ width: '100%' }}>
                    <tbody>
                      {sortedPaymentMethods.map((paymentMethod, i) => (
                        <CreditCardRadioRow
                          paymentMethod={paymentMethod}
                          selectRowRadioInput={checkInputForRowClick}
                          i={i}
                          key={paymentMethod?.mbrPayinfoId}
                        />
                      ))}
                      <tr
                        className={renderedInModal ? 'addNewCreditCardRow-redemptionACIModal' : 'addNewCreditCardRow'}
                        onClick={() => {
                          if (isMobile && useNativeWallet) {
                            onShowMobileAddNewForm();
                          } else {
                            if (paymentLinkFlow === 'edit_payment') {
                              trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_ADD_CREDIT_CARD_CTA_ON_EDIT_PAYMENT_METHOD);
                            } else if (paymentLinkFlow === 'update_payment') {
                              trackEvent(adobeAnalyticsTags?.USER_CLICK_ADD_CREDIT_CARD_CTA_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD);
                            } else if (paymentLinkFlow === 'reactivate_payment') {
                              trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_ADD_NEW_CREDIT_CARD_UNDER_ADD_CREDIT_CARD_MODAL_DURING_REACTIVATION);
                            }
                            if (!renderedInModal) {
                              trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_ADD_NEW_CREDIT_CARD_UNDER_PAYMENT_STEP_DURING_SIGNUP);
                            }
                            setShowAddNewForm(true);
                          }
                        }}
                      >
                        <td className="addNewCreditCardData"> + Add New Credit Card</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={renderedInModal ? 'buttonContainer-redemptionACIModal' : 'buttonContainer'}>
                  <button className={renderedInModal ? 'saveButton-redemptionACIModal' : 'saveButton'} type="submit">SAVE</button>
                </div>
                {props.errors.paymentMethod && props.touched.paymentMethod && (
                  <Error name="paymentMethod">{props.errors}</Error>
                )}
                {
                  props.errors.paymentMethod && props.touched.paymentMethod && props.errors !== undefined && trackEvent(adobeAnalyticsTags?.NEW_CARD_INFO_FAIL_V2)
                }
                {paymentMethodsError && (<small className="error">{paymentMethodsError}</small>) && trackEvent(adobeAnalyticsTags?.NEW_CARD_INFO_FAIL_V2)}
              </Form>
              {onClose && renderedInModal && (
                <Button
                  className={renderedInModal ? 'secondary-redemptionACIModal' : 'secondary'}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              )}
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

SelectPaymentMethodTable.defaultProps = {
  renderedInModal: false,
};

export default SelectPaymentMethodTable;

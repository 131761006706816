import { createSlice } from '@reduxjs/toolkit';

const initialAffiliateReferralCodeState = {
  onAffiliateErrorMessage: { occurred: false, message: '' },
  onCustomerCareErrorMessage: { occurred: false, message: '' },
  referral_code: '',
  customer_care_code: '',
  onAffiliateSuccessMessage: { occurred: false, message: '' },
  onCustomerCareSuccessMessage: { occured: false, message: '' },
};

const affiliateReferralCode = createSlice({
  name: 'affiliateReferralCode',
  initialState: initialAffiliateReferralCodeState,
  reducers: {
    set: (state, action) => action.payload,
    setReferralCode: (state, action) => {
      state.referral_code = action.payload?.toUpperCase().trim();
    },
    setCustomerCareCode: (state, action) => {
      state.customer_care_code = action.payload?.toUpperCase().trim();
    },
    setOnAffiliateErrorMessage: (state, action) => {
      state.onAffiliateErrorMessage = action.payload;
    },
    setOnCustomerCareErrorMessage: (state, action) => {
      state.onCustomerCareErrorMessage = action.payload;
    },
    setOnAffiliateSuccessMessage: (state, action) => {
      state.onAffiliateSuccessMessage = action.payload;
    },
    setOnCustomerCareSuccessMessage: (state, action) => {
      state.onCustomerCareSuccessMessage = action.payload;
    },
    reset: () => initialAffiliateReferralCodeState,
  },
});

export default affiliateReferralCode;

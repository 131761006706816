/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialClaimState = {
  file: null,
  claimClinic: '',
  date: null,
  benefitId: null,
  preSignedObject: null,
  lastClinicSelected: null,
};

const claims = createSlice({
  name: 'claims',
  initialState: initialClaimState,
  reducers: {
    resetClaimState: () => initialClaimState,
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setClaimClinic: (state, action) => {
      state.claimClinic = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setBenefitId: (state, action) => {
      state.benefitId = action.payload;
    },
    setPreSignedObject: (state, action) => {
      state.preSignedObject = action.payload;
    },
    setLastClinicSelected: (state, action) => {
      state.lastClinicSelected = action.payload;
    },
  },
});

export default claims;

import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isLoading, isDeepLinking } from 'store';

import {
  VIEW_INDEX,
  registrationInfo,
  claims,
  navigationHistory,
  dashboardInfo as dashboardInfoReducer,
  deepLink,
} from 'store/slices';

import {
  fetchPets,
  fetchPlanBenefitUsageHistory,
  fetchMembershipPlans,
  fetchPaymentMethods,
} from 'store/actions';

import { trackEvent } from 'utils';
import { adobeAnalyticsTags } from 'utils/constGlobal';

import { redemptionViewModel } from './viewModel';

import RedemptionDashboard from './RedemptionDashboard/RedemptionDashboard';
import MembershipDetails from './MembershipDetails/MembershipDetails';
import BenefitUsageHistory from './BenefitUsageHistory';
import RedeemBenefits from './RedeemBenefits/RedeemBenefits';
import ContactCustomerCare from './ContactCustomerCare';
import PlanTransitionModal from './PlanTransitionModal';
import ReimbursementWarningModal from './ReimbursementWarningModal';
import moment from 'moment';

function Redemption() {
  const viewModel = useSelector((state) => redemptionViewModel(state));
  const loading = useSelector((state) => isLoading(state));
  const deepLinking = useSelector((state) => isDeepLinking(state));
  const [lastPetMasterId, setLastPetMaserId] = useState(null);
  const [showReimbursementWarningModal, setShowReimbursementWarningModal] = useState(false);

  const dispatch = useDispatch();

  const {
    pets,
    step,
    toggles,
    plansWithPets,
    selectedPlanWithPet,
    selectedPlanInfo,
    benefitInfo,
    customerInfo,
    paymentMethodsLoaded,
    reactivationErrorInfo,
    YOUR_WALLET_FALLBACK_LABEL,
    deepLinkInfo,
    contactCustomerCareInfo,
    transitionModalOnCooldown,
    showTransitionMessage,
    showTransitionModal,
    transitionMessage,
    thirdPartyErrorMessage,
    onMembershipReactivationSuccessMessage,
    renewalErrorInfo,
    onMembershipRenewalSuccessMessage,
    dashboardInfo,
    uiText,
    paymentMethodUpdateErrorInfo,
    paymentPlansDetail,
    switchMembershipSuccessMessage,
    onMembershipAutoRenewSuccessMessage,
    petAlreadyEnrolled,
    redeemBenefitsCouponErrorMessage,
  } = viewModel;

  useEffect(() => {
    if (!deepLinking) {
      dispatch(registrationInfo.actions.clearRegistrationInfo());
      dispatch(claims.actions.resetClaimState());
      dispatch(navigationHistory.actions.purgeRegistration());
      dispatch(navigationHistory.actions.purgeClaim());
    }

    dispatch(fetchMembershipPlans());
    dispatch(fetchPets());
  }, [dispatch, deepLinking]);

  // Note petMasterId not pgrMasterId
  const choosePet = useCallback((petMasterId) => {
    if (deepLinkInfo?.destination !== 'pet_selection') {
      dispatch(deepLink.actions.setDeepLinkPetId());
    }
    dispatch(dashboardInfoReducer.actions.clearThirdPartyErrorMessage());
    dispatch(dashboardInfoReducer.actions.setRedeemBenefitsCouponErrorMessage());
    dispatch(dashboardInfoReducer.actions.clearSwitchMembershipSuccessMessage());
    dispatch(dashboardInfoReducer.actions.setPetMasterId(petMasterId));
  }, [deepLinkInfo?.destination, dispatch, selectedPlanWithPet]);

  useEffect(() => {
    if (plansWithPets.length === 0) {
      dispatch(navigationHistory.actions.goToInitialDecision());
    }
  }, [plansWithPets, dispatch]);


  useEffect(() => {
    if (selectedPlanWithPet && lastPetMasterId !== selectedPlanWithPet.petMasterId) {
      dispatch(fetchPlanBenefitUsageHistory(selectedPlanWithPet?.plan?.id, false));
      setLastPetMaserId(selectedPlanWithPet.petMasterId);
      choosePet(selectedPlanWithPet.petMasterId);
    }
  }, [dispatch, selectedPlanWithPet, lastPetMasterId, setLastPetMaserId, choosePet]);

  const handleDecesaedCancellation = () => {
    dispatch(fetchMembershipPlans(true)); // force reload plans
    dispatch(dashboardInfoReducer.actions.setPetMasterId(null)); // clear selected pet because it is now gone
    dispatch(navigationHistory.actions.goBackToRedemptionDashboard());
  };

  const goToRedeemBenefits = () => {
    dispatch(navigationHistory.actions.goToRedeemBenefits());
    trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_REDEEM_BENEFIT_V2);
    dispatch(dashboardInfoReducer.actions.clearThirdPartyErrorMessage());
    dispatch(dashboardInfoReducer.actions.setRedeemBenefitsCouponErrorMessage());
  };

  const goToMembershipDetails = () => {
    // TODO: why is this here, is this needed? Doesn't the top effect make sure we always have a selected pet
    // because the view model defaults to pick first plan as selectedPet and then if that does not match
    // lastPetId it will get selected
    if (selectedPlanWithPet === null) {
      choosePet(plansWithPets[0].petMasterId);
    }

    dispatch(dashboardInfoReducer.actions.clearReactivationError({ occurred: false, message: null }));
    dispatch(dashboardInfoReducer.actions.clearReactivationSuccessMessage());
    dispatch(dashboardInfoReducer.actions.clearAutoRenewSuccessMessage());

    dispatch(dashboardInfoReducer.actions.clearRenewalError({ occurred: false, message: null }));
    dispatch(dashboardInfoReducer.actions.clearRenewalSuccessMessage());

    dispatch(dashboardInfoReducer.actions.clearOnMembershipPaymentMethodUpdateError({ occurred: false, message: null }));

    dispatch(navigationHistory.actions.goToMembershipDetails());

    dispatch(fetchPaymentMethods());
  };

  const goToEnrollNewPet = () => {
    dispatch(navigationHistory.actions.goToPetSelection());
    trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_ADD_A_PET_V2);
  };

  const goToContactCustomerCare = () => {
    dispatch(navigationHistory.actions.goToContactCustomerCare());
    trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_NEED_HELP_V2);
  };

  const goToBenefitHistory = () => {
    dispatch(navigationHistory.actions.goToBenefitUsageHistory());
  };

  const goToSubmitClaim = (benefitId, isRedeemable) => {
    if (isRedeemable) {
      trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_VET_EXAM_REIMBURSEMENT_V2);
      dispatch(navigationHistory.actions.goToClaimsClinicSelection());
      dispatch(claims.actions.setBenefitId(benefitId));
    } else {
      setShowReimbursementWarningModal(true);
    }
  };

  const goBackToDashboard = () => {
    dispatch(navigationHistory.actions.goBackToRedemptionDashboard());
  };

  const loadBenefitsForPlanWithPet = (planWithPet) => {
    dispatch(fetchPlanBenefitUsageHistory(planWithPet.plan.id));
  };

  return (
    <div className="registration-container">
      <PlanTransitionModal
        plansWithPets={plansWithPets}
        isLoading={loading}
        pets={pets}
        showTransitionModal={showTransitionModal}
        transitionModalOnCooldown={transitionModalOnCooldown}
      />

      <ReimbursementWarningModal
        open={showReimbursementWarningModal}
        onHide={() => setShowReimbursementWarningModal(false)}
        uiText={uiText}
        planEndDate={moment(selectedPlanWithPet?.plan?.planEndDate)?.format('MMM DD, YYYY')}
      />

      {step === VIEW_INDEX.REDEMPTION_DASHBOARD.step
        && (
          <RedemptionDashboard
            isLoading={loading}
            showUsageHistory={toggles.BENEFIT_USAGE_HISTORY_TOGGLE_ENABLED}
            selectedPlanWithPet={selectedPlanWithPet}
            planStatus={selectedPlanInfo.planStatus}
            totalBenefitUsage={benefitInfo.totalBenefitUsage}
            plansWithPets={plansWithPets}
            customerInfo={customerInfo}
            customerFirstName={customerInfo.customerFirstName}
            choosePet={choosePet}
            deepLinkInfo={deepLinkInfo}
            deepLinking={deepLinking}
            goToRedeemBenefits={goToRedeemBenefits}
            goToMembershipDetails={goToMembershipDetails}
            goToContactCustomerCare={goToContactCustomerCare}
            goToEnrollNewPet={goToEnrollNewPet}
            goToBenefitHistory={goToBenefitHistory}
            showTransitionMessage={showTransitionMessage}
            transitionMessage={transitionMessage}
            goToSubmitClaim={goToSubmitClaim}
            toggles={toggles}
            uiText={uiText}
            paymentPlansDetail={paymentPlansDetail}
            errorDuringPaymentMethodUpdate={paymentMethodUpdateErrorInfo.errorOccurred}
            errorPaymentMethodUpdateModalTitle={paymentMethodUpdateErrorInfo.modalTitle}
            switchMembershipSuccessMessage={switchMembershipSuccessMessage}
            paymentMethodsLoaded={paymentMethodsLoaded}
          />
        )}
      {step === VIEW_INDEX.REDEEM_BENEFITS.step
        && (
          <RedeemBenefits
            plansWithPets={plansWithPets}
            selectedPlanWithPet={selectedPlanWithPet}
            choosePet={choosePet}
            isLoading={loading}
            palsId={customerInfo.palsId}
            customerFullName={customerInfo.customerFullName}
            deepLinkInfo={deepLinkInfo}
            deepLinking={deepLinking}
            goToSubmitClaim={goToSubmitClaim}
            goBackToDashboard={goBackToDashboard}
            showTransitionMessage={showTransitionMessage}
            transitionMessage={transitionMessage}
            thirdPartyErrorMessage={thirdPartyErrorMessage}
            dashboardInfo={dashboardInfo}
            toggles={toggles}
            uiText={uiText}
            redeemBenefitsCouponErrorMessage={redeemBenefitsCouponErrorMessage}
          />
        )}
      {step === VIEW_INDEX.MEMBERSHIP_DETAILS.step
        && (
          <MembershipDetails
            key={`${step}-${selectedPlanWithPet.petMasterId}`}
            selectedPlanWithPet={selectedPlanWithPet}
            choosePet={choosePet}
            automaticRenew={selectedPlanInfo.automaticRenew}
            planStatus={selectedPlanInfo.planStatus}
            paymentMethodsLoaded={paymentMethodsLoaded}
            handleDecesaedCancellation={handleDecesaedCancellation}
            plansWithPets={plansWithPets}
            planWallet={selectedPlanInfo.planWallet}
            planPrice={selectedPlanInfo.planPrice}
            planAnnualPrice={selectedPlanInfo.planAnnualPrice}
            errorDuringReactivation={reactivationErrorInfo.errorOccurred}
            errorModalTitle={reactivationErrorInfo.modalTitle}
            yourWalletFallbackLabel={YOUR_WALLET_FALLBACK_LABEL}
            deepLinkInfo={deepLinkInfo}
            deepLinking={deepLinking}
            showTransitionMessage={showTransitionMessage}
            transitionMessage={transitionMessage}
            onMembershipReactivationSuccessMessage={onMembershipReactivationSuccessMessage}
            errorDuringRenewal={renewalErrorInfo.errorOccurred}
            errorRenewalModalTitle={renewalErrorInfo.modalTitle}
            errorDuringPaymentMethodUpdate={paymentMethodUpdateErrorInfo.errorOccurred}
            errorPaymentMethodUpdateModalTitle={paymentMethodUpdateErrorInfo.modalTitle}
            onMembershipRenewalSuccessMessage={onMembershipRenewalSuccessMessage}
            toggles={toggles}
            uiText={uiText}
            paymentPlansDetail={paymentPlansDetail}
            onMembershipAutoRenewSuccessMessage={onMembershipAutoRenewSuccessMessage}
            petAlreadyEnrolled={petAlreadyEnrolled}
          />
        )}
      {step === VIEW_INDEX.CONTACT_CUSTOMER_CARE.step
        && (
          <ContactCustomerCare
            goBackToDashboard={goBackToDashboard}
            contactCustomerCareInfo={contactCustomerCareInfo}
            uiText={uiText}
          />
        )}
      {step === VIEW_INDEX.BENEFIT_USAGE_HISTORY.step
        && (
          <BenefitUsageHistory
            plansWithPets={plansWithPets}
            selectedPlanWithPet={selectedPlanWithPet}
            choosePet={choosePet}
            showBenefitValues={benefitInfo.showBenefitValues}
            usageDataByCategory={benefitInfo.usageDataByCategory}
            totalBenefitUsage={benefitInfo.totalBenefitUsage}
            loadedPlansBenefitUsageHistory={benefitInfo.loadedPlansBenefitUsageHistory}
            customerFullName={customerInfo.customerFullName}
            palsId={customerInfo.palsId}
            loadBenefitsForPlanWithPet={loadBenefitsForPlanWithPet}
            showTransitionMessage={showTransitionMessage}
            transitionMessage={transitionMessage}
          />
        )}
    </div>
  );
}

export default Redemption;

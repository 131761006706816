import React, { useState, useEffect } from 'react';

import {
  trackEvent,
  getCssClass,
  isMobile,
} from 'utils';
import { backToDestinations, globalConsts, adobeAnalyticsTags } from 'utils/constGlobal';

import { Button } from 'components-v2/shared/Button-v1';

import './claims-date.css';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';
import { ReactDatePickerDynamicLabel } from 'components-v2/shared/ReactDatePickerDynamicLabel/ReactDatePickerDynamicLabel';
import BackTo from 'components-v2/shared/BackTo/BackTo';
import BackButton from 'components-v2/shared/BackButton/BackButton';

const ClaimsDate = ({
  petName, claimDate, onClaimDateSelected, planStartDate, toggles: { VET_CLINIC_FINDER_VETSOURCE },
}) => {
  const [currentDate, setDate] = useState(claimDate);

  const confirmedDate = (e) => {
    e.preventDefault();
    const VETEXAM_DATE = adobeAnalyticsTags?.VETEXAM_NEXT;
    const dateSplit = currentDate?.split('/');
    VETEXAM_DATE.appointmentDetails.appointmentYear = dateSplit?.[2];
    VETEXAM_DATE.appointmentDetails.appointmentMonth = dateSplit?.[0];
    VETEXAM_DATE.appointmentDetails.appointmentDate = dateSplit?.[1];
    trackEvent(VETEXAM_DATE);
    onClaimDateSelected(currentDate);
  };

  return (
    <div className="ClaimsDatePage-v2">
      {!isMobile ? (
        <div>
          <BackTo where={backToDestinations.DASHBOARD} desktop />
          <PetcoLogoSharedComponent alignCenter />
        </div>
      ) : (
        <BackTo where={backToDestinations.DASHBOARD} />
      )}
      <h2 className={getCssClass('', 'enhanced-title', VET_CLINIC_FINDER_VETSOURCE)}>
        When did {petName} have a vet exam?
      </h2>
      <div
        className={
          getCssClass(
            'registration-container',
            'registration-container-enhanced',
            VET_CLINIC_FINDER_VETSOURCE,
          )
        }
      >
        <fieldset>
          {!VET_CLINIC_FINDER_VETSOURCE && (
            <label htmlFor="claimDate">
              <span>* </span>
              Date of Vet Exam
            </label>
          )}

          <ReactDatePickerDynamicLabel
            planStartDate={planStartDate}
            setDate={setDate}
            currentDate={currentDate}
            truthnessCondition={VET_CLINIC_FINDER_VETSOURCE}
            label="Date Of Vet Exam"
          />

          {currentDate && (
            <>
              <div role="alert" aria-label={`You selected the following date: ${currentDate}. Please press Tab to confirm and continue`} />
              <Button
                type="submit"
                data-testid="next-button"
                onClick={(e) => confirmedDate(e)}
                label="Next"
                className={getCssClass(
                  'standard-button',
                  'button-enhanced',
                  VET_CLINIC_FINDER_VETSOURCE,
                )}
              />
            </>
          )}
          <BackButton />

        </fieldset>
      </div>
    </div>
  );
};

export default ClaimsDate;

import React from 'react';
import Icon from 'components-v2/shared/Icon/Icon';
import './steps-progress.css';

const StepsProgress = ({
  label, percentage,
}) => (
  <div className="component__StepProgress">
    <div className="ProgressContainer">
      <div className="ProgressBar" style={{ width: `${percentage}%` }}>
        <Icon name="steps-icon" />
      </div>
    </div>
    <span className="ProgressLabel">{label}</span>
  </div>
);

export default StepsProgress;

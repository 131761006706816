import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'components-v2/shared/Button-v1';
import { handleKeyUp } from 'utils';
import './ReactivationModal.css';
import Icon from 'components-v2/shared/Icon/Icon';


const ReactivationModal = (props) => {
  const {
    show, title, onHide, children, onAction, termsChecked, selectedPaymentPlan, confirmBtnTxt, cancelBtnTxt, screen,
  } = props;

  return (
    <Modal show={show} onHide={onHide} className={`customModal ReactivationModal ${selectedPaymentPlan === 'yearly' ? 'ReactivationModalYearly' : null} ${screen === 'switchToYearlyModal' ? screen : null}`}>
      <div className="ReactivationModal">
        <Modal.Title className="ReactivationTitle">
          {title}
        </Modal.Title>

        <div
          className="CloseButton"
          role="button"
          tabIndex="0"
          aria-label="Close"
          onClick={onHide}
          onKeyUp={(e) => handleKeyUp(e, onHide)}
        >
          <span><Icon name="closeBig" className="ReactivationCloseIcon" /></span>
        </div>

        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={termsChecked ? () => onAction() && onHide() : null}
            label={confirmBtnTxt !== undefined ? confirmBtnTxt : 'REACTIVATE'}
            className={termsChecked ? 'confirmButton' : 'disabled'}
          />
          <Button
            onClick={onHide}
            label={cancelBtnTxt !== undefined ? cancelBtnTxt : <span>Cancel</span>}
            className="change-plan-status-button"
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ReactivationModal;

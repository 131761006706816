import CreditCardSelectionNonModal from 'components-v2/shared/CreditCardSelectionNonModal';

const PaymentInformationStep = ({
  onDoneStep,
  onShowMobileAddNewForm,
  paymentMethodUpdateErrorInfo,
  deepLinkInfo,
  errorAddingPayment,
  setErrorAddingPayment,
}) => (
  <CreditCardSelectionNonModal
    errorAddingPayment={errorAddingPayment}
    setErrorAddingPayment={setErrorAddingPayment}
    onSelect={onDoneStep}
    onShowMobileAddNewForm={onShowMobileAddNewForm}
    selectOnChange
    showFrameInModal
    paymentMethodUpdateErrorInfo={paymentMethodUpdateErrorInfo}
    deepLinkInfo={deepLinkInfo}
    onClose={() => {
      setErrorAddingPayment(false);
    }}
  />
);

export default PaymentInformationStep;

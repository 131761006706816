/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import { timeStampData } from '../../utils';

const MEMBERSHIP_ICONS = {
  EXAM_CREDIT: 'exam credits',
  FLEA_TICK: 'flea',
  FOOD_BAG: 'bag',
  FOOD_CREDIT: 'food credit',
  NAIL_TRIM: 'nail trim',
  TEETH_BRUSHING: 'teeth',
  UNLIMITED_EXAMS: 'vet exam',
  VACCINATION: 'vaccination',
};

const getIconName = (formattedBenefitName) => {
  for (const [, iconValue] of Object.entries(MEMBERSHIP_ICONS)) {
    if (formattedBenefitName.includes(iconValue)) {
      return iconValue;
    } else {
      return MEMBERSHIP_ICONS.FOOD_BAG;
    }
  }
};

const parseBenefit = (benefit) => {
  const formattedBenefitName = benefit.name.toLowerCase();

  return {
    ...benefit,
    icon: getIconName(formattedBenefitName),
  };
};

const memberships = createSlice({
  name: 'memberships',
  initialState: {
    details: null,
    cacheTime: null,
  },
  reducers: {
    setDetail: (state, action) => {
      // HACK: This is to support Version 1.0 and Version 2.0 through testing... specifically Version 1.0
      const membership = action.payload.find(m => m.petType === null);

      state.details = membership ? {
        id: membership.id,
        monthlyPriceAmount: parseFloat(membership.priceAmount),
        priceAmount: parseFloat(membership.priceAmount),
        discounts: membership.discounts,
        sellPageHeadline: membership.sellPageHeadline,
        sellPageAboveCopy: membership.sellPageAboveCopy,
        sellPageBelowCopy: membership.sellPageBelowCopy,
        membershipBenefits: membership.membershipBenefits.map(parseBenefit),
      } : null;
      timeStampData(state);
    },
    clearMembershipsCacheTimer: (state) => {
      state.cacheTime = null;
    },
  },
});

export default memberships;

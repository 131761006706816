import { createSelector } from 'reselect';
import { currentViewStateSelector } from 'store';
import { navigationCategories } from 'utils/constCategories';

import { VIEW_INDEX } from './store/slices';

export const getAppViewModel = createSelector(
  currentViewStateSelector,
  (state) => state.tokenState,
  (state) => state.customer.membershipPlans,
  (state) => state.navigationHistory,
  (state) => state.toggles,
  (state) => state.customer.pets,
  (state) => state.url,
  (currentViewState, tokenState, membershipPlans, navigationHistory, toggles, pets, url) => {
    const isRedeemingBenefits = currentViewState.category === navigationCategories.REDEMPTION;
    const isRegistering = currentViewState.category === navigationCategories.REGISTRATION;
    const isClaim = currentViewState.category === navigationCategories.CLAIM;

    return {
      tokenLoaded: tokenState,
      navigationHistory,
      isInitialLoad: currentViewState.step === VIEW_INDEX.INITIAL_LOAD.step,
      hasMembershipPlans: membershipPlans.data.length > 0,
      hasPets: pets.data.length > 0,
      isRedeemingBenefits,
      isRegistering,
      isClaim,
      step: currentViewState.step,
      toggles,
      url,
      isRequestFromExternalPetcoLandingPage: url.isRequestFromExternalPetcoLandingPage,
    };
  },
);

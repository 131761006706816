import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { trackEvent } from 'utils';
import { globalConsts } from 'utils/constGlobal';

import { Button } from 'components-v2/shared/Button-v1';
import ComboBox from 'components-v2/shared/ComboBox/ComboBox';
import Container from 'components-v2/shared/Container';
import Effect from 'components-v2/formik-effect';
import Error from 'components-v2/shared/Error';
import { Title } from 'components-v2/shared/Elements';

import './breed-selection.css';

const BreedSelection = ({
  petBreeds, petBreed, petName, setPetBreed,
}) => {
  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));

  useEffect(() => {
    trackEvent({
      formname: 'Membership Signup',
      formstepname: 'Add or select a pet',
      formstep: 3,
      subformname: 'Add a pet',
      subformstepdetails: 'Pet breed',
    });
  }, []);

  function getErrorClassName(className, name, errors, touched) {
    if (errors[name] && touched[name]) {
      return `${className} error`;
    }

    return className;
  }

  function handleChange(currentFormikState, nextFormikState) {
    if (currentFormikState.values.breed !== nextFormikState.values.breed) {
      const breedCode = parseInt(nextFormikState.values.breed, 10);
      const fullBreed = petBreeds.find((breed) => breed.code === breedCode);
      setPetBreed(fullBreed);
    }
  }

  // This only exists if user backs up and wants to select same value
  // Selecting any other option triggers handleChange
  // Selecting the same value does not so they tap continue
  function handleSubmit() {
    setPetBreed(petBreed);
  }

  const breedInitial = 'Select Breed';

  return (
    <div className="BreedSelectionPage">
      <Container>
        <Formik
          initialValues={{
            selectedPet: '',
            ...({ breed: '' }),
          }}
          validationSchema={
            Yup.object().shape({
              ...({
                breed: Yup.string()
                  .oneOf(petBreeds.map((breed) => breed.code), 'Required'),
              }),
            })
}
          /* eslint-disable-next-line react/jsx-no-bind */
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Effect onChange={(currentFormikState, nextFormikState) => {
                handleChange(currentFormikState, nextFormikState);
              }}
              />
              <fieldset>
                <div className="row field-columns">
                  <div
                    className={
                        getErrorClassName('col-6 form-group', 'breed', props.errors, props.touched)
                      }
                  >
                    <Title>
                      What breed is {petName}?
                    </Title>

                    <label htmlFor="breed">
                      <span>*</span>
                      Breed
                    </label>

                    <ComboBox
                      id="breed"
                      ariaLabel="breed"
                      name="breed"
                      text={breedInitial}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      selectedOption={petBreed.code}
                      options={petBreeds.map(
                        (breed) => ({ key: breed.code, value: breed.code, label: breed.value }),
                      )}
                    />

                    {!!props.errors.breed && props.touched.breed && (
                      <Error name="breed" role="alert" aria-label={`${props.error}`}>
                        {props.error}
                      </Error>
                    )}
                  </div>
                </div>
                <br />
                {isMobile
                  && (
                  <div className={getErrorClassName('row field-columns', 'month', props.errors, props.touched)}>
                    <Button
                      id="continue-button"
                      data-testid="continue-button"
                      type="submit"
                      disabled={!(props.isValid && petBreed.code)}
                      label="Continue"
                    />
                  </div>
                  )}
              </fieldset>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default BreedSelection;

import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { Button, ButtonLink } from 'components-v2/shared/Button-v1';
import CreditCardSelection from 'components-v2/shared/CreditCardSelection-v1';
import Container from 'components-v2/shared/Container';
import ErrorModal from 'components-v2/ErrorModal';
import {
  getAge,
  getDateMonthDayYearFormat,
  renderPetIconsForUpdatePlansCC,
  trackEvent,
} from 'utils';

import {
  backToDestinations,
  globalConsts,
  StatusPlan,
  AffiliateDiscountType,
  adobeAnalyticsTags,
  adobeAnalyticsTagsAPU,
} from 'utils/constGlobal';

import {
  customer,
  dashboardInfo as dashboardInfoSlice,
  deepLink,
  DEEPLINK_INDEX,
} from 'store/slices';

import {
  fetchATax,
  fetchMembershipPlans,
  fetchPaymentMethods,
  paymentMethodUpdate,
  disableAutoRenew,
  reactivateMembershipPlan,
  updatePlansCC,
  fetchPlansDetail,
} from 'store/actions';

import Card from 'components-v2/shared/Card-v1';
import List from 'components-v2/shared/List-v1';
import Price from 'components-v2/shared/Price';
import ToggleableCheckbox from 'components-v2/shared/ToggleableCheckbox';
import PlanTransitionMessage from 'components-v2/shared/PlanTransitionMessage';
import ConfirmationModal from 'components-v2/shared/ConfirmationModal-v1';
import DashboardHeader from '../DashboardHeader/DashboardHeader';

import CancelRenewReasonModal from './CancelRenewReasonModal';

import './membership-details.css';
import BackTo from 'components-v2/shared/BackTo/BackTo';
import { DateTime } from 'luxon';
import ReactivationModal from './ReactivateModal/ReactivationModal';
import { Formik } from 'formik';
import Checkbox from 'components-v2/shared/Checkbox';
import { Text } from 'components-v2/shared/Elements';
import moment from 'moment';
import Icon from 'components-v2/shared/Icon/Icon';
import UpdatePlansCCModal from './UpdatePlansCCModal/UpdatePlansCCModal';
import SelectPaymentPlanModal from './SelectPaymentPlanModal/SelectPaymentPlanModal';
import PetPaymentDetails from 'components-v2/shared/PetPaymentDetailsCard/PetPaymentDetails';
import VitalCareTip from 'components-v2/shared/VitalCareTip';
import viewModel from 'components-v2/shared/CreditCardSelection-v1/viewModel';

export const formatEffectiveDate = (startDate, endDate) => `${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`;

function MembershipDetails({
  selectedPlanWithPet,
  choosePet,
  automaticRenew,
  planStatus,
  paymentMethodsLoaded,
  plansWithPets,
  planWallet,
  planPrice,
  planAnnualPrice,
  errorDuringReactivation,
  errorModalTitle,
  yourWalletFallbackLabel,
  handleDecesaedCancellation,
  deepLinking,
  deepLinkInfo,
  showTransitionMessage,
  transitionMessage,
  onMembershipReactivationSuccessMessage,
  errorDuringRenewal,
  errorRenewalModalTitle,
  onMembershipRenewalSuccessMessage,
  toggles,
  uiText,
  errorDuringPaymentMethodUpdate,
  errorPaymentMethodUpdateModalTitle,
  paymentPlansDetail,
  onMembershipAutoRenewSuccessMessage,
  petAlreadyEnrolled,
}) {
  const dispatch = useDispatch();

  const { useAciPayment } = useSelector(viewModel);

  const filteredPlansWithPets = useMemo(() => plansWithPets?.filter((plans) => plans?.plan?.status !== 'canceled'), [plansWithPets]);
  const [showModal, setShowModal] = useState(false);
  const [showCancelRenewReasonModal, setShowCancelRenewReasonModal] = useState(false);
  const [showStartRenewModal, setShowStartRenewModal] = useState(false);
  const [errorAddingPayment, setErrorAddingPayment] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [showPaymentPlanModal, setShowPaymentPlanModal] = useState(false);
  const [isPaymentMethodInvalid, setIsPaymentMethodInvalid] = useState(false);
  const [petCost, setPetCost] = useState(null);
  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));
  const [termsChecked, setTermsChecked] = useState(false);
  const [monthlyCommitmentTermsChecked, setMonthlyCommitmentTermsChecked] = useState(false);
  const [NCRFrame, setNCRFrame] = useState(false);
  const [showUpdatePlansCCModal, setShowUpdatePlansCCModal] = useState(false);
  const [filteredPlansWithPetsCheckedState, setFilteredPlansWithPetsCheckedState] = useState(new Array(filteredPlansWithPets?.filter(plan => plan?.plan?.id !== selectedPlanWithPet?.plan?.id)?.length).fill(false));
  const [updatedWalletID, setUpdatedWalletID] = useState();
  const [selectedPlansForUpdateCC, setSelectedPlansForUpdateCC] = useState([]);
  const [paymentMethodUpdated, setPaymentMethodUpdated] = useState(false);
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState('monthly');
  const [updatedPaymentMethodLink, setUpdatedPaymentMethodLink] = useState('');
  const [petPlanID, setPetPlanID] = useState();
  const [isGracePeriodFlow, setIsGracePeriodFlow] = useState(false);

  const planStatusActive = selectedPlanWithPet.plan.status === StatusPlan.ACTIVE;
  const planStatusExpired = selectedPlanWithPet.plan.status === StatusPlan.EXPIRED;
  const planStatusCanceled = selectedPlanWithPet.plan.status === StatusPlan.CANCELED;

  useEffect(() => {
    dispatch(fetchATax(
      selectedPlanWithPet.plan.id,
      (petDiscountAndTaxInfo) => {
        setPetCost(petDiscountAndTaxInfo);
      },
      () => setIsPaymentMethodInvalid(true),
    ));
  }, [dispatch, selectedPlanWithPet, setPetCost, setIsPaymentMethodInvalid]);

  useEffect(() => {
    if (errorDuringReactivation) {
      window.parent.postMessage({ action: 'scrollTopSmoothly' }, '*');
    }
  }, [errorDuringReactivation]);

  useEffect(() => {
    if (petPlanID !== selectedPlanWithPet.plan.id) {
      setPetPlanID(selectedPlanWithPet.plan.id);
      if (!planStatusCanceled && !planStatusExpired) {
        trackEvent(adobeAnalyticsTags?.USER_LAND_ON_PET_VITALCARE_PLAN);
      }
      if (planStatusCanceled) {
        adobeAnalyticsTags.USER_LAND_ON_PET_VITALCARE_PLAN_WITH_REACTIVATE_WARNING.page.buildVersion = toggles?.FRONTEND_VERSION;
        trackEvent(adobeAnalyticsTags?.USER_LAND_ON_PET_VITALCARE_PLAN_WITH_REACTIVATE_WARNING);
      }
      if (planStatusExpired) {
        adobeAnalyticsTags.USER_LAND_ON_PET_VITALCARE_PLAN_WITH_GRACE_WARNING.page.buildVersion = toggles?.FRONTEND_VERSION;
        trackEvent(adobeAnalyticsTags?.USER_LAND_ON_PET_VITALCARE_PLAN_WITH_GRACE_WARNING);
      }
    }
  }, [selectedPlanWithPet, petPlanID, planStatusCanceled, planStatusExpired, toggles?.FRONTEND_VERSION]);

  const showModalInfo = useCallback(() => {
    window.parent.postMessage({ action: 'scrollTopSmoothly' }, '*');
    setShowModal(true);
  }, [setShowModal]);

  const handleReactivateMembership = (paymentWallet, toggles) => {
    if (paymentWallet) {
      window.parent.postMessage({ action: 'scrollTopSmoothly' }, '*');
      if (toggles?.ENABLE_REACTIVATION_UI && !toggles?.ANNUAL_PAID_UPFRONT_ONLINE) {
        setShowReactivateModal(true);
      } else if (toggles?.ANNUAL_PAID_UPFRONT_ONLINE) {
        setShowPaymentPlanModal(true);
      } else {
        setShowStartRenewModal(true);
      }
    }
  };

  const handleRenewToggle = useCallback(() => {
    if (selectedPlanWithPet.plan.status !== StatusPlan.ACTIVE) return;
    if (planWallet) {
      window.parent.postMessage({ action: 'scrollTopSmoothly' }, '*');

      if (automaticRenew) {
        setShowCancelRenewReasonModal(true);
      } else {
        setShowStartRenewModal(true);
      }
    }
  }, [setShowCancelRenewReasonModal, setShowStartRenewModal, planWallet, selectedPlanWithPet, automaticRenew]);

  const listFooter = () => {
    if (toggles?.ANNUAL_PAID_UPFRONT_ONLINE && toggles?.ENABLE_UNIFIED_MEMBERSHIP) {
      return 'List__Footer__Margin List__Footer__UM';
    } else if (toggles?.ANNUAL_PAID_UPFRONT_ONLINE) {
      return 'List__Footer__Margin';
    } else if (toggles?.ENABLE_UNIFIED_MEMBERSHIP) {
      return 'List__Footer__UM';
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (deepLinking) {
      switch (deepLinkInfo.destination) {
        case DEEPLINK_INDEX.PAYMENT.key:
          dispatch(customer.actions.setSelectedPayment(deepLinkInfo.walletId));
          showModalInfo();
          dispatch(deepLink.actions.resetDeepLink());
          break;

        case DEEPLINK_INDEX.CANCEL_RENEW_PLAN.key:
          handleRenewToggle();
          dispatch(deepLink.actions.resetDeepLink());
          break;

        case DEEPLINK_INDEX.REACTIVATE_MEMBERSHIP_PLAN.key:
          setUpdatedPaymentMethodLink('reactivate_payment');
          handleReactivateMembership(selectedPlanWithPet?.plan?.walletCardId, toggles);
          dispatch(deepLink.actions.resetDeepLink());
          break;

        case DEEPLINK_INDEX.RENEW_MEMBERSHIP_PLAN.key:
          setUpdatedPaymentMethodLink('update_payment');
          setIsGracePeriodFlow(true);
          showModalInfo();
          dispatch(deepLink.actions.resetDeepLink());
          break;

        case DEEPLINK_INDEX.EDIT_PAYMENT_METHOD.key:
          setIsGracePeriodFlow(true);
          showModalInfo();
          dispatch(deepLink.actions.resetDeepLink());
          break;

        default:
          dispatch(deepLink.actions.resetDeepLink());
          break;
      }
    }
  }, [dispatch, deepLinkInfo, deepLinking, showModalInfo, handleRenewToggle, selectedPlanWithPet?.plan?.walletCardId, toggles]);

  const onShowMobileAddNewForm = () => {
    const pgrMasterId = selectedPlanWithPet.pet ? selectedPlanWithPet.pet.pgrMasterId : '';
    const message = `vital_care_wallet_card queryString:entdeeplink=${DEEPLINK_INDEX.PAYMENT.key}&entdeeplinkpet=${pgrMasterId}&entdeeplinkwalletid=`;
    window.parent.postMessage({ action: 'consoleMessage', message }, '*');
  };

  const updatePaymentMethod = async (paymentMethodId, paymentAddFailed) => {
    if (paymentAddFailed) {
      setErrorAddingPayment(true);
      return;
    }
    await dispatch(paymentMethodUpdate(selectedPlanWithPet.plan.id, paymentMethodId, selectedPlanWithPet.plan.status));

    setShowModal(false);
    setPaymentMethodUpdated(true);

    setUpdatedWalletID(paymentMethodId);
    setShowUpdatePlansCCModal(true);
    dispatch(fetchPaymentMethods(true));
    dispatch(fetchMembershipPlans(true));
  };

  const updateAllPlansCCMethod = async (planIDs, walletId) => {
    await dispatch(updatePlansCC(planIDs, walletId));

    setShowUpdatePlansCCModal(false);
    dispatch(fetchPaymentMethods(true));
    dispatch(fetchMembershipPlans(true));
  };

  const membershipPlanRenew = async (paymentMethodId) => {
    await dispatch(paymentMethodUpdate(selectedPlanWithPet.plan.id, paymentMethodId, selectedPlanWithPet.plan.status));

    setPaymentMethodUpdated(false);
    setShowModal(false);

    setUpdatedWalletID(paymentMethodId);
    setShowUpdatePlansCCModal(true);
    dispatch(fetchPaymentMethods(true));
    dispatch(fetchMembershipPlans(true));
  };

  const membershipPlanReactivate = async (paymentMethodId) => {
    if (paymentMethodId) {
      await dispatch(paymentMethodUpdate(selectedPlanWithPet.plan.id, paymentMethodId, selectedPlanWithPet.plan.status));
      await dispatch(reactivateMembershipPlan(selectedPlanWithPet.plan.id, paymentMethodId, errorDuringReactivation, selectedPaymentPlan, true));

      setPaymentMethodUpdated(false);

      setUpdatedWalletID(paymentMethodId);
      setShowUpdatePlansCCModal(true);

      dispatch(fetchPaymentMethods(true));
      dispatch(fetchMembershipPlans(true));
    } else {
      await dispatch(reactivateMembershipPlan(selectedPlanWithPet.plan.id));
    }
  };

  const appendCardInfo = (paymentWallet) => (paymentWallet
    ? `with ${paymentWallet.brand} ending in ${paymentWallet.lastFour}`
    : `- ${yourWalletFallbackLabel}`);

  const handleManageSubscriptionAction = ({ cancellationReasonId, comments, indicatesDeceased } = {}) => {
    if (indicatesDeceased) {
      handleDecesaedCancellation();
    }

    if (planStatus === StatusPlan.ACTIVE && automaticRenew) {
      dispatch(disableAutoRenew(selectedPlanWithPet.plan.id, cancellationReasonId, comments));
    } else {
      dispatch(reactivateMembershipPlan(selectedPlanWithPet.plan.id, selectedPlanWithPet?.plan?.walletCardId, errorDuringReactivation, selectedPlanWithPet?.plan?.paymentPlan, automaticRenew));
    }
  };

  // avoid lint warning
  const handleRenewChange = (e) => {
    e.preventDefault();
  };

  const formatPetInformation = (name, breed, birthdate) => `${name}, ${breed}, ${getAge(birthdate)} old`;

  const isThereAffiliateDiscount = useMemo(() => petCost?.discounts?.find(
    (discount) => discount.displayName === AffiliateDiscountType.AFFILIATE_REFERRAL_NAME,
  ), [petCost]);

  const getAfterMonthsPrice = () => (parseFloat(isThereAffiliateDiscount.amount) + parseFloat(petCost?.total)).toFixed(2);

  const termsAndConditionsUMURL = `${process.env.REACT_APP_TERMS_AND_CONDITIONS_URL_UM}${isMobile ? '?channel=mobile' : ''}`;
  const termsOfUseURL = `${process.env.REACT_APP_TERMS_OF_USE_URL}${isMobile ? '?channel=mobile' : ''}`;
  const privacyPolicyURL = `${process.env.REACT_APP_PRIVACY_POLICY_URL}${isMobile ? '?channel=mobile' : ''}`;
  const californiaPrivacyPolicyURL = `${process.env.REACT_APP_CALIFORNIA_PRIVACY_POLICY_URL}${isMobile ? '?channel=mobile' : ''}`;

  const checkReactivationDate = (date) => {
    if (date === '29th' || date === '30th' || date === '31st') {
      return true;
    } else {
      return false;
    }
  };

  const paymentTenure = toggles?.ANNUAL_PAID_UPFRONT_ONLINE && petCost?.paymentPlan === 'yearly' ? '/year' : petCost?.paymentPlan === 'yearly' ? '/year' : '/month';

  useEffect(() => {
    if (errorDuringReactivation && !onMembershipReactivationSuccessMessage) {
      setNCRFrame(true);
    } else {
      setNCRFrame(false);
    }
  }, [errorDuringReactivation, onMembershipReactivationSuccessMessage]);

  useEffect(() => {
    if (errorDuringRenewal && !onMembershipRenewalSuccessMessage) {
      showModalInfo();
    }
  }, [errorDuringRenewal, onMembershipRenewalSuccessMessage, showModal, showModalInfo]);

  useEffect(() => {
    if (!errorDuringReactivation && onMembershipReactivationSuccessMessage) {
      adobeAnalyticsTags.USER_LAND_ON_REACTIVATION_SUCCESS_PAGE.message.info = onMembershipReactivationSuccessMessage;
      trackEvent(adobeAnalyticsTags?.USER_LAND_ON_REACTIVATION_SUCCESS_PAGE);
    }
  }, [errorDuringReactivation, onMembershipReactivationSuccessMessage]);

  const calcCanceledString = (str) => {
    if (str) {
      const linkSplit = str.split('</link>');
      return linkSplit.map(msg => {
        const [text, link] = msg.split('<link>');
        return (
          <span key={msg}>{text}
            {
              link
                ? (
                  <button
                    onClick={() => {
                      (toggles?.ANNUAL_PAID_UPFRONT_ONLINE
                        ? setShowPaymentPlanModal(true)
                        : setShowReactivateModal(true)
                      );
                      setUpdatedPaymentMethodLink('reactivate_payment');
                      trackEvent(adobeAnalyticsTags?.USER_CLICK_REACTIVATE_YOUR_PLAN);
                    }}
                    className="change-plan-status-button"
                  >
                    {link}
                  </button>
                ) : null
            }
          </span>
        );
      });
    }
  };

  const calcExpiredString = (str) => {
    if (str) {
      const linkSplit = str.split('</link>');
      return linkSplit.map(msg => {
        const [text, link] = msg.split('<link>');
        return (
          <span key={msg}>{text}
            {
              link
                ? <button onClick={() => { trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_UPDATE_YOUR_CARD_LINK_DASHBOARD_FIRST_GRACE_PERIOD); setUpdatedPaymentMethodLink('update_payment'); showModalInfo(); }} className="change-plan-status-button">{link}</button> : null
            }
          </span>
        );
      });
    }
  };

  const calcAutoRenewalDate = (str) => {
    if (str) {
      const dateSplit = str.split('<date>');
      const date = getDateMonthDayYearFormat(selectedPlanWithPet.plan.endDate);
      return (dateSplit.join(date));
    }
  };

  const renderPaymentEffectiveDate = () => {
    if (selectedPlanWithPet.plan?.switchDate) {
      return selectedPlanWithPet.plan?.switchDate && formatEffectiveDate(selectedPlanWithPet.plan?.switchDate, selectedPlanWithPet.plan.endDate);
    } else if (selectedPlanWithPet.plan?.renewDate) {
      return selectedPlanWithPet.plan?.renewDate && formatEffectiveDate(selectedPlanWithPet.plan?.renewDate, selectedPlanWithPet.plan.endDate);
    } else if (petCost?.reactivationDate) {
      return petCost?.reactivationDate && formatEffectiveDate(petCost?.reactivationDate, selectedPlanWithPet.plan.endDate);
    } else {
      return formatEffectiveDate(selectedPlanWithPet.plan.createDt, selectedPlanWithPet.plan.endDate);
    }
  };

  const checkPetInformation = (name, breed, birthdate) => {
    let updatedBreed = breed;
    if (breed.includes('Small Animal')) {
      updatedBreed = breed.replace('Animal', 'Pet');
    }
    if (breed.includes('Other Reptile')) {
      updatedBreed = 'Other - Reptile';
    }
    return formatPetInformation(name, updatedBreed, birthdate);
  };

  const handleUpdatePlansCCChecked = (position, checked, planID) => {
    const updatedCheckedState = filteredPlansWithPetsCheckedState?.map((item, index) => (index === position ? !item : item));

    setFilteredPlansWithPetsCheckedState(updatedCheckedState);

    let selectedPlanIDs = [...selectedPlansForUpdateCC];
    if (!checked) {
      selectedPlanIDs.push(planID);
    } else {
      selectedPlanIDs = selectedPlanIDs.filter(item => item !== planID);
    }

    setSelectedPlansForUpdateCC(selectedPlanIDs);
  };

  const handleUpdatePlansCCPreSelect = useCallback(() => {
    setFilteredPlansWithPetsCheckedState(new Array(filteredPlansWithPets?.filter(plan => plan?.plan?.id !== selectedPlanWithPet?.plan?.id && plan.plan.walletCardId !== Number(updatedWalletID))?.length).fill(true));
    let selectedPlanIDs = [...selectedPlansForUpdateCC];

    const plans = filteredPlansWithPets?.filter(plan => plan?.plan?.id !== selectedPlanWithPet?.plan?.id && plan.plan.walletCardId !== Number(updatedWalletID));
    if (plans) {
      selectedPlanIDs = plans?.map((plan) => plan?.plan?.id);
    }
    setSelectedPlansForUpdateCC(selectedPlanIDs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlanWithPet?.plan?.id, updatedWalletID]);

  const sendAdobeTracker = (planIDs, flowType) => {
    const optionSelected = [];
    planIDs.forEach((planId) => {
      const planPets = plansWithPets?.filter((plans) => plans?.plan?.id === planId);
      // eslint-disable-next-line no-unused-expressions
      planPets?.length > 0 && optionSelected.push(planPets[0]?.pet?.petType);
    });
    if (flowType === 'reactivate') {
      adobeAnalyticsTagsAPU.REACTIVATE_USER_CLICK_ON_SELECTS_PETS_AND_CONFIRM.optionSelected.petSpecies = optionSelected;
      trackEvent(adobeAnalyticsTagsAPU?.REACTIVATE_USER_CLICK_ON_SELECTS_PETS_AND_CONFIRM);
    }
    if (flowType === 'updatePayment' && isGracePeriodFlow === false) {
      adobeAnalyticsTagsAPU.EDIT_YOUR_CARD_USER_CLICK_ON_SELECTS_PETS_AND_CONFIRM.optionSelected.petSpecies = optionSelected;
      trackEvent(adobeAnalyticsTagsAPU?.EDIT_YOUR_CARD_USER_CLICK_ON_SELECTS_PETS_AND_CONFIRM);
    }
    if (isGracePeriodFlow === true) {
      adobeAnalyticsTagsAPU.GRACE_PERIOD_USER_CLICK_ON_SELECTS_PETS_AND_CONFIRM.optionSelected.petSpecies = optionSelected;
      trackEvent(adobeAnalyticsTagsAPU?.GRACE_PERIOD_USER_CLICK_ON_SELECTS_PETS_AND_CONFIRM);
    }
  };

  const renderUpdatePlansCCModalBody = () => (
    <div className="updateCCPetNames">
      {filteredPlansWithPets?.filter(plan => plan.plan.id !== selectedPlanWithPet.plan.id && plan.plan.walletCardId !== Number(updatedWalletID))?.map((plan, index) => (
        <div className="displayUpdateCCPetNames" key={plan.name}>
          <Checkbox
            value={plan.name}
            checked={filteredPlansWithPetsCheckedState[index]}
            handleCheck={() => handleUpdatePlansCCChecked(index, filteredPlansWithPetsCheckedState[index], plan.plan.id)}
          />
          {renderPetIconsForUpdatePlansCC(plan?.pet?.petType)}
          <span>{plan?.name}</span>
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    if (showUpdatePlansCCModal) {
      setSelectedPlansForUpdateCC([]);
      handleUpdatePlansCCPreSelect();
    }
  }, [handleUpdatePlansCCPreSelect, showUpdatePlansCCModal, updatedWalletID]);

  useEffect(() => {
    if (errorDuringPaymentMethodUpdate && errorDuringReactivation) {
      setShowModal(false);
      dispatch(dashboardInfoSlice.actions.clearOnMembershipPaymentMethodUpdateError(
        {
          occurred: false,
          message: null,
        },
      ));
    } else if (errorDuringPaymentMethodUpdate && errorPaymentMethodUpdateModalTitle) {
      setShowModal(true);
    }
  }, [dispatch, errorDuringPaymentMethodUpdate, errorDuringReactivation, errorPaymentMethodUpdateModalTitle, showModal]);

  const selectPaymentPlan = (index, value) => {
    setSelectedPaymentPlan(value);
  };

  useEffect(() => {
    if (toggles?.ANNUAL_PAID_UPFRONT_ONLINE && planStatusCanceled) {
      dispatch(fetchPlansDetail(selectedPlanWithPet?.name, selectedPlanWithPet?.plan?.membershipTypeId, selectedPlanWithPet?.plan?.wcsUserId, 'Reactivation'));
    }
  }, [dispatch, planStatusCanceled, selectedPlanWithPet?.name, selectedPlanWithPet?.plan?.membershipTypeId, selectedPlanWithPet?.plan?.wcsUserId, toggles?.ANNUAL_PAID_UPFRONT_ONLINE]);

  return (
    <div className="MembershipDetailsPage">
      <Modal
        className={useAciPayment ? 'membership-details-modal-aci' : 'membership-details-modal'}
        show={showModal}
        onHide={() => setShowModal(false)}
        data-testid="ncr-modal"
        backdrop="static"
        keyboard={false}
      >
        {toggles?.ENABLE_FIRST_GRACE_PERIOD_UI ? (
          paymentMethodsLoaded && (
            <CreditCardSelection
              errorAddingPayment={errorAddingPayment}
              setErrorAddingPayment={setErrorAddingPayment}
              onSelect={planStatusExpired ? membershipPlanRenew : updatePaymentMethod}
              selectOnChange
              onShowMobileAddNewForm={onShowMobileAddNewForm}
              onClose={() => {
                setShowModal(false);
                setShowUpdatePlansCCModal(false);
                setErrorAddingPayment(false);
                dispatch(dashboardInfoSlice.actions.setOnMembershipRenewalError(
                  {
                    occurred: false,
                    message: null,
                  },
                ));
                dispatch(dashboardInfoSlice.actions.clearOnMembershipPaymentMethodUpdateError(
                  {
                    occurred: false,
                    message: null,
                  },
                ));
              }}
              flow="Renew"
              errorModalTitle={errorRenewalModalTitle || errorPaymentMethodUpdateModalTitle}
              planStatus={selectedPlanWithPet.plan.status}
              toggles={toggles}
              paymentLinkFlow={updatedPaymentMethodLink}
            />
          )
        ) : (
          paymentMethodsLoaded && (
            <CreditCardSelection
              errorAddingPayment={errorAddingPayment}
              setErrorAddingPayment={setErrorAddingPayment}
              onSelect={updatePaymentMethod}
              selectOnChange
              onShowMobileAddNewForm={onShowMobileAddNewForm}
              onClose={() => {
                setShowModal(false);
                setErrorAddingPayment(false);
              }}
              paymentLinkFlow={updatedPaymentMethodLink}
            />
          )
        )}
      </Modal>

      {toggles?.ENABLE_REACTIVATION_UI && planStatusCanceled && (
        <Modal
          className={useAciPayment ? 'membership-details-modal-aci' : 'membership-details-modal'}
          show={NCRFrame}
          onHide={() => setNCRFrame(false)}
          data-testid="ncr-modal"
          backdrop="static"
          keyboard={false}
        >
          {paymentMethodsLoaded && (
            <CreditCardSelection
              errorAddingPayment={errorAddingPayment}
              setErrorAddingPayment={setErrorAddingPayment}
              onSelect={planStatusCanceled && membershipPlanReactivate}
              selectOnChange
              onShowMobileAddNewForm={onShowMobileAddNewForm}
              onClose={() => {
                setNCRFrame(false);
                setErrorAddingPayment(false);
                dispatch(dashboardInfoSlice.actions.setOnMembershipReactivationError(
                  {
                    occurred: false,
                    message: null,
                  },
                ));
                dispatch(dashboardInfoSlice.actions.clearOnMembershipPaymentMethodUpdateError(
                  {
                    occurred: false,
                    message: null,
                  },
                ));
              }}
              flow="Reactivation"
              errorModalTitle={errorModalTitle}
              planStatus={selectedPlanWithPet.plan.status}
              toggles={toggles}
              paymentLinkFlow={updatedPaymentMethodLink}
            />
          )}
        </Modal>
      )}

      <CancelRenewReasonModal
        show={showCancelRenewReasonModal}
        endDate={getDateMonthDayYearFormat(selectedPlanWithPet.plan.endDate)}
        onCancel={() => setShowCancelRenewReasonModal(false)}
        onConfirm={(formValues) => {
          setShowCancelRenewReasonModal(false);
          handleManageSubscriptionAction(formValues);
        }}
        uiText={uiText}
      />

      <ConfirmationModal
        title={`${planStatusActive ? `${uiText?.startAutoRenewHeading1}` : `${uiText?.startAutoRenewHeading2}`}`}
        show={showStartRenewModal}
        onHide={() => setShowStartRenewModal(false)}
        onAction={() => {
          setShowStartRenewModal(false);
          handleManageSubscriptionAction();
        }}
        uiText={uiText}
        screenName={uiText?.startAutoRenewHeading1}
      />

      {toggles?.ANNUAL_PAID_UPFRONT_ONLINE && (
        <SelectPaymentPlanModal
          title={uiText?.reactivationModalTitle}
          subTitle={uiText?.annualCommitmentRequiredTxt}
          show={showPaymentPlanModal}
          onHide={() => setShowPaymentPlanModal(false)}
          onAction={() => {
            setShowPaymentPlanModal(false);
            setShowReactivateModal(true);
            adobeAnalyticsTags.USER_CLICK_ON_REACTIVATE_PAYMENT_PLAN.formDetails.form.subformstepdetails = selectedPaymentPlan;
            if (selectedPaymentPlan === 'yearly') {
              adobeAnalyticsTags.USER_CLICK_ON_REACTIVATE_PAYMENT_PLAN.petInfo[0].petName = paymentPlansDetail[1]?.petName;
              adobeAnalyticsTags.USER_CLICK_ON_REACTIVATE_PAYMENT_PLAN.petInfo[0].orignalPrice = paymentPlansDetail[1]?.actualPrice;
              adobeAnalyticsTags.USER_CLICK_ON_REACTIVATE_PAYMENT_PLAN.petInfo[0].listPrice = paymentPlansDetail[1]?.discountedPrice;
            } else {
              adobeAnalyticsTags.USER_CLICK_ON_REACTIVATE_PAYMENT_PLAN.petInfo[0].petName = paymentPlansDetail[0]?.petName;
              adobeAnalyticsTags.USER_CLICK_ON_REACTIVATE_PAYMENT_PLAN.petInfo[0].orignalPrice = paymentPlansDetail[0]?.actualPrice;
              adobeAnalyticsTags.USER_CLICK_ON_REACTIVATE_PAYMENT_PLAN.petInfo[0].listPrice = paymentPlansDetail[0]?.discountedPrice;
            }
            trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_REACTIVATE_PAYMENT_PLAN);
          }}
        >
          {paymentPlansDetail.length >= 0 && paymentPlansDetail?.map((tenure, index) => (
            <div key={tenure.btnTitle}>
              <PetPaymentDetails
                title={tenure.btnTitle}
                subTitle={tenure.btnSubTitle}
                highlightTitle={tenure.btnHighlightTitle}
                actualPrice={tenure.actualPrice}
                discountedPrice={tenure.discountedPrice}
                perMonthPrice={tenure.perMonthPrice}
                suffix={tenure.btnValue === 'monthly' ? '/mo' : '/yr'}
                value={tenure.btnValue}
                id={index}
                selectedPayment={selectedPaymentPlan}
                setSelectedPayment={selectPaymentPlan}
                displayPlanSavingBanner={tenure.displayPlanSavingBanner}
                toggles={toggles}
              />
            </div>
          ))}
          {petAlreadyEnrolled && (
            <VitalCareTip
              displayCompanion={toggles?.ENABLE_COMPANION_ANIMAL_UI}
              selectedPets={selectedPlanWithPet}
              petAlreadyEnrolled={petAlreadyEnrolled}
              toggles={toggles}
              uiText={uiText}
            />
          )}
        </SelectPaymentPlanModal>
      )}

      <ReactivationModal
        title={`${planStatusActive ? `${uiText?.reactivationModalAutoRenewTitle}` : toggles?.ENABLE_UNIFIED_MEMBERSHIP ? `${uiText?.reactivationModalUmTitle}` : `${uiText?.reactivationModalNonUmTitle}`}`}
        show={showReactivateModal}
        onHide={() => { setShowReactivateModal(false); trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_CANCEL_REACTIVATE_YOUR_PLAN); }}
        termsChecked={toggles?.ANNUAL_PAID_UPFRONT_ONLINE && selectedPaymentPlan === 'monthly' ? termsChecked && monthlyCommitmentTermsChecked : termsChecked}
        selectedPaymentPlan={selectedPaymentPlan}
        onAction={() => {
          trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_REACTIVATE_REACTIVATE_YOUR_PLAN);
          setShowReactivateModal(false);
          membershipPlanReactivate(selectedPlanWithPet?.plan?.walletCardId);
        }}
      >
        <div className="ReactivationTermsText">
          <div className={toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'ReactivationBodyData UM_BodyData' : 'ReactivationBodyData'}>
            <span className="heading" role="heading" aria-level="3">{uiText?.reactivationModalDate}:</span>
            <span className="description">{`${moment().format('MMM DD, YYYY')} - ${moment().add(1, 'year').format('MMM DD, YYYY')}`}</span>
            {(toggles?.ANNUAL_PAID_UPFRONT_ONLINE || !toggles?.ANNUAL_PAID_UPFRONT_ONLINE) && selectedPaymentPlan === 'monthly' && (
              <>
                <span className="heading" role="heading" aria-level="3">{uiText?.reactivationRecurringPayment}:</span>
                <span className="description">{`Billing will occur on the ${moment().format('Do')} of each month`}{checkReactivationDate(moment().format('Do')) && '*'}</span>
              </>
            )}
            {toggles?.ANNUAL_PAID_UPFRONT_ONLINE && selectedPaymentPlan === 'yearly' && (
              <>
                <span className="heading" role="heading" aria-level="3">{uiText?.reactivationYearlyPayment}:</span>
                <span className="description">{`Billing will occur on ${moment().add(1, 'year').format('MMM DD, YYYY')}`}{checkReactivationDate(moment().format('Do'))}</span>
              </>
            )}
            <span className="heading" role="heading" aria-level="3">{uiText?.reactivationAnnualRenewalDate}:</span>
            <span className={checkReactivationDate(moment().format('Do')) && selectedPaymentPlan === 'monthly' ? 'description' : 'descriptionFontSize'}>{moment().add(1, 'year').format('MMMM DD, YYYY')}</span>
            {(toggles?.ANNUAL_PAID_UPFRONT_ONLINE || !toggles?.ANNUAL_PAID_UPFRONT_ONLINE) && selectedPaymentPlan === 'monthly' && (
              <>
                <span className="reactivationText">
                  {`${checkReactivationDate(moment().format('Do')) ? `${uiText?.reactivationModalDisclaimer}` : ''}`}
                </span>
              </>
            )}

          </div>
          <div className="ReactivationTermsSection">
            <Formik>
              <>
                <div className="terms-checkbox-row-section Reactivation-terms-section">
                  <Checkbox
                    id="termsChecked"
                    aria-label={termsChecked
                      ? 'You checked the box and agreed with Petco Vital Care '
                      + 'Terms and Conditions, Terms of Use and Privacy Policy'
                      : 'Check the box if you agree with Petco Vital Care '
                      + 'Terms and Conditions, Terms of Use and Privacy Policy'}
                    handleCheck={() => {
                      setTermsChecked(!termsChecked);
                    }}
                    defaultChecked={termsChecked}
                  />
                  <div>
                    <Text>
                      I agree to Petco's
                      {' '}
                      <a
                        href={termsAndConditionsUMURL}
                        name="termsConditions"
                        className="button-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => trackEvent(adobeAnalyticsTags?.USER_CLICK_TERMS_AND_CONDITIONS_REACTIVATE_YOUR_PLAN)}
                      >
                        {
                          toggles?.ENABLE_UNIFIED_MEMBERSHIP
                            ? 'Vital Care Premier Terms & Conditions'
                            : 'Vital Care Terms & Conditions'
                        }
                      </a>
                      ,
                      {' '}
                      <a
                        href={termsOfUseURL}
                        name="termsUse"
                        className="button-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => trackEvent(adobeAnalyticsTags?.USER_CLICK_TERMS_OF_USE_REACTIVATE_YOUR_PLAN)}
                      >
                        Terms of Use
                      </a>
                      {' '}
                      and
                      {' '}
                      <a
                        href={privacyPolicyURL}
                        name="privacePolicy"
                        className="button-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => trackEvent(adobeAnalyticsTags?.USER_CLICK_PRIVACY_POLICY_REACTIVATE_YOUR_PLAN)}
                      >
                        Privacy Policy
                      </a>. California residents can review our Notice of Financial Incentives&nbsp;
                      <a
                        href={californiaPrivacyPolicyURL}
                        name="californiaPrivacyPolicy"
                        className="button-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >here
                      </a>.
                    </Text>
                  </div>
                </div>
                {toggles?.ANNUAL_PAID_UPFRONT_ONLINE && selectedPaymentPlan === 'monthly' && (
                  <div className="terms-checkbox-row-section Reactivation-terms-section monthlyCommitmentTermsChecked">
                    <Checkbox
                      id="monthlyCommitmentTermsChecked"
                      aria-label={monthlyCommitmentTermsChecked
                        ? 'You checked the box and agreed with Petco Vital Care '
                        + 'Terms and Conditions, Terms of Use and Privacy Policy'
                        : 'Check the box if you agree with Petco Vital Care '
                        + 'Terms and Conditions, Terms of Use and Privacy Policy'}
                      handleCheck={() => {
                        setMonthlyCommitmentTermsChecked(!monthlyCommitmentTermsChecked);
                      }}
                      defaultChecked={monthlyCommitmentTermsChecked}
                    />
                    <div>
                      <Text class="textComponent">
                        {uiText?.reactivationCheckBoxMsg}
                      </Text>
                    </div>
                  </div>
                )}
              </>
            </Formik>
          </div>
        </div>
      </ReactivationModal>

      {!isMobile && (
        <Container>
          <BackTo where={backToDestinations.DASHBOARD} desktop />
        </Container>
      )}

      <DashboardHeader
        plansWithPets={plansWithPets}
        selectedPlanWithPet={selectedPlanWithPet}
        choosePet={(petMasterId) => choosePet(petMasterId)}
        message={planStatusExpired
          ? 'Your pet\'s plan is tied to a payment method that is not working. '
          + 'Please click Edit payment method below to update your card.'
          : null}
        toggles={toggles}
        uiText={uiText}
        screenName="hide"
      />

      {showTransitionMessage && (
        <PlanTransitionMessage
          transitionMessage={transitionMessage}
        />
      )}

      <Container>
        {isMobile && (
          <BackTo where={backToDestinations.DASHBOARD} />
        )}
        {selectedPlanWithPet && (
          <div>
            {toggles?.ENABLE_UNIFIED_MEMBERSHIP
              ? (
                <h2 id="pet-plan-title" tabIndex="0">
                  {selectedPlanWithPet.name}
                  's Vital Care Premier Plan
                </h2>
              )
              : (
                <h2 id="pet-plan-title" tabIndex="0">
                  {selectedPlanWithPet.name}
                  's Vital Care Plan
                </h2>
              )}

            {toggles?.ENABLE_FIRST_GRACE_PERIOD_UI
              && planStatusExpired
              && (
                <div className="RenewalErrorMessage expiredErrorMessage flashMessage" data-testid="RenewalErrorMessage">
                  <span><Icon name="errorIcon" /></span>
                  <span>
                    {calcExpiredString(selectedPlanWithPet?.plan?.paymentUpdateErrorMessage || uiText?.paymentUpdateErrorMessage)}
                  </span>
                </div>
              )}

            {toggles?.ENABLE_REACTIVATION_UI && (
              planStatusCanceled && (
                <div className={toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'activationErrorMessage flashMessage UM_ActivationErrorMessage' : 'activationErrorMessage flashMessage'} data-testid="ReactivationErrorMessage">
                  <span><Icon name="errorIcon" /></span>
                  <span>
                    {calcCanceledString(selectedPlanWithPet?.plan?.planCancelErrorMessage)}
                  </span>
                </div>
              )
            )}

            {!automaticRenew && (
              <div className="RenewalMessage" data-testid="AutomaticRenewMessage" role="alert">
                Auto renewal has been stopped for your plan. Your plan will be effective until {getDateMonthDayYearFormat(selectedPlanWithPet.plan.endDate)}.
              </div>
            )}

            {automaticRenew && onMembershipAutoRenewSuccessMessage && (
              <div className="successMessage flashMessage">
                <span><Icon name="successMessageCheck" /></span>
                {calcAutoRenewalDate(onMembershipAutoRenewSuccessMessage)}
              </div>
            )}

            {toggles?.ENABLE_CC_FLOW && filteredPlansWithPetsCheckedState.length >= 1
              && onMembershipReactivationSuccessMessage && !errorDuringPaymentMethodUpdate && (
              showUpdatePlansCCModal && (
              <UpdatePlansCCModal
                title={`Apply updated payment method to your other Vital Care ${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'Premier' : ''} Plans?`}
                successMessage={onMembershipReactivationSuccessMessage}
                show={showUpdatePlansCCModal}
                onHide={() => { setShowUpdatePlansCCModal(false); trackEvent(adobeAnalyticsTagsAPU?.REACTIVATE_USER_CLICK_ON_SELECTS_PETS_AND_CLOSE); }}
                onAction={() => {
                  setShowUpdatePlansCCModal(false);
                  sendAdobeTracker(selectedPlansForUpdateCC, 'reactivate');
                  updateAllPlansCCMethod(selectedPlansForUpdateCC, updatedWalletID);
                }}
                toggles={toggles}
              >
                {renderUpdatePlansCCModalBody()}
              </UpdatePlansCCModal>
              )
            )}

            {onMembershipReactivationSuccessMessage && (
              <div className="successMessage flashMessage">
                <span><Icon name="successMessageCheck" /></span>
                {onMembershipReactivationSuccessMessage}
              </div>
            )}

            {toggles?.ENABLE_CC_FLOW && filteredPlansWithPetsCheckedState.length >= 1
              && (onMembershipRenewalSuccessMessage || paymentMethodUpdated)
              && !errorDuringPaymentMethodUpdate ? (
                showUpdatePlansCCModal && (
                <UpdatePlansCCModal
                  title={`Apply updated payment method to your other Vital Care ${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'Premier' : ''} Plans?`}
                  successMessage="Payment method updated successfully!"
                  show={showUpdatePlansCCModal}
                  onHide={() => {
                    setShowUpdatePlansCCModal(false);
                    // eslint-disable-next-line no-unused-expressions
                    if (isGracePeriodFlow === true) {
                      trackEvent(adobeAnalyticsTagsAPU?.GRACE_PERIOD_USER_CLICK_ON_SELECTS_PETS_AND_CLOSE);
                    } else {
                      trackEvent(adobeAnalyticsTagsAPU?.EDIT_YOUR_CARD_USER_CLICK_ON_SELECTS_PETS_AND_CLOSE);
                    }
                  }}
                  onAction={() => {
                    setShowUpdatePlansCCModal(false);
                    sendAdobeTracker(selectedPlansForUpdateCC, 'updatePayment');
                    updateAllPlansCCMethod(selectedPlansForUpdateCC, updatedWalletID);
                  }}
                  toggles={toggles}
                >
                  {renderUpdatePlansCCModalBody()}
                </UpdatePlansCCModal>
                )
              ) : (onMembershipRenewalSuccessMessage && (
              <div className="successMessage flashMessage">
                <span><Icon name="successMessageCheck" /></span>
                {onMembershipRenewalSuccessMessage}
              </div>
              ))}


            {isPaymentMethodInvalid && (
              <div className="RenewalMessage">
                We are unable to find a payment method associated with your plan. Please add a payment method.
              </div>
            )}

            <Card>
              <List>
                <List.Item>
                  <Card.Header tabIndex={0}>
                    <span role="heading" aria-level="3">{uiText?.memberSinceTxt}</span>
                  </Card.Header>
                  <Card.Body tabIndex={0} className="Card__Body__Color" data-testid="memberSince-date">
                    {moment(selectedPlanWithPet?.plan?.createDt).format('MMM DD, YYYY')}
                  </Card.Body>
                </List.Item>
                <List.Item>
                  <Card.Header tabIndex={0}>
                    <span role="heading" aria-level="3">{uiText?.planEffectiveDateTxt}</span>
                  </Card.Header>
                  <Card.Body tabIndex={0} className={toggles?.ANNUAL_PAID_UPFRONT_ONLINE ? 'Card__Body__Color' : ''} data-testid="effective-date">
                    {renderPaymentEffectiveDate()}
                  </Card.Body>
                </List.Item>

                <List.Item>
                  <Card.Header tabIndex={0}>
                    <span role="heading" aria-level="3">{uiText?.planIdTxt}</span>
                  </Card.Header>
                  <Card.Body tabIndex={0} className={toggles?.ANNUAL_PAID_UPFRONT_ONLINE ? 'Card__Body__Color' : ''}>
                    <span data-testid="membership-number">{selectedPlanWithPet.plan.id}</span>
                  </Card.Body>
                </List.Item>

                <List.Item>
                  <Card.Header tabIndex={0}>
                    <span role="heading" aria-level="3">{uiText?.petInformationTxt}</span>
                  </Card.Header>
                  <Card.Body tabIndex={0} className={toggles?.ANNUAL_PAID_UPFRONT_ONLINE ? 'Card__Body__Color' : ''}>
                    {selectedPlanWithPet.pet
                      && (
                        <span data-testid="pet-information">
                          {checkPetInformation(selectedPlanWithPet.pet.name,
                            selectedPlanWithPet.pet.primaryBreed,
                            selectedPlanWithPet.pet.birthDate)}
                          {/* Note: If PGR has handled the pet Breed change to Unknown/Other - Small Animal
                          to Small Pet then remove the above the function and uncomment formatPetInformation */}

                          {/* {formatPetInformation(selectedPlanWithPet.pet.name,
                            selectedPlanWithPet.pet.primaryBreed,
                            selectedPlanWithPet.pet.birthDate)} */}
                        </span>
                      )}
                  </Card.Body>
                </List.Item>

                <List.Item>
                  <Card.Header tabIndex={0}>
                    <span
                      role="heading"
                      aria-level="3"
                      id={isThereAffiliateDiscount && isThereAffiliateDiscount?.amount > 0 ? 'recurring-payment-header-without-spacing' : 'recurring-payment-header-with-spacing'}
                    >
                      {uiText?.paymentTxt}
                    </span>
                  </Card.Header>
                  <Card.Body className={toggles?.ANNUAL_PAID_UPFRONT_ONLINE ? 'Card__Body__Color' : ''}>
                    {petCost && (
                      <div tabIndex={0}>
                        {petCost?.discounts?.find((discount) => discount.displayName === AffiliateDiscountType.AFFILIATE_REFERRAL_NAME && discount.amount > 0) ? (
                          <>
                            <div className="recurring-payment-section">
                              <h5 className="recurring-payment-headline">Discounted Amount (first 3 months)</h5>
                              <span className="recurring-payment" data-testid="recurring-payment">
                                ${petCost.total}/month {appendCardInfo(planWallet)}
                              </span>
                            </div>
                            <div className="recurring-payment-section">
                              <h5 className="recurring-payment-headline">Regular Amount (after discount period)</h5>
                              <span className="recurring-payment" data-testid="recurring-payment">
                                ${getAfterMonthsPrice()}/month {appendCardInfo(planWallet)}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span data-testid="recurring-payment">
                            ${petCost?.total}{paymentTenure} {appendCardInfo(planWallet)}
                          </span>
                        )}
                      </div>
                    )}
                    <ButtonLink
                      className="editPaymentMethod"
                      onClick={() => { trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_EDIT_PAYMENT_METHOD); setUpdatedPaymentMethodLink('edit_payment'); showModalInfo(); }}
                      tabIndex="0"
                      variant="textLink"
                      aria-label="Edit payment method"
                    >
                      {planWallet ? 'Edit payment method' : 'Add payment method'}
                    </ButtonLink>
                  </Card.Body>
                </List.Item>

                <List.Item>
                  <div className="wrapper">
                    <div className="left-container" tabIndex={0}>
                      <Card.Header>
                        <span role="heading" aria-level="3">{uiText?.autoRenewPlanTxt}</span>
                      </Card.Header>
                      <span className={toggles?.ANNUAL_PAID_UPFRONT_ONLINE ? 'Card__Body__Color' : ''}>
                        on {getDateMonthDayYearFormat(selectedPlanWithPet.plan.endDate)}
                      </span>
                    </div>
                    {planStatusActive && (
                      <div className="right-container">
                        <ToggleableCheckbox
                          data-testid="renew-toggle"
                          id="renew-toggle"
                          tabIndex="0"
                          checked={automaticRenew}
                          onClick={() => handleRenewToggle()}
                          onChange={(e) => handleRenewChange(e)}
                          enabled={planStatusActive}
                          annualPaidUpFront={toggles?.ANNUAL_PAID_UPFRONT_ONLINE}
                        />
                      </div>
                    )}
                  </div>
                </List.Item>
                <List.Footer className={listFooter()}>
                  <table className="SummaryTable">
                    <caption className={toggles?.ANNUAL_PAID_UPFRONT_ONLINE ? 'SummaryTableTitle' : ''}>Price Summary</caption>
                    <thead />
                    <tbody>
                      {petCost && (petCost.discounts.length > 0 || parseFloat(petCost.tax) > 0) && (
                        <tr>
                          <th scope="row" className="SummaryTable__Th" tabIndex={0}>
                            <span>
                              Plan Price
                            </span>
                          </th>
                          <td className="SummaryTable__Td" tabIndex={0}>
                            <span>
                              ${parseFloat(planPrice).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      )}

                      {petCost && petCost.discounts.map(
                        (discount) => (
                          discount.amount > 0 && (
                            <tr key={discount.displayName}>
                              {discount.displayName === AffiliateDiscountType.AFFILIATE_REFERRAL_NAME && discount.amount > 0
                                ? (
                                  <th scope="row" className="SummaryTable__Th" tabIndex={0}>
                                    <span>{discount.displayName}*</span>
                                  </th>
                                ) : (
                                  <th scope="row" className="SummaryTable__Th" tabIndex={0}>
                                    {discount.displayName}
                                  </th>
                                )}
                              <td className="SummaryTable__Td" tabIndex={0}>
                                <span aria-label="minus">-</span>
                                $
                                {parseFloat(discount.amount).toFixed(2)}
                                <div className="sr-only" aria-label="Discounted">Discounted</div>
                              </td>
                            </tr>
                          )
                        ),
                      )}

                      {isThereAffiliateDiscount && isThereAffiliateDiscount.amount > 0 && (
                        <div className="affiliate-tax" />
                      )}

                      {petCost && parseFloat(petCost.tax) > 0 && (
                        <tr>
                          <th scope="row" className={toggles?.ANNUAL_PAID_UPFRONT_ONLINE ? 'SummaryTable__Th SummaryTable__Th__Tax' : 'SummaryTable__Th'} tabIndex={0}>
                            <span>Tax</span>
                          </th>
                          <td className="SummaryTable__Td" tabIndex={0}>
                            <span>
                              $
                              {parseFloat(petCost.tax).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      )}

                      {petCost
                        ? (
                          <tr>
                            <th scope="row" className="SummaryTable__Th" tabIndex={0}>
                              <span className="footer-total">Total</span>
                            </th>
                            <td className="SummaryTable__Td" tabIndex={0}>
                              <Price asterisk={isThereAffiliateDiscount} value={petCost.total} annualPaidUpFront={toggles?.ANNUAL_PAID_UPFRONT_ONLINE} paymentTenure={petCost?.paymentPlan} />
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <th scope="row" tabIndex={0}>
                              <span className="footer-total">Total</span>
                            </th>
                            <td className="SummaryTable__Td" tabIndex={0}>
                              <Price value={planAnnualPrice} annualPaidUpFront={toggles?.ANNUAL_PAID_UPFRONT_ONLINE} paymentTenure={petCost?.paymentPlan} />
                              <span className="price-observation">* Plus tax if applicable</span>
                            </td>
                          </tr>
                        )}
                      {isThereAffiliateDiscount && isThereAffiliateDiscount.amount > 0 ? <div><p className="referral-savings-message">*Referral savings valid for first 3 months</p></div> : ''}

                    </tbody>
                  </table>
                </List.Footer>
              </List>
            </Card>

            {(!toggles?.ENABLE_REACTIVATION_UI && !toggles?.ENABLE_FIRST_GRACE_PERIOD_UI) ? (
              !planStatusActive && (
                <Button
                  type="submit"
                  disabled={!planWallet}
                  className={`membership-details-buttons ${planWallet ? '' : 'grayed-button'}`}
                  onClick={() => handleReactivateMembership(planWallet)}
                  label="Reactivate Membership Plan"
                />
              )
            ) : !toggles?.ENABLE_REACTIVATION_UI ? (
              planStatusCanceled && (
                <Button
                  type="submit"
                  disabled={!planWallet}
                  className={`membership-details-buttons ${planWallet ? '' : 'grayed-button'}`}
                  onClick={() => handleReactivateMembership(planWallet)}
                  label="Reactivate Membership Plan"
                />
              )
            ) : !toggles?.ENABLE_FIRST_GRACE_PERIOD_UI ? (
              planStatusExpired && (
                <Button
                  type="submit"
                  disabled={!planWallet}
                  className={`membership-details-buttons ${planWallet ? '' : 'grayed-button'}`}
                  onClick={() => handleReactivateMembership(planWallet)}
                  label="Reactivate Membership Plan"
                />
              )
            ) : null}
          </div>
        )}

        {!toggles?.ENABLE_REACTIVATION_UI && (
          <ErrorModal
            title={errorModalTitle}
            show={errorDuringReactivation}
          >
            <button
              className="btn btn-primary retry-button"
              onClick={() => {
                dispatch(dashboardInfoSlice.actions.setOnMembershipReactivationError({ occurred: false, message: null }));
              }}
            >
              Ok
            </button>
          </ErrorModal>
        )}
      </Container>
    </div>
  );
}

export default MembershipDetails;

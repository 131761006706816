import React from 'react';
import { Modal } from 'react-bootstrap';
import { handleKeyUp } from 'utils';
import { Button } from '../Button';
import './confirmation-modal.css';

const ConfirmationModal = (props) => {
  const {
    show,
    title,
    onHide,
    children,
    onAction,
    uiText,
    screenName,
  } = props;

  return (
    <Modal show={show} onHide={onHide}>
      <div className="ConfirmationModal">
        <Modal.Title>
          <h1>
            {title}
          </h1>
        </Modal.Title>

        <div
          className="CloseButton"
          role="button"
          tabIndex="0"
          aria-label="Close"
          onClick={onHide}
          onKeyUp={(e) => handleKeyUp(e, onHide)}
        >
          <span>✖</span>
        </div>

        <Modal.Body>
          <p>
            {uiText?.startAutoRenewDescriptionTxt}
          </p>
          {children}
        </Modal.Body>
        <Modal.Footer className={`${screenName === uiText?.startAutoRenewHeading1 && 'noMarginTop'}`}>
          <Button
            onClick={() => onAction() && onHide()}
            label="Yes"
            className="confirmButton"
          />
          <Button
            onClick={onHide}
            label="No"
            className="secondary"
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

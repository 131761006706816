import { createSlice } from '@reduxjs/toolkit';

const maintenanceError = createSlice({
  name: 'maintenanceError',
  initialState: false,
  reducers: {
    setTrue: () => true,
    setFalse: () => false,
  },
});

export default maintenanceError;

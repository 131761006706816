/* eslint-disable */
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Button } from 'components-v2/shared/Button-v1';
import Error from 'components-v2/shared/Error';
import { customer } from 'store/slices';
import CreditCardComboBox from './CreditCardComboBox'; // TODO: Convert to use CSS Variables
import NCRFrame from './NCRFrame';
import viewModel from './viewModel';

import classnames from 'classnames';
import Icon from 'components-v2/shared/Icon/Icon';
import { getCardInfoForAdobeTracking, trackEvent } from 'utils';
import { adobeAnalyticsTags, StatusPlan } from 'utils/constGlobal';
import AciPayment from '../AciPayment';
import SelectPaymentMethodTable from '../SelectPaymentMethod/SelectPaymentMethodTable';
import './credit-card-selection.css';

let flag = true;

const CreditCardSelection = ({
  onSelect,
  onClose,
  selectOnChange,
  onShowMobileAddNewForm,
  showFrameInModal = false,
  id = 'paymentMethod',
  name = 'paymentMethod',
  errorModalTitle,
  flow,
  planStatus,
  toggles,
  paymentLinkFlow,
  errorAddingPayment,
  setErrorAddingPayment
}) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [confirmRef, setConfirmRef] = useState(null);
  const {
    paymentMethods = [],
    paymentMethodsError,
    fetchPaymentMethods,
    isMobile,
    useNativeWallet,
    useAciPayment,
    uiText,
  } = useSelector(viewModel);

  const umMessageClass = toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'UM_ActivationErrorMessage' : '';

  useEffect(() => {
    if (!paymentMethods.length > 0 && !isLoaded) {
      dispatch(fetchPaymentMethods());
      setIsLoaded(true);
    }
  }, [paymentMethods, isLoaded, dispatch, fetchPaymentMethods]);

  const refOuter = useRef(null);
  const firstFocusableRef = useRef(null);
  const lastFocusableRef = useRef(null);

  useEffect(() => {
    const focusableElements = refOuter.current?.querySelectorAll('[tabindex]');
    if (focusableElements) {
      firstFocusableRef.current = focusableElements[0];
      lastFocusableRef.current = focusableElements[focusableElements.length - 1];
    }
  }, [confirmRef]);

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Tab') {
      if (document.activeElement === lastFocusableRef.current) {
        e.preventDefault();
        firstFocusableRef.current?.focus();
      }
    }
  }, [confirmRef]);

  const confirmBtnRef = useCallback((node) => {
    setConfirmRef(node);
  }, []);

  const handleNCRModalDismissal = () => { setShowAddNewForm(false); };

  const handleSubmit = (props) => {
    flag = true;
    dispatch(customer.actions.resetPaymentMethodError(null));
    if (props?.value) {
      dispatch(customer.actions.setSelectedPayment(props?.value));
    } else {
      dispatch(customer.actions.setSelectedPayment(props));
    }
    if (props?.values?.paymentMethod) {
      onSelect(props.values.paymentMethod);
    } else {
      onSelect(props);
    }
  };

  useEffect(() => {
    if (!showAddNewForm && errorModalTitle) {
      const adobeTrackerForNcrPaymentErrorTag = adobeAnalyticsTags?.USER_VIEWS_NCR_PAYMENT_ERROR_ON_EDIT_PAYMENT_METHOD_AND_UPDATE_CARD_FLOW;
      if (flow === 'Reactivation') {
        if (paymentLinkFlow === 'reactivate_payment') {
          adobeTrackerForNcrPaymentErrorTag.formDetails.form.formstepname = 'reactivate warning';
          trackEvent(adobeTrackerForNcrPaymentErrorTag);
        }
      } else if (flow === 'Renew') {
        if (paymentLinkFlow === 'edit_payment') {
          adobeTrackerForNcrPaymentErrorTag.formDetails.form.formstepname = 'edit payment method';
          trackEvent(adobeTrackerForNcrPaymentErrorTag);
        }
        if (paymentLinkFlow === 'update_payment') {
          adobeTrackerForNcrPaymentErrorTag.formDetails.form.formstepname = 'update payment method';
          trackEvent(adobeTrackerForNcrPaymentErrorTag);
        }
      }
    }
  }, [flow, errorModalTitle, paymentLinkFlow, showAddNewForm]);

  useEffect(() => {
    if (errorAddingPayment && useAciPayment) {
      setShowAddNewForm(true);
    }
  }, [useAciPayment, errorAddingPayment]);

  const paymentForm = useAciPayment ? (
    <>
      <button className="ACIPaymentModalBackButton" onClick={() => {
        setErrorAddingPayment(false)
        dispatch(customer.actions.clearPaymentMethodError());
        setShowAddNewForm(false)
        }}>
        {"< Back"}
      </button>
      <button className="ACIPaymentModalCloseButton" onClick={() => {
        if (paymentLinkFlow === 'edit_payment') {
          trackEvent(adobeAnalyticsTags?.USER_CLICK_CLOSE_ICON_ON_EDIT_PAYMENT_METHOD_MODAL);
        } else if (paymentLinkFlow === 'update_payment') {
          trackEvent(adobeAnalyticsTags?.USER_CLICK_CLOSE_ICON_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD_MODAL);
        } else if (paymentLinkFlow === "reactivate_payment") {
          trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_CLOSE_ICON_BUTTON_ON_ADD_A_NEW_CREDIT_CARD_MODAL_DURING_REACTIVATION);
        }
        onClose();
      }}>
        <Icon name='closeModal' />
      </button>
      <br />
      <div className='PaymentModalHeader'>{uiText?.paymentModalHeading}</div>
      <div className='ACIPaymentContainer'>
        <AciPayment
          onCancel={(obj) => {
            trackEvent(adobeAnalyticsTags.USER_CLICK_CLOSE_ICON_ON_EDIT_PAYMENT_METHOD_MODAL);
            console.info(JSON.stringify(obj, null, 2));
            setErrorAddingPayment(false);
            dispatch(customer.actions.clearPaymentMethodError());
            handleNCRModalDismissal();
          }}
          onCreditCardAddition={({ created, walletId }) => {
            console.info(JSON.stringify({ created, walletId }, null, 2));
            const cardInfo = getCardInfoForAdobeTracking(sortedPaymentMethods, walletId);
            if (created) {
              if (toggles?.ENABLE_FIRST_GRACE_PERIOD_UI && planStatus === StatusPlan.EXPIRED && paymentLinkFlow !== 'update_payment' && paymentLinkFlow !== 'edit_payment') {
                trackEvent(adobeAnalyticsTags?.ADD_NEW_PAYMENT_METHOD_FIRST_GRACE_PERIOD_SUCCESS);
              }
              if (paymentLinkFlow === 'update_payment') {
                adobeAnalyticsTags.USER_SUCCESSFULLY_ADDED_CREDIT_CARD_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
                trackEvent(adobeAnalyticsTags?.USER_SUCCESSFULLY_ADDED_CREDIT_CARD_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD);
              } else if (paymentLinkFlow === 'edit_payment') {
                adobeAnalyticsTags.USER_SUCCESSFULLY_ADDED_CREDIT_CARD_ON_EDIT_PAYMENT_METHOD.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
                trackEvent(adobeAnalyticsTags?.USER_SUCCESSFULLY_ADDED_CREDIT_CARD_ON_EDIT_PAYMENT_METHOD);
              } else if (paymentLinkFlow === "reactivate_payment") {
                adobeAnalyticsTags.USER_SUCCESSFULLY_ADDED_NEW_CREDIT_CARD_DURING_REACTIVATION.serviceDetails.orderPaymentType = cardInfo?.[0]?.brand;
                trackEvent(adobeAnalyticsTags?.USER_SUCCESSFULLY_ADDED_NEW_CREDIT_CARD_DURING_REACTIVATION);
              }
            }
            if (created && selectOnChange) {
              // onSelect(walletId);
              // commenting/removing the above function because, by default on adding a new card,
              // onSelect is triggered and wallet card id of the plan is updated,
              // whereas it should be updated post clicking confirm CTA(VLTR-327) on onCardSelection PopUp.
              setShowAddNewForm(false);
            } else {
              setShowAddNewForm(false);
            }
          }}
          errorAddingPayment={errorAddingPayment}
          setErrorAddingPayment={setErrorAddingPayment}
          uiText={uiText}
        />
      </div>
    </>)
    : (
      <>
        <button className="iframe-close-button" onClick={() => setShowAddNewForm(false)}><Icon name='closeModal' /></button>
        <br />
        <NCRFrame
          dismissNCRFrame={handleNCRModalDismissal}
          onCreditCardAddition={(created, walletId) => {
            if (created) {
              if (toggles?.ENABLE_FIRST_GRACE_PERIOD_UI && planStatus === StatusPlan.EXPIRED && paymentLinkFlow !== 'update_payment' && paymentLinkFlow !== 'edit_payment') {
                trackEvent(adobeAnalyticsTags?.ADD_NEW_PAYMENT_METHOD_FIRST_GRACE_PERIOD_SUCCESS);
              }
              if (paymentLinkFlow === 'update_payment') {
                trackEvent(adobeAnalyticsTags?.USER_CLICK_CONTINUE_SUCCESS_ADD_NEW_ON_UPDATE_PAYMENT_METHOD);
              }
              if (paymentLinkFlow === 'edit_payment') {
                trackEvent(adobeAnalyticsTags?.USER_CLICK_CONTINUE_SUCCESS_ADD_NEW_ON_EDIT_PAYMENT_METHOD);
              }
            }
            if (created && selectOnChange) {
              // onSelect(walletId);
              // commenting/removing the above function because, by default on adding a new card,
              // onSelect is triggered and wallet card id of the plan is updated,
              // whereas it should be updated post clicking confirm CTA(VLTR-327) on onCardSelection PopUp.
              setShowAddNewForm(false);
            } else {
              setShowAddNewForm(false);
            }
          }}
        />
      </>);

  const sortedPaymentMethods = [...paymentMethods].sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

  return (
    <>
      {showAddNewForm && (
        <>
          {showFrameInModal
            && (
              <Modal
                className={classnames("complete-your-purchase-modal", {
                  "aci-payment-modal": useAciPayment
                })}
                show={showAddNewForm}
                data-testid="ncr-modal"
                backdrop="static"
                keyboard={false}
                centered
              >
                <button className="iframe-close-button" onClick={() => setShowAddNewForm(false)}>x</button>
                <br />
                {paymentForm}
              </Modal>
            )}
          {!showFrameInModal && paymentForm}
        </>
      )}

      {!showAddNewForm && !useAciPayment ? (
        <div className="SelectPaymentMethod">
          {(flow === 'Reactivation' || 'Renew') && errorModalTitle && <p className={`activationErrorMessage flashMessage ${umMessageClass}`}><span><Icon name="errorIcon" /></span>{errorModalTitle}</p>}
          {(flow === 'Reactivation' || 'Renew') && errorModalTitle && flag && paymentLinkFlow === 'update_payment' && trackEvent(adobeAnalyticsTags?.USER_SELECT_EXISTING_ON_UPDATE_PAYMENT_METHOD_WITH_ERROR)}
          {flag = false}
          <Formik
            initialValues={{ paymentMethod: '' }}
            validateOnMount
            validationSchema={Yup.object().shape({ paymentMethod: Yup.string().required('Required') })}
            enableReinitialize
          >
            {(props) => (
              <div>
                <Form ref={refOuter} onKeyDown={onKeyDown}>
                  <CreditCardComboBox
                    id={id}
                    name={name}
                    paymentMethods={paymentMethods}
                    selectedOption={props.values.paymentMethod}
                    onSelectAddNewCard={() => {
                      if (isMobile && useNativeWallet && !useAciPayment) {
                        onShowMobileAddNewForm();
                      } else {
                        if (toggles?.ENABLE_FIRST_GRACE_PERIOD_UI && planStatus === StatusPlan.EXPIRED && paymentLinkFlow !== 'update_payment' && paymentLinkFlow !== 'edit_payment') {
                          trackEvent(adobeAnalyticsTags?.SELECT_ADD_NEW_PAYMENT_METHOD_FIRST_GRACE_PERIOD);
                        }
                        if (paymentLinkFlow === 'update_payment') {
                          trackEvent(adobeAnalyticsTags?.USER_SELECT_ADD_NEW_ON_UPDATE_PAYMENT_METHOD);
                        }
                        if (paymentLinkFlow === 'edit_payment') {
                          trackEvent(adobeAnalyticsTags?.USER_SELECT_ADD_NEW_CARD_ON_EDIT_PAYMENT_METHOD);
                        }
                        setShowAddNewForm(true);
                      }
                    }}
                    onChange={async (nameParam, value) => {
                      const filteredVal = paymentMethods.filter(payMethod => payMethod.walletId === value);

                      if (toggles?.ENABLE_FIRST_GRACE_PERIOD_UI && planStatus === StatusPlan.EXPIRED && paymentLinkFlow !== 'update_payment' && paymentLinkFlow !== 'edit_payment') {
                        adobeAnalyticsTags.SELECT_EXISTING_PAYMENT_METHOD_FIRST_GRACE_PERIOD.serviceDetails.orderPaymentType = filteredVal?.[0]?.brand;
                        trackEvent(adobeAnalyticsTags?.SELECT_EXISTING_PAYMENT_METHOD_FIRST_GRACE_PERIOD);
                      }
                      if (paymentLinkFlow === 'update_payment') {
                        adobeAnalyticsTags.USER_SELECT_EXISTING_ON_UPDATE_PAYMENT_METHOD.serviceDetails.orderPaymentType = filteredVal?.[0]?.brand;
                        trackEvent(adobeAnalyticsTags?.USER_SELECT_EXISTING_ON_UPDATE_PAYMENT_METHOD);
                      }
                      if (paymentLinkFlow === 'edit_payment') {
                        adobeAnalyticsTags.USER_SELECT_EXISTING_CARD_ON_EDIT_PAYMENT_METHOD.serviceDetails.orderPaymentType = filteredVal?.[0]?.brand;
                        trackEvent(adobeAnalyticsTags?.USER_SELECT_EXISTING_CARD_ON_EDIT_PAYMENT_METHOD);
                      }
                      await props.setFieldValue(nameParam, value);
                      await props.handleChange(nameParam, value);
                      await props.setFieldTouched(nameParam, value);
                    }}
                  />
                  {props.errors.paymentMethod && props.touched.paymentMethod && (
                    <Error name="paymentMethod">{props.error}</Error>
                  )}

                  {props.errors.paymentMethod && props.touched.paymentMethod && props.error !== undefined && (
                    (() => {
                      if (toggles?.ENABLE_FIRST_GRACE_PERIOD_UI && planStatus === StatusPlan.EXPIRED && paymentLinkFlow !== 'update_payment' && paymentLinkFlow !== 'edit_payment') {
                        return trackEvent(adobeAnalyticsTags?.ADD_NEW_PAYMENT_METHOD_FIRST_GRACE_PERIOD_FAIL);
                      }
                      if (paymentLinkFlow === 'update_payment') { return trackEvent(adobeAnalyticsTags?.USER_CLICK_CONTINUE_FAIL_ADD_NEW_ON_UPDATE_PAYMENT_METHOD); }
                      if (paymentLinkFlow === 'edit_payment') { return trackEvent(adobeAnalyticsTags?.USER_CLICK_CONTINUE_FAIL_ADD_NEW_ON_EDIT_PAYMENT_METHOD); }
                    })()
                  )}
                  {paymentMethodsError && (<small className="error">{paymentMethodsError}</small>) && (
                    (() => {
                      if (toggles?.ENABLE_FIRST_GRACE_PERIOD_UI && planStatus === StatusPlan.EXPIRED && paymentLinkFlow !== 'update_payment' && paymentLinkFlow !== 'edit_payment') {
                        return trackEvent(adobeAnalyticsTags?.ADD_NEW_PAYMENT_METHOD_FIRST_GRACE_PERIOD_FAIL);
                      }
                      if (paymentLinkFlow === 'update_payment') { return trackEvent(adobeAnalyticsTags?.USER_CLICK_CONTINUE_FAIL_ADD_NEW_ON_UPDATE_PAYMENT_METHOD); }
                      if (paymentLinkFlow === 'edit_payment') { return trackEvent(adobeAnalyticsTags?.USER_CLICK_CONTINUE_FAIL_ADD_NEW_ON_EDIT_PAYMENT_METHOD); }
                    })()
                  )}

                  {onClose && (
                    <Button
                      tabIndex={0}
                      className="secondary"
                      onClick={(e) => {
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {
                    props.values.paymentMethod && (
                      <Button
                        innerRef={confirmBtnRef}
                        tabIndex={0}
                        type="submit"
                        onClick={() => handleSubmit(props)}
                      >
                        Confirm
                      </Button>
                    )
                  }

                </Form>

              </div>

            )}
          </Formik>
        </div>
      ) : !showAddNewForm && (
        <>
          <button className="ACIPaymentModalCloseButton" onClick={onClose}>
            <Icon name='closeModal' />
          </button>
          <br />
          <div className='PaymentModalHeader'>{uiText?.paymentModalHeading}</div>
          <SelectPaymentMethodTable
            renderedInModal={true}
            sortedPaymentMethods={sortedPaymentMethods}
            onShowMobileAddNewForm={onShowMobileAddNewForm}
            setShowAddNewForm={setShowAddNewForm}
            selectOnChange={selectOnChange}
            onSelect={handleSubmit}
            onClose={onClose}
            flag={flag}
            umMessageClass={umMessageClass}
            flow={flow}
            errorModalTitle={errorModalTitle}
            paymentLinkFlow={paymentLinkFlow}
          />
        </>)
      }
    </>
  );
};

export default CreditCardSelection;

import React from 'react';

import Container from 'components-v2/shared/Container';
import Icon from 'components-v2/shared/Icon/Icon';
import { Text } from 'components-v2/shared/Elements';

import './plan-transition-message.css';

const TransitionItemMessage = ({ petName, answer }) => (
  <span>
    <br />
    {`${petName} - ${answer.selectedBenefitText}`}
  </span>
);

const PlanTransitionMessage = ({ transitionMessage = { message: '', onError: false } }) => {
  const getInnerContainerClass = () => ((transitionMessage?.onError)
    ? 'failed-inner-container'
    : 'successfully-inner-container');

  const getMessageClass = () => ((transitionMessage?.onError) ? 'failed-message' : 'congrats-message');
  return (
    <div className="TransitionMessage">
      <Container>
        <div className={getInnerContainerClass()}>
          {!transitionMessage?.onError && (
            <>
              <div className="TransitionMessageHeader">
                <Icon className="icon" name="checked" size="15" />
                <Text className="congrats">Congrats, decision confirmed!</Text>
              </div>
              <div>
                <Text className={getMessageClass()}>
                  You’ve made the following selection for your pets:
                  <br />
                  {transitionMessage
                    ?.transitions
                    ?.map(({ petName, answer }) => (
                      <TransitionItemMessage
                        key={petName}
                        petName={petName}
                        answer={answer}
                      />
                    ))}
                </Text>
              </div>
            </>
          )}
          {transitionMessage?.onError && (
            <div>
              <Text className={getMessageClass()}>{transitionMessage?.message}</Text>
            </div>
          )}
        </div>
      </Container>

    </div>
  );
};

export default PlanTransitionMessage;

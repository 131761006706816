/* eslint-disable no-console */
let monitoringInterval = null;

const logMarks = (marks) => {
  marks.forEach(m => console.log(`%cName: ${m.name} Duration: ${m.startTime / 1000}`, 'color: aqua'));
};

const logMeasures = (measures) => {
  const performanceLevel = Object.freeze({
    GOOD: 'green', FAIR: 'orange', POOR: 'red', UNKNOWN: 'inherit',
  });

  console.log('%c____________________________________________________________________________________________________________', 'font-weight: bold;');

  measures.forEach((m) => {
    const durationInSeconds = m.duration / 1000;
    let level = performanceLevel.UNKNOWN;

    if (durationInSeconds < 1) level = performanceLevel.GOOD;

    if (durationInSeconds > 1) {
      if (durationInSeconds < 3) level = performanceLevel.FAIR;
      if (durationInSeconds > 3) level = performanceLevel.POOR;
    }

    console.log(`%cName: ${m.name} Duration: ${durationInSeconds}`, `color: ${level}`);
  });
};

export const monitor = (interval = 5000) => {
  if (performance && performance.mark) {
    const initialMarks = performance.getEntriesByType('mark');

    if (initialMarks.length === 0) console.log('No marks created.');
    else {
      console.log(`${initialMarks.length} marks`);
      initialMarks.forEach((m) => console.log(m.name));
    }

    let measuresLength = 0;
    let marksLength = 0;

    monitoringInterval = setInterval(() => {
      const marks = performance.getEntriesByType('mark');

      if (marksLength !== marks.length) {
        marksLength = marks.length;
        logMarks(marks);
      }

      const measures = performance.getEntriesByType('measure');

      if (measuresLength !== measures.length) {
        measuresLength = measures.length;
        logMeasures(measures);
      }
    }, interval);
  } else {
    console.log('This browser does not support performance monitoring.');
  }
};

export const stopMonitoring = () => {
  clearInterval(monitoringInterval);
};

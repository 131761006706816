import React from 'react';

import { getDateFullMonthDayYearFormat } from 'utils';

import BackToButton from 'components-v2/shared/BackToButton';
import { Button } from 'components-v2/shared/Button-v1';
import Card from 'components-v2/shared/Card-v1';
import Container from 'components-v2/shared/Container';
import List from 'components-v2/shared/List-v1';
import { Title } from 'components-v2/shared/Elements';

import { Thumbnail } from 'components-v2/claim/ClaimsUploadInvoices/Thumbnail';

import './claim-review.css';

const ClaimReview = ({
  petName, claimClinic, claimDate, claimFile, doneReviewing, goToClinicSelection,
}) => (
  <div className="ReviewClaim">
    <Container>
      <BackToButton label="Back to Upload" />
      <Title>
        Review your Submission
      </Title>

      <Card>
        <List>

          <List.Item>
            <Title className="pet-name">
              {petName}
            </Title>
          </List.Item>

          <List.Item>
            <List.ItemLeftWrapper>
              <List.ItemTitle>Vet Office</List.ItemTitle>
              <List.SubItem className="item-title">{claimClinic.name}</List.SubItem>
              <List.SubItem className="item-description">
                {claimClinic.address}
                ,
                {claimClinic.city} {claimClinic.state} {claimClinic.zipCode}
              </List.SubItem>
            </List.ItemLeftWrapper>
            <List.ItemRightWrapper className="edit" onClick={(e) => goToClinicSelection(e)} role="button" aria-label="edit your submission" tabIndex="0">
              Edit
            </List.ItemRightWrapper>
          </List.Item>

          <List.Item>
            <List.ItemLeftWrapper>
              <List.ItemTitle>Date of Visit</List.ItemTitle>
              <List.SubItem>{getDateFullMonthDayYearFormat(claimDate)}</List.SubItem>
            </List.ItemLeftWrapper>
          </List.Item>

          <List.Item>
            <List.ItemLeftWrapper>
              {claimFile !== null && (
              <div>
                <List.ItemTitle>Vet Exam Invoice</List.ItemTitle>
                <List.ImageWrapper>
                  <Thumbnail
                    file={claimFile}
                  />
                </List.ImageWrapper>
              </div>
              )}
            </List.ItemLeftWrapper>
          </List.Item>

        </List>
      </Card>

      <Button
        label="Submit"
        type="submit"
        onClick={(e) => doneReviewing(e)}
        className="continue-button"
      />

    </Container>
  </div>
);

export default ClaimReview;

import React from 'react';
import Icon from 'components-v2/shared/Icon/Icon';
import './back-to.css';
import { handleKeyUp } from 'utils';
import { navigationHistory } from 'store/slices';
import { useDispatch } from 'react-redux';
import { backToDestinations, backToDestinationsLabels } from 'utils/constGlobal';


const BackTo = ({ where, desktop = false }) => {
  const dispatch = useDispatch();

  let destination = null;
  let label = null;

  switch (where) {
    case backToDestinations.DASHBOARD:
      destination = navigationHistory.actions.goToRedemptionDashboard;
      label = backToDestinationsLabels.DASHBOARD;
      break;
    case backToDestinations.SELL_SCREEN:
      destination = navigationHistory.actions.goToSellScreen;
      label = backToDestinationsLabels.SELL_SCREEN;
      break;
    default:
      destination = navigationHistory.actions.goToInitialDecision;
  }
  
  return desktop ? (
    <div className="back-to-desktop-wrapper">
      <div className="back-to-container">
        <div 
          className="back-to"
          role="button"
          aria-label={label}
          tabIndex="0"
          onClick={() => dispatch(destination())}
          onKeyUp={
            (e) => handleKeyUp(e, () => dispatch(destination()))
          }
        >
          <Icon
            name="backToArrowLeft"
            color="#001952"
            size="30"
          />
          <p>{label}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="back-to-container">
      <div 
        className="back-to"
        role="button"
        aria-label={label}
        tabIndex="0"
        onClick={() => dispatch(destination())}
        onKeyUp={
          (e) => handleKeyUp(e, () => dispatch(destination()))
        }
      >
        <Icon
          name="backToArrowLeft"
          color="#001952"
          size="30"
        />
        <p>{label}</p>
      </div>
    </div>
  );
};

export default BackTo;

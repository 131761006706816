/* eslint-disable no-unused-expressions */
import { createSelector } from 'reselect';

import { currentViewStateSelector } from 'store';
import { getPetInfo, getpetCostsByMembershipId } from 'utils';
import { navigationSubcategories } from 'utils/constNavigationSubcategories';

import {
  DiscountType,
} from 'utils/constGlobal';

export const registrationViewModel = createSelector(
  currentViewStateSelector,
  (state) => state.membershipsV2,
  (state) => state.toggles,
  (state) => state.customer.pets,
  (state) => state.customer.membershipPlans,
  (state) => state.customer.user,
  (state) => state.registrationInfo,
  (state) => state.deepLink,
  (state) => state.petTypes,
  (state) => state.url,
  (state) => state.affiliateReferralCode,
  (state) => state.uiText,
  (state) => state.paymentPlansDetail,
  (currentViewState, membershipsV2, toggles, pets, membershipPlans, userInfo, registrationInfo, deepLink, petTypes, url, affiliateReferralCode, uiText, paymentPlansDetail) => {
    const creatingPets = currentViewState.subcategory === navigationSubcategories.PET_CREATION;
    const orderByPosition = (membershipBenefits) => {
      const criteria = (benefit1, benefit2) => {
        if (benefit1.benefitPosition < benefit2.benefitPosition) {
          return -1;
        } else if (benefit1.benefitPosition > benefit2.benefitPosition) {
          return 1;
        } else {
          return 0;
        }
      };

      return membershipBenefits.slice().sort(criteria);
    };

    // TODO: This requires a follow up conversation with the business. This is accepted
    // tech debt for the time being. Because the frontend is built to operate on the assumption
    // of there being only ONE Membership, we're selecting the FIRST Membership that has a Pet Type
    // assigned to it (effectively, the first 2.0 Membership), so that everything downstream
    // continues to work as expected without additional calls to the taxes endpoint, so the totals show
    // correctly, etc.
    // Added for single pet pricing fixing
    const petInfo = pets.data
      .filter((pet) => registrationInfo.enrolledPets.some((enrolledPet) => enrolledPet.id === pet.petMasterId));

    let membershipDetails;
    let membershipIds = [];
    const membershipIdsNew = [];
    if (petInfo.length > 0) {
      membershipDetails = membershipsV2?.memberships?.find(m => petInfo.some((pet) => pet.petType === m.petType && m.petType !== null));
      membershipIds = membershipsV2?.memberships?.filter(m => petInfo.find((pet) => pet.petType === m.petType && m.petType !== null)).map((member) => member.id);
      petInfo?.forEach((m) => {
        const membershipObj = membershipsV2?.memberships?.find((pet) => pet.petType === m.petType && pet.petType !== null);
        membershipObj && membershipIdsNew.push(membershipObj?.id);
      });
    } else {
      membershipDetails = membershipsV2?.memberships?.find(m => m.petType !== null);
    }
    // todo fix string compare
    // active or expired plan under same program, should get the discount
    const petAlreadyEnrolled = membershipDetails
      ? membershipPlans.data.some((plan) => plan.status === 'active' || plan.status === 'expired')
      : false;
    const multipetDiscount = membershipDetails
      ? membershipDetails.discounts.find((discount) => discount.type === DiscountType.MULTIPET)
      : undefined;

    const affiliateDiscount = registrationInfo.taxAndDiscountInfo?.affiliateDiscount?.amount ?? undefined;
    const annualPrice = membershipDetails ? membershipDetails.priceAmount : '';
    const annualPriceWithDiscount = multipetDiscount ? annualPrice - multipetDiscount.amount : annualPrice;
    const membershipInfo = {
      // membershipId: membershipDetails ? membershipDetails.id : '',
      membershipId: membershipIdsNew ? membershipIdsNew.join() : '',
      paymentMethod: registrationInfo ? registrationInfo.paymentMethod : '',
      annualPrice,
      annualPriceWithDiscount,
      monthlyPrice: membershipDetails ? membershipDetails.monthlyPriceAmount : '',
      membershipBenefits: membershipDetails ? orderByPosition(membershipDetails.membershipBenefits) : [],
      sellPageHeadline: membershipDetails && membershipDetails.sellPageHeadline,
      showComingSoon: !membershipDetails,
      hasMultipetDiscount: multipetDiscount !== undefined,
      hasAffiliateDiscount: affiliateDiscount !== undefined && affiliateDiscount > 0,
      affiliateDiscount,
      multipetDiscount: multipetDiscount ? multipetDiscount.amount : '',
      petAlreadyEnrolled,
      discountInfo: [],
      sellPageAboveCopy: membershipDetails?.sellPageAboveCopy ?? '',
      sellPageBelowCopy: membershipDetails?.sellPageBelowCopy ?? '',
    };
    // let combinetotalPriceWithoutTax = 0;
    let petTotalPriceWithoutTax = 0;
    const enrolledPetsFullData = pets.data
      .filter((pet) => registrationInfo.enrolledPets.some((enrolledPet) => enrolledPet.id === pet.petMasterId))
      .map((pet, i) => {
        const enrolledPet = registrationInfo.enrolledPets.find(
          (enrolled) => enrolled.id === pet.petMasterId,
        );

        let taxAndDiscountInfo = { discounts: [], subtotal: 0 };
        let petPriceAmount = 0;
        if (registrationInfo?.taxAndDiscountInfo && registrationInfo?.taxAndDiscountInfo.length > 0) {
          const taxAndDiscount = registrationInfo?.taxAndDiscountInfo.filter(m => pet.petType === m.petType && m.petType !== null);
          taxAndDiscountInfo = taxAndDiscount?.[0]?.petCosts;
          petPriceAmount = taxAndDiscount?.[0]?.priceAmount;
          taxAndDiscountInfo = taxAndDiscountInfo?.[0];
        }

        // const taxAndDiscountInfo = registrationInfo.taxAndDiscountInfo
        //   ? registrationInfo.taxAndDiscountInfo.petCosts?.[0]
        //   : { discounts: [], subtotal: 0 };

        let totalDiscounts;
        taxAndDiscountInfo?.discounts?.forEach((petDiscount) => {
          const index = membershipInfo
            ?.discountInfo
            ?.findIndex((membershipDiscount) => membershipDiscount.displayName === petDiscount.displayName);
          // not found
          if (index === -1) {
            membershipInfo?.discountInfo?.push({
              displayName: petDiscount.displayName,
              amount: parseFloat(petDiscount.amount),
            });
            totalDiscounts += parseFloat(petDiscount.amount);
          } else {
            membershipInfo.discountInfo[index].amount += parseFloat(petDiscount.amount);
            totalDiscounts += parseFloat(petDiscount.amount);
          }
        });

        // const subtotalPrice = parseFloat(annualPriceNew) - parseFloat(totalDiscounts);
        // combinetotalPriceWithoutTax += parseFloat(subtotalPrice);

        petTotalPriceWithoutTax += parseFloat(taxAndDiscountInfo?.subtotal);
        const petCost = {
          discounts: taxAndDiscountInfo?.discounts ?? undefined,
          subtotal: taxAndDiscountInfo?.subtotal,
          tax: taxAndDiscountInfo?.tax,
          total: taxAndDiscountInfo?.total,
          preDiscountPrice: petPriceAmount !== 0 ? petPriceAmount : annualPrice,
          // preDiscountPrice: annualPriceNew,
        };

        return {
          id: pet.petMasterId,
          petMasterId: pet.petMasterId,
          pgrMasterId: pet.pgrMasterId,
          name: pet.name,
          type: pet.petType,
          breed: pet.primaryBreed,
          birthDate: pet.birthDate,
          petCost,
          // todo get rid of these hard coded flags and info blocks
          proCareInfo: enrolledPet.proCareInfo,
          annualPlan: enrolledPet.annualPlan,
          info: getPetInfo(
            enrolledPet.proCareInfo, toggles,
          ),
        };
      });

    // if we have not picked a payment method yet do not show tax
    const showTax = registrationInfo.paymentMethod !== null;
    let petTotalTax = 0;
    let petTotalPrice = 0;
    let petSubTotalPrice = 0;
    let paymentTenure = '';
    let disclaimerMessage = '';

    if (registrationInfo?.taxAndDiscountInfo && registrationInfo?.taxAndDiscountInfo.length > 0) {
      const priceInformation = registrationInfo?.taxAndDiscountInfo[registrationInfo?.taxAndDiscountInfo.length - 1];
      petTotalTax = priceInformation ? priceInformation?.tax : 0;
      petTotalPrice = priceInformation ? priceInformation?.total : 0;
      petSubTotalPrice = priceInformation ? priceInformation?.subtotal : petTotalPriceWithoutTax;
      paymentTenure = priceInformation ? priceInformation?.paymentTenure : '';
      disclaimerMessage = priceInformation?.paymentDisclaimerMessage;
    }
    const enrollmentInfo = {
      productName: registrationInfo?.taxAndDiscountInfo?.name,
      // totalTax: registrationInfo.taxAndDiscountInfo ? registrationInfo.taxAndDiscountInfo.tax : 0,
      totalTax: petTotalTax,
      showTax,
      // totalPrice: registrationInfo.taxAndDiscountInfo ? registrationInfo.taxAndDiscountInfo.total : 0,
      totalPrice: petTotalPrice,
      totalPriceWithoutTax: petSubTotalPrice,

      // totalPriceWithoutTax: registrationInfo.taxAndDiscountInfo ? registrationInfo.taxAndDiscountInfo.subtotal : 0,
      // totalPriceWithoutTax: combinetotalPriceWithoutTax,
    };

    let errorModalBody = '';
    if (registrationInfo.onMembershipCreateError.errors) {
      registrationInfo.onMembershipCreateError.errors.forEach((errorString) => { errorModalBody += `${errorString}`; });
    }

    const creationErrorInfo = {
      errorOccurred: registrationInfo.onMembershipCreateError.error,
      modalTitle: registrationInfo.onMembershipCreateError.message,
      modalBody: errorModalBody,
    };

    const getNumberOfPlansByPetType = (petType) => {
      let total = 0;
      enrolledPetsFullData?.forEach(pet => {
        if (pet.type === petType) {
          total += 1;
        }
      });
      return total;
    };

    const getMembershipsV2Details = () => {
      const plans = [];
      membershipsV2?.memberships?.forEach((membership) => {
        const quantity = getNumberOfPlansByPetType(membership?.petType);
        if (quantity) {
          plans.push({
            productName: membership?.name,
            price: membership?.priceAmount,
            quantity,
          });
        }
      });
      return plans;
    };

    const analyticsDataObject = {
      formname: '',
      formstepname: '',
      formstep: null,
      subformname: '',
      customerEmail: userInfo?.email,
      multipetdiscount: membershipInfo?.multipetDiscount,
      tax: enrollmentInfo?.totalTax,
      total: enrollmentInfo?.totalPrice,
      subformstepdetails: getMembershipsV2Details(),
    };

    const webSocketNotification = toggles?.ENABLEVCVERSION2 && registrationInfo.listenedNotification;

    const petCostsByMembershipIdData = toggles?.ENABLEVCVERSION2 && registrationInfo?.taxAndDiscountInfo
      && registrationInfo?.taxAndDiscountInfo.length > 0 && getpetCostsByMembershipId(registrationInfo?.taxAndDiscountInfo);

    const paymentMethodUpdateErrorInfo = {
      errorOccurred: registrationInfo.onMembershipPaymentMethodError?.occurred,
      modalTitle: registrationInfo.onMembershipPaymentMethodError?.message,
      status: registrationInfo.onMembershipPaymentMethodError?.statusCode,
    };

    const paymentAuthCCInfo = {
      enablePaymentAuthCC: registrationInfo.onMembershipPaymentAuthCC?.isTotalPrice,
      paymentAuthCCData: registrationInfo.onMembershipPaymentAuthCC?.authCCResponse,
    };

    return {
      step: currentViewState.step,
      membershipInfo,
      toggles,
      pets: pets.data,
      membershipPlans: membershipPlans.data,
      hasUserMembershipPlans: membershipPlans.data.length > 0,
      enrolledPets: enrolledPetsFullData,
      creationErrorInfo,
      enrollmentInfo,
      creatingPets,
      benefits: membershipInfo.membershipBenefits,
      deepLinkInfo: deepLink,
      userInfo,
      petTypes,
      affiliateReferralCode,
      analyticsDataObject,
      registrationInfoData: registrationInfo,
      companionAnimalFeature: url.companionAnimalFeature,
      paymentTenure,
      disclaimerMessage,
      uiText,
      paymentPlansDetail,
      webSocketNotification,
      wsConnectionId: registrationInfo?.selectedWSConnectionId,
      petCostsByMembershipId: petCostsByMembershipIdData,
      paymentMethodUpdateErrorInfo,
      wcsUserId: userInfo.id,
      paymentAuthCCInfo,
      affiliate: url.affiliate,
    };
  },
);

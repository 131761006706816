import React from 'react';
import './membership-float-label-input.css';

const MembershipFloatLabelInput = ({
  onChange,
  value,
  label,
  name,
  placeholder,
}, ref) => (
  <div className="MembershipFloatLabelInput">
    {label.length > 0 && (
      <label className="MembershipFloatLabelInput__label" htmlFor={name}>{label}</label>
    )}
    <input
      className={`MembershipFloatLabelInput__input ${value.length > 0 && 'MembershipFloatLabelInput__input--filled'}`}
      type="text"
      name={name}
      id={name}
      placeholder={placeholder}
      onChange={onChange}
      ref={ref}
    />
  </div>
);

export default React.forwardRef(MembershipFloatLabelInput);

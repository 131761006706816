import { createSelector } from 'reselect';

export interface URLState {
  url: string;
  uiVersion: string;
  locationSearch: string
  mobileFeature: string
  isRequestFromExternalPetcoLandingPage: boolean
  apuKey: string
  affiliate: any
  threeKey: any
  isOBO: boolean
}

export const referralViewModel = createSelector(
  (state: any) => state.url,
  (url) => ({ url }),
);

import React, { useEffect } from 'react';

import ComboBox from 'components-v2/shared/ComboBox/ComboBox';
import { trackEvent } from 'utils';
import { adobeAnalyticsTags } from 'utils/constGlobal';
import './credit-card-combo-box.css';

const addANewOptionValue = 'ADD_A_NEW_CARD';
const paymentMethodInitial = 'Add or Select Payment';

const addNewOption = {
  key: 0,
  value: addANewOptionValue,
  label: 'Add new payment method',
};

const getSelectedPaymentMethodBrand = (paymentMethods) => paymentMethods && paymentMethods.find && (paymentMethods.find(
  (paymentMethod) => paymentMethod.isSelected,
) || {}).brand;

const getSelectedPaymentMethod = (paymentMethods) => paymentMethods && paymentMethods.find && (paymentMethods.find(
  (paymentMethod) => paymentMethod.isSelected,
) || {}).mbrPayinfoId;

const CreditCardCombobox = ({
  id,
  name,
  paymentMethods,
  onChange,
  onSelectAddNewCard,
}) => {
  const paymentMethodOptions = [...paymentMethods.map(
    (paymentMethod) => ({
      key: paymentMethod.mbrPayinfoId,
      value: paymentMethod.mbrPayinfoId,
      label: `${paymentMethod.brand} ending in ${paymentMethod.lastFour}`,
    }),
  ), addNewOption];
  const selectedPaymentMethod = getSelectedPaymentMethod(paymentMethods);
  const selectedPaymentMethodBrand = getSelectedPaymentMethodBrand(paymentMethods);

  useEffect(() => {
    if (selectedPaymentMethod) {
      let paymentInfo = adobeAnalyticsTags?.PAYMENT_INFO_V2;
      const serviceDetails = {
        stepname: 'complete your purchase | payment',
        orderPaymentType: selectedPaymentMethodBrand,
      };
      paymentInfo = { ...paymentInfo, serviceDetails };
      onChange(name, selectedPaymentMethod, paymentInfo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod, selectedPaymentMethodBrand]);

  return (
    <div className="CreditCardComboBox">
      <ComboBox
        id={id}
        name={name}
        ariaLabel="Add or select payment method"
        text={paymentMethodInitial}
        onChange={(nameParam, value) => {
          if (value === addANewOptionValue) {
            onSelectAddNewCard();
          } else {
            onChange(nameParam, value);
          }
        }}
        selectedOption={selectedPaymentMethod}
        options={paymentMethodOptions}
      />
    </div>
  );
};

export default CreditCardCombobox;

export const removeNonAlphaNumeric = (word) => (word || '').replace(/[^a-zA-Z0-9]/g, '');

export const doesItHaveNumber = (word) => /\d/.test(word || '');

export const doesItHaveLetter = (word) => /[a-zA-Z]/g.test(word || '');

export const validateZipCode = (code) => {
  if (code.length === 5 && code && !doesItHaveLetter(code)) {
    return true; 
  } else {
    return false;
  }
};

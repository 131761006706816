import React from 'react';
import Icon from 'components-v2/shared/Icon/Icon';
import { globalConsts } from 'utils/constGlobal';
import './back-button.css';
import { handleKeyUp } from 'utils';

const BackToButton = ({ label }) => {
  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));

  return isMobile
    ? (
      <div 
        className="BackToButton"
        role="button"
        tabIndex="0"
        onClick={() => window.history.back()}
        onKeyUp={(e) => handleKeyUp(e, () => window.history.back())}
      >
        <Icon
          name="arrowLeft"
          color="#001952"
          size="30"
        />
        <h4>{label}</h4>
      </div>
    ) : null;
};

export default BackToButton;

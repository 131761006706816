import { createSelector } from 'reselect';

const viewModel = createSelector(
  (state) => state.registrationInfo,
  (registrationInfo) => ({
    ncrIframe: registrationInfo.ncrIframe,
  }),
);

export default viewModel;

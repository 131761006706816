/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { timeStampData } from '../../utils';

const membershipsV2 = createSlice({
  name: 'membershipsV2',
  initialState: {
    details: null,
    memberships: [],
    cacheTime: null,
  },
  reducers: {
    setDetail: (state, action) => {
      state.memberships = action.payload;
      timeStampData(state);
    },
    clearMembershipsCacheTimer: (state) => {
      state.cacheTime = null;
    },
  },
});

export default membershipsV2;

import { createSlice } from '@reduxjs/toolkit';

const petTypes = createSlice({
  name: 'petTypes',
  initialState: [],
  reducers: {
    set: (state, action) => action.payload,
  },
});

export default petTypes;

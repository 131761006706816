import React from 'react';
import { Modal } from 'react-bootstrap';
import Icon from 'components-v2/shared/Icon/Icon';
import { Title, Text } from 'components-v2/shared/Elements';
import { Button } from 'components-v2/shared/Button';

import './reimbursement-warning-modal.css';
import { ButtonLink } from 'components-v2/shared/Button-v1';


const ReimbursementWarningModal = ({
  open,
  onHide,
  uiText,
  planEndDate,
}) => {
  const addingEndDateToReimbursementWarningModalBodyTxt = (text, endDate) => (text?.replace('{endDate}.', endDate));

  return (
    <div className="ReimbursementWarningModal">
      <Modal
        backdrop="static"
        keyboard="false"
        show={open}
        onHide={onHide}
      >
        <div className="ReimbursementWarningModalBody">
          <Icon name="closeSmall" onClick={() => onHide()} />
          <Modal.Title>
            <Title>{uiText?.ReimbursementWarningModalTitle}</Title>
          </Modal.Title>
          <Modal.Body>
            <Text>{addingEndDateToReimbursementWarningModalBodyTxt(uiText?.ReimbursementWarningModalBodyTxt, planEndDate)}</Text>
            <div className="ContactCustomer">
              <Text>{uiText?.ReimbursementWarningModalBodyCustomerCareTxt}</Text>
              <Text>{uiText?.ReimbursementWarningModalBodyCustomerCareOperationTimingsTxt}</Text>
              <a
                href={`mailto:${uiText?.customerCareEmailVOYC}`}
                target="_parent"
              >
                <Text className="ContactCustomer__Phone">{uiText?.customerCareEmailVOYC}</Text>
              </a>
              {uiText?.ReimbursementWarningModalBodyCustomerCareReferenceCode && (
                <Text className="ContactCustomer__Reference">
                  {uiText.ReimbursementWarningModalBodyCustomerCareReferenceCode}
                </Text>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => onHide()}
              label="OK"
            />
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default ReimbursementWarningModal;

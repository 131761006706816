import React from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { getUsStates } from 'utils';

import { Button, ButtonGroup } from 'components-v2/shared/Button-v1';
import ComboBox from 'components-v2/shared/ComboBox-v1';
import { Input } from 'components-v2/shared/Elements';

import './add-clinic-modal.css';

const states = getUsStates();

const AddClinicModal = ({ open, onClose, onClinicAdded }) => (
  <Modal
    className="full-height-modal add-vet-modal"
    show={open}
    onHide={onClose}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header>
      <Modal.Title>Add A Clinic</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="AddClinicModal">
        <Formik
          initialValues={{
            clinicName: '',
            clinicAddress: '',
            city: '',
            state: '',
            zipcode: '',
            phoneNumber: '',
            faxNumber: '',
          }}
          validateOnMount
          validationSchema={
              Yup.object().shape({
                clinicName: Yup.string().required('Required'),
                clinicAddress: Yup.string().required('Required'),
                city: Yup.string().required('Required'),
                state: Yup.string().required('Required'),
                zipcode: Yup.string().required('Required'),
                phoneNumber: Yup.string().required('Required'),
              })
            }
          onSubmit={(values) => {
            // This matches Clinic Search API model
            // id purposely left out so we know it is manually added
            const clinicToAdd = {
              name: values.clinicName,
              address: values.clinicAddress,
              city: values.city,
              country: 'US',
              humanCountry: 'United States',
              email: '',
              faxPhoneNumber: values.faxNumber,
              phoneNumber: values.phoneNumber,
              state: values.state,
              zipCode: values.zipcode,
            };
            onClinicAdded(clinicToAdd);
            onClose();
          }}
        >
          {(props) => (
            <Form className="form">
              <div className="form-group">
                <label htmlFor="clinicName">
                  <span>*</span> Clinic Name
                </label>
                <Input
                  name="clinicName"
                  placeholder="Clinic Name"
                  id="clinicName"
                />
                {props.errors.clinicName && props.touched.clinicName && (
                <div className="input-error">
                  {props.errors.clinicName}
                </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="clinicAddress">
                  <span>*</span> Clinic Address
                </label>
                <Input
                  name="clinicAddress"
                  placeholder="Clinic Address"
                  id="clinicAddress"
                />
                {props.errors.clinicAddress && props.touched.clinicAddress && (
                <div className="input-error">
                  {props.errors.clinicAddress}
                </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="city">
                  <span>*</span> City
                </label>
                <Input
                  name="city"
                  placeholder="City"
                  id="city"
                />
                {props.errors.city && props.touched.city && (
                <div className="input-error">
                  {props.errors.city}
                </div>
                )}
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="state">
                    <span>*</span> State
                  </label>
                  <ComboBox
                    id="state"
                    name="state"
                    ariaLabel="state"
                    text="Select a State"
                    selectedOption={props.values.state}
                    options={states.map(
                      (state) => ({
                        key: state.id,
                        value: state.postalAbbreviation,
                        label: state.label,
                      }),
                    )}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                  />
                  {props.errors.state && props.touched.state && (
                  <div className="input-error">
                    {props.errors.state}
                  </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="zipcode">
                    <span>*</span> Zip
                  </label>
                  <Input
                    name="zipcode"
                    placeholder="Zip"
                    id="zipcode"
                  />
                  {props.errors.zipcode && props.touched.zipcode && (
                  <div className="input-error">
                    {props.errors.zipcode}
                  </div>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="phoneNumber">
                  <span>*</span> Phone Number
                </label>
                <Input
                  name="phoneNumber"
                  placeholder="Phone Number"
                  id="phoneNumber"
                />
                {props.errors.phoneNumber && props.touched.phoneNumber && (
                <div className="input-error">
                  {props.errors.phoneNumber}
                </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="phoneNumber">Fax Number (Optional)</label>
                <Input
                  name="faxNumber"
                  placeholder="Fax Number (Optional)"
                  id="faxNumber"
                />
                {props.errors.faxNumber && props.touched.faxNumber && (
                <div className="input-error">
                  {props.errors.faxNumber}
                </div>
                )}
              </div>

              <ButtonGroup>
                <Button
                  disabled={!props.isValid}
                  label="Add My Clinic"
                  type="submit"
                  className="confirm-button"
                />
                <Button
                  onClick={onClose}
                  className="secondary"
                  label="Cancel"
                  type="button"
                />
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </div>
    </Modal.Body>
  </Modal>
);

export default AddClinicModal;

import React from 'react';
import './button.css';

interface ButtonLinkProps {
  children?: React.ReactNode;
  className?: string;
  href?: string;
  label?: string;
  target?: string;
  variant?: string;
  onClick: any;
  tabIndex: any
  onKeyUp: any;
  role?: any
}

export const ButtonLink = ({
  children, className, href, label, target, variant, ...rest
}: ButtonLinkProps) => {
  let classNames = 'ButtonLink';

  if (className) classNames += ` ${className}`;

  if (variant) {
    if (variant === 'textLink') classNames += ' TextLink';
    else classNames += ` ${variant}`;
  }

  return (
    <a
      role="button"
      className={classNames}
      href={href}
      target={target}
      {...rest}
    >
      {label}
      {children}
    </a>
  );
};

ButtonLink.defaultProps = {
  children: undefined,
  className: undefined,
  href: undefined,
  label: undefined,
  target: undefined,
  variant: undefined,
};

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  label?: string;
  ariaLabel?: string;
  type?: any;
  id?: string;
  tabIndex?: any
}

export const Button = ({
  children, className, disabled, onClick, label, ariaLabel, ...rest
}: ButtonProps) => {
  let classNames = 'Button-v2';

  if (className) classNames += ` ${className}`;

  if (disabled) classNames += ' disabled';

  return (
    <button aria-label={ariaLabel} className={classNames} disabled={disabled} onClick={onClick} {...rest}>
      {label}
      {children}
    </button>
  );
};

Button.defaultProps = {
  children: undefined,
  className: undefined,
  disabled: undefined,
  onClick: undefined,
  label: undefined,
  ariaLabel: undefined,
};

interface ButtonGroupProps {
  children?: React.ReactNode;
}

export const ButtonGroup = ({ children }: ButtonGroupProps) => <div className="ButtonGroup">{children}</div>;

ButtonGroup.defaultProps = {
  children: undefined,
};

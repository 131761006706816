import React, { useState } from 'react';
import { Field } from 'formik';
import './elements.css';

const StandardInput = ({ children, className, ...rest }) => {
  const classNames = `InputWrapper${className ? ` ${className}` : ''}`;

  return (
    <div className={classNames}>
      <input type="text" {...rest} />
    </div>
  );
};

// TODO: Do not like this as a dependency... Would like to investigate getting away from this if possible.
export const Input = (props) => (
  <Field as={StandardInput} {...props} />
);
export const DateInput = (props) => {
  const [readOnly, setReadOnly] = useState(false);
  return (
    <input
      {...props}
      onFocus={() => setReadOnly(true)}
      onBlur={() => setReadOnly(false)}
      readOnly={readOnly}
    />
  );
};

// TODO: temporary workaround to fix keyboard overlap issue in Add Pet till we fix the shared component above
export const DateInput2 = (props) => {
  const [readOnly, setReadOnly] = useState(true);
  return (
    <input
      {...props}
      onFocus={() => setReadOnly(true)}
      onBlur={() => setReadOnly(true)}
      readOnly={readOnly}
    />
  );
};

export const Text = ({
  children, className, tabIndex, content, ...rest
}) => {
  const classNames = `component__text${className ? ` ${className}` : ''}`;

  return (
    <p className={classNames} tabIndex={tabIndex} {...rest} role="main">
      {content}
      {children}
    </p>
  );
};

export const Textarea = ({ children, className, ...rest }) => (
  <textarea className={className} {...rest}>
    {children}
  </textarea>
);

export const Title = ({
  as, children, role, className, tabIndex, content, ...rest 
}) => {
  const classNames = `title${className ? ` ${className}` : ''}`;

  switch (as) {
    case 'h1':
      return <h1 className={classNames} tabIndex={tabIndex} role={role} {...rest}>{content}{children}</h1>;
    case 'h2':
      return <h2 className={classNames} tabIndex={tabIndex} role={role} {...rest}>{content}{children}</h2>;
    case 'h3':
      return <h3 className={classNames} tabIndex={tabIndex} role={role} {...rest}>{content}{children}</h3>;
    case 'h4':
      return <h4 className={classNames} tabIndex={tabIndex} role={role} {...rest}>{content}{children}</h4>;
    case 'h5':
      return <h5 className={classNames} tabIndex={tabIndex} role={role} {...rest}>{content}{children}</h5>;
    case 'h6':
      return <h6 className={classNames} tabIndex={tabIndex} role={role} {...rest}>{content}{children}</h6>;
    default:
      return <h1 className={classNames} tabIndex={tabIndex} role={role} {...rest}>{content}{children}</h1>;
  }
};

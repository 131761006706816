/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect';
import { configureStore } from '@reduxjs/toolkit';

import { DeepLinkValues } from 'utils/constGlobal';
import { createFetchJSON } from '../helpers/fetchJSON';

import authError from './slices/authError';
import customer from './slices/customer';
import claims from './slices/claims';
import dashboardInfo from './slices/dashboardInfo';
import fatalError from './slices/fatalError';
import loadingCount from './slices/loadingCount';
import memberships from './slices/memberships';
import membershipsV2 from './slices/memberships-v2';
import maintenanceError from './slices/maintenanceError';
import navigationHistory from './slices/navigationHistory';
import petTypes from './slices/petTypes';
import registrationInfo from './slices/registrationInfo';
import tokenState from './slices/tokenState';
import toggles from './slices/toggles';
import url from './slices/url';
import deepLink from './slices/deepLink';
import affiliateReferralCode from './slices/affiliateReferralCode';
import { VIEW_INDEX } from './slices';
import uiText from './slices/uiText';
import paymentPlansDetail from './slices/paymentPlansDetail';

export const ANNUAL_LIMIT = ['$5,000', '$10,000', '$15,000', '$20,000', 'Unlimited'];

export const REIMBURSEMENT_PERCENTAGE = ['70%', '80%', '90%'];

export const ANNUAL_DEDUCTIBLE_AMOUNT = ['$100', '$250', '$500'];

export const getFilesSignatures = () => () => { };

export const sendInvoiceFiles = () => () => {
  // future POST request to the target backend
};

export const currentViewStateSelector = (state) => state.navigationHistory.history[state.navigationHistory.current];

export const isLoading = createSelector(
  currentViewStateSelector,
  (state) => state.loadingCount,
  (state) => state.customer.pets,
  (state) => state.deepLink,
  (state) => state.customer.membershipPlans.data,
  (currentViewState, loadingCountParam, pets, deepLinkParam, membershipPlans) => {
    const petsNotLoaded = pets && pets.loadingPets;
    const waitingToLoadPetsOnPetSelection = petsNotLoaded
      && currentViewState.key === VIEW_INDEX.PET_SELECTION.key;
    const waitingToLoadPetsAndDeepLink = petsNotLoaded && deepLink.destination !== DeepLinkValues.INITIAL;
    const hasPlanWithoutAnswer = membershipPlans && membershipPlans.find((plan) => plan.transitions?.length);
    return loadingCountParam > 0
      || waitingToLoadPetsOnPetSelection
      || waitingToLoadPetsAndDeepLink
      || (hasPlanWithoutAnswer && petsNotLoaded);
  },
);

export const isDeepLinking = createSelector(
  (state) => state.deepLink,
  (deepLinkParam) => deepLinkParam.destination !== DeepLinkValues.INITIAL,
);
export const fetchJSON = createFetchJSON({
  loadingCount, fatalError, maintenanceError, authError,
});

const store = configureStore({
  reducer: {
    registrationInfo: registrationInfo.reducer,
    petTypes: petTypes.reducer,
    memberships: memberships.reducer,
    membershipsV2: membershipsV2.reducer,
    loadingCount: loadingCount.reducer,
    navigationHistory: navigationHistory.reducer,
    fatalError: fatalError.reducer,
    maintenanceError: maintenanceError.reducer,
    authError: authError.reducer,
    customer: customer.reducer,
    tokenState: tokenState.reducer,
    dashboardInfo: dashboardInfo.reducer,
    toggles: toggles.reducer,
    uiText: uiText.reducer,
    claims: claims.reducer,
    url: url.reducer,
    deepLink: deepLink.reducer,
    affiliateReferralCode: affiliateReferralCode.reducer,
    paymentPlansDetail: paymentPlansDetail.reducer,
  },
});

export const getPreloadedStore = (preloadedState) => configureStore({
  preloadedState,
  reducer: {
    registrationInfo: registrationInfo.reducer,
    petTypes: petTypes.reducer,
    memberships: memberships.reducer,
    membershipsV2: membershipsV2.reducer,
    loadingCount: loadingCount.reducer,
    navigationHistory: navigationHistory.reducer,
    fatalError: fatalError.reducer,
    maintenanceError: maintenanceError.reducer,
    authError: authError.reducer,
    customer: customer.reducer,
    tokenState: tokenState.reducer,
    dashboardInfo: dashboardInfo.reducer,
    toggles: toggles.reducer,
    uiText: uiText.reducer,
    claims: claims.reducer,
    url: url.reducer,
    deepLink: deepLink.reducer,
    affiliateReferralCode: affiliateReferralCode.reducer,
    paymentPlansDetail: paymentPlansDetail.reducer,
  },
});

export default store;

import { createSlice } from '@reduxjs/toolkit';

const toggles = createSlice({
  name: 'toggles',
  initialState: {},
  reducers: {
    set: (state, action) => action.payload,
  },
});

export default toggles;

import React from 'react';
import PropTypes from 'prop-types';
import './progress.css';

const Progress = ({
  label, percentage,
}) => (
  <div className="Progress">
    <div className="ProgressContainer">
      <div className="ProgressBar" style={{ width: `${percentage}%` }} />
    </div>
    <div className="ProgressLabel">{label}</div>
  </div>
);

Progress.propTypes = {
  label: PropTypes.string,
};

Progress.defaultProps = {
  label: '',
};

export default Progress;

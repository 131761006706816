import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { fetchClinicList } from 'store/actions';
import useDebounce from 'utils/debounce';
import Logger from 'utils/logger';

import { Button, ButtonLink } from 'components-v2/shared/Button';
import { Title } from 'components-v2/shared/Elements';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';
import MembershipFloatLabelInput from 'components-v2/shared/MembershipFloatLabelInput';
import ClaimClinicSelectionCard from './ClaimClinicSelectionCard';
import AddClinicModal from './AddClinicModal/AddClinicModal';
import './claims-clinic-selection.css';
import BackTo from 'components-v2/shared/BackTo/BackTo';
import { backToDestinations, globalConsts, adobeAnalyticsTags } from 'utils/constGlobal';
import { handleKeyUp, trackEvent } from 'utils';

const LOGGER = Logger.getLogger('StoreLocator');
let lastIndexOfDisplayedClinicList = 0;

const ClaimClinicSelection = ({
  onClinicSelectionCompletion,
  petName,
  claimClinic,
  claimLastClinicSelected,
  onChangeClinicSelection,
  toggles,
}) => {
  const dispatch = useDispatch();
  const [lastSearchedQuery, setLastSearchedQuery] = useState('');
  const [searchingError, setSearchingError] = useState(false);
  const [clinicList, setClinicList] = useState([]);
  const [numberOfPagesToShow, setNumberOfPagesToShow] = useState(1);
  const [isAddAVetModalOpen, setIsAddAVetModalOpen] = useState(false);
  const [searched, setSearched] = useState(false);
  const [query, setQuery] = useState('');
  const clinicCardRef = useRef();

  const PAGE_SIZE = toggles.CLINIC_LIST_PAGE_SIZE;
  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));

  const displayedClinicList = useMemo(
    () => {
      const filterItemsToBeDisplayed = () => {
        const criteria = (index) => index < PAGE_SIZE * numberOfPagesToShow;
        return clinicList?.filter((_, index) => criteria(index));
      };

      lastIndexOfDisplayedClinicList = filterItemsToBeDisplayed().length - 1;
      return filterItemsToBeDisplayed();
    },

    [clinicList, numberOfPagesToShow, PAGE_SIZE],
  );

  const onClinicAdded = (vetClinicAdded) => {
    setClinicList([vetClinicAdded]);
    onChangeClinicSelection(vetClinicAdded);
  };
  const debouncedSearch = useDebounce(query, 250);

  const fetchClinicsErrorHandler = (response) => {
    LOGGER.info(`Error trying get clinics from by query: [${query}]`, response);
    setClinicList([]);
    setSearchingError(true);
  };

  const fetchClinicsSuccessHandler = (clinics = []) => {
    setClinicList(clinics);
    setSearched(true);
    setSearchingError(false);
    setLastSearchedQuery(debouncedSearch);
  };

  useEffect(() => {
    if (claimClinic) {
      setClinicList([claimClinic]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = () => {
      if (debouncedSearch.length > 2 && debouncedSearch !== lastSearchedQuery) {
        dispatch(fetchClinicList(query, fetchClinicsSuccessHandler, fetchClinicsErrorHandler));
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const clearState = () => {
    setClinicList([]);
    setSearchingError(false);
    setQuery('');
    setLastSearchedQuery('');
  };

  const getClinicJSX = (props, clinic) => (
    <li key={clinic.id ? clinic.id : 1}>
      <ClaimClinicSelectionCard
        clinic={clinic}
        onChange={() => {
          props.setFieldTouched('formikVetClinic');
          props.setFieldValue('formikVetClinic', clinic.id);
          onChangeClinicSelection(clinic);
          onClinicSelectionCompletion();
        }}
      />
    </li>
  );

  const getFullList = (props) => displayedClinicList.map((clinic) => getClinicJSX(props, clinic));

  const handleButtonLink = (props) => {
    setIsAddAVetModalOpen(true);
    clearState();
    props.setFieldValue('query', '');
    window.parent.postMessage({ action: 'scrollTopSmoothly' }, '*');
  };

  return (
    <div data-testid="ClaimsVetSelectionPage-v2" className="ClaimsVetSelectionPage-v2">
      <div className="ClaimsVetSelectionPage__Container-v2">
        {!isMobile ? (
          <div>
            <BackTo where={backToDestinations.DASHBOARD} desktop />
            <PetcoLogoSharedComponent alignCenter />
          </div>
        ) : (
          <BackTo where={backToDestinations.DASHBOARD} />
        )}
        <Title>
          Where was {petName}'s exam?
        </Title>
        {(claimLastClinicSelected && typeof claimLastClinicSelected === 'object') && (
          <div className="persisted-clinic">
            <ClaimClinicSelectionCard
              clinic={claimLastClinicSelected}
              onChange={(clinic) => {
                onChangeClinicSelection(clinic);
                onClinicSelectionCompletion();
              }}
              noPaddingTop
            />
            <div className="persisted-clinic-line">
              <span>OR</span>
            </div>
          </div>
        )}
        <Formik
          initialValues={{
            query,
            formikVetClinic: claimClinic?.id,
          }}
          validateOnMount
          validationSchema={
            Yup.object().shape({
              query: Yup.string(),
              formikVetClinic: Yup.string().required(),
            })
          }
        >
          {(props) => (
            <Form className="search-form">
              <MembershipFloatLabelInput
                value={query}
                name="query"
                label="Veterinary Office"
                placeholder="Search by zip, phone, clinic, or vet name"
                ref={input => input && input.focus()}
                onChange={async (e) => {
                  const fieldQuery = e.target.value;
                  props.setFieldTouched('query');
                  props.setFieldValue('query', fieldQuery);
                  setQuery(fieldQuery);
                  setSearched(false);
                }}
              />
              {clinicList?.length > 0 && (
                <div>
                  <div>
                    <p className="results">
                      {displayedClinicList.length} of {clinicList.length} results
                    </p>
                  </div>
                  <ul className="vet-clinic-list" ref={clinicCardRef}>
                    {getFullList(props)}
                  </ul>
                  <div className="border-gray" />
                </div>
              )}
              {clinicList?.length > displayedClinicList?.length
                && (
                  <Button
                    type="button"
                    label="LOAD MORE"
                    variant="textLink"
                    className="load-more-button"
                    onClick={() => {
                      setNumberOfPagesToShow(numberOfPagesToShow + 1);
                      trackEvent(adobeAnalyticsTags?.LOAD_MORE);
                      clinicCardRef?.current?.children[lastIndexOfDisplayedClinicList].children[0].children[1].focus();
                    }}
                    aria-label="Click here to load more clinics."
                  />
                )}
              {searched
                && (
                  <div style={{ 'text-align': 'center' }}>
                    <div
                      style={{ display: 'inline-block' }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleButtonLink(props);
                        }
                      }}
                    >
                      <ButtonLink
                        tabIndex="0"
                        label="Don't see your vet? Add here"
                        onClick={() => {
                          trackEvent(adobeAnalyticsTags?.DONTSEEYOURVET_ADDHERE);
                          handleButtonLink(props);
                        }}
                        type="button"
                        variant="textLink"
                        aria-label="Don't see your vet? Add here"
                        className="add-clinic-link"
                        onKeyUp={(e) => handleKeyUp(e, () => {
                          trackEvent(adobeAnalyticsTags?.DONTSEEYOURVET_ADDHERE);
                          handleButtonLink(props);
                        })}
                        role="button"
                      />
                    </div>
                  </div>
                )}
              {clinicList?.length === 0
                && searched
                && (
                  <div className="no-results-list">
                    No Search Results.
                  </div>
                )}
              {searchingError
                && (
                  <div className="no-results-list">
                    "Unfortunately, we can't find your clinic right now. Please add it using the "Add here" link below."
                  </div>
                )}
            </Form>
          )}
        </Formik>

        <AddClinicModal
          open={isAddAVetModalOpen}
          onClinicAdded={onClinicAdded}
          onClose={() => setIsAddAVetModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default ClaimClinicSelection;

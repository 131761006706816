import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'components-v2/shared/Button-v1';
import { Title, Text } from 'components-v2/shared/Elements';
import {
  fetchMembershipPlans,
  changeMembershipPlanSubscription,
} from 'store/actions';
import { YOUR_PET_NAME_FALLBACK_LABEL } from 'utils/constGlobal';
import { useDispatch } from 'react-redux';
import Logger from 'utils/logger';
import {
  dashboardInfo,
} from 'store/slices';

import './plan-transition-modal.css';
import { handleKeyUp } from 'utils';

const LOGGER = Logger.getLogger('PlanTransitionModal');

function PlanTransitionModal({
  plansWithPets,
  pets,
  showTransitionModal,
  transitionModalOnCooldown,
}) {
  const [answers, setAnswers] = useState([]);

  const getPlansNotAnsweredMigration = (plansWithPetsParam) => plansWithPetsParam.filter((item) => {
    const hasTransitionAvailable = item.plan.transitions && item.plan.transitions.length;

    if (hasTransitionAvailable) {
      return item;
    }
    return null;
  });
  const dispatch = useDispatch();
  const plansWithPetsToAnswer = getPlansNotAnsweredMigration(plansWithPets);

  const {
    header,
    description,
    warningMessage,
  } = plansWithPetsToAnswer.length && plansWithPetsToAnswer[0].plan.transitions[0];

  useEffect(() => {
    const plansWithoutAnswer = getPlansNotAnsweredMigration(plansWithPets);
    const petsLoadedPlansWithoutAnswer = () => plansWithoutAnswer.length > 0;
    if (petsLoadedPlansWithoutAnswer() && !transitionModalOnCooldown) {
      dispatch(dashboardInfo.actions.setShowTransitionModal(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansWithPets, pets, transitionModalOnCooldown]);

  const choiceClicked = (planId, choiceId, selectedBenefitText) => {
    const planHaveAnswerButNewChoiceIsDifferent = (planId, choiceId) => answers
      .find((answer) => answer.planId === planId && answer.choiceId !== choiceId);

    const answerFound = answers.find((answer) => answer.planId === planId);

    if (!answerFound) {
      setAnswers([
        ...answers,
        {
          planId,
          choiceId,
          selectedBenefitText,
        },
      ]);
    } else if (planHaveAnswerButNewChoiceIsDifferent(planId, choiceId)) {
      const cleanedAnswers = answers.filter((answer) => answer.planId !== planId);
      const newChoice = {
        planId,
        choiceId,
        selectedBenefitText,
      };
      cleanedAnswers.push(newChoice);
      setAnswers(cleanedAnswers);
    }
  };

  const getSuccessfulTransitionMessage = (answersParam) => {
    const messageData = {};

    const transitionPlansDTO = [];

    plansWithPets.forEach((planWithPet) => {
      const transitionAnswer = answersParam.find((answer) => answer.planId === planWithPet.plan.id);
      if (transitionAnswer) {
        const dto = { petName: planWithPet.name, answer: transitionAnswer };
        transitionPlansDTO.push(dto);
      }
    });
    messageData.transitions = transitionPlansDTO;
    messageData.onError = false;
    return messageData;
  };

  const getFailedTransitionMessage = () => {
    const messageData = {
      message: "We were unable to process your transition request, don't worry, we'll try on your next login.",
      onError: true,
    };

    return messageData;
  };

  const transitionSuccessHandler = (response) => {
    // do whatever is needed on a success of an individual transition
    LOGGER.info(`Plan transition success: Request URL: ${response.url}.`);
  };

  const transitionErrorHandler = (response) => {
    // do whatever is needed on a failure of an individual transition
    dispatch(dashboardInfo.actions.setTransitionModalOnCooldown(true));
    LOGGER.error(`Plan transition failed: Request URL: ${response.url}.`);
  };

  const onSavePlanTransitionChoice = (answersParam) => {
    const promises = [];

    answersParam.forEach((answer) => {
      const { planId, choiceId } = answer;
      promises.push(dispatch(changeMembershipPlanSubscription(
        planId,
        choiceId,
        transitionSuccessHandler,
        transitionErrorHandler,
      )));
    });

    Promise.all(promises)
      .then((responses) => {
        const hasFailedTransaction = (responsesParam) => {
          let failedTransaction = false;
          responsesParam.forEach((response) => {
            if (!response || response.status !== 204) {
              failedTransaction = true;
            }
          });
          return failedTransaction;
        };
        dispatch(dashboardInfo.actions.setShowTransitionMessage(true));
        if (!hasFailedTransaction(responses)) {
          dispatch(dashboardInfo.actions.setTransitionMessage(getSuccessfulTransitionMessage(answersParam)));
          dispatch(fetchMembershipPlans(true));
          LOGGER.info(`Plan transition(s) occured successfully, response: ${responses}`);
        } else {
          dispatch(dashboardInfo.actions.setTransitionMessage(getFailedTransitionMessage()));
          dispatch(dashboardInfo.actions.setShowTransitionModal(false));
        }
      })
      .catch((err) => {
        dispatch(dashboardInfo.actions.setTransitionMessage(getFailedTransitionMessage()));
        dispatch(dashboardInfo.actions.setShowTransitionModal(false));
        dispatch(dashboardInfo.actions.setShowTransitionMessage(true));
        LOGGER.error(`Plan transition failed: ${err}.`);
      });
  };

  const getBlurbClassName = (planId, choiceId) => (answers
    .find((answer) => answer.planId === planId && answer.choiceId === choiceId)
    ? 'blurb marked'
    : 'blurb');

  return (
    <div className="TransitionModalPage">
      {pets?.data?.length > 0 && plansWithPetsToAnswer?.length && plansWithPetsToAnswer[0]?.plan
        ? (
          <Modal
            className="plan-transition-modal"
            backdrop="static"
            keyboard="false"
            show={showTransitionModal}
          >
            <div className="TransitionModalBody">
              <Title>{header}</Title>
              <Text className="explanation">{description}</Text>
              <Text className="warning">{warningMessage}</Text>

              <div id="pet-transition-cards">

                {plansWithPetsToAnswer.map((planWithPet) => {
                  const planId = planWithPet.plan.id;
                  const name = (planWithPet.pet && planWithPet.pet.name) || YOUR_PET_NAME_FALLBACK_LABEL;
                  const {
                    target, origin, targetBlurb, originBlurb,
                  } = planWithPet.plan.transitions[0];

                  return (
                    <div className="pet-transition-choice" key={planId}>
                      <Title as="h2" className="pet-name">{name}</Title>
                      <div className="choices">
                        <div
                          data-testid="new-transition-blurb"
                          key={target}
                          role="button"
                          tabIndex="0"
                          onClick={() => choiceClicked(planId, target, targetBlurb)}
                          onKeyUp={(e) => handleKeyUp(e, () => choiceClicked(planId, target, targetBlurb))}
                          value={target}
                          className={getBlurbClassName(planId, target)}
                        >
                          <div className="badge-wrapper">
                            <Text className="badge">NEW</Text>
                          </div>
                          <Text>{targetBlurb}</Text>
                        </div>

                        <div
                          data-testid="current-transition-blurb"
                          key={origin}
                          role="button"
                          tabIndex="0"
                          onClick={() => choiceClicked(planId, origin, originBlurb)}
                          onKeyUp={(e) => handleKeyUp(e, () => choiceClicked(planId, target, originBlurb))}
                          value={origin}
                          className={getBlurbClassName(planId, origin)}
                        >
                          <div className="badge-wrapper">
                            <Text className="badge">CURRENT</Text>
                          </div>
                          <Text>{originBlurb}</Text>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>


              <Button
                type="button"
                label="Save"
                id="save-button"
                className={plansWithPetsToAnswer.length === answers.length ? 'active-button' : ''}
                onClick={() => {
                  onSavePlanTransitionChoice(answers);
                }}
                disabled={plansWithPetsToAnswer.length !== answers.length}
              />
            </div>
          </Modal>
        )
        : (
          <></>
        )}
    </div>
  );
}

export default PlanTransitionModal;

import React, { useState } from 'react';
import './toggle-switch.css';
import { Button, ButtonGroup } from 'components-v2/shared/Button';

const ToggleSwitch = ({ labelNames, setSelectedButton, selectedButton }) => {
  const [clickedId, setClickedId] = useState(selectedButton);

  const toggleButton = (i, name) => {
    setClickedId(i);
    setSelectedButton(name);
  };

  return (
    <div className="toggle-switch-page-v2">
      <ButtonGroup>
        {labelNames.map((labelName, i) => (
          <Button
            key={i}
            label={labelName.name}
            className={i === clickedId ? null : 'button-disabled'}
            onClick={() => toggleButton(i, labelName.value)}
          />
        ))}
      </ButtonGroup>
    </div>
  );
};

export default ToggleSwitch;

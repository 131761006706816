import React from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import useTrapFocus from 'utils/useTrapFocus';
import { getUsStates } from 'utils';

import { Button, ButtonGroup } from 'components-v2/shared/Button-v1';

import './add-clinic-modal.css';
import { MembershipFloatingLabelInput } from 'components-v2/shared/MembershipFloatingLabelInput/MembershipFloatingLabelInput';
import { MembershipFloatingLabelComboBox } from 'components-v2/shared/MembershipFloatingLabelComboBox/MembershipFloatingLabelComboBox';

const states = getUsStates();

const AddClinicModal = ({ open, onClose, onClinicAdded }) => {
  const keyDownHandler = useTrapFocus('#clinicName, button:not([disabled]), #clinicAddress, #city, #state, #zip, #phoneNumber, #faxNumber');

  return (
    <Modal
      className="full-height-modal add-vet-modal-v2"
      show={open}
      onHide={onClose}
      backdrop="static"
      keyboard={false}

    >
      <Modal.Header>
        <Modal.Title id="modal-title">
          <h1>
            Add a Vet
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="AddClinicModal-v2">
          <Formik
            initialValues={{
              clinicName: '',
              clinicAddress: '',
              city: '',
              state: '',
              zipcode: '',
              phoneNumber: '',
              faxNumber: '',
            }}
            validateOnMount
            validationSchema={
              Yup.object().shape({
                clinicName: Yup.string().required('Clinic Name is Required'),
                clinicAddress: Yup.string().required('Clinic Address is Required'),
                city: Yup.string().required('City is Required'),
                state: Yup.string().required('State is Required'),
                zipcode: Yup.string().required('Zip code is Required'),
                phoneNumber: Yup.string().required('Phone Number is Required'),
                faxNumber: Yup.string().required('Fax Number is Required'),
              })
            }
            onSubmit={(values) => {
              // This matches Clinic Search API model
              // id purposely left out so we know it is manually added
              const clinicToAdd = {
                name: values.clinicName,
                address: values.clinicAddress,
                city: values.city,
                country: 'US',
                humanCountry: 'United States',
                email: '',
                faxPhoneNumber: values.faxNumber,
                phoneNumber: values.phoneNumber,
                state: values.state,
                zipCode: values.zipcode,
              };
              onClinicAdded(clinicToAdd);
              onClose();
            }}
          >
            {(props) => (
              <Form className="" onKeyDown={keyDownHandler}>
                <div className="add-clinic-modal-grid-container">
                  <div className="name-box">
                    <MembershipFloatingLabelInput
                      name="clinicName"
                      placeholder="Clinic Name"
                      label="Clinic Name"
                      ariaRequired="true"
                      value={props.values.clinicName}
                      ariaLabel={props.errors.clinicName}
                      onChange={async (e) => {
                        props.setFieldTouched('clinicName');
                        props.setFieldValue('clinicName', e.target.value);
                      }}
                    />
                    {props.errors.clinicName && props.touched.clinicName && (
                      <div className="input-error" tabIndex={0}>
                        {props.errors.clinicName}
                      </div>
                    )}
                  </div>

                  <div className="address-box">
                    <MembershipFloatingLabelInput
                      name="clinicAddress"
                      placeholder="Clinic Address"
                      label="Clinic Address"
                      ariaRequired="true"
                      value={props.values.clinicAddress}
                      ariaLabel={props.errors.clinicAddress}
                      onChange={async (e) => {
                        props.setFieldTouched('clinicAddress');
                        props.setFieldValue('clinicAddress', e.target.value);
                      }}
                    />
                    {props.errors.clinicAddress && props.touched.clinicAddress && (
                      <div className="input-error" tabIndex={0}>
                        {props.errors.clinicAddress}
                      </div>
                    )}
                  </div>

                  <div className="city-box">
                    <MembershipFloatingLabelInput
                      name="city"
                      placeholder="City"
                      label="City"
                      ariaRequired="true"
                      value={props.values.city}
                      ariaLabel={props.errors.city}
                      onChange={async (e) => {
                        props.setFieldTouched('city');
                        props.setFieldValue('city', e.target.value);
                      }}
                    />
                    {props.errors.city && props.touched.city && (
                      <div className="input-error" tabIndex={0}>
                        {props.errors.city}
                      </div>
                    )}
                  </div>

                  <div className="state-box" aria-required="true">
                    <MembershipFloatingLabelComboBox
                      id="state"
                      labelId="combo-box-label"
                      name="state"
                      ariaLabel={props.errors.state}
                      text="State"
                      selectedOption={props.values.state}
                      options={states}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                    />
                    {props.errors.state && props.touched.state && (
                      <div className="input-error" tabIndex={0}>
                        {props.errors.state}
                      </div>
                    )}
                  </div>

                  <div className="zip-box">
                    <MembershipFloatingLabelInput
                      name="zip"
                      placeholder="Zip"
                      label="Zip"
                      ariaRequired="true"
                      value={props.values.zipcode}
                      ariaLabel={props.errors.zipcode}
                      onChange={async (e) => {
                        props.setFieldTouched('zipcode');
                        props.setFieldValue('zipcode', e.target.value);
                      }}
                    />
                    {props.errors.zipcode && props.touched.zipcode && (
                      <div className="input-error" tabIndex={0}>
                        {props.errors.zipcode}
                      </div>
                    )}
                  </div>

                  <div className="phone-number-box">
                    <MembershipFloatingLabelInput
                      name="phoneNumber"
                      placeholder="Phone Number"
                      label="Phone Number"
                      ariaRequired="true"
                      value={props.values.phoneNumber}
                      ariaLabel={props.errors.phoneNumber}
                      onChange={async (e) => {
                        props.setFieldTouched('phoneNumber');
                        props.setFieldValue('phoneNumber', e.target.value);
                      }}
                    />
                    {props.errors.phoneNumber && props.touched.phoneNumber && (
                      <div className="input-error" tabIndex={0}>
                        {props.errors.phoneNumber}
                      </div>
                    )}
                  </div>

                  <div className="fax-number-box">
                    <MembershipFloatingLabelInput
                      name="faxNumber"
                      placeholder="Fax Number"
                      label="Fax Number"
                      ariaRequired="true"
                      value={props.values.faxNumber}
                      ariaLabel={props.errors.faxNumber}
                      onChange={async (e) => {
                        props.setFieldTouched('faxNumber');
                        props.setFieldValue('faxNumber', e.target.value);
                      }}
                    />
                    {props.errors.faxNumber && props.touched.faxNumber && (
                      <div className="input-error" tabIndex={0}>
                        {props.errors.faxNumber}
                      </div>
                    )}
                  </div>
                </div>

                <ButtonGroup>
                  <Button
                    disabled={!props.isValid}
                    label="ADD MY VET"
                    type="submit"
                    className="confirm-button"
                  />
                  <Button
                    onClick={onClose}
                    className="secondary"
                    label="CANCEL"
                    type="button"
                  />
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddClinicModal;

import React from 'react';

import ComboBox from 'components-v2/shared/ComboBox/ComboBox';
import './membership-floating-label-combo-box.css';
import { globalConsts } from 'utils/constGlobal';

export const MembershipFloatingLabelComboBox = ({
  labelId,
  name,
  ariaLabel,
  text,
  selectedOption,
  options,
  className,
  onChange,
  onBlur,
  id,
}) => {
  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));

  const getId = (selectedOption) => {
    if (isMobile) {
      return selectedOption ? 'mobile-has-value-floating-select-id' : 'mobile-no-value-floating-select-id';
    } else {
      return selectedOption ? 'react-select-has-value-floating-select-id' : 'react-select-no-value-floating-select-id';
    }
  };

  const getClassName = () => (isMobile ? 'mobile-container combox-box-container' : 'combox-box-container');

  return (
    <div className={getClassName()}>
      {selectedOption?.length > 0
        && (
          <label id={labelId} htmlFor={name}>
            State
          </label>
        )}
      <ComboBox
        id={getId(selectedOption)}
        inputId={id}
        name={name}
        ariaLabel={ariaLabel}
        text={text}
        selectedOption={selectedOption}
        options={options?.map(
          (state) => ({
            key: state.id,
            value: state.postalAbbreviation,
            label: state.label,
          }),
        )}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

import React from 'react';
import './price.css';

const Price = ({
  value, dataName, noPeriod, modifiers, asterisk, annualPaidUpFront, paymentTenure,
}) => {
  const className = `Price ${modifiers && modifiers.some('scratched') ? ' scratched' : null}`;
  const priceTenure = paymentTenure === 'yearly' ? '/yr' : '/mo';
  return (
    <div className={className}>
      <span data-testid={dataName}>
        $
        {value}
      </span>
      {!noPeriod && (
        <sub aria-label={paymentTenure === 'yearly' ? 'Per Year' : 'Per Month'} className={annualPaidUpFront ? 'Price__sub' : ''}>{priceTenure}{`${asterisk ? '*' : ''}`}</sub>
      )}
    </div>
  );
};

export default Price;

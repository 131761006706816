import { createSelector } from 'reselect';

import { currentViewStateSelector } from 'store';

import {
  getPlansWithPetSortedByStatus,
} from 'utils';

import { YOUR_PET_NAME_FALLBACK_LABEL } from 'utils/constGlobal';

export const claimViewModel = createSelector(
  currentViewStateSelector,
  (state) => state.dashboardInfo,
  (state) => state.claims,
  (state) => state.customer.pets,
  (state) => state.customer.membershipPlans,
  (state) => state.toggles,
  (currentViewState, dashboardInfo, claims, pets, membershipPlans, toggles) => {
    const plansWithPet = getPlansWithPetSortedByStatus(membershipPlans.data, pets.data);
    const selectedPlanWithPet = plansWithPet.find((petWithPlan, index) => (dashboardInfo.petMasterId
      ? petWithPlan.petMasterId === dashboardInfo.petMasterId 
      : index === 0));

    const planStartDate = selectedPlanWithPet?.plan ? Date.parse(selectedPlanWithPet.plan.createDt) : '';
    const claimBenefit = selectedPlanWithPet?.plan.membershipBenefits.find((benefit) => benefit.isClaim);

    return {
      step: currentViewState.step,
      petName: selectedPlanWithPet?.pet?.name ?? YOUR_PET_NAME_FALLBACK_LABEL,
      petType: selectedPlanWithPet?.pet?.petType,
      planStartDate,
      benefitId: claims.benefitId,
      planId: selectedPlanWithPet?.plan.id,
      claimClinic: claims.claimClinic,
      claimDate: claims.date,
      claimFile: claims.file,
      claimPresignedObject: claims.preSignedObject,
      claimLastClinicSelected: claims.lastClinicSelected,
      palsRewardsValue: claimBenefit ? claimBenefit.value : '',
      toggles,
    };
  },
);

import React from 'react';

import { ButtonLink } from 'components-v2/shared/Button-v1';
import Icon from 'components-v2/shared/Icon/Icon';
import { Text } from 'components-v2/shared/Elements';
import { handleKeyUp } from 'utils';
import './file-image.css';

export const FileImage = ({ onRemoveClick, file }) => (
  <div className="FileImageWrapper">
    <div className="border">
      {file.type === 'application/pdf' ? (
        <div className="FileImage__IconFileWrapper">
          <div className="inner-wrapper">
            <Icon name="file" />
            <Text>{file.name}</Text>
          </div>
        </div>
      )
        : (
          <div className="FileImage__IconFileWrapper">
            <div className="inner-wrapper">
              <img
                alt="Uploaded vet invoice"
                src={file.src}
              />
            </div>
          </div>
        )}

    </div>
    <div>
      <ButtonLink
        id="remove-button"
        label="Remove"
        tabIndex="0"
        value={file}
        onClick={() => onRemoveClick(file)}
        onKeyUp={(e) => handleKeyUp(e, () => onRemoveClick(file))}
        role="button"
      />
    </div>
  </div>
);

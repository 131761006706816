import React from 'react';
import './back-button.css';
import { handleKeyUp, trackEvent } from 'utils';
import { sharedViewModel } from '../viewModel';
import { useSelector } from 'react-redux';

const BackButton = ({ tagEvent, toggles }) => {
  const viewModel = useSelector((state) => sharedViewModel(state));

  const { BACK_BUTTON_TOGGLE } = viewModel;

  return BACK_BUTTON_TOGGLE ? (
    <div
      className={`BackButton ${toggles?.ENABLE_UNIFIED_MEMBERSHIP && 'BackButton_UM'}`}
      role="button"
      tabIndex="0"
      onClick={() => { tagEvent ? (trackEvent(tagEvent), window.history.back()) : window.history.back(); }}
      onKeyUp={(e) => handleKeyUp(e, () => { tagEvent ? (trackEvent(tagEvent), window.history.back()) : window.history.back(); })}
    >
      <p>Back</p>
    </div>
  ) : <></>;
};

export default BackButton;

/* eslint-disable import/no-cycle */
import 'whatwg-fetch';

import { globalConsts } from 'utils/constGlobal';
import { getToken } from './authToken';
import store from '../store';
import { getRequestHeaders, getRequestHeaderForThreeKeyPilot } from '../utils';

const monitorRequests = process?.env?.REACT_APP_ENV === 'local' || false;

// as a sugegstion instead of throw a error:
/*
if (!response.ok) {
    return response.json().then((json) => {
      const error = {
        status: response.status,
        statusText: response.statusText,
        jsonError: { ...json },
      };
      return Promise.reject(response, error);
    });
  }
*/
const tryFetch = (response) => {
  if (!response.ok) {
    throw response;
  }
  return response;
};

const tryStatusActions = (response, actions) => {
  if (response.status in actions) {
    actions[response.status](response);
  }
  return response;
};

export const createFetchJSON = ({
  loadingCount, fatalError, maintenanceError, authError,
}) => (
  url,
  next,
  dispatch,
  error = () => {
    dispatch(fatalError.actions.set(true));
  },
  options = {},
  statusActions = {},
  trackLoading = true,
) => {
  if (monitorRequests) {
    const markStart = `mark_xhr_start_for_${url}`;
    performance.mark(markStart);
  }

  if (trackLoading) {
    dispatch(loadingCount.actions.increment());
  }
  const currentState = store.getState();
  const apuKeyParam = currentState?.url?.apuKey;
  const threeKeyParam = currentState?.url?.threeKey;
  const token = getToken();
  let apuKeyHeader = {};
  let threeKeyHeader = {};
  if (url.indexOf('config') === -1) {
    apuKeyHeader = getRequestHeaders(apuKeyParam, currentState?.uiText?.ApuKey);
  }
  if (url.indexOf('config') === -1) {
    threeKeyHeader = getRequestHeaderForThreeKeyPilot(threeKeyParam, currentState?.uiText?.ThreeKey);
  }
  const optionsWithHeaders = {
    ...options,
    headers: {
      Accept: 'application/json',
      'Cache-Control': 'no-store, no-cache',
      Authorization: token,
      'is-mobile': localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY),
      ...options.headers,
      ...apuKeyHeader,
      ...threeKeyHeader,
    },
  };

  return fetch(url, { credentials: 'include', ...optionsWithHeaders })
    .then((response) => tryStatusActions(response, {
      403: () => dispatch(authError.actions.setTrue()),
      503: () => dispatch(maintenanceError.actions.setTrue()),
      ...statusActions,
    }))
    .then((response) => tryFetch(response))
    .then((response) => {
      if (monitorRequests) {
        const markStart = `mark_xhr_start_for_${url}`;
        const markComplete = `mark_xhr_complete_for_${url}`;
        const measureName = `measure_xhr_${url}`;
        performance.mark(markComplete);
        performance.measure(measureName, markStart, markComplete);
      }

      if (response.status === 204) {
        return response;
      }
      return response.json();
    })
    .then(next)
    .catch(error)
    .finally(() => {
      if (trackLoading) {
        dispatch(loadingCount.actions.decrement());
      }
    });
};

import React from 'react';

let focusableModalElements = null;
let firstElement = null;
let lastElement = null;

const useTrapFocus = (args) => {
  React.useEffect(() => {
    // here we query all focusable elements, customize as your own need
    // focusableModalElements = window.document.querySelectorAll('#clinicName, button:not([disabled]), #clinicAddress, #city, #state, #zip, #phoneNumber, #faxNumber');
    focusableModalElements = window.document.querySelectorAll(args);
    firstElement = focusableModalElements[0];
    lastElement = focusableModalElements[focusableModalElements.length - 1];
    firstElement?.focus();
  }, [() => null]);

  const keyDownHandler = React.useCallback((e) => {
    // only execute if tab is pressed
    if (e.key !== 'Tab') return;
    // if going forward by pressing tab and lastElement is active shift focus to first focusable element 
    if (!e.shiftKey && document.activeElement === lastElement) {
      e.preventDefault();
      firstElement.focus();
    }
    // if going backward by pressing tab and firstElement is active shift focus to last focusable element 
    if (e.shiftKey && document.activeElement === firstElement) {
      e.preventDefault();
      lastElement.focus();
    }
  }, []);
  return keyDownHandler;
};

export default useTrapFocus;

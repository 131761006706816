import React from 'react';

import './coming-soon.css';

const ComingSoonPage = () => (
  <div className="ComingSoonWrapper">
    <div>
      <img
        src="/img/coming-soon.png"
        alt="Petco Message"
        title="Exciting things are coming soon!"
      />
    </div>
  </div>
);

export default ComingSoonPage;

import React from 'react';
import './card.css';

const Card = ({ children, className, ...rest }) => {
  const classNames = `Card${className ? ` ${className}` : ''}`;

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  );
};

Card.Header = ({
  children, className, contentAlign, ...rest
}) => {
  const classNames = `Card__Header${className ? ` ${className}` : ''}`;
  const style = contentAlign === 'center' ? { display: 'flex', justifyContent: 'center', padding: 0 } : null;

  return (
    <div className={classNames} style={style} {...rest}>
      {children}
    </div>
  );
};

Card.Body = ({
  children, className, noPadding, ...rest
}) => {
  const classNames = `Card__Body${className ? ` ${className}` : ''}`;
  const style = noPadding ? { padding: 0 } : null;

  return (
    <div className={classNames} style={style} {...rest}>
      {children}
    </div>
  );
};

export default Card;

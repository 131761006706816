import React from 'react';
import './membership-floating-label-input.css';

export const MembershipFloatingLabelInput = ({
  onChange,
  value,
  label,
  name,
  placeholder,
  ariaLabel,
  ...rest
}) => (
  <div className="MembershipFloatingLabelInputContainer">
    {value?.length > 0 && (
      <label className="MembershipFloatLabel__label" htmlFor={name}>{label}</label>
    )}
    <input
      className={`MembershipFloatLabel__input ${value?.length > 0 && 'MembershipFloatLabel__input--filled'}`}
      type="text"
      name={name}
      label={label}
      id={name}
      placeholder={placeholder}
      onChange={onChange}
      aria-label={ariaLabel}
      {...rest}
    />
  </div>
);

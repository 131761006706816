import React from 'react';
import { connect } from 'react-redux';

import { isLoading } from '../store';

class PetcoLoader extends React.Component {
  getClassName() {
    const className = 'text-center fade modal';
    const { show, tokenLoaded } = this.props;

    return show || !tokenLoaded ? `${className} show` : className;
  }

  render() {
    return (
      <div id="petco-loader" className={this.getClassName()}>
        <img src="/img/petco-loading.gif" alt="Your request is being processed." aria-label="Loading please wait" aria-live="polite" role="alert" />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    show: isLoading(state),
    tokenLoaded: state.tokenState,
  }),
)(PetcoLoader);

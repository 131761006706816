import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  VIEW_INDEX,
  DEEPLINK_INDEX,
  navigationHistory,
  registrationInfo,
} from 'store/slices';
import {
  isLoading,
  isDeepLinking,
} from 'store';
import {
  fetchMembershipsV2,
} from 'store/actions';

import { getNextStep, trackEvent } from 'utils';

import { adobeAnalyticsTags } from 'utils/constGlobal';
import SellScreen from './SellScreen/SellScreen';
import PetSelection from './PetSelection/PetSelection';
import PetCreation from './PetCreation';
import PurchaseSuccess from './PurchaseSuccess/PurchaseSuccess';
import CompleteYourPurchase from './CompleteYourPurchase/CompleteYourPurchase';
import ChooseYourPlan from './ChooseYourPlan/ChooseYourPlan';

import { registrationViewModel } from './viewModel';
import PetCreationPage from './PetCreationPage/PetCreationPage';

function Registration({ isLoadingMemberships = true }) {
  const viewModel = useSelector((state) => registrationViewModel(state));
  const loading = useSelector((state) => isLoading(state));
  const deepLinking = useSelector((state) => isDeepLinking(state));
  const [loadingMemberships, setLoadingMemberships] = useState(isLoadingMemberships);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMembershipsV2(() => {
      setLoadingMemberships(false);
    }));
  }, [dispatch, setLoadingMemberships]);

  const {
    step,
    membershipInfo,
    toggles,
    pets,
    membershipPlans,
    hasUserMembershipPlans,
    enrolledPets,
    enrollmentInfo,
    creationErrorInfo,
    creatingPets,
    benefits,
    deepLinkInfo,
    userInfo,
    petTypes,
    affiliateReferralCode,
    analyticsDataObject,
    registrationInfoData,
    companionAnimalFeature,
    paymentTenure,
    disclaimerMessage,
    uiText,
    paymentPlansDetail,
    webSocketNotification,
    wsConnectionId,
    petCostsByMembershipId,
    paymentMethodUpdateErrorInfo,
    wcsUserId,
    paymentAuthCCInfo,
    affiliate,
  } = viewModel;

  const enrollNowClicked = () => {
    if (pets.length > 0) {
      dispatch(navigationHistory.actions.goToPetSelection());
    } else {
      dispatch(navigationHistory.actions.goToPetCreationPage());
    }
  };

  const onSelectedPetsUpdate = (selectedPets, keyInfo) => {
    dispatch(registrationInfo.actions.setSelectedPetsToEnroll(
      selectedPets.map((pet) => ({
        ...pet,
        [keyInfo]: true,
      })),
    ));
    dispatch(registrationInfo.actions.setTaxAndDiscountInfo(null));
  };

  const petSelectionComplete = () => {
    const action = getNextStep(toggles, VIEW_INDEX.PET_SELECTION);
    dispatch(action());
  };

  const onShowMobileAddNewForm = () => {
    let message = `vital_care_wallet_card queryString:entdeeplink=${DEEPLINK_INDEX.ENROLLMENT_CONFIRMATION_WALLET.key}&entdeeplinkpets=`;
    let addedOne = false;
    enrolledPets.forEach((pet) => {
      if (addedOne) {
        message += ',';
      }

      message += String(pet.pgrMasterId);
      addedOne = true;
    });

    message += '&entdeeplinkwalletid=';

    trackEvent(adobeAnalyticsTags?.ADD_NEW_PAYMENT_INFO_V2);
    window.parent.postMessage({ action: 'consoleMessage', message }, '*');
  };

  return (
    <div className="registration-container">
      {creatingPets && (
        <PetCreation
          isLoading={loading}
        />
      )}
      {step === VIEW_INDEX.SELL_SCREEN.step
        && (
          <SellScreen
            isLoading={loading}
            userName={userInfo?.data?.firstName}
            enrollNowClicked={enrollNowClicked}
            annualPrice={membershipInfo.annualPrice}
            sellPageHeadline={membershipInfo.sellPageHeadline}
            sellPageAboveCopy={membershipInfo.sellPageAboveCopy}
            sellPageBelowCopy={membershipInfo.sellPageBelowCopy}
            membershipBenefits={membershipInfo.membershipBenefits}
            hasUserMembershipPlans={hasUserMembershipPlans}
            showComingSoon={membershipInfo.showComingSoon}
            benefits={benefits}
            deepLinking={deepLinking}
            deepLinkInfo={deepLinkInfo}
            analyticsDataObject={analyticsDataObject}
            toggles={toggles}
            sellScreenDetails={registrationInfoData.sellScreenDetails}
            companionAnimalFeature={companionAnimalFeature}
          />
        )}
      {step === VIEW_INDEX.PET_SELECTION.step
        && (
          <PetSelection
            onSelectedPetsUpdate={onSelectedPetsUpdate}
            annualPrice={membershipInfo.annualPrice}
            annualPriceWithDiscount={membershipInfo.annualPriceWithDiscount}
            multipetDiscount={membershipInfo.multipetDiscount}
            hasMultipetDiscount={membershipInfo.hasMultipetDiscount}
            petAlreadyEnrolled={membershipInfo.petAlreadyEnrolled}
            hasUserMembershipPlans={hasUserMembershipPlans}
            pets={pets}
            membershipPlans={membershipPlans}
            enrolledPets={enrolledPets}
            isLoading={loading}
            deepLinking={deepLinking}
            deepLinkInfo={deepLinkInfo}
            loadingMemberships={loadingMemberships}
            petTypes={petTypes}
            analyticsDataObject={analyticsDataObject}
            registrationInfoData={registrationInfoData}
            affiliateReferralInfo={affiliateReferralCode}
            petSelectionComplete={petSelectionComplete}
            toggles={toggles}
            companionAnimalFeature={companionAnimalFeature}
            uiText={uiText}
            affiliate={affiliate}
          />
        )}
      {step === VIEW_INDEX.PET_ADDITION_FORM.step
        && (
          <PetCreationPage
            onSelectedPetsUpdate={onSelectedPetsUpdate}
            annualPrice={membershipInfo.annualPrice}
            annualPriceWithDiscount={membershipInfo.annualPriceWithDiscount}
            multipetDiscount={membershipInfo.multipetDiscount}
            hasMultipetDiscount={membershipInfo.hasMultipetDiscount}
            petAlreadyEnrolled={membershipInfo.petAlreadyEnrolled}
            hasUserMembershipPlans={hasUserMembershipPlans}
            pets={pets}
            petTypes={petTypes}
            membershipPlans={membershipPlans}
            enrolledPets={enrolledPets}
            isLoading={loading}
            deepLinking={deepLinking}
            deepLinkInfo={deepLinkInfo}
            loadingMemberships={loadingMemberships}
            analyticsDataObject={analyticsDataObject}
            registrationInfoData={registrationInfoData}
            toggles={toggles}
            companionAnimalFeature={companionAnimalFeature}
            uiText={uiText}
          />
        )}
      {step === VIEW_INDEX.CHOOSE_YOUR_PLAN.step && toggles?.ANNUAL_PAID_UPFRONT_ONLINE
        && (
          <ChooseYourPlan
            isLoading={loading}
            enrolledPets={enrolledPets}
            enrollmentInfo={enrollmentInfo}
            hasMultipetDiscount={membershipInfo.hasMultipetDiscount}
            hasAffiliateDiscount={membershipInfo.hasAffiliateDiscount}
            affiliateDiscountInfo={membershipInfo.affiliateDiscount}
            petAlreadyEnrolled={membershipInfo.petAlreadyEnrolled}
            membershipTotalPrice={enrollmentInfo.totalPrice}
            membershipTotalPriceWithoutTax={enrollmentInfo.totalPriceWithoutTax}
            membershipId={membershipInfo.membershipId}
            paymentMethod={membershipInfo.paymentMethod}
            showTax={enrollmentInfo.showTax}
            totalTax={enrollmentInfo.totalTax}
            errorDuringCreation={creationErrorInfo.errorOccurred}
            errorModalTitle={creationErrorInfo.modalTitle}
            errorModalBody={creationErrorInfo.modalBody}
            storeLocatorEnabled={toggles.YOUR_STORE_LOCATION_TOGGLE_ENABLED}
            discountInfo={membershipInfo.discountInfo}
            onShowMobileAddNewForm={onShowMobileAddNewForm}
            deepLinking={deepLinking}
            deepLinkInfo={deepLinkInfo}
            affiliateReferralCode={affiliateReferralCode}
            analyticsDataObject={analyticsDataObject}
            toggles={toggles}
            uiText={uiText}
            paymentPlansDetail={paymentPlansDetail}
            wcsId={userInfo?.id}
          />
        )}
      {step === VIEW_INDEX.COMPLETE_YOUR_PURCHASE.step
        && (
          <CompleteYourPurchase
            isLoading={loading}
            enrolledPets={enrolledPets}
            enrollmentInfo={enrollmentInfo}
            hasMultipetDiscount={membershipInfo.hasMultipetDiscount}
            hasAffiliateDiscount={membershipInfo.hasAffiliateDiscount}
            affiliateDiscountInfo={membershipInfo.affiliateDiscount}
            petAlreadyEnrolled={membershipInfo.petAlreadyEnrolled}
            membershipTotalPrice={enrollmentInfo.totalPrice}
            membershipTotalPriceWithoutTax={enrollmentInfo.totalPriceWithoutTax}
            membershipId={membershipInfo.membershipId}
            paymentMethod={membershipInfo.paymentMethod}
            showTax={enrollmentInfo.showTax}
            totalTax={enrollmentInfo.totalTax}
            errorDuringCreation={creationErrorInfo.errorOccurred}
            errorModalTitle={creationErrorInfo.modalTitle}
            errorModalBody={creationErrorInfo.modalBody}
            storeLocatorEnabled={toggles.YOUR_STORE_LOCATION_TOGGLE_ENABLED}
            discountInfo={membershipInfo.discountInfo}
            onShowMobileAddNewForm={onShowMobileAddNewForm}
            deepLinking={deepLinking}
            deepLinkInfo={deepLinkInfo}
            affiliateReferralCode={affiliateReferralCode}
            toggles={toggles}
            paymentTenure={paymentTenure}
            disclaimerMessage={disclaimerMessage}
            webSocketNotification={webSocketNotification}
            wsConnectionId={wsConnectionId}
            petCostsByMembershipId={petCostsByMembershipId}
            paymentMethodUpdateErrorInfo={paymentMethodUpdateErrorInfo}
            wcsUserId={wcsUserId}
            paymentAuthCCInfo={paymentAuthCCInfo}
            uiText={uiText}
          />
        )}
      {step === VIEW_INDEX.PURCHASE_SUCCESS.step
        && (
          <PurchaseSuccess
            multipetDiscount={membershipInfo.multipetDiscount}
            affiliateDiscount={membershipInfo.affiliateDiscount}
            membershipTotalPrice={enrollmentInfo.totalPrice}
            membershipTotalPriceWithoutTax={enrollmentInfo.totalPriceWithoutTax}
            totalTax={enrollmentInfo.totalTax}
            analyticsDataObject={analyticsDataObject}
            toggles={toggles}
            paymentTenure={paymentTenure}
            uiText={uiText}
            enrolledPets={enrolledPets}
          />
        )}
    </div>
  );
}

export default Registration;

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { dashboardInfo, deepLink, DEEPLINK_INDEX } from 'store/slices';
import { isMobile, renderPetIconsForUpdatePlansCC, trackEvent } from 'utils';
import {
  adobeAnalyticsTags,
  adobeAnalyticsTagsAPU, deviceName,
  StatusPlan,
} from 'utils/constGlobal';

import Card from 'components-v2/shared/Card-v1';
import Container from 'components-v2/shared/Container';
import { Text, Title } from 'components-v2/shared/Elements';
import Icon from 'components-v2/shared/Icon/Icon';
import List from 'components-v2/shared/List-v1';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';
import PlanTransitionMessage from 'components-v2/shared/PlanTransitionMessage';
import Progress from 'components-v2/shared/Progress';
import DashboardHeader from '../DashboardHeader/DashboardHeader';

import PlanSavingsModal from 'components-v2/registration/ChooseYourPlan/PlanSavingsModal';
import { ButtonLink } from 'components-v2/shared/Button';
import Checkbox from 'components-v2/shared/Checkbox';
import CreditCardSelection from 'components-v2/shared/CreditCardSelection-v1';
import PetPaymentDetails from 'components-v2/shared/PetPaymentDetailsCard/PetPaymentDetails';
import { Formik } from 'formik';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import {
  fetchMembershipPlans,
  fetchPlansDetail,
  paymentMethodUpdate,
  switchToYearlyPayment,
  updatePlansCC,
} from 'store/actions';
import SwitchToYearlyPlanDetails from '../MembershipDetails/ReactivateModal/ReactivationModal';
import UpdatePlansCCModal from '../MembershipDetails/UpdatePlansCCModal/UpdatePlansCCModal';
import './redemption-dashboard.css';

const RedemptionDashboard = ({
  isLoading,
  customerInfo,
  customerFirstName,
  showUsageHistory,
  planStatus,
  totalBenefitUsage,
  plansWithPets,
  selectedPlanWithPet,
  choosePet,
  deepLinkInfo,
  deepLinking,
  goToRedeemBenefits,
  goToMembershipDetails,
  goToContactCustomerCare,
  goToEnrollNewPet,
  goToBenefitHistory,
  showTransitionMessage,
  transitionMessage,
  goToSubmitClaim,
  toggles,
  uiText,
  paymentPlansDetail,
  switchMembershipSuccessMessage,
  paymentMethodsLoaded,
  errorDuringPaymentMethodUpdate,
  errorPaymentMethodUpdateModalTitle,
}) => {
  const dispatch = useDispatch();

  const [isOpenPlanSavingModal, setIsOpenPlanSavingModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [switchToYearly, setSwitchToYearly] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUpdatePlansCCModal, setShowUpdatePlansCCModal] = useState(false);
  const [updatedWalletID, setUpdatedWalletID] = useState();
  const [selectedPlansForUpdateCC, setSelectedPlansForUpdateCC] = useState([]);
  const [, setPaymentMethodUpdated] = useState(false);
  const filteredPlansWithPets = plansWithPets?.filter((plans) => plans?.plan?.status !== 'canceled');
  const [filteredPlansWithPetsCheckedState, setFilteredPlansWithPetsCheckedState] = useState(new Array(filteredPlansWithPets?.filter(plan => plan?.plan?.id !== selectedPlanWithPet?.plan?.id)?.length).fill(false));
  const [validPCCs, setValidPCCs] = useState([]);
  const [errorAddingPayment, setErrorAddingPayment] = useState(false);

  const vetOfYourChoiceBenefitAvailable = useMemo(() => selectedPlanWithPet?.plan?.membershipBenefits?.find(
    (benefit) => benefit.name === 'Vet of Your Choice' && benefit.isClaim === true,
  ), [selectedPlanWithPet]);

  const getLabel = (totalBenefitUsageParam) => {
    const text = 'in benefits used so far this year!';
    return totalBenefitUsageParam ? `$${totalBenefitUsageParam.toFixed(2)} ${text}` : `$0.00 ${text}`;
  };

  // Why is this hard coded to 30 or 0? because months ago Jack Li said that the bussiness didn't come
  // with a limit value, then we'd either show some progress on this bar or not, based if the customer had
  // any usage or not. Currently, the show benefit usage history link is toggled off on the application, it
  // might get removed on the future.
  const getPercentage = (totalBenefitUsageParam) => (totalBenefitUsageParam ? '30' : '0');

  // wait for pets to load then process deep link
  useEffect(() => {
    if (deepLinking && !isLoading) {
      // Note this is purposely pgrMasterId not petMasterId
      let foundPlanWithPet = selectedPlanWithPet?.pet
        ? selectedPlanWithPet?.pet?.pgrMasterId === deepLinkInfo?.petId
        : false;

      if (!foundPlanWithPet) {
        // Note this is purposely pgrMasterId not petMasterId
        const deepLinkedPlan = plansWithPets.find(
          (planWithPet) => planWithPet.pet && planWithPet.pet.pgrMasterId === deepLinkInfo.petId,
        );

        if (deepLinkedPlan) {
          foundPlanWithPet = true;
          choosePet(deepLinkedPlan.petMasterId);
        }
      }

      switch (deepLinkInfo.destination) {
        case DEEPLINK_INDEX.PET_SELECTION.key:
        case DEEPLINK_INDEX.PET_CREATION.key:
        case DEEPLINK_INDEX.ENROLLMENT_CONFIRMATION.key:
        case DEEPLINK_INDEX.ENROLLMENT_CONFIRMATION_WALLET.key:
          goToEnrollNewPet();
          break;

        case DEEPLINK_INDEX.PLAN_REDEMPTION.key:
        case DEEPLINK_INDEX.SUBMIT_CLAIM.key:
          if (foundPlanWithPet) {
            goToRedeemBenefits();
          } else {
            dispatch(deepLink.actions.resetDeepLink());
          }
          break;

        case DEEPLINK_INDEX.PLAN_USAGE_HISTORY.key:
          if (foundPlanWithPet && showUsageHistory) {
            goToBenefitHistory();
          }
          // There is no deeper links off this view so we clean up deep link here
          dispatch(deepLink.actions.resetDeepLink());
          break;

        case DEEPLINK_INDEX.PLAN_DETAILS.key:
        case DEEPLINK_INDEX.PAYMENT.key:
        case DEEPLINK_INDEX.CANCEL_RENEW_PLAN.key:
        case DEEPLINK_INDEX.REACTIVATE_MEMBERSHIP_PLAN.key:
          if (foundPlanWithPet) {
            goToMembershipDetails();
          } else {
            dispatch(deepLink.actions.resetDeepLink());
          }
          break;

        default:
          dispatch(deepLink.actions.resetDeepLink());
          break;
      }
    }
  }, [dispatch,
    selectedPlanWithPet,
    deepLinkInfo,
    deepLinking,
    isLoading,
    showUsageHistory,
    goToEnrollNewPet,
    choosePet,
    plansWithPets,
    goToRedeemBenefits,
    goToBenefitHistory,
    goToMembershipDetails]);

  const checkRoverTextInStorage = localStorage.getItem('roverBenefitSuccessMessage');
  const checkRoverTextInStorageToHide = localStorage.getItem('hideRoverBenefitSuccessMessage');
  const THIRD_PARTY_CATEGORY_NAME = 'Your Third Party Benefits';

  const checkRoverBenefitStatus = useMemo(() => {
    let roverBenefit;
    if ((selectedPlanWithPet?.pet?.petType === 'Dog' || selectedPlanWithPet?.pet?.petType === 'Cat')) {
      const { membershipBenefits } = selectedPlanWithPet.plan || {};
      const filteredRoverBenefit = membershipBenefits.filter((benefit) => benefit.categoryName === THIRD_PARTY_CATEGORY_NAME);
      roverBenefit = filteredRoverBenefit;
    }
    if (toggles?.ENABLE_ROVER_BANNER && (planStatus !== StatusPlan.CANCELED || selectedPlanWithPet?.plan?.preAuthFailed) && (selectedPlanWithPet?.pet?.petType === 'Dog' || selectedPlanWithPet?.pet?.petType === 'Cat') && roverBenefit[0]?.isRedeemable && roverBenefit[0]?.redeemedCount === 0 && (checkRoverTextInStorage === null || checkRoverTextInStorage !== undefined) && (checkRoverTextInStorageToHide === null || checkRoverTextInStorage !== uiText?.roverBannerMessage)) {
      localStorage.setItem('roverBenefitSuccessMessage', uiText?.roverBannerMessage);
      localStorage.removeItem('hideRoverBenefitSuccessMessage');
      return true;
    } else {
      return false;
    }
  }, [toggles?.ENABLE_ROVER_BANNER, planStatus, selectedPlanWithPet?.plan?.preAuthFailed, selectedPlanWithPet?.pet?.petType, selectedPlanWithPet?.plan?.membershipBenefits, checkRoverTextInStorage, checkRoverTextInStorageToHide]);

  useEffect(() => {
    if (checkRoverBenefitStatus) {
      adobeAnalyticsTags.USER_LANDS_ON_DASHBOARD_PAGE_WITH_ROVER_BANNER.page.buildVersion = toggles?.FRONTEND_VERSION;
      trackEvent(adobeAnalyticsTags.USER_LANDS_ON_DASHBOARD_PAGE_WITH_ROVER_BANNER);
    } else if (planStatus === StatusPlan.EXPIRED) {
      adobeAnalyticsTags.DASHBOARD_LANDING_PAGE_FIRST_GRACE_PERIOD.page.buildVersion = toggles?.FRONTEND_VERSION;
      trackEvent(adobeAnalyticsTags?.DASHBOARD_LANDING_PAGE_FIRST_GRACE_PERIOD);
    } else if (planStatus === StatusPlan.CANCELED) {
      adobeAnalyticsTags.USER_LAND_ON_REACTIVE_WARNING_MESSAGE.page.buildVersion = toggles?.FRONTEND_VERSION;
      trackEvent(adobeAnalyticsTags?.USER_LAND_ON_REACTIVE_WARNING_MESSAGE);
    } else if (planStatus === StatusPlan.ACTIVE) {
      adobeAnalyticsTags.USER_LAND_ON_DASHBOARD.page.buildVersion = toggles?.FRONTEND_VERSION;
      trackEvent(adobeAnalyticsTags?.USER_LAND_ON_DASHBOARD);
    }
  }, [planStatus, selectedPlanWithPet?.plan?.id, toggles?.FRONTEND_VERSION, checkRoverBenefitStatus]);

  const BenefitUsageHistoryLink = () => (
    <List.Item as="button" onClick={() => goToBenefitHistory()} tabIndex="0" aria-label="View Benefit Usage History">
      <Icon name="benefitUsageHistory" color="#F08423" size="25" />
      <span>View Benefit Usage History</span>
      <Icon name="arrowRight" className="alignRight" color="#979797" />
    </List.Item>
  );

  const togglePlanSavingsModal = () => {
    setIsOpenPlanSavingModal(!isOpenPlanSavingModal);
    if (!isOpenPlanSavingModal) {
      trackEvent(adobeAnalyticsTagsAPU?.USER_CLICKS_ON_LEARN_MORE_ON_SWITCH_NOW_BANNER);
    }
  };

  const checkShowSwitchNowBanner = useMemo(() => {
    if (toggles?.ANNUAL_PAID_UPFRONT_ONLINE && selectedPlanWithPet?.plan?.enableSwitchNowBannerForAllPccs && selectedPlanWithPet?.plan?.showSwitchNowBanner && selectedPlanWithPet?.plan?.paymentPlan === 'monthly' && (selectedPlanWithPet?.plan?.status === 'active' || selectedPlanWithPet?.plan?.status === 'expired')) {
      return true;
    } else if (validPCCs?.includes(customerInfo?.primaryPCC) && selectedPlanWithPet?.plan?.paymentPlan === 'monthly' && (selectedPlanWithPet?.plan?.status === 'active' || selectedPlanWithPet?.plan?.status === 'expired') && selectedPlanWithPet?.plan?.showSwitchNowBanner) {
      return true;
    } else if (toggles?.ANNUAL_PAID_UPFRONT_ONLINE && toggles?.SWITCH_NOW_BANNER && selectedPlanWithPet?.plan?.paymentPlan === 'monthly' && (selectedPlanWithPet?.plan?.status === 'active' || selectedPlanWithPet?.plan?.status === 'expired') && selectedPlanWithPet?.plan?.showSwitchNowBanner) {
      return true;
    } else {
      return false;
    }
  }, [customerInfo?.primaryPCC, selectedPlanWithPet?.plan?.paymentPlan, selectedPlanWithPet?.plan?.status, toggles?.ANNUAL_PAID_UPFRONT_ONLINE, toggles?.SWITCH_NOW_BANNER, validPCCs, selectedPlanWithPet?.plan?.showSwitchNowBanner, selectedPlanWithPet?.plan?.enableSwitchNowBannerForAllPccs]);

  useEffect(() => {
    if (checkShowSwitchNowBanner && selectedPlanWithPet?.plan?.id) {
      dispatch(fetchPlansDetail(selectedPlanWithPet?.name, selectedPlanWithPet?.plan?.membershipTypeId, selectedPlanWithPet?.plan?.wcsUserId, 'Dashboard', selectedPlanWithPet?.plan?.id));
    }
  }, [checkShowSwitchNowBanner, dispatch, selectedPlanWithPet?.name, selectedPlanWithPet?.plan?.id, selectedPlanWithPet?.plan?.membershipTypeId, selectedPlanWithPet?.plan?.wcsUserId]);

  const planSavings = paymentPlansDetail?.yearly?.planSavingText.map((plan, i) => (
    <div key={plan?.text}>
      <h5 className="Dialog-title">
        {plan?.heading}
      </h5>
      <p className="Dialog-message">
        {plan?.text}
      </p>
    </div>
  ));

  const deviceNameStr = deviceName();

  const termsAndConditionsUMURL = `${process.env.REACT_APP_TERMS_AND_CONDITIONS_URL_UM}${isMobile ? '?channel=mobile' : ''}`;
  const termsOfUseURL = `${process.env.REACT_APP_TERMS_OF_USE_URL}${isMobile ? '?channel=mobile' : ''}`;
  const privacyPolicyURL = `${process.env.REACT_APP_PRIVACY_POLICY_URL}${isMobile ? '?channel=mobile' : ''}`;
  const californiaPrivacyPolicyURL = `${process.env.REACT_APP_CALIFORNIA_PRIVACY_POLICY_URL}${isMobile ? '?channel=mobile' : ''}`;

  const renderSwitchToYearlyPaymentPlanDetails = () => (
    paymentPlansDetail?.yearly?.petData?.map((tenure, index) => (
      paymentPlansDetail?.uiTextData?.filter((data) => data?.btnName === 'yearly')?.map((data) => (
        <div key={data?.btnName} className="switchToYearlyPaymentPlanDetails">
          <PetPaymentDetails
            title={uiText?.switchToYearlyPaymentPlanText}
            highlightTitle={uiText?.greenHighlightedDiscountText}
            actualPrice={tenure.actualPrice}
            discountedPrice={tenure.discountedPrice}
            perMonthPrice={tenure.perMonthPrice}
            suffix="/yr"
            screen="switchToYearlyFromDashboard"
            id={index}
            displayPlanSavingBanner={paymentPlansDetail?.displayPlanSavingBanner}
          />
        </div>
      ))))
  );

  const renderSwitchToYearlyDetails = () => (
    <div className="ReactivationTermsText">
      <div className="ReactivationBodyData">
        <span className="heading" role="heading" aria-level="3">New Plan Effective Date:</span>
        <span className="description">{`${moment().format('MMM DD, YYYY')} - ${moment(selectedPlanWithPet?.plan?.nextPaymentDate).add(1, 'year').format('MMM DD, YYYY')}`}</span>
        <span className="heading" role="heading" aria-level="3">Yearly Payment:</span>
        <span className="description">{`Billing will occur on ${moment(selectedPlanWithPet?.plan?.nextPaymentDate).add(1, 'year').format('MMMM DD, YYYY')}`}</span>
        <span className="heading" role="heading" aria-level="3">Annual Renewal Date:</span>
        <span className="descriptionFontSize">{moment(selectedPlanWithPet?.plan?.nextPaymentDate).add(1, 'year').format('MMMM DD, YYYY')}</span>
      </div>
      <div className="ReactivationTermsSection">
        <Formik>
          <div className="terms-checkbox-row-section Reactivation-terms-section">
            <Checkbox
              id="termsChecked"
              aria-label={termsChecked
                ? 'You checked the box and agreed with Petco Vital Care '
                + 'Terms and Conditions, Terms of Use and Privacy Policy'
                : 'Check the box if you agree with Petco Vital Care '
                + 'Terms and Conditions, Terms of Use and Privacy Policy'}
              handleCheck={() => {
                setTermsChecked(!termsChecked);
              }}
              defaultChecked={termsChecked}
            />
            <div>
              <Text>
                I agree to Petco's
                {' '}
                <a
                  href={termsAndConditionsUMURL}
                  name="termsConditions"
                  className="button-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vital Care Premier Terms & Conditions
                </a>
                ,
                {' '}
                <a
                  href={termsOfUseURL}
                  name="termsUse"
                  className="button-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
                {' '}
                and
                {' '}
                <a
                  href={privacyPolicyURL}
                  name="privacePolicy"
                  className="button-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>. California residents can review our Notice of Financial Incentives&nbsp;
                <a
                  href={californiaPrivacyPolicyURL}
                  name="californiaPrivacyPolicy"
                  className="button-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >here
                </a>.
              </Text>
            </div>
          </div>
        </Formik>
      </div>
    </div>
  );

  const switchPlan = async () => {
    await dispatch(switchToYearlyPayment(selectedPlanWithPet?.plan?.id, selectedPlanWithPet?.plan?.walletCardId));

    // dispatch(fetchPaymentMethods(true));
    dispatch(fetchMembershipPlans(true));
  };

  const updatePaymentMethod = async (paymentMethodId, paymentAddFailed) => {
    if (paymentAddFailed) {
      setErrorAddingPayment(true);
      return;
    }
    await dispatch(paymentMethodUpdate(selectedPlanWithPet?.plan?.id, paymentMethodId, selectedPlanWithPet?.plan?.status));
    await dispatch(switchToYearlyPayment(selectedPlanWithPet?.plan?.id, paymentMethodId));

    setShowModal(false);
    setPaymentMethodUpdated(true);

    setUpdatedWalletID(paymentMethodId);
    setShowUpdatePlansCCModal(true);
    // dispatch(fetchPaymentMethods(true));
    dispatch(fetchMembershipPlans(true));
  };

  const updateAllPlansCCMethod = async (planIDs, walletId) => {
    await dispatch(updatePlansCC(planIDs, walletId));

    setShowUpdatePlansCCModal(false);
    // dispatch(fetchPaymentMethods(true));
    dispatch(fetchMembershipPlans(true));
  };

  const onShowMobileAddNewForm = () => {
    const pgrMasterId = selectedPlanWithPet?.pet ? selectedPlanWithPet?.pet.pgrMasterId : '';
    const message = `vital_care_wallet_card queryString:entdeeplink=${DEEPLINK_INDEX.PAYMENT.key}&entdeeplinkpet=${pgrMasterId}&entdeeplinkwalletid=`;
    window.parent.postMessage({ action: 'consoleMessage', message }, '*');
  };

  const renderUpdatePlansCCModalBody = () => (
    <div className="updateCCPetNames">
      {filteredPlansWithPets?.filter(plan => plan.plan.id !== selectedPlanWithPet?.plan.id && plan.plan.walletCardId !== Number(updatedWalletID))?.map((plan, index) => (
        <div className="displayUpdateCCPetNames" key={index}>
          <Checkbox
            value={plan.name}
            checked={filteredPlansWithPetsCheckedState[index]}
            handleCheck={() => handleUpdatePlansCCChecked(index, filteredPlansWithPetsCheckedState[index], plan.plan.id)}
          />
          {renderPetIconsForUpdatePlansCC(plan?.pet?.petType)}
          <span>{plan?.name}</span>
        </div>
      ))}
    </div>
  );

  const handleUpdatePlansCCChecked = (position, checked, planID) => {
    const updatedCheckedState = filteredPlansWithPetsCheckedState?.map((item, index) => (index === position ? !item : item));

    setFilteredPlansWithPetsCheckedState(updatedCheckedState);

    let selectedPlanIDs = [...selectedPlansForUpdateCC];
    if (!checked) {
      selectedPlanIDs.push(planID);
    } else {
      selectedPlanIDs = selectedPlanIDs.filter(item => item !== planID);
    }

    setSelectedPlansForUpdateCC(selectedPlanIDs);
  };

  const handleUpdatePlansCCPreSelect = useCallback(() => {
    setFilteredPlansWithPetsCheckedState(new Array(filteredPlansWithPets?.filter(plan => plan?.plan?.id !== selectedPlanWithPet?.plan?.id && plan.plan.walletCardId !== Number(updatedWalletID))?.length).fill(true));
    let selectedPlanIDs = [...selectedPlansForUpdateCC];

    const plans = filteredPlansWithPets?.filter(plan => plan?.plan?.id !== selectedPlanWithPet?.plan?.id && plan.plan.walletCardId !== Number(updatedWalletID));
    if (plans) {
      selectedPlanIDs = plans?.map((plan) => plan?.plan?.id);
    }
    setSelectedPlansForUpdateCC(selectedPlanIDs);
  }, [filteredPlansWithPets, selectedPlanWithPet?.plan?.id, selectedPlansForUpdateCC, updatedWalletID]);

  useEffect(() => {
    if (errorDuringPaymentMethodUpdate) {
      setShowModal(true);
    }
  }, [errorDuringPaymentMethodUpdate, showModal]);

  useEffect(() => {
    if (showUpdatePlansCCModal) {
      setSelectedPlansForUpdateCC([]);
      handleUpdatePlansCCPreSelect();
    }
  }, [handleUpdatePlansCCPreSelect, showUpdatePlansCCModal, updatedWalletID]);

  const injectPetNameInString = (str) => {
    if (str) {
      const spliStr = str.split(',');
      const index = spliStr.indexOf('pet\'s');
      spliStr[index] = `${selectedPlanWithPet?.name}'s`;
      return spliStr.join('');
    }
  };

  useEffect(() => {
    if (customerInfo && toggles?.PILOT_PCC) {
      const pccArr = toggles?.PILOT_PCC.split(',')?.map((removeWhitespace) => removeWhitespace?.trim());
      setValidPCCs(pccArr);
    }
  }, [customerInfo, toggles?.PILOT_PCC]);

  return (
    !isLoading && !deepLinking && (
      <div className="RedemptionDashboardPage-v2" role="main">
        <Container className="dashboard-welcome-container">
          <PetcoLogoSharedComponent />
          <Container className="dashboard-welcome-message-container">
            <Title>{`${uiText?.welcomeBackTxt}, ${customerFirstName}!`}</Title>
          </Container>
        </Container>


        <DashboardHeader
          planStatus={planStatus}
          plansWithPets={plansWithPets}
          selectedPlanWithPet={selectedPlanWithPet}
          choosePet={(petMasterId) => choosePet(petMasterId)}
          goToMembershipDetails={goToMembershipDetails}
          switchMembershipSuccessMessage={switchMembershipSuccessMessage}
          customerInfo={customerInfo}
          toggles={toggles}
          uiText={uiText}
        />

        {showTransitionMessage && (
          <PlanTransitionMessage
            transitionMessage={transitionMessage}
          />
        )}

        <Container className="RedemptionDashboardContainer-v2">
          <Card>
            <List.Ul className="List-List-Style">

              {planStatus !== StatusPlan.CANCELED && (
                <li>
                  <List.Item
                    as="button"
                    data-testid="buttonRedeemYourBenefits"
                    aria-label="Redeem your benefits"
                    onClick={() => goToRedeemBenefits()}
                    tabIndex="0"
                  >
                    {toggles?.ENABLE_UNIFIED_MEMBERSHIP
                      ? <Icon name="um_RedeemBenefits" size="25" />
                      : <Icon name="redeemBenefits" size="25" />}
                    <span>{uiText?.redeemBenefitsTxt}</span>
                    <Icon name="arrowRight" className="alignRight" />
                  </List.Item>
                </li>
              )}

              {toggles?.ENABLE_REACTIVATION_UI ? (
                planStatus !== StatusPlan.CANCELED && vetOfYourChoiceBenefitAvailable && (
                  <li>
                    <List.Item
                      as="button"
                      aria-label="Non-Petco Vet Reimbursement"
                      onClick={() => {
                        goToSubmitClaim(vetOfYourChoiceBenefitAvailable.id, vetOfYourChoiceBenefitAvailable.isRedeemable);
                      }}
                      tabIndex="0"
                    >
                      {toggles?.ENABLE_UNIFIED_MEMBERSHIP
                        ? <Icon name="iconVetStethoscope_UM" size="25" />
                        : <Icon name="iconVetStethoscope" size="25" />}
                      <span>{uiText?.vetExamTxt}</span>
                      <Icon name="arrowRight" className="alignRight" />
                    </List.Item>
                  </li>
                )
              ) : (
                vetOfYourChoiceBenefitAvailable && (
                  <li>
                    <List.Item
                      as="button"
                      aria-label="Non-Petco Vet Reimbursement"
                      onClick={() => {
                        goToSubmitClaim(vetOfYourChoiceBenefitAvailable.id, vetOfYourChoiceBenefitAvailable.isRedeemable);
                      }}
                      tabIndex="0"
                    >
                      <Icon name="iconVetStethoscope" size="25" />
                      <span>{uiText?.vetExamTxt}</span>
                      <Icon name="arrowRight" className="alignRight" />
                    </List.Item>
                  </li>
                )
              )}
              <li>
                <List.Item
                  as="button"
                  aria-label="View Details of your plan"
                  onClick={() => {
                    goToMembershipDetails();
                    trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_ABOUT_YOUR_VITAL_CARE_PLAN_V2);
                  }}
                  tabIndex="0"
                >
                  {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? (
                    <React.Fragment>
                      <Icon className="heart" name="um_ViewMembershipDetails" size="25" />
                      <span>{uiText?.viewMembershipDetailsTxt1}</span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Icon className="heart" name="viewMembershipDetails" size="25" />
                      <span>{uiText?.viewMembershipDetailsTxt2}</span>
                    </React.Fragment>
                  )}
                  <Icon name="arrowRight" className="alignRight" />
                </List.Item>
              </li>

              <li>
                <List.Item
                  as="button"
                  role="button"
                  aria-label="Add a Pet"
                  onClick={() => goToEnrollNewPet()}
                  tabIndex="0"
                >
                  {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? (
                    <Icon name="um_addNewPetV2" size="25" />)
                    : (
                      <Icon className="addNewPet" name="addNewPetV2" size="25" />
                    )}
                  <span>{uiText?.addNewPetTxt}</span>
                  <Icon name="arrowRight" className="alignRight" />
                </List.Item>
              </li>

              <li>
                <List.Item
                  as="button"
                  role="button"
                  aria-label="Need Help?"
                  onClick={() => goToContactCustomerCare()}
                  tabIndex="0"
                >
                  <Icon className="call" name="call" size="25" />
                  <span>{uiText?.needHelpTxt}</span>
                  <Icon name="arrowRight" className="alignRight" />
                </List.Item>
              </li>
            </List.Ul>
          </Card>

          {checkShowSwitchNowBanner && (
            <div className="switchToYearlyContainer">
              {deviceNameStr === 'desktop'
                && (
                  <div className="iconContainer">
                    <Icon name="apu_DiscountIcon" />
                  </div>
                )}
              <div className="txtDescription">
                <p>{injectPetNameInString(uiText?.switchToYearlyBannerMessage)}</p>
                <p>{uiText?.switchToYearlyBannerText}</p>
              </div>
              <div className="switchBtnContainer">
                <button
                  className="switchNowBtn"
                  onClick={() => {
                    setSwitchToYearly(true);
                    trackEvent(adobeAnalyticsTagsAPU?.USER_CLICKS_ON_SWITCH_NOW_BUTTON_ON_SWITCH_NOW_BANNER);
                  }}
                >
                  {uiText?.switchToYearlyBtnName}
                </button>
                <ButtonLink
                  className="txtLearnMore"
                  label={uiText?.btnLabelLearnMore}
                  variant="textLink"
                  onClick={togglePlanSavingsModal}
                />
              </div>
            </div>
          )}

          {switchToYearly && (
            <SwitchToYearlyPlanDetails
              title="Change your payment plan?"
              show={switchToYearly}
              screen="switchToYearlyModal"
              confirmBtnTxt="CONFIRM"
              onHide={() => {
                setSwitchToYearly(false);
                trackEvent(adobeAnalyticsTagsAPU?.USER_CLICKS_ON_CANCEL_OR_CLOSEICON_ON_CHANGE_YOUR_PAYMENT_PLAN_MODAL);
              }}
              termsChecked={termsChecked}
              onAction={() => {
                switchPlan();
                setSwitchToYearly(false);
                trackEvent(adobeAnalyticsTagsAPU?.USER_CLICKS_ON_CONFIRM_ON_CHANGE_YOUR_PAYMENT_PLAN_MODAL);
              }}
            >
              {renderSwitchToYearlyPaymentPlanDetails()}
              {renderSwitchToYearlyDetails()}
            </SwitchToYearlyPlanDetails>
          )}

          {showUsageHistory && (
            <Card>
              <Card.Header>
                <Icon name="congratulations" color="#F08423" size="25" />
                <span className="congratulations-text">
                  {+totalBenefitUsage ? 'Congratulations!' : 'Redeem more benefits to fill up this bar!'}
                </span>
              </Card.Header>
              <List>
                <List.Item className="noPointer" role="button">
                  <Progress percentage={getPercentage(totalBenefitUsage)} label={getLabel(totalBenefitUsage)} />
                </List.Item>

                <BenefitUsageHistoryLink />
              </List>
            </Card>
          )}
        </Container>
        {/* multi-pet savings popup below */}
        <Container>
          <div className="savings-modal-wrapper">
            {isOpenPlanSavingModal
              && (
                <PlanSavingsModal
                  title="Your Plan Savings"
                  handleClose={togglePlanSavingsModal}
                >
                  {planSavings}
                </PlanSavingsModal>
              )}
          </div>
        </Container>

        {/* CreditCardSelection selection popup */}
        <Modal
          className={toggles?.USE_ACI_PAYMENT ? 'membership-details-modal-aci' : 'membership-details-modal'}
          show={showModal}
          onHide={() => setShowModal(false)}
          data-testid="ncr-modal"
          backdrop="static"
          keyboard={false}
        >
          {paymentMethodsLoaded && (
            <CreditCardSelection
              errorAddingPayment={errorAddingPayment}
              setErrorAddingPayment={setErrorAddingPayment}
              onSelect={updatePaymentMethod}
              selectOnChange
              onShowMobileAddNewForm={onShowMobileAddNewForm}
              onClose={() => {
                setShowModal(false);
                setErrorAddingPayment(false);
                setShowUpdatePlansCCModal(false);
                dispatch(dashboardInfo.actions.clearOnMembershipPaymentMethodUpdateError(
                  {
                    occurred: false,
                    message: null,
                  },
                ));
              }}
              flow="SwitchPlanPaymentUpdate"
              errorModalTitle={errorPaymentMethodUpdateModalTitle}
              // planStatus={selectedPlanWithPet.plan.status}
              toggles={toggles}
            // paymentLinkFlow={updatedPaymentMethodLink}
            />
          )}
        </Modal>

        {!errorDuringPaymentMethodUpdate && (
          showUpdatePlansCCModal && (
            <UpdatePlansCCModal
              title={`Apply updated payment method to your other Vital Care ${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'Premier' : ''} Plans?`}
              successMessage="Payment method updated successfully!"
              show={showUpdatePlansCCModal}
              onHide={() => setShowUpdatePlansCCModal(false)}
              onAction={() => {
                setShowUpdatePlansCCModal(false);
                updateAllPlansCCMethod(selectedPlansForUpdateCC, updatedWalletID);
              }}
              toggles={toggles}
            >
              {renderUpdatePlansCCModalBody()}
            </UpdatePlansCCModal>
          )
        )}
      </div>
    )
  );
};

export default RedemptionDashboard;

import Container from 'components-v2/shared/Container';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Title, Text } from 'components-v2/shared/Elements';
import PetForm from 'components-v2/shared/PetForm/PetForm';
import { fetchTypes, createPetV2 } from 'store/actions';
import { deepLink, DEEPLINK_INDEX, navigationHistory } from 'store/slices';
import NeedHelp from 'components-v2/shared/NeedHelp';
import './pet-creation-page.css';
import {
  isMobile, trackEvent, getYear, getAgeInString, getMonth, getDay,
} from 'utils';
import { adobeAnalyticsTags, backToDestinations } from 'utils/constGlobal';
import BackTo from 'components-v2/shared/BackTo/BackTo';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';
import { temporaryPGRTextValueCleanUp } from 'utils/temporaryFunctions';

const PetCreationPage = ({
  petTypes = [],
  registrationInfoData,
  isLoading,
  loadingMemberships,
  deepLinking,
  deepLinkInfo,
  toggles,
  companionAnimalFeature,
  uiText,
}) => {
  const dispatch = useDispatch();

  // handles deep linking into the app for this page
  useEffect(() => {
    if (!isLoading && !loadingMemberships && deepLinking) {
      switch (deepLinkInfo.destination) {
        case DEEPLINK_INDEX.SELL_SCREEN.key:
          dispatch(navigationHistory.actions.goToSellScreen());
          dispatch(deepLink.actions.resetDeepLink());
          break;

        default:
          dispatch(deepLink.actions.resetDeepLink());
          break;
      }
    }
  }, [dispatch,
    deepLinkInfo,
    isLoading,
    deepLinking,
    loadingMemberships]);

  useEffect(() => {
    dispatch(fetchTypes());
  }, [dispatch]);

  const onSubmit = async ({
    name, type, breed, birthdate,
  }) => {
    const { actualPetType, actualBreed } = temporaryPGRTextValueCleanUp(type, breed);
    let addPetAdobe = adobeAnalyticsTags?.SIGNUP_ADD_PET_V2;
    const petInfoAdobe = {
      petInfo: [{
        petName: name,
        petBreed: actualBreed,
        petSpecies: actualPetType,
        petAge: {
          year: getYear(birthdate),
          month: getMonth(birthdate),
          day: getDay(birthdate),
          age: getAgeInString(birthdate),
        },
      }],
    };
    addPetAdobe = { ...addPetAdobe, ...petInfoAdobe };
    trackEvent(addPetAdobe);
    await dispatch(createPetV2(
      name,
      actualPetType,
      actualBreed,
      birthdate,
      () => dispatch(navigationHistory.actions.goToPetSelection()),
    ));
  };

  return (
    <Container className="pet-creation-page">
      {isMobile ? (
        <BackTo where={backToDestinations.SELL_SCREEN} />
      ) : (
        <BackTo where={backToDestinations.SELL_SCREEN} desktop />
      )}
      <PetcoLogoSharedComponent />
      {
        toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <Title className="pet-creation-page page-title UM-title">{uiText?.petSelectionHeadingTxt1}</Title>
          : <Title className="page-title">{uiText?.petSelectionHeadingTxt2}</Title>
      }

      <Text className="subtitle">Looks like you have no pets in your profile yet!</Text>

      <PetForm
        petTypes={petTypes}
        onSubmit={onSubmit}
        afterSubmitErrorMessage={registrationInfoData?.onPetNameRetry?.message}
        toggles={toggles}
        companionAnimalFeature={companionAnimalFeature}
      />

      <Text className="bottom">
        If you have multiple pets, not to worry!
        You can add them later.
      </Text>

      <NeedHelp
        uiText={uiText}
      />
    </Container>
  );
};

export default PetCreationPage;

/* eslint-disable */
import React from 'react';
import Barcode from 'react-barcode';
import './coupon-bar-code.css';

const CouponBarCode = ({
  coupons = [],
}) => coupons.map((coupon) => (
  <div key={coupon.couponNumber}>
    <div className="BarcodeWrapper-v2" aria-label={`Barcode scanner code ${coupon.couponNumber}`} tabIndex="0">
      <Barcode
        value={coupon.couponNumber}
        height={60}
        fontOptions="bold"
        fontSize={15}
        textMargin={10}
      />
    </div>
  </div>
));

export default CouponBarCode;
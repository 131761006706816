import React from 'react';
import Icon from 'components-v2/shared/Icon/Icon';
import Container from 'components-v2/shared/Container';
import './petco-logo-shared-component.css';
import { useSelector } from 'react-redux';
import { sharedViewModel } from '../viewModel';

export const PetcoLogoSharedComponent = ({ alignCenter }) => {
  const viewModel = useSelector((state) => sharedViewModel(state));
  const { ENABLE_UNIFIED_MEMBERSHIP } = viewModel;
  
  return (
    <div className="PetcoLogoSharedComponent-v2">
      <Container>
        <div className={`${alignCenter ? 'top-logo-center' : 'top-logo-space-between'} top-logo-wrapper`}>
          {ENABLE_UNIFIED_MEMBERSHIP ? <Icon className="logoPetcoVitalCare UM-logo" name="petcoVitalCareUMLogo" /> : <Icon className="logoPetcoVitalCare" name="petcoVitalCareLogo" />}
        </div>
      </Container>
    </div>
  );
};

/**
   * Contains temporary logic that the FE Client has in place. PGR could not update their backend data to match UX expectations
   * so the FE Client temporarily replaces values received from the backend so that we can render the appropriate text.
   *
   * This function cleans that temporary swap and changes the values back into their original state. One day, when PGR updates the values
   * to Small Pet and Other - Reptile, we can remove this function.
   *
   * @param type - the type value passed into onSubmit
   * @param breed - the breed value passed into onSubmit
   * @returns An object containing the original "actual" type and breed.
   *
   */
export function temporaryPGRTextValueCleanUp(type, breed) {
  let actualPetType;
  if (type === 'Small Pet') {
    actualPetType = 'Small Animal';
  } else {
    actualPetType = type;
  }


  let actualBreed;
  if (breed.includes('Small Pet')) {
    actualBreed = breed.replace('Pet', 'Animal');
  }
  if (breed.includes('Other - Reptile')) {
    actualBreed = breed.replace('Other - Reptile', 'Other Reptile');
  } else {
    actualBreed = breed;
  }
  return { actualPetType, actualBreed };
}

import React from 'react';
import { ErrorMessage } from 'formik';
import './error.css';

interface ErrorProps {
  name: string
  children?: any
}

const Error = ({ name, children }: ErrorProps) => (
  <ErrorMessage name={name}>
    {(msg) => <small className="Message">{msg}</small>}
  </ErrorMessage>
);
Error.defaultProps = {
  children: null,
};

export default Error;

export const isValidFileType = ({ type }) => {
  const allowedTypes = ['image/jpg', 'image/png', 'image/jpeg'];
  return allowedTypes.includes(type);
};

export const isValidFileSize = (size, sizeLimit) => {
  if (size > sizeLimit) {
    return false;
  }
  return true;
};

export const MB_SI_BASE_TEN = 1000000;

export const postClaimFileToS3 = (
  file,
  preSignedObject,
  onSuccessPostingToBucket,
  onErrorPostingToBucket,
  setLoading,
) => {
  const formData = new FormData();
  const { url, fields } = preSignedObject;

  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  formData.append('file', file);
  setLoading('Uploading image...');

  fetch(url, {
    method: 'POST',
    body: formData,
  })
    .then((result) => {
      if (result.status === 204) {
        onSuccessPostingToBucket(result);
        setLoading('');
      } else {
        setLoading('');
        onErrorPostingToBucket(`Attempt to save img into s3 bucket was unsuccessful, status code: ${result.status}`);
      }
    })
    .catch((error) => {
      onErrorPostingToBucket(error);
      setLoading('');
    });
};

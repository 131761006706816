import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import useTrapFocus from 'utils/useTrapFocus';
import { fetchCancellationReasons } from 'store/actions';

import { Button, ButtonGroup } from 'components-v2/shared/Button-v1';
import Checkbox from 'components-v2/shared/Checkbox';
import { Textarea } from 'components-v2/shared/Elements';

import Error from 'components-v2/shared/Error';

import './cancel-renewal-reason-modal.css';

const isCommentEnable = (cancellationReasons, selectedReasonId) => {
  if (selectedReasonId) {
    const selectedReason = cancellationReasons.find((reason) => reason.id === selectedReasonId);
    return selectedReason.openEnded;
  }
  return false;
};

const CancelRenewReasonModal = ({
  show, onConfirm, onCancel, endDate, uiText,
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const [cancellationReasons, setCancellationReasons] = useState([]);
  const dispatch = useDispatch();
  const keyDownHandlerFirstModal = useTrapFocus('button:not([disabled])');
  const keyDownHandlerSecondModal = useTrapFocus('[tabindex="-1"]:not(div.show),button:not([disabled]),h1#stop-auto-renew-heading');
  const onTextAreaRefChange = useCallback((ref) => {
    if (ref !== null) {
      ref.focus();
      const navBarOffSetForMobile = 300;
      const top = Math.round(ref.getBoundingClientRect().top) + navBarOffSetForMobile;
      const message = `focusing on comment top:${top}`;
      window.parent.postMessage({ action: 'consoleMessage', message }, '*');
    }
  }, []);

  useEffect(() => {
    setConfirmed(false);
  }, [show]);

  useEffect(() => {
    async function fetchReasons() {
      await dispatch(fetchCancellationReasons((reasons) => {
        setCancellationReasons(reasons);
      }));
    }

    if (confirmed) fetchReasons();
  }, [confirmed, dispatch, setCancellationReasons]);

  const cancel = () => {
    onCancel();
    setConfirmed(false);
  };

  const stopRenewClicked = ({ cancellationReasonId, comments }) => {
    const cancellationReason = cancellationReasons.find((item) => item.id === cancellationReasonId);
    const { indicatesDeceased } = cancellationReason;
    onConfirm({
      cancellationReasonId,
      indicatesDeceased,
      comments,
    });
  };

  const autoRenewDescriptionTxtHandling = (text) => {
    if (text) {
      const filteredTxt = text.replace('{endDate}', endDate);
      return filteredTxt;
    }
  };

  return (
    <Modal show={show} onHide={cancel}>
      <div className="CancelRenewReasonModal">
        {
          !confirmed
            ? (
              <div onKeyDown={keyDownHandlerFirstModal}>
                <Modal.Title tabIndex={0}>
                  <h1 className="stopAutoRenew-title">
                    {uiText?.stopAutoRenewHeading1}
                  </h1>
                </Modal.Title>
                <Modal.Body tabIndex={0}>
                  <p>
                    {autoRenewDescriptionTxtHandling(uiText?.stopAutoRenewDescriptionTxt)}
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => setConfirmed(true)}
                    label="Continue"
                    className="confirmButton"
                    tabIndex={0}
                  />
                  <Button
                    className="secondary"
                    onClick={cancel}
                    label="Cancel"
                    tabIndex={0}
                  />
                </Modal.Footer>
              </div>
            )
            : (
              <div onKeyDown={keyDownHandlerSecondModal}>
                <Modal.Title>
                  <h1 tabIndex={0} id="stop-auto-renew-heading">
                    {uiText?.stopAutoRenewHeading2}
                  </h1>
                </Modal.Title>
                <Modal.Body>
                  <p tabIndex={0}>
                    {uiText?.stopAutoRenewMessage}
                  </p>
                  <Formik
                    initialValues={{ cancellationReasonId: '', comments: '' }}
                    validateOnMount
                    validationSchema={Yup.object().shape({
                      cancellationReasonId: Yup.number().required('Required'),
                      comments: Yup.string()
                        .max(100, 'Too Long! Max 100 characters'),
                    })}
                  >
                    {(props) => cancellationReasons && (
                      <Form>
                        <fieldset className="reason-list">
                          <legend>
                            <p tabIndex={0}>
                              <strong>{uiText?.yourReasonTxt}</strong>
                            </p>
                            {cancellationReasons.map((reason) => (
                              <Checkbox
                                key={reason.id}
                                label={reason.description}
                                name="cancellationReasonId"
                                type="radio"
                                variant="radial"
                                onChange={() => {
                                  props.setFieldTouched('cancellationReasonId');
                                  props.setFieldValue('cancellationReasonId', reason.id);
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    props.setFieldTouched('cancellationReasonId');
                                    props.setFieldValue('cancellationReasonId', reason.id);
                                  }
                                }}
                                checked={reason.id === props.values.cancellationReasonId}
                              />
                            ))}
                          </legend>
                        </fieldset>
                        {isCommentEnable(cancellationReasons, props.values.cancellationReasonId) && (
                          <div className="comment-wrapper">
                            <label htmlFor="comments">
                              <strong tabIndex={0}>{uiText?.commentsTxt}</strong>
                            </label>
                            <Textarea
                              className="comment-text-area"
                              tabIndex={0}
                              name="comments"
                              ref={onTextAreaRefChange}
                              onChange={(event) => {
                                props.setFieldValue('comments', event.target.value);
                                props.setFieldTouched('comments');
                              }}
                              placeholder="Please tell us more"
                              aria-label="Please tell us more"
                            />
                            {props.errors.comments && props.touched.comments && (
                              <Error name="comments">
                                {props.error}
                              </Error>
                            )}
                          </div>
                        )}
                        <ButtonGroup>
                          <Button
                            data-testid="stop-auto-renew-button"
                            disabled={!(props.isValid && props.touched.cancellationReasonId)}
                            onClick={() => stopRenewClicked(props.values)}
                            label="Stop Auto Renew"
                            className="confirmButton"
                          />
                          <Button
                            className="secondary"
                            onClick={cancel}
                            label="No, Keep Auto Renew"
                          />
                        </ButtonGroup>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </div>
            )
        }
      </div>
    </Modal>
  );
};

export default CancelRenewReasonModal;

import * as Sentry from '@sentry/browser';
import moment from 'moment';

const logInfoOnSentry = (message, extras) => {
  Sentry.withScope((scope) => {
    scope.setExtras(extras);
    Sentry.captureMessage(message);
  });
};

const logErrorOnSentry = (error, extra) => {
  Sentry.withScope((scope) => {
    scope.setExtras(extra);
    Sentry.captureException(error);
  });
};

/**
 *Usage:

 * LOGGER.info('Finish request to api');

 * LOGGER.info('NCR-Form loading', someObjectWithSomeContext);

 * LOGGER.error('some message');

 * LOGGER.error('some another message', someJavascriptErrorObject);
 *
 *
 * @param {*} moduleIdentifier Logger identifier.
 * @param {*} enableSentry     Send to sentry the info and error message.
 */
const getLogger = (moduleIdentifier = 'Main', enableSentry = true) => {
  const formatDate = () => moment().format('YYYY-MM-DDTHH:mm:ss SSS');

  /* eslint-disable no-console */
  return {
    debug: (...args) => {
      console.warn(`[DEBUG] ${formatDate()} [${moduleIdentifier}] ->`, ...args);
    },
    log: (...args) => {
      console.warn(`[LOG] ${formatDate()} [${moduleIdentifier}] ->`, ...args);
    },
    info: (...args) => {
      const formatedDate = formatDate();
      console.info(`[INFO] ${formatedDate} [${moduleIdentifier}] ->`, ...args);
      if (enableSentry) {
        const [message, ...extra] = args;

        logInfoOnSentry(`[INFO] [${moduleIdentifier}] -> ${message}`, extra);
      }
    },
    warn: (...args) => {
      console.warn(`[WARN] ${formatDate()} [${moduleIdentifier}] ->`, ...args);
    },
    error: (...args) => {
      console.error(`[ERROR] ${formatDate()} [${moduleIdentifier}] ->`, ...args);

      if (enableSentry) {
        const errorObjects = args.filter((arg) => arg instanceof Error);
        const nonErrorArguments = args.filter((arg) => !(arg instanceof Error));

        if (!errorObjects) {
          return;
        }

        logErrorOnSentry(...errorObjects, ...nonErrorArguments);
      }
    },
    trace: (...args) => {
      console.log(`[TRACE] ${formatDate()} [${moduleIdentifier}] ->`, ...args);
    },
  };
};

export default { getLogger };

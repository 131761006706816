/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DeepLinkValues } from 'utils/constGlobal';

export const DEEPLINK_INDEX = {
  PET_SELECTION: { key: 'pet_selection' },
  PET_CREATION: { key: 'pet_creation' },
  ENROLLMENT_CONFIRMATION: { key: 'enrollment_confirmation' },
  ENROLLMENT_CONFIRMATION_WALLET: { key: 'wallet_selection' },
  PLAN_REDEMPTION: { key: 'plan_redemption' },
  PLAN_USAGE_HISTORY: { key: 'plan_usage_history' },
  PLAN_DETAILS: { key: 'plan_details' },
  SUBMIT_CLAIM: { key: 'submit_claim' },
  PAYMENT: { key: 'payment' },
  CANCEL_RENEW_PLAN: { key: 'cancel_renew_plan' },
  EDIT_PAYMENT_METHOD: { key: 'edit_payment_method' },
  REACTIVATE_MEMBERSHIP_PLAN: { key: 'reactivate_membership_plan' },
  RENEW_MEMBERSHIP_PLAN: { key: 'renew_membership_plan' },
  SELL_SCREEN: { key: 'sell_screen' },
};

export const deepLink = createSlice({
  name: 'deepLink',
  initialState: {
    destination: DeepLinkValues.INITIAL,
    petId: '',
    petIds: [],
    walletId: '',
  },
  reducers: {
    setDeepLinkPetId: (state, action) => {
      state.petId = action.payload;
    },
    setDeepLinkPetIds: (state, action) => {
      state.petIds = action.payload;
    },
    setDeepLinkWalletId: (state, action) => {
      state.walletId = action.payload;
    },
    setDeepLinkDestination: (state, action) => {
      state.destination = action.payload;
    },
    resetDeepLink: (state) => {
      state.destination = DeepLinkValues.INITIAL;
    },
  },
});

export default deepLink;

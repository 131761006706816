import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { DateInput2 } from 'components-v2/shared/Elements';

import './react-date-picker-dynamic-label.css';

import {
  formatDateToCalendar,
  getDatePickerCssClass,
} from 'utils';

export const ReactDatePickerDynamicLabel = ({
  truthnessCondition,
  setDate,
  currentDate,
  planStartDate,
  label,
}) => {
  const startRef = useRef();

  const onKeyDown = (e) => {
    if (e.key === 'Tab') {
      startRef.current.setOpen(false);
    }
  };

  const handleChange = (date) => {
    if (date) {
      const formatedDate = formatDateToCalendar(date);
      setDate(formatedDate);
    } else {
      setDate(null);
    }
  };

  const parseDate = (date) => {
    if (date != null) {
      return new Date(date);
    } else {
      return '';
    }
  };

  return (
    <div className="react-date-picker-dynamic-label" role="combobox" aria-haspopup="dialog" aria-expanded="true">
      {currentDate && (
        <label htmlFor="date">{label}</label>
      )}
      <DatePicker
        ref={startRef}
        onKeyDown={onKeyDown}
        onFocus={(e) => e.target.readOnly = true}
        name="date"
        id="date"
        selected={currentDate ? parseDate(currentDate) : null}
        onChange={handleChange}
        placeholderText="Select Date"
        className={
          getDatePickerCssClass(
            'default-date-style',
            'enhanced-date',
            truthnessCondition,
            currentDate,
          )
        }
        maxDate={new Date()}
        minDate={planStartDate}
        autoComplete="off"
        dateFormat="MMMM d, yyyy"
        customInput={<DateInput2 />}
        tabIndex="0"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        formatWeekDay={nameOfDay => <span aria-label={nameOfDay} tabIndex={0}>{nameOfDay.substring(0, 2)}</span>}
      />
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getAppViewModel } from '../viewModel';
import { fetchToggles, fetchUiText } from '../store/actions';

const VersionSelector = (props) => {
  const { tokenLoaded } = props.viewModel;
  const dispatch = useDispatch();

  useEffect(async () => {
    if (tokenLoaded) {
      await dispatch(fetchUiText());
      await dispatch(fetchToggles());
    }
  }, [tokenLoaded]);

  // const uiVersion = url.uiVersion;
  const applicationUIVersion = props.viewModel.toggles.APPLICATION_USER_INTERFACE_VERSION;

  if (applicationUIVersion) {
    const App = require('App-v2').default;
    return <App />;
  } else {
    const mainBodyStyles = {
      minHeight: `${window.screen.height}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    const PetcoLoader = require('../components-v2/PetcoLoader').default;
    return (
      <div style={mainBodyStyles}>
        <PetcoLoader />
      </div>
    );
  }
};

export default connect((state) => ({
  viewModel: getAppViewModel(state),
}))(VersionSelector);

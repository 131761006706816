import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  navigationHistory,
  VIEW_INDEX,
} from 'store/slices';

import { createPet } from 'store/actions';

import BackToButton from 'components-v2/shared/BackToButton';
import Container from 'components-v2/shared/Container';
import LogoHeader from 'components-v2/shared/LogoHeader';
import StepsProgress from 'components-v2/shared/StepsProgress';
import { petCreationViewModel } from './viewModel';

import AddPetName from './AddPetName';
import BirthdaySelection from './BirthdaySelection';
import BreedSelection from './BreedSelection';
import TypeSelection from './TypeSelection';

const PetCreation = ({ isLoading }) => {
  const viewModel = useSelector((state) => petCreationViewModel(state));

  const [petSubmission, setPetSubmission] = useState(false);
  const [callCreatePet, setCallCreatePet] = useState(false);
  const [petName, setPetName] = useState('');
  const [petType, setPetType] = useState(null);
  const [petBreed, setPetBreed] = useState({});
  const [petBreeds, setPetBreeds] = useState([]);
  const [petBirthdate, setPetBirthdate] = useState(null);

  const dispatch = useDispatch();

  const {
    step,
    stepName,
    stepToUseForPercentBar,
    percentage,
    totalPetSteps,
    petTypes,
    onGoingErrorOnPetName,
  } = viewModel;

  // TODO: Discuss this usage of useEffect for flow control.
  useEffect(() => {
    if (!isLoading && petSubmission) {
      if (onGoingErrorOnPetName) {
        dispatch(navigationHistory.actions.goToName());
      } else {
        dispatch(navigationHistory.actions.goBackToPetSelection());
      }
    }
  }, [onGoingErrorOnPetName, petSubmission, isLoading, dispatch]);

  useEffect(() => {
    const createPetAsync = async () => {
      await dispatch(createPet(petName, petType, petBreed, petBirthdate));
      setPetSubmission(true);
    };

    if (callCreatePet) {
      createPetAsync();
      setCallCreatePet(false);
    }
  }, [dispatch, callCreatePet, setPetSubmission, petName, petType, petBreed, petBirthdate, setCallCreatePet]);

  const petNameEntered = async (name) => {
    setPetName(name);

    if (onGoingErrorOnPetName) {
      setCallCreatePet(true);
    } else {
      dispatch(navigationHistory.actions.goToType());
    }
  };

  const petTypeSelected = async (type) => {
    setPetType(type);
    setPetBreeds(type.breeds);
    dispatch(navigationHistory.actions.goToBreed());
  };

  const petBreedSelected = async (breed) => {
    setPetBreed(breed);
    dispatch(navigationHistory.actions.goToBirthday());
  };

  const petBirthdateEntered = async (birthdate) => {
    setPetBirthdate(birthdate);
    setCallCreatePet(true);
  };

  const getBackStepLabel = (currentStep) => {
    const stepBackLabels = {
      [VIEW_INDEX.NAME.step]: 'Back to Pet Selection',
      [VIEW_INDEX.TYPE.step]: 'Back to Pet Name',
      [VIEW_INDEX.BREED.step]: 'Back to Pet Type',
      [VIEW_INDEX.BIRTHDAY.step]: 'Back to Pet Breed',
    };

    return stepBackLabels[currentStep];
  };

  return (
    <>
      <Container>
        <BackToButton label={getBackStepLabel(step)} />
      </Container>
      <div className="registration-container">
        <div className="registration-logo-header">
          <LogoHeader center />
        </div>
        <div className="progress-container">
          <StepsProgress
            percentage={percentage}
            label={`Step ${stepToUseForPercentBar} / ${totalPetSteps} ${stepName}`}
          />
        </div>
        {step === VIEW_INDEX.NAME.step
            && (
            <AddPetName
              petName={petName}
              onGoingErrorOnPetName={onGoingErrorOnPetName}
              petNameEntered={petNameEntered}
            />
            )}
        {step === VIEW_INDEX.TYPE.step
            && (
            <TypeSelection
              petName={petName}
              petTypes={petTypes}
              setPetType={petTypeSelected}
            />
            )}
        {step === VIEW_INDEX.BREED.step
            && (
            <BreedSelection
              petName={petName}
              petBreed={petBreed}
              petBreeds={petBreeds}
              setPetBreed={petBreedSelected}
            />
            )}
        {step === VIEW_INDEX.BIRTHDAY.step
            && (
            <BirthdaySelection
              petName={petName}
              setPetBirthdate={petBirthdateEntered}
            />
            )}
      </div>
    </>
  );
};

export default PetCreation;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  isValidFileType,
  isValidFileSize,
  MB_SI_BASE_TEN,
  postClaimFileToS3,
} from 'utils/FileUtils';

import { Button } from 'components-v2/shared/Button';
import { Text } from 'components-v2/shared/Elements';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';

import Logger from 'utils/logger';

import { fetchPresignedPostURL } from 'store/actions';
import { claims } from 'store/slices';
import { FileImage } from './FileImage';
import FileDialogInput from './FileDialogInput';

import './claims-upload-invoices.css';
import BackTo from 'components-v2/shared/BackTo/BackTo';
import { backToDestinations, globalConsts, adobeAnalyticsTags } from 'utils/constGlobal';
import BackButton from 'components-v2/shared/BackButton/BackButton';
import Container from 'components-v2/shared/Container';
import { trackEvent } from 'utils';

const LOGGER = Logger.getLogger('ClaimsUploadInvoices');

const ClaimsUploadInvoices = ({
  claimFile, onDoneInvoiceUpload, claimFileSizeLimit, claimPresignedObject,
}) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [currentFile, setCurrentFile] = useState(claimFile);
  const [uploadSuccessful, setUploadSuccessful] = useState(claimFile ?? false);
  const dispatch = useDispatch();

  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));

  const confimedInvoice = (e) => {
    e.preventDefault();
    onDoneInvoiceUpload(currentFile, claimPresignedObject);
  };

  const onSuccessGettingURLObject = (claimPresignedObjectParam, file) => {
    const onSuccessPostingToBucket = (result) => {
      setUploadSuccessful(true);
      // TODO: Bring back
      // LOGGER.log('Success posting to bucket', result);
      dispatch(claims.actions.setPreSignedObject(claimPresignedObjectParam));
    };

    const onErrorPostingToBucket = (error) => {
      setCurrentFile(null);
      setMessage('Unable to upload image, please try again.');
      setUploadSuccessful(false);
      setShowMessage(true);
      // TODO: Bring back
      // LOGGER.error('Error posting to bucket', error);
    };
    dispatch(claims.actions.setPreSignedObject(claimPresignedObjectParam));
    postClaimFileToS3(file, claimPresignedObjectParam, onSuccessPostingToBucket, onErrorPostingToBucket, setLoading);
    // TODO: Bring back
    // LOGGER.log('Success to get pre signed url on ClaimsUploadInvoices', claimPresignedObjectParam);
  };

  const errorCallback = (response) => {
    setMessage('Unable to upload image, please try again.');
    setCurrentFile('');
    // TODO: Bring back
    // LOGGER.error('Unable to get pre signed post url to upload an image on ClaimsUploadInvoices', response);
  };

  const onFileAdded = (files) => {
    setUploadSuccessful(false);
    setMessage('');
    setShowMessage(false);

    // we don't accept multiple
    const file = files[0];
    if (!file) return;

    if (!isValidFileType(file)) {
      setMessage('Invalid file type.');
      setShowMessage(true);
      return;
    }

    if (!isValidFileSize(file.size, claimFileSizeLimit)) {
      setMessage('File size is too big. Please email receipt to customer support at vitalcare@petco.com');
      setShowMessage(true);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const updatedFile = {
        name: file.name,
        src: reader.result,
        size: file.size,
        type: file.type,
      };
      setCurrentFile(updatedFile);
    }, false);
    reader.readAsDataURL(file);
    dispatch(fetchPresignedPostURL(file, onSuccessGettingURLObject, errorCallback));
  };

  const onRemoveClick = () => {
    setShowMessage(false);
    setMessage('');
    setCurrentFile(null);
  };

  const getButtonClass = () => `${uploadSuccessful ? 'button-continue button-active' : 'button-continue invalid-button'}`;

  return (
    <div className="ClaimsUploadInvoicesPage-v2">
      {!isMobile ? (
        <div>
          <BackTo where={backToDestinations.DASHBOARD} desktop />
          <PetcoLogoSharedComponent />
        </div>
      ) : (
        <BackTo where={backToDestinations.DASHBOARD} />
      )}
      <h2>Upload your vet invoice</h2>
      <Container>
        <p className="claim_p">
          Upload a picture of your vet exam invoice. Invoice image must include: Date of Exam,
          Vet Exam Line Item & Price, Veterinary Hospital Name.
        </p>
      </Container>

      <p className="claim_p">
        You may only upload one image per submission.
      </p>

      <div className="FileImageGallery">
        {currentFile && (
          <FileImage
            key={currentFile.name}
            file={currentFile}
            onRemoveClick={onRemoveClick}
          />
        )}
      </div>

      {currentFile === null
        && (
          <div tabIndex="0">
            <FileDialogInput
              onFileAdded={onFileAdded}
              showContinue={currentFile !== null}
              files={[currentFile]}
            />
            <p className="claim_p claim_p--info" aria-label={`Upload must be less than ${(claimFileSizeLimit / MB_SI_BASE_TEN).toString().slice(0, 2)}MB in JPG or PNG format.`}>
              {`Upload must be less than
              ${(claimFileSizeLimit / MB_SI_BASE_TEN).toString().slice(0, 2)}MB in JPG or PNG format.`}
            </p>
          </div>
        )}

      {currentFile !== null
        && (
          <Button
            label="Review Submission"
            disabled={!uploadSuccessful}
            onClick={(e) => {
              if (uploadSuccessful) {
                trackEvent(adobeAnalyticsTags?.VETEXAM_REVIEW_SUBMISSION);
                confimedInvoice(e);
              }
            }}
            className={getButtonClass()}
            id="button-continue"
          />
        )}

      <div className="Message">
        {showMessage && (
          <span id="message-field" role="alert" aria-label={message}>{message}</span>
        )}
      </div>

      {loading && (
        <div>
          <Text id="loading-message">{loading}</Text>
        </div>
      )}
      <BackButton tagEvent={adobeAnalyticsTags?.VETEXAM_BACK} />

    </div>
  );
};

export default ClaimsUploadInvoices;

import React from 'react';

import './subtotal.css';

const Subtotal = (props) => {
  // eslint-disable-next-line class-methods-use-this
  const {
    title, value, preDiscountValue, type, dataName, customStyle, asterisk, tenure,
  } = props;
  const ariaLabel = title || 'Subtotal';
  const ariaLabelPaymentTenure = tenure === '/yr' ? 'yearly' : 'monthly';
  return (
    <div className={`${customStyle ? 'Subtotal-v2 finishtYourPurchase-mt' : 'Subtotal-v2'}`}>
      <span className={`${customStyle ? 'styled subtotal-text' : 'subtotal-text'}`} aria-label={title || 'Subtotal'} tabIndex={0}>{title || 'Subtotal'}</span>
      <div className="subtotal-value" tabIndex={0}>
        {
          !preDiscountValue || preDiscountValue === value ? (
            <span
              aria-label={`${ariaLabel} ${value?.toFixed(2)} ${ariaLabelPaymentTenure} for this pet`}
              data-testid={dataName}
              className={`${customStyle ? 'styled' : ''} price`}
            >
              ${value?.toFixed(2)}{tenure}<sub>{`${asterisk ? '*' : ''}`}</sub>
            </span>
          ) : (
            <>
              <span
                aria-label={`${value?.toFixed(2)}  ${ariaLabelPaymentTenure} for this pet`}
                data-testid={dataName}
                className={`${customStyle ? 'styled' : ''} price`}
              >
                ${value?.toFixed(2)}<span className="price price--pre-discount" aria-label={`Strikeout ${preDiscountValue?.toFixed(2)}`}>${preDiscountValue?.toFixed(2)}</span>
                <span className="tenure">{tenure}</span>
              </span>
            </>
          )
        }
        <span className={`${customStyle ? 'styled' : ''} type`}>{type}</span>
      </div>
    </div>
  );
};

export default Subtotal;

import React from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  fatalError,
  navigationHistory,
} from 'store/slices';

import ErrorModal from './ErrorModal';
import { trackEvent } from 'utils';
import { adobeAnalyticsTags } from 'utils/constGlobal';

class ErrorBoundary extends React.Component {
  componentDidMount() {
    if (this.props.authError) {
      trackEvent(adobeAnalyticsTags?.USER_LANDS_ON_NON_PAYMENT_RELATED_ERROR_MODAL);
    } else if (this.props.fatalErrorState) {
      trackEvent(adobeAnalyticsTags?.USER_LANDS_ON_TECHNICAL_ERROR_MODAL);
    } else if (this.props.maintenanceError) {
      this.adobeTrackerForGenericError('Uh-oh... we\'re undergoing maintenance.', 'Sorry for the inconvience!');
    }
  }

  adobeTrackerForGenericError(errorModalTitle, errorModalBody) {
    const adobeTrackerForGenericErrorTag = adobeAnalyticsTags.USER_LANDS_ON_GENERIC_ERROR_MODAL;
    adobeTrackerForGenericErrorTag.message.title = errorModalTitle;
    adobeTrackerForGenericErrorTag.message.error = errorModalBody;
    trackEvent(this.adobeTrackerForGenericErrorTag);
  }

  componentDidCatch(error, errorInfo) {
    const { dispatch } = this.props;
    dispatch(fatalError.actions.set(true));
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const {
      authError,
      fatalErrorState,
      maintenanceError,
      children,
      dispatch,
    } = this.props;

    if (authError) {
      return (
        <ErrorModal
          title="Session Expired"
          message="You went idle and your session has expired."
          show
        >
          <button
            className="btn btn-primary="
            onClick={() => {
              window.parent.postMessage({ action: 'renewSession' }, '*');
            }}
          >
            Renew Session
          </button>
        </ErrorModal>
      );
    } else if (fatalErrorState) {
      return (
        <ErrorModal
          title="Uh-oh... something went wrong"
          message="We're experiencing a technical issue."
          show
        >
          <button
            className="btn btn-primary retry-button"
            onClick={() => {
              dispatch(fatalError.actions.set(false));
            }}
          >
            Retry
          </button>

          <button
            className="btn btn-primary retry-button"
            onClick={() => {
              dispatch(fatalError.actions.set(false));
              dispatch(navigationHistory.actions.goToInitialDecision());
            }}
          >
            Home
          </button>

          <a className="btn btn-primary" href="https://stores.petco.com/">
            Find a store
          </a>
        </ErrorModal>
      );
    } else if (maintenanceError) {
      return (
        <ErrorModal
          title="Uh-oh... we're undergoing maintenance."
          message="Sorry for the inconvience!"
          show
        >
          <a className="btn btn-primary" href="https://stores.petco.com/">
            Find a store
          </a>
        </ErrorModal>
      );
    }

    return children;
  }
}

export default connect(
  (state) => ({
    fatalErrorState: state.fatalError,
    maintenanceError: state.maintenanceError,
    authError: state.authError,
    navigationHistory: state.navigationHistory,
  }),
)(ErrorBoundary);

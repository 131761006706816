import React from 'react';

import BackToButton from 'components-v2/shared/BackToButton';
import { ButtonLink } from 'components-v2/shared/Button-v1';
import Container from 'components-v2/shared/Container';
import { Title, Text } from 'components-v2/shared/Elements';

import './customer-care.css';

const ContactCustomerCare = ({
  contactCustomerCareInfo,
  uiText,
}) => (
  <div className="ContactCustomerCare">
    <Container>
      <BackToButton label="Back to dashboard" />

      <Title>Contact Customer Care</Title>

      <div className="ContactWrapper">
        <div className="ContactOption">
          <Title as="h2">Email</Title>

          <div className="OptionCard">
            <Text>{uiText?.customerCareEmailCopy}</Text>

            <ButtonLink
              href={`mailto:${uiText?.customerCareEmail}`}
              target="_parent"
            >
              {uiText?.customerCareButtonText}
            </ButtonLink>
          </div>
        </div>

        <div className="ContactOption">
          <Title as="h2">{uiText?.customerCareContactHeading}</Title>

          <div className="OptionCard">
            <Text>{uiText?.customerCarePhoneInfo}</Text>
            <Text>{uiText?.weekDays} {uiText?.timeZone}</Text>
            <Text>{uiText?.weekEnds} {uiText?.timeZone}</Text>
            <Text className="phoneNumber">
              {uiText?.customerCarePhone}
            </Text>
          </div>
        </div>
      </div>
    </Container>
  </div>
);

export default ContactCustomerCare;

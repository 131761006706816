import React from 'react';

import { getDateFullMonthDayYearFormat, isMobile } from 'utils';

import { Button } from 'components-v2/shared/Button';
import Card from 'components-v2/shared/Card';
import Container from 'components-v2/shared/Container';
import List from 'components-v2/shared/List';
import { Title } from 'components-v2/shared/Elements';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';
import Icon from 'components-v2/shared/Icon/Icon';
import { Thumbnail } from 'components-v2/claim/ClaimsUploadInvoices/Thumbnail';

import './claim-review.css';
import BackTo from 'components-v2/shared/BackTo/BackTo';
import { backToDestinations, adobeAnalyticsTags } from 'utils/constGlobal';
import BackButton from 'components-v2/shared/BackButton/BackButton';

const ClaimReview = ({
  petName, petType, claimClinic, claimDate, claimFile, doneReviewing, goToClinicSelection,
}) => (
  <div className="ReviewClaim-v2">
    <div className="claim-review-header-wrapper">
      {!isMobile && (
        <div>
          <BackTo where={backToDestinations.DASHBOARD} desktop />
          <PetcoLogoSharedComponent alignCenter />
        </div>
      )}
    </div>

    <Container>
      {isMobile && (
        <BackTo where={backToDestinations.DASHBOARD} />
      )}
      <Title>
        Review your Submission
      </Title>

      <Card>
        <List>

          <List.Item className="card-header">
            {petType === 'Dog' ? (<Icon name="dog" />) : (<></>)}
            {petType === 'Cat' ? (<Icon name="cat" />) : (<></>)}
            <Title className="pet-name" tabIndex="0">
              {petName}
            </Title>
          </List.Item>

          <List.Item>
            <List.ItemLeftWrapper>
              <List.ItemTitle>
                <h3 tabIndex="0">
                  Vet Office
                </h3>
              </List.ItemTitle>
              <List.SubItem className="item-description">{claimClinic.name}</List.SubItem>
              <List.SubItem className="item-description">
                {claimClinic.address}
                ,
                {claimClinic.city} {claimClinic.state} {claimClinic.zipCode}
              </List.SubItem>
            </List.ItemLeftWrapper>
            <List.ItemRightWrapper className="edit" onClick={(e) => goToClinicSelection(e)} role="button" aria-label="edit your submission" tabIndex="0">
              Edit
            </List.ItemRightWrapper>
          </List.Item>

          <List.Item>
            <List.ItemLeftWrapper>
              <List.ItemTitle>
                <h3 tabIndex="0">
                  Date of Visit
                </h3>
              </List.ItemTitle>
              <List.SubItem className="item-description">{getDateFullMonthDayYearFormat(claimDate)}</List.SubItem>
            </List.ItemLeftWrapper>
          </List.Item>

          <List.Item>
            <List.ItemLeftWrapper>
              {claimFile !== null && (
              <div>
                <List.ItemTitle>
                  <h3 tabIndex="0">
                    Uploaded Documents (1)
                  </h3>
                </List.ItemTitle>
                <List.ImageWrapper>
                  <Thumbnail
                    file={claimFile}
                  />
                </List.ImageWrapper>
              </div>
              )}
            </List.ItemLeftWrapper>
          </List.Item>

        </List>
      </Card>

      <Button
        label="Submit"
        type="submit"
        onClick={(e) => doneReviewing(e)}
        className="continue-button"
      />

      <BackButton tagEvent={adobeAnalyticsTags?.VETEXAM_REVIEW_SUBMISSION_BACK} />

    </Container>
  </div>
);

export default ClaimReview;

import React from 'react';
import { Title, Text } from 'components-v2/shared/Elements';

import './need-help.css';
import uiText from 'store/slices/uiText';

const NeedHelp = ({ uiText }) => (
  <div className="NeedHelp">
    <Title as="h3" className="need-help-title">Need Help?</Title>
    <Text className="text need-help">
      {uiText?.needHelpEmail}
      <span className="phone-number"> {uiText?.needHelpPhone}</span>
    </Text>
  </div>
);

export default NeedHelp;

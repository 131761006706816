import { Button, ButtonLink } from 'components-v2/shared/Button';
import { Input } from 'components-v2/shared/Elements';
import { Form, Formik } from 'formik';
import {
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { affiliateReferralCode } from 'store/slices';
import { handleKeyUp, trackEvent } from 'utils';
import { adobeAnalyticsTags } from 'utils/constGlobal';
import './referral-code.css';
import { URLState, referralViewModel } from './viewModel';
import { validateReferralCode } from 'store/actions';

interface ReferralCodeProps {
  onSubmit: (data: any) => void;
  affiliateReferralInfo: any;
  toggles: any;
  uiText: any;
}

interface onSubmitReferralCodeValidateProps {
  referralCode?: string | undefined
  customerCareCode?: string | undefined
}

const ReferralCode = ({
  onSubmit, affiliateReferralInfo, toggles, uiText,
}: ReferralCodeProps) => {
  const [displayReferralForm, setDisplayReferralForm] = useState(false);
  const [displayCustomerCareForm, setDisplayCustomerCareForm] = useState(false);
  const [displayUndoCustomerCareText, setDisplayUndoCustomerCareText] = useState(false);
  const [displayUndoText, setDisplayUndoText] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = ({ referralCode, customerCareCode }: any) => {
    const data = customerCareCode ? { customerCareCode } : { referralCode };
    onSubmit(data);
    adobeAnalyticsTags.USER_CLICKS_ON_APPLY_PROMO_CODE_BUTTON.serviceDetails.orderPromoCode = referralCode;
    trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_APPLY_PROMO_CODE_BUTTON);
  };

  const data = useSelector((state) => referralViewModel(state));
  const url: URLState | any = data.url;

  useEffect(() => {
    if (!affiliateReferralInfo?.onAffiliateErrorMessage?.occurred && affiliateReferralInfo?.onAffiliateSuccessMessage && affiliateReferralInfo?.referral_code !== undefined && affiliateReferralInfo?.referral_code !== '') {
      setDisplayReferralForm(false);
      setDisplayUndoText(true);
      adobeAnalyticsTags.USER_LANDS_ON_REFERRAL_CODE_APPLIED_SCREEN.page.buildVersion = toggles?.FRONTEND_VERSION;
      adobeAnalyticsTags.USER_LANDS_ON_REFERRAL_CODE_APPLIED_SCREEN.serviceDetails.orderPromoCode = affiliateReferralInfo?.referral_code;
      trackEvent(adobeAnalyticsTags?.USER_LANDS_ON_REFERRAL_CODE_APPLIED_SCREEN);
    } else if (affiliateReferralInfo?.onAffiliateErrorMessage?.occurred) {
      setDisplayReferralForm(true);
      adobeAnalyticsTags.USER_ENCOUNTER_ERROR_WHILE_CLICK_ON_APPLY_PROMO_CODE_BUTTON.page.buildVersion = toggles?.FRONTEND_VERSION;
      adobeAnalyticsTags.USER_ENCOUNTER_ERROR_WHILE_CLICK_ON_APPLY_PROMO_CODE_BUTTON.message.error = affiliateReferralInfo?.onAffiliateErrorMessage?.message;
      adobeAnalyticsTags.USER_ENCOUNTER_ERROR_WHILE_CLICK_ON_APPLY_PROMO_CODE_BUTTON.serviceDetails.orderPromoCode = affiliateReferralInfo?.referral_code;
      trackEvent(adobeAnalyticsTags?.USER_ENCOUNTER_ERROR_WHILE_CLICK_ON_APPLY_PROMO_CODE_BUTTON);
    }
    if (!affiliateReferralInfo?.onCustomerCareErrorMessage?.occurred && affiliateReferralInfo?.onCustomerCareSuccessMessage && affiliateReferralInfo?.customer_care_code !== undefined && affiliateReferralInfo?.customer_care_code !== '') {
      setDisplayCustomerCareForm(false);
      setDisplayUndoCustomerCareText(true);
    } else if (affiliateReferralInfo?.onCustomerCareErrorMessage?.occurred) {
      setDisplayCustomerCareForm(true);
    }
  }, [affiliateReferralInfo, toggles?.FRONTEND_VERSION]);

  const BUTTON_LABEL = 'APPLY';

  return (
    <div className="referral-code">
      {displayReferralForm
        && (
          <div className="AffiliateForm">
            <Formik
              initialValues={{
                referralCode: '',
              }}
              validateOnMount
              onSubmit={handleSubmit}
            >
              {(props) => (
                <>
                  <Form>
                    <div className="form-field-wrapper">
                      <div className="form-field-col">
                        <div className="input-container">
                          {affiliateReferralInfo?.onAffiliateErrorMessage?.occurred && (
                            <div className="input-error" role="alert" aria-label="Invalid Referral Code">
                              {affiliateReferralInfo?.onAffiliateErrorMessage?.message}
                            </div>
                          )}
                          {props.errors.referralCode && props.touched.referralCode && (
                            <div className="input-error" role="alert" aria-label={`${props.errors.referralCode}`}>
                              {props.errors.referralCode}
                            </div>
                          )}
                          <Input
                            tabIndex="0"
                            type="text"
                            id="referralCode"
                            name="referralCode"
                            placeholder="Enter Referral Code"
                            aria-label="Enter Referral Code"
                            className={affiliateReferralInfo?.onAffiliateErrorMessage?.occurred ? 'referral-code referral-code-error' : 'referral-code'}
                          />
                        </div>

                      </div>
                      <div className="form-field-col">
                        <div className="input-container">
                          <Button
                            type="submit"
                            tabIndex="0"
                            className={props.isValid ? 'button-active' : ''}
                            disabled={!props.isValid}
                            label={BUTTON_LABEL}
                            aria-label={BUTTON_LABEL}
                            id="affiliate-form-apply-button"
                          />
                        </div>
                      </div>
                    </div>

                  </Form>
                </>
              )}
            </Formik>
          </div>
        )}
      {!displayReferralForm && !displayUndoText
        && (
          <ButtonLink
            className={`button-link ${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'button-link-um' : ''}`}
            label={uiText?.haveAReferralCodeTxt}
            variant="textLink"
            tabIndex="0"
            onClick={() => {
              setDisplayReferralForm(true);
              trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_HAVE_A_REFERRAL_CODE_LINK);
            }}
            onKeyUp={(e: Event) => handleKeyUp(e, () => setDisplayReferralForm(true))}
            role="button"
          />
        )}
      {!displayReferralForm && displayUndoText
        && (
          <div className="applied">
            <img
              alt="Icon with a check success"
              src="/assets/Icon_Check_Success.svg"
            />
            <span className="applied-text">{uiText?.referralCodeAppliedTxt}</span>
            <ButtonLink
              className={`button-link button-link-w ${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? ' button-link-UM' : ''}`}
              label="Undo"
              variant="textLink"
              tabIndex="0"
              onClick={() => {
                trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_APPLY_PROMO_UNDO_BUTTON);
                dispatch(affiliateReferralCode.actions.setReferralCode(''));
                dispatch(affiliateReferralCode.actions.setOnAffiliateSuccessMessage({ occurred: false, message: '' }));
                dispatch(affiliateReferralCode.actions.setOnAffiliateErrorMessage(
                  {
                    occurred: false,
                    message: '',
                  },
                ));
                setDisplayReferralForm(true);
              }}
              onKeyUp={(e: Event) => handleKeyUp(e, () => {
                dispatch(affiliateReferralCode.actions.setReferralCode(''));
                setDisplayReferralForm(true);
              })}
            />
          </div>
        )}
      {url.isOBO && !displayCustomerCareForm && !displayUndoCustomerCareText
        && (
          <ButtonLink
            className={`button-link ${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'button-link-um' : ''}`}
            label="Enter Customer Care Code"
            variant="textLink"
            tabIndex="0"
            onClick={() => {
              setDisplayCustomerCareForm(true);
              trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_HAVE_A_REFERRAL_CODE_LINK);
            }}
            onKeyUp={(e: Event) => handleKeyUp(e, () => setDisplayReferralForm(true))}
            role="button"
          />
        )}
      {displayCustomerCareForm
        && (
          <div className="AffiliateForm">
            <Formik
              initialValues={{
                customerCareCode: '',
              }}
              validateOnMount
              onSubmit={handleSubmit}
            >
              {(props) => (
                <>
                  <Form>
                    <div className="form-field-wrapper">
                      <div className="form-field-col">
                        <div className="input-container">
                          {affiliateReferralInfo?.onCustomerCareErrorMessage?.occurred && (
                            <div className="input-error" role="alert" aria-label="Invalid Customer Care Code">
                              {affiliateReferralInfo?.onCustomerCareErrorMessage?.message}
                            </div>
                          )}
                          {props.errors.customerCareCode && props.touched.customerCareCode && (
                            <div className="input-error" role="alert" aria-label={`${props.errors.customerCareCode}`}>
                              {props.errors.customerCareCode}
                            </div>
                          )}
                          <Input
                            tabIndex="0"
                            type="text"
                            id="customerCareCode"
                            name="customerCareCode"
                            placeholder="Enter Customer Care Code"
                            aria-label="Enter Customer Care"
                            className={affiliateReferralInfo?.onCustomerCareErrorMessage?.occurred ? 'referral-code referral-code-error' : 'referral-code'}
                          />
                        </div>

                      </div>
                      <div className="form-field-col">
                        <div className="input-container">
                          <Button
                            type="submit"
                            tabIndex="0"
                            className={props.isValid ? 'button-active' : ''}
                            disabled={!props.isValid}
                            label={BUTTON_LABEL}
                            aria-label={BUTTON_LABEL}
                            id="affiliate-form-apply-button"
                          />
                        </div>
                      </div>
                    </div>

                  </Form>
                </>
              )}
            </Formik>
          </div>
        )}
      {!displayCustomerCareForm && displayUndoCustomerCareText
        && (
          <div className="applied">
            <img
              alt="Icon with a check success"
              src="/assets/Icon_Check_Success.svg"
            />
            <span className="applied-text">Customer Care Code Applied</span>
            <ButtonLink
              className={`button-link button-link-w ${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? ' button-link-UM' : ''}`}
              label="Undo"
              variant="textLink"
              tabIndex="0"
              onClick={() => {
                trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_APPLY_PROMO_UNDO_BUTTON);
                dispatch(affiliateReferralCode.actions.setCustomerCareCode(''));
                dispatch(affiliateReferralCode.actions.setOnCustomerCareSuccessMessage({ occurred: false, message: '' }));
                dispatch(affiliateReferralCode.actions.setOnCustomerCareErrorMessage(
                  {
                    occurred: false,
                    message: '',
                  },
                ));
                setDisplayCustomerCareForm(true);
              }}
              onKeyUp={(e: Event) => handleKeyUp(e, () => {
                dispatch(affiliateReferralCode.actions.setCustomerCareCode(''));
                setDisplayReferralForm(true);
              })}
            />
          </div>
        )}
    </div>
  );
};

export default ReferralCode;

import { createSelector } from 'reselect';

import {
  currentViewStateSelector,
} from 'store';

import {
  getPlansWithPetSortedByStatus,
  getTotalBenefitUsageHistory,
  getUsageDataByCategory,
  sortDateList,
} from 'utils';

import {
  DiscountType,
  YOUR_PET_NAME_FALLBACK_LABEL,
} from 'utils/constGlobal';

import moment from 'moment';

export const redemptionViewModel = createSelector(
  currentViewStateSelector,
  (state) => state.customer,
  (state) => state.toggles,
  (state) => state.customer.pets,
  (state) => state.customer.membershipPlans,
  (state) => state.dashboardInfo,
  (state) => state.customer.paymentMethods.data,
  (state) => state.deepLink,
  (state) => state.uiText,
  (state) => state.paymentPlansDetail,
  (currentViewState, customer, toggles, pets, membershipPlans, dashboardInfo, paymentMethods, deepLink, uiText, paymentPlansDetail) => {
    const YOUR_WALLET_FALLBACK_LABEL = 'Your Wallet';
    const { CUSTOMER_CARE_OPERATION_OPEN, CUSTOMER_CARE_OPERATION_CLOSE } = toggles;
    const CONTACT_CUSTOMER_CARE_INFO = {
      weekDaysOperational: `${moment(CUSTOMER_CARE_OPERATION_OPEN, 'h').format('ha')}-${moment(CUSTOMER_CARE_OPERATION_CLOSE, 'h').format('ha')}`,
    };

    const plansWithPets = getPlansWithPetSortedByStatus(membershipPlans.data, pets.data);

    let selectedPlanWithPet;

    if (deepLink.destination === 'pet_selection') {
      selectedPlanWithPet = plansWithPets.find((planWithPet, index) => (dashboardInfo.petMasterId
        ? planWithPet.petMasterId === dashboardInfo.petMasterId
        : index === 0));
    } else if (deepLink.petId !== undefined) {
      selectedPlanWithPet = plansWithPets.find((planWithPet, index) => (deepLink.petId
        ? planWithPet?.pet?.pgrMasterId === deepLink?.petId
        : index === 0));
    } else {
      selectedPlanWithPet = plansWithPets.find((planWithPet, index) => (dashboardInfo.petMasterId
        ? planWithPet.petMasterId === dashboardInfo.petMasterId
        : index === 0));
    }

    if (selectedPlanWithPet) {
      selectedPlanWithPet = {
        ...selectedPlanWithPet,
        plan: {
          ...selectedPlanWithPet.plan,
          membershipBenefits: selectedPlanWithPet.plan.membershipBenefits.map(
            (benefit) => ({ ...benefit, redeemedOn: sortDateList(benefit.redeemedOn) }),
          ),
        },
      };
    }

    let wallet = null;
    if (selectedPlanWithPet !== undefined) {
      wallet = paymentMethods.find((walletParam) => selectedPlanWithPet.plan.walletCardId === parseInt(walletParam.mbrPayinfoId, 10));
      selectedPlanWithPet.name = selectedPlanWithPet.pet
        ? selectedPlanWithPet.pet.name
        : YOUR_PET_NAME_FALLBACK_LABEL;
    }

    const totalBenefitUsage = selectedPlanWithPet
      && dashboardInfo.planBenefitUsageHistory
      && dashboardInfo.planBenefitUsageHistory.benefitUsages
      ? getTotalBenefitUsageHistory(
        selectedPlanWithPet.petMasterId,
        membershipPlans.data,
        dashboardInfo.planBenefitUsageHistory,
      )
      : null;

    let usageDataByCategory = [];

    const loadedPlansBenefitUsageHistory = dashboardInfo.planBenefitUsageHistory !== null;

    if (loadedPlansBenefitUsageHistory) {
      const membershipPlan = membershipPlans.data.find(
        (plan) => plan.id === dashboardInfo.planBenefitUsageHistory.membershipPlanId,
      );

      if (membershipPlan) {
        usageDataByCategory = getUsageDataByCategory(membershipPlan, dashboardInfo.planBenefitUsageHistory);
      }
    }

    const { DASHBOARD_BENEFIT_VALUES_INFO_ENABLED } = toggles;
    const showBenefitValues = DASHBOARD_BENEFIT_VALUES_INFO_ENABLED && dashboardInfo.planBenefitUsageHistory;

    const benefitInfo = {
      totalBenefitUsage,
      usageDataByCategory,
      showBenefitValues,
      loadedPlansBenefitUsageHistory,
    };

    const plan = selectedPlanWithPet ? selectedPlanWithPet.plan : null;
    const multipetDiscount = plan
      ? plan.discounts.find((discount) => discount.type === DiscountType.MULTIPET)
      : undefined;
    const planPrice = plan ? plan.priceAmount : '';

    const selectedPlanInfo = {
      planPrice,
      planAnnualPrice: multipetDiscount ? planPrice - multipetDiscount.amount : planPrice,
      planStatus: plan && plan.status,
      planWallet: wallet,
      automaticRenew: plan ? plan.automaticRenew : false,
    };

    const customerInfo = {
      customerFirstName: customer.user.data.firstName,
      customerFullName: `${customer.user.data.firstName} ${customer.user.data.lastName}`,
      palsId: customer.user.data.palsId,
      primaryPCC: customer.user.data.pcc,
    };

    const reactivationErrorInfo = {
      errorOccurred: dashboardInfo?.onMembershipReactivationError?.occurred,
      modalTitle: dashboardInfo?.onMembershipReactivationError?.message,
    };

    const renewalErrorInfo = {
      errorOccurred: dashboardInfo?.onMembershipRenewalError?.occurred,
      modalTitle: dashboardInfo?.onMembershipRenewalError?.message,
    };

    const paymentMethodUpdateErrorInfo = {
      errorOccurred: dashboardInfo?.onMembershipPaymentMethodUpdateError?.occurred,
      modalTitle: dashboardInfo?.onMembershipPaymentMethodUpdateError?.message,
    };

    const petsEnrolled = plansWithPets.filter(plan => plan.plan.status === 'active' || plan.plan.status === 'expired');
    const multiPetEnrolled = petsEnrolled.length >= 1;

    return {
      pets,
      step: currentViewState.step,
      toggles,
      customerInfo,
      plansWithPets,
      benefitInfo,
      selectedPlanWithPet,
      selectedPlanInfo,
      paymentMethodsLoaded: paymentMethods !== null,
      reactivationErrorInfo,
      YOUR_WALLET_FALLBACK_LABEL,
      deepLinkInfo: deepLink,
      contactCustomerCareInfo: CONTACT_CUSTOMER_CARE_INFO,
      transitionModalOnCooldown: dashboardInfo.transitionModalOnCooldown,
      showTransitionMessage: dashboardInfo.showTransitionMessage,
      showTransitionModal: dashboardInfo.showTransitionModal,
      transitionMessage: dashboardInfo.transitionMessage,
      thirdPartyErrorMessage: dashboardInfo.thirdPartyErrorMessage,
      onMembershipReactivationSuccessMessage: dashboardInfo.onMembershipReactivationSuccessMessage,
      onMembershipAutoRenewSuccessMessage: dashboardInfo.onMembershipAutoRenewSuccessMessage,
      renewalErrorInfo,
      onMembershipRenewalSuccessMessage: dashboardInfo.onMembershipRenewalSuccessMessage,
      dashboardInfo,
      uiText,
      paymentMethodUpdateErrorInfo,
      paymentPlansDetail,
      switchMembershipSuccessMessage: dashboardInfo.switchMembershipSuccessMessage,
      petAlreadyEnrolled: multiPetEnrolled,
      redeemBenefitsCouponErrorMessage: dashboardInfo.redeemBenefitsCouponErrorMessage,
    };
  },
);

import React from 'react';
import './checkbox.css';

const Checkbox = ({
  id, label, disabled, handleCheck, variant, ...rest
}) => {
  if (variant === 'radial') {
    return (
      <div
        role="radio"
        onKeyPress={rest.onKeyPress}
        aria-checked={rest.checked}
      >
        <label htmlFor={id} className="component__Checkbox" tabIndex={0}>
          <input id={id} type="checkbox" className="radial" onClick={handleCheck} {...rest} tabIndex={-1} />
          <span className="radial__IconContainer" />
          {label}
        </label>
      </div>
    );
  } else {
    return (
      <label htmlFor={id} className="component__Checkbox">
        <input id={id} type="checkbox" className="checkbox__input" onClick={handleCheck} {...rest} />
        <span className="checkbox__IconContainer">
          <img src="/assets/icon-checkbox.svg" alt="" />
        </span>
        {label}
      </label>
    );
  }
};

export default Checkbox;

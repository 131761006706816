/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { timeStampData } from 'utils';

const customer = createSlice({
  name: 'customer',
  initialState: {
    addresses: [],
    paymentMethods: {
      data: [],
      cacheTime: null,
      error: '',
    },
    pets: {
      cacheTime: null,
      loadingPets: false,
      data: [],
    },
    membershipPlans: {
      cacheTime: null,
      data: [],
    },
    cacheTime: null,
    user: {
      data: {
        organizationDistinguishedName: '',
        lastName: '',
        resourceId: '',
        challengeQuestion: '',
        orgizationId: '',
        nickName: '',
        userId: '',
        firstName: '',
        resourceName: '',
        distinguishedName: '',
        addressId: '',
        logonId: '',
        palsId: '',
      },
      email: '',
      id: '',
    },
  },
  reducers: {
    setCustomer: (state, action) => action.payload,
    setUser: (state, action) => {
      timeStampData(state);
      state.user = action.payload;
    },
    setCustomerAddress: (state, action) => {
      state.addresses = action.payload;
    },
    // todo call this once we do NCR integration and card changes
    clearCustomerCacheTimer: (state) => {
      state.cacheTime = null;
    },
    setPaymentMethods: (state, action) => {
      timeStampData(state.paymentMethods);
      state.paymentMethods.data = action.payload;
    },
    resetPaymentMethodError: (state, action) => {
      state.paymentMethods.error = action.payload;
    },
    clearPaymentMethodsCacheTimer: (state) => {
      state.paymentMethods.cacheTime = null;
    },
    setSelectedPayment: (state, action) => {
      const payments = state.paymentMethods.data.map((item) => ({
        ...item,
        isSelected: item.mbrPayinfoId === action.payload,
      }));
      state.paymentMethods.data = payments;
    },
    setPets: (state, action) => {
      timeStampData(state.pets);
      state.pets.loadingPets = false;
      state.pets.data = action.payload;
    },
    clearPetsCacheTimer: (state) => {
      state.pets.cacheTime = null;
    },
    setLoadingPets: (state, action) => {
      state.pets.loadingPets = action.payload;
    },
    setMembershipPlans: (state, action) => {
      timeStampData(state.membershipPlans);
      const newArray = action.payload.map((plan) => ({
        ...plan,
        transitionAnswered: true,
        choices: [
          {
            id: 1,
            badgeLabel: 'NEW',
            blurbText: 'Unlimited 30% off full groom or bath services',
          },
          {
            id: 2,
            badgeLabel: 'CURRENT',
            blurbText: 'Unlimited teeth brush and nail trim',
          },
        ],
      }));
      state.membershipPlans.data = newArray;
    },
    clearMembershipPlansCacheTimer: (state) => {
      state.membershipPlans.cacheTime = null;
    },
    clearPaymentMethodError: (state) => {
      state.paymentMethods.error = '';
    },
  },
});

export default customer;

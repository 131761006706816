import { createSlice } from '@reduxjs/toolkit';

// set api response value in the global state "paymentPlansDetail"
const paymentPlansDetail = createSlice({
  name: 'paymentPlansDetail',
  initialState: {},
  reducers: {
    set: (state, action) => action.payload,
  },
});

export default paymentPlansDetail;

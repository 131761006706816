import React from 'react';

import './thumbnail.css';

export const Thumbnail = ({ file }) => (
  <div className="FileImage__IconFileWrapper" key={file.name}>
    <img
      alt="Uploaded vet invoice"
      src={file.src}
    />
  </div>
);

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  navigationHistory,
  DEEPLINK_INDEX,
  deepLink,
} from 'store/slices';
import { 
  trackEvent,
  stickyButtonStyle,
  isAndroidDevice,
  isIoSDevice,
  stickyButtonStyleDiv,
} from 'utils';

import { Button } from 'components-v2/shared/Button';
import CardPlan from 'components-v2/shared/CardPlan';
import Container from 'components-v2/shared/Container';
import Icon from 'components-v2/shared/Icon/Icon';
import { Title, Text } from 'components-v2/shared/Elements';
import ComingSoonPage from './ComingSoonPage';

import './sell-screen.css';
import BackTo from 'components-v2/shared/BackTo/BackTo';
import { backToDestinations, adobeAnalyticsTags, deviceName } from 'utils/constGlobal';
import SellScreenV2 from './SellScreenV2';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';

const SellScreen = ({
  userName,
  // annualPrice,
  membershipBenefits,
  showComingSoon,
  // sellPageHeadline,
  // sellPageAboveCopy,
  // sellPageBelowCopy,
  isLoading,
  enrollNowClicked,
  hasUserMembershipPlans,
  deepLinking,
  deepLinkInfo,
  toggles,
  sellScreenDetails,
  companionAnimalFeature,
}) => {
  const dispatch = useDispatch();
  const deviceNameStr = deviceName();
  const isApp = deviceNameStr === 'mobile app';
  const [containerStyle, setContainerStyle] = useState({});
  const [containerDivStyle, setContainerDivStyle] = useState({});
  const height = isAndroidDevice ? 160 : isIoSDevice ? 190 : 0;
  const marginLeft = isAndroidDevice ? 6 : isIoSDevice ? -0.5 : '';
  const umVitalCareText = toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'Vital Care Premier' : 'Vital Care';
  useEffect(() => {
    const style = stickyButtonStyle(height, marginLeft);
    const divStyle = stickyButtonStyleDiv(height);
    setContainerStyle(style);
    setContainerDivStyle(divStyle);
  }, []);

  useEffect(() => {
    adobeAnalyticsTags.USER_LAND_ON_SELL_SCREEN_V2.page.buildVersion = toggles?.FRONTEND_VERSION;
    trackEvent(adobeAnalyticsTags?.USER_LAND_ON_SELL_SCREEN_V2);
  }, [trackEvent, adobeAnalyticsTags]);

  useEffect(() => {
    // watch out for those purge actions. They are very important because they
    // remove from navigation history the key passed to them. As the
    // purgeFromHistory reducer already removes history records without
    // categories. That's why the INITIAL_LOAD key gets pulled off.
    dispatch(navigationHistory.actions.purgePetSelection());
  }, [dispatch]);

  useEffect(() => {
    if (deepLinking) {
      switch (deepLinkInfo.destination) {
        case DEEPLINK_INDEX.PET_CREATION.key:
        case DEEPLINK_INDEX.PET_SELECTION.key:
        case DEEPLINK_INDEX.ENROLLMENT_CONFIRMATION.key:
        case DEEPLINK_INDEX.ENROLLMENT_CONFIRMATION_WALLET.key:
          enrollNowClicked();
          break;

        default:
          dispatch(deepLink.actions.resetDeepLink());
          break;
      }
    }
  }, [dispatch, deepLinkInfo, deepLinking, enrollNowClicked]);

  const onFormSubmit = (e) => {
    e.preventDefault();

    enrollNowClicked();

    trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_ENROLL_NOW_BUTTON_V2);
  };

  if (isLoading) return null;

  const onHrefClick = () => {
    trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_LEARN_MORE_V2);
  };

  if (isLoading) return null;

  const tableDataBackgroundClass = toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'Table__Data Table__Data--ColouredBackground UM-Background' : 'Table__Data Table__Data--ColouredBackground';


  return (
    showComingSoon
      ? <ComingSoonPage />
      : (
        <div className="MembershipInfoPage-v2">
          <div className="PageHeader" tabIndex={0}>
            <Title className={toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'UM-Title' : ''} content={`Welcome back, ${userName}!`} />
            {(
              toggles?.ENABLE_UNIFIED_MEMBERSHIP)
              ? (
                <Icon
                  name="petcoVitalCareUMLogo"
                  className="petcoVitalCareLogo UM-logo"
                />
              ) : (
                <Icon
                  name="petcoVitalCareLogo"
                  className="petcoVitalCareLogo"
                />
              )}
          </div>
          <div style={toggles?.ENABLE_STICKY_ENROLL_NOW_UI ? containerDivStyle : null}>
            <Container style={toggles?.ENABLE_STICKY_ENROLL_NOW_UI ? containerStyle : null}>

              {hasUserMembershipPlans && (<BackTo where={backToDestinations.DASHBOARD} />)}
              {toggles?.ENABLE_COMPANION_ANIMAL_UI || companionAnimalFeature ? (

                <SellScreenV2
                  sellScreenDetails={sellScreenDetails}
                  toggles={toggles}
                  companionAnimalFeature={companionAnimalFeature}
                />

              ) : (
                <>
                  <div className="CoreCareCardPlan">
                    <CardPlan>
                      <div className="CardPlan__Body noPadding">
                        <div className="QuoteSelection">
                          <div className="QuoteSelection__BenefitBanner">
                            <Title content={`${umVitalCareText} is the most comprehensive wellness system Petco has ever created.`} />
                            <Text>
                              From the nutrition and supplies pets need each day, to the services that keep them at optimal health,
                              {` ${umVitalCareText}`} makes it easier and more affordable for you to care for your pet’s whole health all in one place.
                            </Text>
                            <Text>$19.99 a month, annual commitment required.</Text>
                          </div>
                          <div className="QuoteSelection__BenefitHeader">
                            <div className="QuoteSelection__BenefitText">Included Benefits</div>
                          </div>
                          <table className="Table">
                            <tbody className="Table__Body">
                              {/* {membershipBenefits?.map((item) => (
                          <tr className="Table__Row" key={item.id}>
                            <td className="Table__Data Table__Data--Bold">{item.name}</td>
                            <td className="Table__Data Table__Data--ColouredBackground">{item.marketingDescription}</td>
                          </tr>
                        ))} */}
                              <tr className="Table__Row">
                                <td className="Table__Data Table__Data--Bold">Rewards</td>
                                <td className={tableDataBackgroundClass}>$15 Pals Rewards every month</td>
                              </tr>
                              <tr className="Table__Row">
                                <td className="Table__Data Table__Data--Bold">Unlimited routine vet exams</td>
                                <td className={tableDataBackgroundClass}>Available at participating Petco locations or receive $20 Pals Rewards per exam when you visit a vet of your choice</td>
                              </tr>
                              <tr className="Table__Row">
                                <td className="Table__Data Table__Data--Bold">Nutrition</td>
                                <td className={tableDataBackgroundClass}>10% off all nutrition. Any brand of dry, wet, fresh or frozen.</td>
                              </tr>
                              <tr className="Table__Row">
                                <td className="Table__Data Table__Data--Bold">Grooming (Dog Plan)</td>
                                <td className={tableDataBackgroundClass}>20% off every full-service groom*, including packages and add-ons</td>
                              </tr>
                              <tr className="Table__Row">
                                <td className="Table__Data Table__Data--Bold">Litter (Cat Plan)</td>
                                <td className={tableDataBackgroundClass}>20% off all litter.</td>
                              </tr>
                              {toggles?.ENABLE_ROVER_UI && (
                                <tr className="Table__Row">
                                  <td className="Table__Data Table__Data--Bold">Rover.com</td>
                                  <td className={tableDataBackgroundClass}>$20 off any Rover.com service (One Time Use)</td>
                                </tr>
                              )}
                              <tr className="Table__Row">
                                <td className="Table__Data Table__Data--Bold">Multi-pet discount </td>
                                <td className={tableDataBackgroundClass}>$2 discount per pet, per month when you enroll the whole crew</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardPlan>
                  </div>
                  <div className="MembershipInfoPage__AdditionalInfo">
                    <p>
                      *Full-service baths include deep-cleaning shampoo, blow-dry, 15-minute brushout,
                      scented spritz, gland expression, nail trim and ear cleaning. Full-service haircuts
                      include breed-specific cut and style, deep-cleaning shampoo, blow-dry, 15-minute brushout,
                      scented spritz, gland expression, nail trim and ear cleaning. Services for dogs only.
                    </p>
                  </div>
                </>
              )}
              <Button className="Button--Dark" onClick={onFormSubmit} label="ENROLL NOW" />
              <a className="MembershipInfoPage__LearnMore" href="https://www.petco.com/shop/en/petcostore/c/vitalcare" target="blank" rel="noopener noreferrer" aria-label="Learn More about Vital Care" onClick={onHrefClick}>Learn More</a>
            </Container>
          </div>
        </div>
      )
  );
};

export default SellScreen;

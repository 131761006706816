import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { fetchClinicList } from 'store/actions';
import useDebounce from 'utils/debounce';
import Logger from 'utils/logger';

import BackToButton from 'components-v2/shared/BackToButton';
import { Button, ButtonLink } from 'components-v2/shared/Button-v1';
import Checkbox from 'components-v2/shared/Checkbox';
import Container from 'components-v2/shared/Container';
import { Title } from 'components-v2/shared/Elements';
import AddClinicModal from './AddClinicModal';

import './claims-clinic-selection.css';

const LOGGER = Logger.getLogger('StoreLocator');

const ClaimClinicSelection = ({
  onClinicSelectionCompletion,
  petName,
  claimClinic,
  onChangeClinicSelection,
  toggles,
}) => {
  const dispatch = useDispatch();
  const [lastSearchedQuery, setLastSearchedQuery] = useState('');
  const [searchingError, setSearchingError] = useState(false);
  const [clinicList, setClinicList] = useState([]);
  const [numberOfPagesToShow, setNumberOfPagesToShow] = useState(1);
  const [isAddAVetModalOpen, setIsAddAVetModalOpen] = useState(false);
  const [searched, setSearched] = useState(false);
  const [query, setQuery] = useState('');

  const PAGE_SIZE = toggles.CLINIC_LIST_PAGE_SIZE;

  const displayedClinicList = useMemo(
    () => {
      const filterItemsToBeDisplayed = () => {
        const criteria = (index) => index < PAGE_SIZE * numberOfPagesToShow;
        return clinicList.filter((_, index) => criteria(index));
      };

      return filterItemsToBeDisplayed();
    },
    [clinicList, numberOfPagesToShow, PAGE_SIZE],
  );

  const onClinicAdded = (vetClinicAdded) => {
    setClinicList([vetClinicAdded]);
    onChangeClinicSelection(vetClinicAdded);
  };

  const debouncedSearch = useDebounce(query, 250);

  const getAriaLabel = (item) => `${item.description} clinic`;

  const fetchClinicsErrorHandler = useCallback((response) => {
    LOGGER.info(`Error trying get clinics from by query: [${query}]`, response);
    setClinicList([]);
    setSearchingError(true);
  }, [query]);

  const fetchClinicsSuccessHandler = useCallback((clinics = []) => {
    setClinicList(clinics);
    setSearched(true);
    setSearchingError(false);
    setLastSearchedQuery(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    if (claimClinic) {
      setClinicList([claimClinic]);
    }
  }, [claimClinic]);

  useEffect(() => {
    const fetchData = () => {
      if (debouncedSearch.length > 2 && debouncedSearch !== lastSearchedQuery) {
        dispatch(fetchClinicList(query, fetchClinicsSuccessHandler, fetchClinicsErrorHandler));
      }
    };
    fetchData();
  }, [debouncedSearch, dispatch, fetchClinicsErrorHandler, fetchClinicsSuccessHandler, lastSearchedQuery, query]);

  const clearState = () => {
    setClinicList([]);
    setSearchingError(false);
    setQuery('');
    setLastSearchedQuery('');
  };
  return (
    <div data-testid="ClaimsVetSelectionPage" className="ClaimsVetSelectionPage">
      <Container>
        <BackToButton label="Back to Benefits" />
        <Title>
          Where was {petName}'s exam?
        </Title>
        <Formik
          initialValues={{
            query,
            formikVetClinic: claimClinic.id,
          }}
          validateOnMount
          validationSchema={
            Yup.object().shape({
              query: Yup.string(),
              formikVetClinic: Yup.string().required(),
            })
          }
        >
          {(props) => (
            <Form className="search-form">
              <div className="vet-office-wrapper">
                <label className="vet-office-text" htmlFor="query">
                  <span className="red">*</span>
                  Veterinary Office
                </label>
              </div>
              <input
                type="text"
                name="query"
                id="query"
                placeholder="Search by clinic name, zip, phone, or vet name"
                className="vet-clinic-search-input"
                onChange={async (e) => {
                  const fieldQuery = e.target.value;
                  props.setFieldTouched('query');
                  props.setFieldValue('query', fieldQuery);
                  setQuery(fieldQuery);
                  setSearched(false);
                }}
              />
              {clinicList.length > 0 && (
                <div>
                  <div>
                    <p className="results">
                      {displayedClinicList.length} of {clinicList.length} results
                    </p>
                  </div>
                  <ul className="vet-clinic-list">
                    {displayedClinicList.map((clinic) => (
                      <li key={clinic.id ? clinic.id : 1}>
                        <div className="item-wrapper">
                          <Checkbox
                            id="formikVetClinic"
                            type="radio"
                            variant="radial"
                            name="formikVetClinic"
                            value={clinic.id}
                            onChange={() => {
                              props.setFieldTouched('formikVetClinic');
                              props.setFieldValue('formikVetClinic', clinic.id);
                              onChangeClinicSelection(clinic);
                            }}
                            checked={clinic.id === claimClinic.id}
                            aria-label={getAriaLabel(clinic)}
                          />
                          <div className="item-content">
                            <h3 className="item-title">{clinic.name}</h3>
                            <p className="item-description">
                              {clinic.address}
                              ,
                              {clinic.city} {clinic.state} {clinic.zipCode}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {clinicList.length > displayedClinicList.length
                && (
                  <ButtonLink
                    type="button"
                    label="Load more clinics"
                    variant="textLink"
                    className="load-more-button"
                    onClick={() => setNumberOfPagesToShow(numberOfPagesToShow + 1)}
                    aria-label="Click here to load more clinics."
                  />
                )}
              {clinicList.length === 0
                && searched
                && (
                  <div className="no-results-list">
                    No Search Results.
                  </div>
                )}
              {searchingError
                && (
                  <div className="no-results-list">
                    "Unfortunately, we can't find your clinic right now. Please add it using the "Add here" link below."
                  </div>
                )}
              <div className="dont-see-wrapper">
                <p className="dont-see">
                  Don't see your vet?
                  <button
                    onClick={() => {
                      setIsAddAVetModalOpen(true);
                      clearState();
                      props.setFieldValue('query', '');
                      window.parent.postMessage({ action: 'scrollTopSmoothly' }, '*');
                    }}
                    type="button"
                    aria-label="Don't see your vet? Add here"
                    className="add-clinic-link"
                  >
                    Add here
                  </button>
                </p>
              </div>

              {claimClinic && (
                <Button
                  label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    onClinicSelectionCompletion();
                  }}
                  className={`next-button ${props.isValid ? 'button-active' : ''}`}
                />
              )}
            </Form>
          )}
        </Formik>

        <AddClinicModal
          open={isAddAVetModalOpen}
          onClinicAdded={onClinicAdded}
          onClose={() => setIsAddAVetModalOpen(false)}
        />
      </Container>
    </div>
  );
};

export default ClaimClinicSelection;

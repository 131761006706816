export const globalConsts = {
  LOCALSTORE_MOBILE_KEY: 'Petco_Membership_isRequestFromMobile',
};

export const StatusPlan = Object.freeze({
  ACTIVE: 'active',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
});

export const DeepLinkValues = Object.freeze({
  INITIAL: '',
});

export const backToDestinations = Object.freeze({
  DASHBOARD: 'dashboard',
  SELL_SCREEN: 'sellScreen',
});

export const backToDestinationsLabels = Object.freeze({
  DASHBOARD: 'Back to dashboard',
  SELL_SCREEN: 'Go to plan details',
});

export const DiscountType = Object.freeze({
  MULTIPET: 'multipet',
});

export const AffiliateDiscountType = Object.freeze({
  AFFILIATE: 'affiliate',
  AFFILIATE_DISCOUNT_NAME: 'Affiliate Discount',
  AFFILIATE_REFERRAL_NAME: 'Referral Savings',
});

export const webSocketStatusType = Object.freeze({
  SUCCESS: 201,
  ERROR: 500,
  DENIEDERROR: 400,
});

export const YOUR_PET_NAME_FALLBACK_LABEL = 'Your Pet';

export const isSafariBrowser = () => (navigator.vendor
  && navigator.vendor.indexOf('Apple') > -1
  && navigator.userAgent
  && navigator.userAgent.indexOf('CriOS') === -1
  && navigator.userAgent.indexOf('FxiOS') === -1);

const urlSearchParams = new URL(window.location.href.toLocaleLowerCase()).searchParams;

export const deviceName = () => {
  const ua = navigator.userAgent;
  // Macintosh for macbooks
  if (/(tablet|iPad|MacIntel|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
    // } else if (JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY))) {
  } else if (urlSearchParams.get('channel') === 'mobile') {
    return 'mobile app';
  } else if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(ua)) {
    return 'mobile web';
  }
  return 'desktop';
};

const deviceNameStr = deviceName();

export const adobeAnalyticsTags = Object.freeze({
  USER_LAND_ON_SELL_SCREEN_V2: {
    event: 'pageView',
    page: {
      pageName: 'vital care sell screen',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'pageView_vital_care_sell_screen',
    },
  },
  USER_LANDS_ON_PET_ENROLL_PAGE: {
    event: 'pageView',
    page: {
      pageName: 'vital care enroll a pet',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_enroll_pet',
    },
  },
  USER_CLICK_ON_ENROLL_NOW_BUTTON_V2: {
    event: 'click',
    clickName: 'vital care sell screen | enroll now',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_sell_screen_enroll_now',
    },
  },
  USER_CLICK_ON_LEARN_MORE_V2: {
    event: 'click',
    clickName: 'vital care sell screen | learn more',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_sell_screen_learn_more',
    },
  },
  USER_CLICK_ON_PET_TOGGLE_V2: {
    event: 'click',
    clickName: 'vital care dashboard | pet toggle',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard_pet_toggle',
    },
  },
  USER_CLICK_ON_REDEEM_BENEFIT_V2: {
    event: 'click',
    clickName: 'vital care dashboard | redeem benefit',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard_redeem_benefit',
    },
  },
  USER_CLICK_ON_VET_EXAM_REIMBURSEMENT_V2: {
    event: 'click',
    clickName: 'vital care dashboard | vet exam reimbursement',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard_vet_exam_reimbursement',
    },
  },
  USER_CLICK_ON_ABOUT_YOUR_VITAL_CARE_PLAN_V2: {
    event: 'click',
    clickName: 'vital care dashboard | about your vital care plan',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard_about_vc_plan',
    },
  },
  USER_CLICK_ON_ADD_A_PET_V2: {
    event: 'click',
    clickName: 'vital care dashboard | add a pet',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard_add_a_pet',
    },
  },
  USER_CLICK_ON_NEED_HELP_V2: {
    event: 'click',
    clickName: 'vital care dashboard | need help',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard_need_help',
    },
  },
  FROM_LANDING_PAGE: {
    formname: 'Membership Signup',
    formstepname: 'add or select a pet',
    form_step: 1,
    subformname: 'Add a pet',
    subformstepdetails: 'vital care service landing page',
  },
  SIGNUP_ADD_PET_V2: {
    event: 'click',
    clickName: 'vital care | create pet profile',
    serviceDetails: {
      stepName: 'vital care | who would you like to enroll',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_create_pet_profile',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'add or select a pet',
        formstep: 4,
        subformname: 'add a pet',
        subformstepdetails: 'create pet profile',
      },
    },
    petInfo: [],
  },
  USER_CLICKS_ON_HAVE_A_REFERRAL_CODE_LINK: {
    event: 'click',
    clickName: 'vital care | enroll a pet | have a referral code',
    serviceDetails: {
      stepName: 'vital care | enroll a pet',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_have_a_referral_code',
    },
  },
  USER_CLICKS_ON_APPLY_PROMO_CODE_BUTTON: {
    event: 'click',
    clickName: 'vital care | enroll a pet | apply promo',
    serviceDetails: {
      stepName: 'vital care | enroll a pet',
      orderPromoCode: '',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_promo_code_apply',
    },
  },
  USER_ENCOUNTER_ERROR_WHILE_CLICK_ON_APPLY_PROMO_CODE_BUTTON: {
    event: 'pageView',
    page: {
      pageName: 'vital care enroll a pet',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    serviceDetails: {
      orderPromoCode: '',
    },
    ga: {
      gaEvent: 'vital_care_enroll_pet',
    },
    message: {
      error: '',
      type: 'vital care premier referral code application',
    },
  },
  USER_LANDS_ON_REFERRAL_CODE_APPLIED_SCREEN: {
    event: 'pageView',
    page: {
      pageName: 'vital care enroll a pet',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    serviceDetails: {
      orderPromoCode: '',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_enroll_pet',
    },
    message: {
      info: 'referral code applied',
      type: 'vital care premier referral code application',
    },
  },
  USER_CLICKS_ON_APPLY_PROMO_UNDO_BUTTON: {
    event: 'click',
    clickName: 'vital care | enroll a pet | undo',
    serviceDetails: {
      stepName: 'vital care | enroll a pet',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_referral_code_undo',
    },
  },
  SIGNUP_ADD_PET: {
    formname: 'Membership Signup',
    formstepname: 'add or select a pet',
    form_step: 4,
    subformname: 'Add a pet',
    subformstepdetails: 'create pet profile',
  },
  SIGNUP_ADD_PET_STEP_1_V2: {
    event: 'click',
    clickName: 'vital care | add a pet',
    serviceDetails: {
      stepName: 'vital care | add or select a pet',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_add_a_pet',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'add or select a pet',
        formstep: 1,
        subformname: 'add a pet',
        subformstepdetails: 'pet name',
      },
    },
  },
  SIGNUP_ADD_PET_STEP_1: {
    formname: 'Membership Signup',
    formstepname: 'add or select a pet',
    form_step: 1,
    subformname: 'Add a pet',
    subformstepdetails: 'Who would you like to enroll in Vital Care',
  },
  JOIN_NOW_MULTIPLE_V2: {
    event: 'click',
    clickName: 'vital care | select a multiple pet | join now',
    serviceDetails: {
      stepName: 'vital care | add or select a pet',
    },
    user: {
      deviceType: deviceNameStr,
    },
    petInfo: [],
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'enroll a pet',
        formstep: '1',
        subformname: 'join now',
        subformstepdetails: 'multiple pet',
      },
    },
    ga: {
      gaEvent: 'vital_care_join_now_multiple_pet',
    },
  },
  JOIN_NOW_MULTIPLE: {
    formname: 'Membership Signup',
    link_name: 'Join Now',
    subformname: 'Add a pet',
    pets_selection: 'Multiple',
  },
  JOIN_NOW_SINGLE_V2: {
    event: 'click',
    clickName: 'vital care | select a single pet | join now',
    serviceDetails: {
      stepName: 'vital care | add or select a pet',
    },
    petInfo: [],
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'enroll a pet',
        formstep: '1',
        subformname: 'join now',
        subformstepdetails: 'single pet',
      },
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_join_now_single_pet',
    },
  },
  JOIN_NOW_SINGLE: {
    formname: 'Membership Signup',
    link_name: 'Join Now',
    subformname: 'Add a pet',
    pets_selection: 'Single',
  },
  USER_LANDS_ON_CHECKOUT_PAGE: {
    event: 'pageView',
    page: {
      pageName: 'vital care checkout',
      pageType: 'vital care',
      buildVersion: 'version', // need to work on this <from storeconf(wcs) & from github CI_COMMIT_SHA(react)>
    },
    user: {
      deviceType: deviceNameStr,
    },
    petInfo: [],
    ga: {
      gaEvent: 'vital_care_checkout',
    },
  },
  USER_LANDS_ON_PAYMENT_ERROR_ON_SIGNUP_FLOW: {
    event: 'pageView',
    page: {
      pageName: 'vital care checkout',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    serviceDetails: {
      stepname: 'complete your purchase | payment',
    },
    ga: {
      gaEvent: 'vital_care_checkout',
    },
    message: {
      error: 'there is a issue with the payment method selected. please select different method to proceed',
      type: 'payment error',
    },
  },
  EDIT_PAYMENT_REVIEW_V2: {
    event: 'click',
    clickName: 'checkout | enter payment information | edit',
    serviceDetails: {
      stepname: 'complete your purchase | review',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_click_enter_payment_information_edit',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'review',
        formstep: '5',
        subformname: 'review',
        subformstepdetails: 'enter payment details',
      },
    },
  },
  PAYMENT_REVIEW_V2: {
    event: 'click',
    clickName: 'checkout | enter payment information',
    serviceDetails: {
      stepname: 'complete your purchase | review',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_enter_payment_information',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'review',
        formstep: '5',
        subformname: 'review',
        subformstepdetails: 'enter payment details',
      },
    },
  },
  PAYMENT_REVIEW: {
    formname: 'Membership Signup',
    formstepname: 'review',
    form_step: 5,
    subformname: 'review',
    subformstepdetails: 'enter payment details',
  },
  PAYMENT_INFO_V2: {
    event: 'click',
    clickName: 'payment type | select payment',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_select_payment',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  PAYMENT_INFO: {
    formname: 'Membership Signup',
    formstepname: 'payment info',
    form_step: 5,
    subformname: 'payment info',
    subformstepdetails: 'add or select payment',
  },
  ADD_NEW_PAYMENT_INFO_V2: {
    event: 'click',
    clickName: 'payment type | add a payment',
    serviceDetails: {
      stepname: 'complete your purchase | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  NEW_CARD_INFO_FAIL_V2: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'complete your purchase | payment',
      paymentStatus: 'fail',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup:complete purchase',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method_continue',
    },
  },
  NEW_CARD_INFO_V2: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'complete your purchase | payment',
      paymentStatus: 'success',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup:complete purchase',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method_continue',
    },
  },
  EDIT_CARD_INFO_V2: {
    event: 'click',
    clickName: 'checkout | payment | edit',
    serviceDetails: {
      stepname: 'complete your purchase | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_click_checkout_payment_edit',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'payment info',
        subformstepdetails: 'add or select payment',
      },
    },
  },
  EDIT_SEARCHED_STORE_V2: {
    event: 'click',
    clickName: 'checkout | search store | edit',
    serviceDetails: {
      stepname: 'complete your purchase | your store',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_click_search_or_skip_store_edit',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'searched store',
        formstep: '5',
        subformname: 'your store',
        subformstepdetails: 'search store or skip store',
      },
    },
  },
  SEARCHED_STORE_V2: {
    event: 'click',
    clickName: 'checkout | search store',
    serviceDetails: {
      stepname: 'complete your purchase | your store',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_search_or_skip_store',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'searched store',
        formstep: '5',
        subformname: 'your store',
        subformstepdetails: 'search store or skip store',
      },
    },
  },
  SEARCHED_STORE_SKIP_V2: {
    event: 'click',
    clickName: 'checkout | skip store',
    serviceDetails: {
      stepname: 'complete your purchase | your store',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_search_or_skip_store',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'searched store',
        formstep: '5',
        subformname: 'your store',
        subformstepdetails: 'search store or skip store',
      },
    },
  },
  SEARCHED_STORE: {
    formname: 'Membership Signup',
    formstepname: 'searched store',
    form_step: 5,
    subformname: 'your store',
    subformstepdetails: 'search store or skip store',
  },
  FAILURE_SCREEN_V2: {
    event: 'click',
    clickName: 'checkout | submit payment',
    serviceDetails: {
      stepname: 'complete your purchase | submit payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_submit_payment',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'failure screen',
        formstep: '5',
        subformname: 'failure screen',
        subformstepdetails: 'submit payment',
      },
    },
  },
  ORDER_CONFIRMATION_SCREEN_V2: {
    event: 'pageView',
    page: {
      pageName: 'vital care order confirmation page',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_order_confirmation_page',
    },
    petInfo: [{
      petName: '<Ppp>',
      vitalcareId: '43501',
      petSpecies: '<dog/cat/fish/bird/raptile>',
      petBreed: '<Affenpinscher/Abyssinian/Betta/Amazon/Anole>',
      petAge: {
        year: '<2021>',
        month: '<05>',
        day: '<15>',
        age: '1 year 4 months',
      },
    }],
    serviceDetails: {
      orderId: '<order id>',
      orderStatus: '<success>',
      totalTransaction: '<35.98>',
      orderTaxAmount: '<2.88>',
    },
  },
  SUCCESS_SCREEN_V2: {
    event: 'click',
    clickName: 'checkout | submit payment',
    serviceDetails: {
      stepname: 'complete your purchase | submit payment',
      totalTransaction: '<35.98>',
      orderTaxAmount: '<2.88>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    petInfo: [{
      petName: '<Ppp>',
      orignalPrice: '<19.99>',
      listPrice: '<17.99>',
    }],
    ga: {
      gaEvent: 'vital_care_submit_payment',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'success screen',
        formstep: '5',
        subformname: 'success screen',
        subformstepdetails: 'submit payment',
      },
    },
  },
  SUCCESS_SCREEN_TERM_CONDITION_V2: {
    event: 'click',
    clickName: 'checkout | terms and conditions',
    serviceDetails: {
      stepname: 'complete your purchase | finish your purchase',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_terms_and_conditions',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'finish your purchase screen',
        formstep: '5',
        subformname: 'terms and conditions screen',
        subformstepdetails: 'terms and conditions',
      },
    },
  },
  SUCCESS_SCREEN: {
    formname: 'Membership Signup',
    formstepname: 'success screen',
    form_step: 6,
    subformname: 'success screen',
    subformstepdetails: 'submit payment',
  },
  MEMBERSHIP_DASHBOARD_STEP_2: {
    formname: 'Membership Dashboard',
    formstepname: 'Redeem benefits',
    form_step: 2,
  },
  MEMBERSHIP_DASHBOARD_STEP_3: {
    formname: 'Membership Dashboard',
    formstepname: 'View details',
    form_step: 3,
  },
  MEMBERSHIP_DASHBOARD_STEP_4: {
    formname: 'Membership Dashboard',
    formstepname: 'Add a pet',
    form_step: 4,
  },
  CONTACT_CUSTOMER_CARE_STEP_5: {
    formname: 'contact customer care',
    formstepname: 'Need Help',
    form_step: 5,
  },
  MEMBERSHIP_DASHBOARD_VIEW_BENEFIT_STEP_2: {
    formname: 'Membership Dashboard',
    formstepname: 'redeem claim',
    form_step: 2,
    subformname: 'View Benefit',
  },
  USER_LAND_ON_DASHBOARD: {
    event: 'pageView',
    page: {
      pageName: 'vital care dashboard',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard',
    },
  },
  USER_LAND_ON_REACTIVE_WARNING_MESSAGE: {
    event: 'pageView',
    page: {
      pageName: 'vital care dashboard',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard',
    },
    message: {
      warning: 'your pet\'s plan is cancelled, and their benefits are no longer accessible',
      type: 'reactivate warning',
    },
  },
  DASHBOARD_LANDING_PAGE_FIRST_GRACE_PERIOD: {
    event: 'pageView',
    page: {
      pageName: 'vital care dashboard',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard',
    },
    message: {
      warning: 'your pet\'s plan is tied to a payment method that is no longer working. your pet\'s plan was cancelled',
      type: 'grace warning',
    },
  },
  USER_CLICK_ON_UPDATE_YOUR_CARD_LINK_DASHBOARD_FIRST_GRACE_PERIOD: {
    event: 'click',
    clickName: 'vital care dashboard | grace warning | update your card',
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care dashboard',
        formstepname: 'grace period',
        formstep: '1',
        subformname: 'grace period',
        subformstepdetails: 'please update your card',
      },
    },
    ga: {
      gaEvent: 'click_vital_care_grace_update_card',
    },
  },
  USER_VIEWS_ADD_CREDIT_CARD_MODAL_ON_UPDATE_PAYMENT_METHOD: {
    event: 'modalView',
    modal: {
      name: 'add credit card',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care dashboard',
        formstepname: 'grace period',
        formstep: '1',
        subformname: 'grace period',
        subformstepdetails: 'add credit card',
      },
    },
    ga: {
      gaEvent: 'modalView_add_credit_card',
    },
  },
  USER_SELECT_EXISTING_CREDIT_CARD_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | select existing payment',
    serviceDetails: {
      stepname: 'grace period | payment',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add credit card',
        subformstepdetails: 'select existing payment',
      },
    },
    ga: {
      gaEvent: 'vital_care_select_existing_payment',
    },
  },
  USER_SELECT_EXISTING_CREDIT_CARD_AND_SAVE_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | select existing payment | save',
    serviceDetails: {
      stepname: 'grace period | payment',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'select existing payment',
        subformstepdetails: 'save',
      },
    },
    ga: {
      gaEvent: 'vital_care_select_existing_payment_save',
    },
  },
  USER_CLICK_ADD_CREDIT_CARD_CTA_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | add new credit card',
    serviceDetails: {
      stepname: 'grace period | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add credit card',
        subformstepdetails: 'add new credit card',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_credit_card',
    },
  },
  USER_ERROR_WHEN_ADDING_CREDIT_CARD: {
    event: 'click',
    clickName: 'add new payment method | save',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
      orderPaymentType: '',
      paymentStatus: 'fail',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_add_new_card_save',
    },
    formDetails: {
      form: {
        formname: 'edit payment method',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add new credit card',
        subformstepdetails: 'save',
      },
    },
    message: {
      error: 'payment decline/payment failure. please try again or use another card/there is an issue with the payment method selected. Please select a different payment method to proceed.',
      type: 'payment error',
      errorCode: '500',
    },
  },
  USER_SUCCESSFULLY_ADDED_CREDIT_CARD_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'add new payment method | save',
    serviceDetails: {
      stepname: 'grace period | payment',
      orderPaymentType: '<Visa>',
      paymentStatus: 'success',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add new credit card',
        subformstepdetails: 'save',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_card_save',
    },
    message: {
      info: 'card added successfully',
    },
  },
  USER_CLICK_CLOSE_ICON_FIRST_GRACE_PERIOD_ON_UPDATE_PAYMENT_METHOD_MODAL: {
    event: 'click',
    clickName: 'add credit card | cancel',
    serviceDetails: {
      stepname: 'grace period | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add credit card',
        subformstepdetails: 'cancel',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method_cancel',
    },
  },
  SELECT_EXISTING_PAYMENT_METHOD_FIRST_GRACE_PERIOD: {
    event: 'click',
    clickName: 'payment type | select payment',
    serviceDetails: {
      stepname: 'grace period | payment',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_select_payment',
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  SELECT_ADD_NEW_PAYMENT_METHOD_FIRST_GRACE_PERIOD: {
    event: 'click',
    clickName: 'payment type | add a payment',
    serviceDetails: {
      stepname: 'grace period | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method',
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  ADD_NEW_PAYMENT_METHOD_FIRST_GRACE_PERIOD_SUCCESS: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'grace period | payment',
      paymentStatus: 'success',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method_continue',
    },
  },
  ADD_NEW_PAYMENT_METHOD_FIRST_GRACE_PERIOD_FAIL: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'grace period | payment',
      paymentStatus: 'fail',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method_continue',
    },
  },
  SELECT_VET: {
    event: 'click',
    clickName: 'vital care vet clinic list | select vet',
    serviceDetails: {
      stepname: 'vet exam reimbursements | where was pet exam',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vet exam reimbursements',
        formstepname: 'where was pet exam',
        formstep: '1',
        subformname: 'exam location',
        subformstepdetails: 'select vet',
      },
    },
    store: {
      storeZip: '<92126>',
      storeArea: '<8290 mira blvd>',
      storeName: '<petco vet hospital mira blvd>',
      storePhone: '<9864223455>',
    },
    ga: {
      gaEvent: 'vital_care_when_was_pet_exam_select_vet',
    },
  },
  LOAD_MORE: {
    event: 'click',
    clickName: 'vital care vet clinic list | load more',
    serviceDetails: {
      stepname: 'vet exam reimbursements | where was pet exam',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vet exam reimbursements',
        formstepname: 'where was pet exam',
        formstep: 1,
        subformname: 'exam location',
        subformstepdetails: 'load more',
      },
    },
    ga: {
      gaEvent: 'vital_care_where_was_pet_exam_load_more',
    },
  },
  DONTSEEYOURVET_ADDHERE: {
    event: 'click',
    clickName: 'vital care vet clinic list | don\'t see your vet add here',
    serviceDetails: {
      stepname: 'vet exam reimbursements | where was pet exam',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_where_was_pet_exam_dont_see_your_vet_add_here',
    },
    formDetails: {
      form: {
        formname: 'vet exam reimbursements',
        formstepname: 'where was pet exam',
        formstep: '1',
        subformname: 'exam location',
        subformstepdetails: 'don\'t see your vet add here',
      },
    },
  },
  VETEXAM_NEXT: {
    event: 'click',
    clickName: 'vital care vet exam | next',
    serviceDetails: {
      stepname: 'vet exam reimbursements | when did pet have a vet exam',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vet exam reimbursements',
        formstepname: 'when did pet have vet exam',
        formstep: '2',
        subformname: 'exam date',
        subformstepdetails: 'date of vet exam',
      },
    },
    appointmentDetails: {
      appointmentYear: '<2022>',
      appointmentMonth: '<feb>',
      appointmentDate: '<28>',
    },
    ga: {
      gaEvent: 'vital_care_when_did_pet_have_a_vet_exam_next',
    },
  },
  VETEXAM_UPLOAD: {
    event: 'click',
    clickName: 'vital care upload vet invoice | upload',
    serviceDetails: {
      stepname: 'vet exam reimbursements | upload your vet invoice',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vet exam reimbursements',
        formstepname: 'upload your vet invoice',
        formstep: '3',
        subformname: 'upload vet invoice',
        subformstepdetails: 'upload',
      },
    },
    ga: {
      gaEvent: 'vital_care_your_vet_invoice_upload',
    },
  },
  VETEXAM_BACK: {
    event: 'click',
    clickName: 'vital care upload vet invoice | back',
    serviceDetails: {
      stepname: 'vet exam reimbursements | upload your vet invoice',
    },
    ga: {
      gaEvent: 'vital_care_upload_your_vet_invoice_back',
    },
    formDetails: {
      form: {
        formname: 'vet exam reimbursements',
        formstepname: 'upload your vet invoice',
        formstep: '3',
        subformname: 'upload vet invoice',
        subformstepdetails: 'back',
      },
    },
  },
  VETEXAM_REVIEW_SUBMISSION: {
    event: 'click',
    clickName: 'vital care upload vet invoice | review submission',
    serviceDetails: {
      stepname: 'vet exam reimbursements | upload your vet invoice',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vet exam reimbursements',
        formstepname: 'upload your vet invoice',
        formstep: '4',
        subformname: 'uploaded vet invoice',
        subformstepdetails: 'review submission',
      },
    },
    ga: {
      gaEvent: 'vital_care_upload_your_vet_invoice_review_submission',
    },
  },
  VETEXAM_REVIEW_SUBMISSION_BACK: {
    event: 'click',
    clickName: 'vital care upload vet invoice | back',
    serviceDetails: {
      stepname: 'vet exam reimbursements | upload your vet invoice',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_upload_your_vet_invoice_back',
    },
    formDetails: {
      form: {
        formname: 'vet exam reimbursements',
        formstepname: 'upload your vet invoice',
        formstep: '4',
        subformname: 'uploaded vet invoice',
        subformstepdetails: 'back',
      },
    },
  },
  VETEXAM_RECEIPT_SUBMITTED: {
    event: 'pageView',
    page: {
      pageName: 'vital care vet exam receipt',
      pageType: 'vital care',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_vet_exam_receipt',
    },
    formDetails: {
      form: {
        formname: 'vet exam reimbursements',
        formstepname: 'vital care vet exam receipt',
        formstep: '5',
        subformname: 'vital care vet exam receipt',
        subformstepdetails: 'submitted',
      },
    },
  },
  VETEXAM_RETURN_TO_DASHBOARD: {
    event: 'click',
    clickName: 'vital care vet exam receipt | return to dashboard',
    serviceDetails: {
      stepname: 'vet exam reimbursements | vet exam receipt',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_vet_exam_receipt_return_to_dashboard',
    },
  },
  USER_LAND_ON_PET_VITALCARE_PLAN: {
    event: 'pageView',
    page: {
      pageName: 'vital care pet plan details',
      pageType: 'vital care',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details',
    },
  },
  USER_LAND_ON_PET_VITALCARE_PLAN_WITH_REACTIVATE_WARNING: {
    event: 'pageView',
    page: {
      pageName: 'vital care pet plan details',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details',
    },
    message: {
      warning: 'your pet\'s plan is cancelled, and their benefits are no longer accessible',
      type: 'reactivate warning',
    },
  },
  USER_LAND_ON_PET_VITALCARE_PLAN_WITH_GRACE_WARNING: {
    event: 'pageView',
    page: {
      pageName: 'vital care pet plan details',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details',
    },
    message: {
      warning: 'your pet\'s plan is tied to a payment method that is no longer working. your pet\'s plan was cancelled',
      type: 'grace warning',
    },
  },
  USER_CLICK_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'vital care pet plan | edit payment method',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_edit_payment_method',
    },
  },
  USER_SELECT_EXISTING_CARD_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | select payment',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_select_payment',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_SELECT_ADD_NEW_CARD_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | add a payment',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_add_new_payment_method',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_CLICK_CONTINUE_SUCCESS_ADD_NEW_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
      paymentStatus: 'success',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_add_new_payment_method_continue',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_CLICK_CONTINUE_FAIL_ADD_NEW_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
      paymentStatus: 'fail',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_add_new_payment_method_continue',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_CONTINUE_SUCCESS_ADD_NEW_CARD_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
      paymentStatus: 'success',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_add_new_payment_method_continue',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_CONTINUE_FAIL_ADD_NEW_CARD_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
      paymentStatus: 'fail',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_add_new_payment_method_continue',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_VIEWS_ADD_CREDIT_CARD_MODAL_ON_EDIT_PAYMENT_METHOD: {
    event: 'modalView',
    modal: {
      name: 'add credit card',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'edit payment method',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'add credit card',
      },
    },
    ga: {
      gaEvent: 'modalView_add_credit_card',
    },
  },
  USER_SELECT_EXISTING_CREDIT_CARD_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | select existing card',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_select_existing_payment',
    },
    formDetails: {
      form: {
        formname: 'edit payment method',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add credit card',
        subformstepdetails: 'select existing card',
      },
    },
  },
  USER_SELECT_EXISTING_CREDIT_CARD_AND_SAVE_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | select existing card | save',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_select_existing_payment_save',
    },
    formDetails: {
      form: {
        formname: 'edit payment method',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'select existing payment',
        subformstepdetails: 'save',
      },
    },
  },
  USER_CLICKS_ON_ADD_CREDIT_CARD_CTA_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | add new credit card',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_add_new_credit_card',
    },
    formDetails: {
      form: {
        formname: 'edit payment method',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add credit card',
        subformstepdetails: 'add new credit card',
      },
    },
  },
  USER_SUCCESSFULLY_ADDED_CREDIT_CARD_ON_EDIT_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'add new payment method | save',
    serviceDetails: {
      stepname: 'vital care pet plan details | edit payment method',
      orderPaymentType: '<Visa>',
      paymentStatus: 'success',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_add_new_card_save',
    },
    formDetails: {
      form: {
        formname: 'edit payment method',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add new credit card',
        subformstepdetails: 'save',
      },
    },
    message: {
      info: 'card added successfully',
    },
  },
  USER_CLICK_CLOSE_ICON_ON_EDIT_PAYMENT_METHOD_MODAL: {
    event: 'click',
    clickName: 'add credit card |  cancel',
    serviceDetails: {
      stepname: 'reactivate | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'edit payment method',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add credit card',
        subformstepdetails: 'cancel',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method_cancel',
    },
  },
  USER_CLICK_ON_UPDATE_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'vital care pet plan details | update payment method',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_update_payment_method',
    },
    modal: {
      name: 'please update payment method',
    },
    message: {
      error: 'there is a issue with the payment method associated with your plan. please select add or upadte your payment method',
    },
    formDetails: {
      form: {
        formname: 'vital care',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'update payment method',
        subformstepdetails: 'na',
      },
    },
  },
  USER_SELECT_EXISTING_ON_UPDATE_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | select payment',
    serviceDetails: {
      stepname: 'vital care pet plan details | update payment method',
      orderPaymentType: '<visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_select_payment',
    },
    formDetails: {
      form: {
        formname: 'vital care',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_SELECT_ADD_NEW_ON_UPDATE_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'payment type | add a payment',
    serviceDetails: {
      stepname: 'vital care pet plan details | update payment method',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_add_new_payment_method',
    },
    formDetails: {
      form: {
        formname: 'vital care',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_SELECT_EXISTING_ON_UPDATE_PAYMENT_METHOD_WITH_ERROR: {
    event: 'click',
    clickName: 'payment type | select payment',
    serviceDetails: {
      stepname: 'vital care pet plan details | update payment method',
      orderPaymentType: '<visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_select_payment',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
    message: {
      error: 'there is a issue with the payment method selected. please select different method to proceed',
    },
  },
  USER_SELECT_ADD_NEW_ON_UPDATE_PAYMENT_METHOD_WITH_ERROR: {
    event: 'click',
    clickName: 'payment type | add a payment',
    serviceDetails: {
      stepname: 'vital care pet plan details | update payment method',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_add_new_payment_method',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
    message: {
      error: 'there is a issue with the payment method selected. please select different method to proceed',
    },
  },
  USER_CLICK_CONTINUE_SUCCESS_ADD_NEW_ON_UPDATE_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'vital care pet plan details | update payment method',
      paymentStatus: 'success',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_add_new_payment_method_continue',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_CLICK_CONTINUE_FAIL_ADD_NEW_ON_UPDATE_PAYMENT_METHOD: {
    event: 'click',
    clickName: 'add new payment method | continue',
    serviceDetails: {
      stepname: 'vital care pet plan details | update payment method',
      paymentStatus: 'fail',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_pet_plan_details_add_new_payment_method_continue',
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
  },
  USER_CLICK_REACTIVATE_YOUR_PLAN: {
    event: 'click',
    clickName: 'vital care dashboard | reactivate message | reactivate your plan',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_reactivate_message_reactivate_plan',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'click here to reactivate plan',
        subformstepdetails: 'click here',
      },
    },
  },
  USER_CLICK_TERMS_AND_CONDITIONS_REACTIVATE_YOUR_PLAN: {
    event: 'click',
    clickName: 'vital care pet plan | reactivate message | terms and conditions',
    user: {
      deviceType: deviceNameStr,
    },
    modal: {
      name: 'reactivate your vital care plan',
    },
    ga: {
      gaEvent: 'vital_care_reactivate_message_terms',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'reactivate your vital care plan',
        subformstepdetails: 'terms and conditions',
      },
    },
  },
  USER_CLICK_TERMS_OF_USE_REACTIVATE_YOUR_PLAN: {
    event: 'click',
    clickName: 'vital care pet plan | reactivate message | terms of use',
    user: {
      deviceType: deviceNameStr,
    },
    modal: {
      name: 'reactivate your vital care plan',
    },
    ga: {
      gaEvent: 'vital_care_reactivate_message_terms_of_use',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'reactivate your vital care plan',
        subformstepdetails: 'terms of use',
      },
    },
  },
  USER_CLICK_PRIVACY_POLICY_REACTIVATE_YOUR_PLAN: {
    event: 'click',
    clickName: 'vital care pet plan | reactivate message| privacy policy',
    user: {
      deviceType: deviceNameStr,
    },
    modal: {
      name: 'reactivate your vital care plan',
    },
    ga: {
      gaEvent: 'vital_care_reactivate_message_privacy_policy',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'reactivate your vital care plan',
        subformstepdetails: 'privacy policy',
      },
    },
  },
  USER_CLICK_ON_REACTIVATE_REACTIVATE_YOUR_PLAN: {
    event: 'click',
    clickName: 'vital care pet plan | reactivate message | reactivate',
    user: {
      deviceType: deviceNameStr,
    },
    modal: {
      name: 'reactivate your vital care plan',
    },
    ga: {
      gaEvent: 'vital_care_reactivate_message_reactivate',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'reactivate your vital care plan',
        subformstepdetails: 'reactivate',
      },
    },
  },
  USER_CLICK_ON_REACTIVATE_PAYMENT_PLAN: {
    event: 'click',
    clickName: 'vital care pet plan | reactivate payment plan | continue',
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'select a payment plan',
        formstep: '2',
        subformname: 'select a payment plan proceed with reactivation',
        subformstepdetails: 'monthly',
      },
    },
    petInfo: [{
      petName: 'xyz',
      orignalPrice: '19.99',
      listPrice: '17.99',
    }],
    ga: {
      gaEvent: 'vital_care_reactivate_payment_plan',
    },
  },
  USER_CLICK_ON_CANCEL_REACTIVATE_YOUR_PLAN: {
    event: 'click',
    clickName: 'vital care pet plan | reactivate message | cancel',
    user: {
      deviceType: deviceNameStr,
    },
    modal: {
      name: 'reactivate your vital care plan',
    },
    ga: {
      gaEvent: 'vital_care_plan_reactivate_message_cancel',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'reactivate your vital care plan',
        subformstepdetails: 'cancel',
      },
    },
  },
  USER_LAND_ON_REACTIVATION_SUCCESS_PAGE: {
    event: 'pageView',
    page: {
      pageName: 'vital care pet plan details',
      pageType: 'vital care',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'pet_vital_care_plan_details',
    },
    message: {
      info: 'your plan has successfully reactivated',
      type: 'payment',
    },
  },
  USER_VIEWS_NCR_PAYMENT_ERROR_ON_EDIT_PAYMENT_METHOD_AND_UPDATE_CARD_FLOW: {
    event: 'modalView',
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: '',
        formstep: '1',
        subformname: 'payment info',
        subformstepdetails: 'na',
      },
    },
    ga: {
      gaEvent: 'modal_vital_care_pet_plan_details_payment',
    },
    message: {
      error: 'there is a issue with the payment method selected. please select different method to proceed',
      type: 'payment error',
    },
  },
  USER_LANDS_ON_PAYMENT_RELATED_ISSUE_MODAL: {
    event: 'modalView',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'modal_vital_care_error',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'submit payment',
        formstep: '5',
        subformname: 'payment error',
        subformstepdetails: 'payment declined',
      },
    },
    message: {
      title: 'unable to create membership plan. please try again.',
      error: 'payment declined',
      type: 'payment error',
    },
  },
  USER_LANDS_ON_NON_PAYMENT_RELATED_ERROR_MODAL: {
    event: 'modalView',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'modal_vital_care_error',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'submit payment',
        formstep: '5',
        subformname: 'non-payment error',
        subformstepdetails: 'generic error',
      },
    },
    message: {
      title: 'session expired',
      error: 'you went idle and your session has expired',
      type: 'generic error',
    },
  },
  USER_LANDS_ON_TECHNICAL_ERROR_MODAL: {
    event: 'modalView',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'modal_vital_care_error',
    },
    formDetails: {
      form: {
        formname: 'vital care',
        formstepname: 'error modal',
        formstep: '5',
        subformname: 'technical error',
        subformstepdetails: 'some thing went wrong',
      },
    },
    message: {
      title: 'uh-oh... something went wrong',
      error: 'We\'re experiencing a technical issue.',
      type: 'technical error',
    },
  },
  USER_LANDS_ON_GENERIC_ERROR_MODAL: {
    event: 'modalView',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'modal_vital_care_error',
    },
    formDetails: {
      form: {
        formname: 'vital care',
        formstepname: 'error modal',
        formstep: '5',
        subformname: 'generic error',
        subformstepdetails: 'generic error',
      },
    },
    message: {
      title: '',
      error: '',
      type: 'generic error',
    },
  },
  USER_SELECTS_MORE_THAN_SIX_PETS_ERROR_MODAL: {
    event: 'modalView',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'modal_vital_care_error',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'enroll a pet',
        formstep: '1',
        subformname: 'less pet selection',
        subformstepdetails: 'pet selection must be 6 or less',
      },
    },
    message: {
      title: 'uh-oh... something went wrong',
      error: 'pet selection must be 6 or less',
      type: 'less pet selection',
    },
  },
  USER_LANDS_ON_DASHBOARD_PAGE_WITH_ROVER_BANNER: {
    event: 'pageView',
    page: {
      pageName: 'vital care dashboard',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_dashboard',
    },
    message: {
      info: 'You have a new Rover benefit available!',
    },
  },
  USER_CLICKS_ON_HERE_HYPERLINK_ON_DASHBOARD_PAGE_WITH_ROVER_BANNER: {
    event: 'click',
    clickName: 'vital care dashboard | rover banner | click here',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_rover_banner_click_here',
    },
  },
  USER_CLICKS_CLOSE_ICON_ON_DASHBOARD_PAGE_WITH_ROVER_BANNER: {
    event: 'click',
    clickName: 'vital care dashboard | rover banner | cancel',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_rover_banner_cancel',
    },
  },
  USER_LANDS_ON_REDEEM_BENEFITS_PAGE: {
    event: 'pageView',
    page: {
      pageName: 'vital care redeem benefits',
      pageType: 'vital care',
      buildVersion: 'version',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_redeem_benefits',
    },
  },
  USER_CLICKS_ON_GET_MY_CODE_CTA_ON_THE_ROVER_BENEFIT: {
    event: 'click',
    clickName: 'vital care redeem benefits | rover card | get my code',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_rover_card_get_code',
    },
  },
  USER_SELECTS_VITAL_CARE_PREMIER_TERMS_AND_CONDITIONS_CHECKBOX_ON_FINISH_YOUR_PURCHASE_STEP: {
    event: 'click',
    clickName: 'vital care | checkout | tnc checkbox',
    serviceDetails: {
      stepname: 'complete your purchase | checkbox',
    },
    user: {
      deviceType: deviceNameStr,
    },
    optionSelected: {
      checkboxes: ['vital care premier tc'],
    },
    ga: {
      gaEvent: 'vc_checkout_tnc_checkbox',
    },
  },
  USER_DESELECTS_VITAL_CARE_PREMIER_TERMS_AND_CONDITIONS_CHECKBOX_ON_FINISH_YOUR_PURCHASE_STEP: {
    event: 'click',
    clickName: 'vital care | checkout | tnc checkbox',
    serviceDetails: {
      stepname: 'complete your purchase | checkbox',
    },
    user: {
      deviceType: deviceNameStr,
    },
    optionUnCheckedByCx: {
      checkboxes: ['vital care premier tc'],
    },
    ga: {
      gaEvent: 'vc_checkout_tnc_checkbox',
    },
  },
  USER_SELECTS_12_MONTH_COMMITMENT_CHECKBOX_ON_FINISH_YOUR_PURCHASE_STEP: {
    event: 'click',
    clickName: 'vital care | checkout | 12 months commitment checkbox',
    serviceDetails: {
      stepname: 'complete your purchase | checkbox',
    },
    user: {
      deviceType: deviceNameStr,
    },
    optionSelected: {
      checkboxes: ['vital care premier 12 months commitment'],
    },
    ga: {
      gaEvent: 'vc_checkout_12m_commitment_checkbox',
    },
  },
  USER_DESELECTS_12_MONTH_COMMITMENT_CHECKBOX_ON_FINISH_YOUR_PURCHASE_STEP: {
    event: 'click',
    clickName: 'vital care | checkout | 12 months commitment checkbox',
    serviceDetails: {
      stepname: 'complete your purchase | checkbox',
    },
    user: {
      deviceType: deviceNameStr,
    },
    optionUnCheckedByCx: {
      checkboxes: ['vital care premier 12 months commitment'],
    },
    ga: {
      gaEvent: 'vc_checkout_12m_commitment_checkbox',
    },
  },
  USER_SELECTS_EXISTING_CARD_AND_CLICK_ON_SAVE_UNDER_PAYMENT_STEP_DURING_SIGNUP: {
    event: 'click',
    clickName: 'payment type | select existing payment | save',
    serviceDetails: {
      stepname: 'complete your purchase | payment',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_select_existing__payment_save',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'select existing payment',
        subformstepdetails: 'save',
      },
    },
  },
  USER_SELECTS_EXISTING_CARD_AND_EXPERIENCES_ERROR_RELATED_TO_PAYMENT_DECLINED_OR_FAILURE_DURING_SIGNUP: {
    event: 'click',
    clickName: 'checkout | submit payment',
    serviceDetails: {
      stepname: 'complete your purchase | submit payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_submit_payment',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'error screen',
        formstep: '5',
        subformname: 'error screen',
        subformstepdetails: 'submit payment',
      },
    },
    message: {
      error: '<payment decline/failure. please try again or use another card.>',
      type: 'payment error',
      errorCode: '500',
    },
  },
  USER_CLICKS_ON_ADD_NEW_CREDIT_CARD_UNDER_PAYMENT_STEP_DURING_SIGNUP: {
    event: 'click',
    clickName: 'payment type | add new credit card',
    serviceDetails: {
      stepname: 'complete your purchase | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_add_new_credit_card',
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'add card',
        subformstepdetails: 'add new credit card',
      },
    },
  },
  USER_ADD_NEW_CARD_DURING_MEMBERSHIP_SIGNUP_SUCCESS: {
    event: 'click',
    clickName: 'add new payment method | add card',
    serviceDetails: {
      stepname: 'complete your purchase | payment',
      paymentStatus: 'success',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup:complete purchase',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'add new payment method',
        subformstepdetails: 'add card',
      },
    },
    ga: {
      gaEvent: 'vital_care_signup_add_new_payment_addcard',
    },
    message: {
      info: 'card added successfully',
    },
  },
  USER_ADD_NEW_CARD_DURING_MEMBERSHIP_SIGNUP_ERROR: {
    event: 'click',
    clickName: 'add new payment method | add card',
    serviceDetails: {
      stepname: 'complete your purchase | payment',
      paymentStatus: 'fail',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup:complete purchase',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'add new payment method',
        subformstepdetails: 'add card',
      },
    },
    ga: {
      gaEvent: 'vital_care_signup_add_new_payment_addcard',
    },
    message: {
      error: 'payment decline/failure. please try again or use another card.',
      type: 'payment error',
      errorCode: '500',
    },
  },
  USER_CLICKS_ON_CLOSE_ICON_BUTTON_ON_ADD_A_NEW_CREDIT_CARD_MODAL_DURING_SIGNUP: {
    event: 'click',
    clickName: 'add new payment method | cancel',
    serviceDetails: {
      stepname: 'complete your purchase | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup:complete purchase',
        formstepname: 'payment info',
        formstep: '5',
        subformname: 'add new card',
        subformstepdetails: 'cancel',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method_cancel',
    },
  },
  USER_SELECTS_EXISTING_CARD_DURING_REACTIVATION: {
    event: 'click',
    clickName: 'payment type | select existing payment',
    serviceDetails: {
      stepname: 'reactivate | payment',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_select_existing_payment',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add credit card',
        subformstepdetails: 'select existing payment',
      },
    },
  },
  USER_SELECTS_EXISTING_CARD_AND_CLICK_ON_SAVE_DURING_REACTIVATION: {
    event: 'click',
    clickName: 'payment type | select existing payment | save',
    serviceDetails: {
      stepname: 'reactivate | payment',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_select_existing_payment_save',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'select existing payment',
        subformstepdetails: 'save',
      },
    },
  },
  USER_CLICKS_ON_ADD_NEW_CREDIT_CARD_UNDER_ADD_CREDIT_CARD_MODAL_DURING_REACTIVATION: {
    event: 'click',
    clickName: 'payment type | add new credit card',
    serviceDetails: {
      stepname: 'reactivate | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_add_new_credit_card',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add credit card',
        subformstepdetails: 'add new credit card',
      },
    },
  },
  USER_SUCCESSFULLY_ADDED_NEW_CREDIT_CARD_DURING_REACTIVATION: {
    event: 'click',
    clickName: 'add new payment method | save',
    serviceDetails: {
      stepname: 'reactivate | payment',
      paymentStatus: 'success',
      orderPaymentType: '<Visa>',
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vital_care_add_new_card_save',
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add new credit card',
        subformstepdetails: 'save',
      },
    },
    message: {
      info: 'card added successfully',
    },
  },
  USER_CLICKS_ON_CLOSE_ICON_BUTTON_ON_ADD_A_NEW_CREDIT_CARD_MODAL_DURING_REACTIVATION: {
    event: 'click',
    clickName: 'add credit card |  cancel',
    serviceDetails: {
      stepname: 'reactivate | payment',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'payment info',
        formstep: '1',
        subformname: 'add credit card',
        subformstepdetails: 'cancel',
      },
    },
    ga: {
      gaEvent: 'vital_care_add_new_payment_method_cancel',
    },
  },
});

export const adobeAnalyticsTagsAPU = Object.freeze({
  USER_CLICK_ON_MONTHLY_PLAN_TAB: {
    event: 'click',
    clickName: 'vital care | select a payment plan | monthly',
    serviceDetails: {
      stepName: 'vital care | select a payment plan',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'select a payment plan',
        formstep: '2',
        subformname: 'select a payment plan',
        subformstepdetails: 'monthly',
      },
    },
    ga: {
      gaEvent: 'vital_care_select_a_payment_plan_monthly',
    },
  },
  USER_CLICK_ON_YEARLY_PLAN_TAB: {
    event: 'click',
    clickName: 'vital care | select a payment plan | yearly',
    serviceDetails: {
      stepName: 'vital care | select a payment plan',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'select a payment plan',
        formstep: '2',
        subformname: 'select a payment plan',
        subformstepdetails: 'yearly',
      },
    },
    ga: {
      gaEvent: 'vital_care_select_a_payment_plan_yearly',
    },
  },
  USER_CLICK_ON_WHAT_IS_THIS_LINK: {
    event: 'click',
    clickName: 'vital care | select a payment plan | what\'s this link',
    serviceDetails: {
      stepName: 'vital care | select a payment plan',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'select a payment plan',
        formstep: '2',
        subformname: 'select a payment plan',
        subformstepdetails: 'what\'s this link',
      },
    },
    ga: {
      gaEvent: 'vital_care_select_a_payment_plan_what\'s_this_link',
    },
  },
  USER_CLICK_ON_CONTINUE_YEARLY: {
    event: 'click',
    clickName: 'vital care | yearly | continue',
    serviceDetails: {
      stepName: 'vital care | select a payment plan',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'select a payment plan',
        formstep: '2',
        subformname: 'select a payment plan',
        subformstepdetails: 'yearly continue',
      },
    },
    ga: {
      gaEvent: 'vital_care_select_a_payment_plan_continue',
    },
  },
  USER_CLICK_ON_CONTINUE_MONTHLY: {
    event: 'click',
    clickName: 'vital care | monthly | continue',
    serviceDetails: {
      stepName: 'vital care | select a payment plan',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'select a payment plan',
        formstep: '2',
        subformname: 'select a payment plan',
        subformstepdetails: 'monthly continue',
      },
    },
    ga: {
      gaEvent: 'vital_care_select_a_payment_plan_continue',
    },
  },
  USER_CLICK_ON_BACK: {
    event: 'click',
    clickName: 'vital care | select a payment plan | back',
    serviceDetails: {
      stepName: 'vital care | select a payment plan',
    },
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'membership signup',
        formstepname: 'select a payment plan',
        formstep: '2',
        subformname: 'select a payment plan',
        subformstepdetails: 'back',
      },
    },
    ga: {
      gaEvent: 'vital_care_select_payment_plan_back',
    },
  },
  REACTIVATE_USER_CLICK_ON_SELECTS_PETS_AND_CONFIRM: {
    event: 'click',
    clickName: 'apply updated payment method to other plans | confirm',
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'apply updated payment method to other plans',
        formstep: '2',
        subformname: 'select pets',
        subformstepdetails: 'confirm',
      },
    },
    optionSelected: {
      petSpecies: ['<dog>', '<cat>'],
    },
    ga: {
      gaEvent: 'vc_select_pet_payment_confirm',
    },
  },
  REACTIVATE_USER_CLICK_ON_SELECTS_PETS_AND_CLOSE: {
    event: 'click',
    clickName: 'apply updated payment method to other plans | close',
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care reactivate plan',
        formstepname: 'apply updated payment method to other plans',
        formstep: '2',
        subformname: 'select pets',
        subformstepdetails: 'close',
      },
    },
    ga: {
      gaEvent: 'vc_select_pet_payment_close',
    },
  },
  GRACE_PERIOD_USER_CLICK_ON_SELECTS_PETS_AND_CONFIRM: {
    event: 'click',
    clickName: 'apply updated payment method to other plans | confirm',
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'apply updated payment method to other plans',
        formstep: '2',
        subformname: 'select pets',
        subformstepdetails: 'confirm',
      },
    },
    optionSelected: {
      petSpecies: ['<dog>', '<cat>'],
    },
    ga: {
      gaEvent: 'vc_select_pet_payment_confirm',
    },
  },
  GRACE_PERIOD_USER_CLICK_ON_SELECTS_PETS_AND_CLOSE: {
    event: 'click',
    clickName: 'apply updated payment method to other plans | close',
    formDetails: {
      form: {
        formname: 'grace period',
        formstepname: 'apply updated payment method to other plans',
        formstep: '2',
        subformname: 'select pets',
        subformstepdetails: 'close',
      },
    },
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'vc_select_pet_payment_close',
    },
  },
  EDIT_YOUR_CARD_USER_CLICK_ON_SELECTS_PETS_AND_CONFIRM: {
    event: 'click',
    clickName: 'apply updated payment method to other plans | confirm',
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'edit payment method',
        formstep: '2',
        subformname: 'apply updated payment method to other plans',
        subformstepdetails: 'confirm',
      },
    },
    optionSelected: {
      petSpecies: ['<dog>', '<cat>'],
    },
    ga: {
      gaEvent: 'vc_select_pet_payment_confirm',
    },
  },
  EDIT_YOUR_CARD_USER_CLICK_ON_SELECTS_PETS_AND_CLOSE: {
    event: 'click',
    clickName: 'apply updated payment method to other plans | close',
    user: {
      deviceType: deviceNameStr,
    },
    formDetails: {
      form: {
        formname: 'vital care pet plan details',
        formstepname: 'edit payment method',
        formstep: '2',
        subformname: 'apply updated payment method to other plans',
        subformstepdetails: 'close',
      },
    },
    ga: {
      gaEvent: 'vc_select_pet_payment_close',
    },
  },
  USER_CLICKS_ON_SWITCH_NOW_BUTTON_ON_SWITCH_NOW_BANNER: {
    event: 'click',
    clickName: 'vital care dashboard | switch now',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'click_vcboard_switch_now',
    },
  },
  USER_CLICKS_ON_LEARN_MORE_ON_SWITCH_NOW_BANNER: {
    event: 'click',
    clickName: 'vital care dashboard | learn more',
    user: {
      deviceType: deviceNameStr,
    },
    ga: {
      gaEvent: 'click_vcboard_learn_more',
    },
  },
  USER_CLICKS_ON_CONFIRM_ON_CHANGE_YOUR_PAYMENT_PLAN_MODAL: {
    event: 'click',
    clickName: 'vital care dashboard | change your payment plan modal | confirm',
    user: {
      deviceType: deviceNameStr,
    },
    modal: {
      name: 'change your payment plan',
    },
    formDetails: {
      form: {
        formname: 'account',
        formstepname: 'vital care dashboard',
        formstep: '1',
        subformname: 'change your payment plan',
        subformstepdetails: 'confirm',
      },
    },
    ga: {
      gaEvent: 'click_vcboard_change_urpay_plan_confirm',
    },
  },
  USER_CLICKS_ON_CANCEL_OR_CLOSEICON_ON_CHANGE_YOUR_PAYMENT_PLAN_MODAL: {
    event: 'click',
    clickName: 'vital care dashboard | change your payment plan modal | cancel',
    user: {
      deviceType: deviceNameStr,
    },
    modal: {
      name: 'change your payment plan',
    },
    formDetails: {
      form: {
        formname: 'account',
        formstepname: 'vital care dashboard',
        formstep: '1',
        subformname: 'change your payment plan',
        subformstepdetails: 'cancel',
      },
    },
    ga: {
      gaEvent: 'click_vcboard_change_urpay_plan_cancel',
    },
  },
});

export const transactionType = Object.freeze({
  SIGNUP: 'signup',
  REACTIVATION: 'reactivation',
  RENEWAL: 'renewal',
  paymentErrorMessage: 'There is an issue with the payment method selected. Please select a different payment method to proceed.',
});

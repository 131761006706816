import React, { 
  useEffect, 
  useState, 
  useRef, 
  useCallback,
} from 'react';
import Select from 'react-select';
import { deviceName } from 'utils/constGlobal';
import './credit-card-combo-box.css';

const addANewOptionValue = 'ADD_A_NEW_CARD';
const paymentMethodInitial = 'Add or Select Payment';

const addNewOption = {
  key: 0,
  value: addANewOptionValue,
  label: 'Add new payment method',
};

const getSelectedPaymentMethod = (paymentMethods) => paymentMethods && paymentMethods.find && (paymentMethods.find(
  (paymentMethod) => paymentMethod.isSelected,
) || {}).mbrPayinfoId;

const CreditCardCombobox = ({
  id,
  name,
  paymentMethods,
  onChange,
  onSelectAddNewCard,
  selectedOption,
  onBlur,
  isSearchable = true,
}) => {
  const paymentMethodOptions = [...paymentMethods.map(
    (paymentMethod) => ({
      key: paymentMethod.mbrPayinfoId,
      value: paymentMethod.mbrPayinfoId,
      label: `${paymentMethod.brand} ending in ${paymentMethod.lastFour}`,
    }),
  ), addNewOption];
  const selectedPaymentMethod = getSelectedPaymentMethod(paymentMethods);
  useEffect(() => {
    if (selectedPaymentMethod) {
      onChange(name, selectedPaymentMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod]);
  const isMobile = deviceName() === 'mobile app';

  const dropdownRef = useRef();
  useEffect(() => dropdownRef.current && dropdownRef.current.focus());

  const handleChange = (option) => {
    let optionValue = option?.target?.value;
    if (optionValue === undefined || optionValue !== addANewOptionValue) {
      optionValue = option.value;
    }
    if (optionValue === addANewOptionValue) {
      onSelectAddNewCard();
    } else if (isMobile) {
      // option is actualy the event
      onChange(name, option.target.value);
    } else {
      onChange(name, option.value);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(name, true);
    }
  };

  const selectedOptionEvaluated = paymentMethodOptions.find((op) => {
    // avoid breaking Selects that aren't set to have an initial value
    if (selectedOption !== undefined) {
      if (typeof op.value === 'string') {
        return op.value.toUpperCase() === selectedOption.toUpperCase();
      }
      return op.value === selectedOption;
    } else {
      return undefined;
    }
  });

  const selectedValue = selectedOptionEvaluated ? selectedOptionEvaluated.value : paymentMethodInitial;
  
  return (
    <>

      {isMobile
        && (
          <select
            id={id}
            ref={dropdownRef}
            value={selectedValue}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-label="Add or select payment method dropdown button"
            tabIndex="0"
          >
            <option value={paymentMethodInitial} disabled>{paymentMethodInitial}</option>
            {
              paymentMethodOptions.map((option) => (
                <option tabIndex="0" value={option.value} key={option.key}>{option.label}</option>
              ))
            }
          </select>
        )}
      {!isMobile
        && (
          // Wrapper Div with the role of "listbox" added for accessibility
          <div role="listbox">
            <Select
              id={id}
              ref={dropdownRef}
              className="react-select"
              aria-label="Add or select payment method dropdown button"
              isSearchable={isSearchable}
              placeholder={paymentMethodInitial}
              onChange={handleChange}
              onBlur={handleBlur}
              options={paymentMethodOptions}
              // from the docs https://github.com/JedWatson/react-select#further-options,
              // on installation and usage, if you want to set an initial value on a
              // Select from react-select, use value, not defaultValue
              value={selectedOptionEvaluated || ''}
            />
          </div>
        )}
    </>
  );
};

export default CreditCardCombobox;

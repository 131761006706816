import React from 'react';
import { Button } from 'components-v2/shared/Button';

import './claims-clinic-selection.css';
import { formatPhone, formatToTitleCase } from 'utils';

const ClaimClinicSelectionCard = ({
  clinic,
  onChange,
  noPaddingTop,
}) => (
  <div className="item-wrapper" id={noPaddingTop ? 'no-padding-top' : ''}>
    <div className="item-content">
      <div className="item-title-container">
        <h3 className="item-title">{formatToTitleCase(clinic?.name)}</h3>
        <p className="item-description item-description--nowrap">{!Number.isNaN(parseInt(clinic?.distance, 10)) && `${clinic?.distance?.toFixed(1)} mi`}</p>
      </div>
      <p className="item-description">
        {formatToTitleCase(clinic?.address)}
      </p>
      <p className="item-description">
        {formatToTitleCase(clinic?.city)} {formatToTitleCase(clinic?.state)} {clinic?.zipCode}
      </p>
      <p className="item-description item-description-phone">
        {formatPhone(clinic?.phoneNumber)}
      </p>
    </div>
    {/* <Button
      label="SELECT VET"
      className="select-vet-button"
      onClick={() => onChange(clinic)}
      aria-label={`select vet ${clinic?.name}`}
    /> */}
    {/* below is a workaround solution to stop button from autofocusing post render after entering something in the input  */}
    <div
      className="select-vet-button-div-parent"
      onClick={(e) => {
        e.preventDefault();
        onChange(clinic);
      }}
      onBlur={(e) => e.preventDefault()}
      aria-label={`select vet ${clinic?.name}`}
      tabIndex="0"
    >
      SELECT VET
    </div>
  </div>
);

export default ClaimClinicSelectionCard;

import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { handleKeyUp, trackEvent } from 'utils';

import './pet-list-grid.css';
import { dashboardInfo } from 'store/slices';
import { adobeAnalyticsTags } from 'utils/constGlobal';
import Icon from 'components-v2/shared/Icon/Icon';

const PetListGrid = ({
  selectedPlanWithPet = {}, choosePet, plansWithPets = [], screenName, petTypes, chooseTab, selectedTab, toggles,
}) => {
  const selectedPetMasterId = selectedPlanWithPet.petMasterId;
  const dispatch = useDispatch();

  const handleMoveTabsWithKeys = (e) => {
    const tabList = e.target.parentElement;
    const index = [...tabList.children].indexOf(e.target);
    const lastIndex = tabList.children.length - 1;
    switch (e.key) {
      case 'ArrowLeft':
        if (index !== 0) tabList.children[index - 1].focus();
        break;

      case 'ArrowRight':
        if (index !== lastIndex) tabList.children[index + 1].focus();
        break;

      default:
        break;
    }
  };

  const handleChoosePet = (planWithPet) => {
    trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_PET_TOGGLE_V2);
    if (choosePet && selectedPetMasterId !== planWithPet.petMasterId) {
      choosePet(planWithPet.petMasterId);
      dispatch(dashboardInfo.actions.clearReactivationSuccessMessage());
      dispatch(dashboardInfo.actions.clearAutoRenewSuccessMessage());
      dispatch(dashboardInfo.actions.clearRenewalSuccessMessage());
      dispatch(dashboardInfo.actions.clearOnMembershipPaymentMethodUpdateError({ occurred: false, message: null }));
    }
  };

  const handlePetIcon = (petType) => {
    if (petType === 'Dog') {
      return <Icon name="dog" size={43} />;
    } else if (petType === 'Bird') {
      return <Icon name="bird" size={43} />;
    } else if (petType === 'Reptile') {
      return <Icon name="reptile" size={43} />;
    } else if (petType === 'Fish') {
      return <Icon name="fish" size={43} />;
    } else if (petType === 'Small Animal') {
      return <Icon name="smallAnimal" size={43} />;
    } else if (petType === 'Cat') {
      return <Icon name="cat" size={40} />;
    } else {
      return <Icon name="pet-selection-ruff-mews" size={40} />;
    }
  };

  const handleSellScreenPetIcon = (petType, selectedTab) => {
    if (petType === 'Dog') {
      return selectedTab === 'Dog' ? <Icon name="sellScreenDogSelected" size={43} /> : <Icon name="sellScreenDog" size={43} />;
    } else if (petType === 'Bird') {
      return selectedTab === 'Bird' ? <Icon name="sellScreenBirdSelected" size={43} /> : <Icon name="sellScreenBird" size={43} />;
    } else if (petType === 'Reptile') {
      return selectedTab === 'Reptile' ? <Icon name="sellScreenReptileSelected" size={43} /> : <Icon name="sellScreenReptile" size={43} />;
    } else if (petType === 'Fish') {
      return selectedTab === 'Fish' ? <Icon name="sellScreenFishSelected" size={43} /> : <Icon name="sellScreenFish" size={43} />;
    } else if (petType === 'Small Pet') {
      return selectedTab === 'Small Pet' ? <Icon name="sellScreenSmallAnimalSelected" size={43} /> : <Icon name="sellScreenSmallAnimal" size={43} />;
    } else {
      return selectedTab === 'Cat' ? <Icon name="sellScreenCatSelected" size={40} /> : <Icon name="sellScreenCat" size={40} />;
    }
  };

  return (
    <div>
      {screenName !== 'Sell_Screen' ? (
        <div className="PetListGrid-v2" role="tablist">
          {plansWithPets.map((planWithPet) => (
            <div
              className={`PetListGrid__Item ${selectedPetMasterId === planWithPet.petMasterId && 'PetListGrid__Item--Marked'}`}
              // Todo: eliminate this
              // eslint-disable-next-line react/no-unknown-property
              as="button"
              role="tab"
              type="button"
              aria-selected={selectedPetMasterId === planWithPet.petMasterId ? 'true' : 'else'}
              tabIndex="0"
              aria-label={`pet ${planWithPet.name}`}
              key={planWithPet.petMasterId}
              onClick={() => handleChoosePet(planWithPet)}
              onKeyUp={(e) => handleKeyUp(e, () => handleChoosePet(planWithPet))}
              onKeyDown={(e) => handleMoveTabsWithKeys(e)}
              data-testid={planWithPet.name}
            >

              {toggles?.ENABLE_DASHBOARD_BADGE && (planWithPet.plan.status === 'expired' || planWithPet.plan.status === 'canceled')
                ? (
                  <div className="badge">
                    <span className="errorBadge"><Icon name="errorIcon" /></span>
                  </div>
                )
                : null}

              {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? (
                <div className={`PetListGrid__IconWrapper ${selectedPetMasterId === planWithPet.petMasterId && 'UM__PetListGrid__IconWrapper--Marked'}`}>
                  {handlePetIcon(planWithPet?.pet?.petType || '')}
                </div>
              ) : (
                <div className={`PetListGrid__IconWrapper ${selectedPetMasterId === planWithPet.petMasterId && 'PetListGrid__IconWrapper--Marked'}`}>
                  {handlePetIcon(planWithPet?.pet?.petType)}
                </div>
              )}
              <h2 className="petNameHeader-v2">{planWithPet.name}</h2>
            </div>
          ))}
        </div>
      ) : (
        <div className="PetListGrid-v2 SellScreen-v2">
          {petTypes.map((petType) => (
            <div
              className={`PetListGrid__Item ${selectedTab === petType && 'PetListGrid__Item--Marked'}`}
              // Todo: fix this
              // eslint-disable-next-line react/no-unknown-property
              as="button"
              role="button"
              aria-label={`${petType} plan icon`}
              tabIndex={selectedTab === petType ? '0' : '-1'}
              key={petType}
              onClick={() => { chooseTab(petType); trackEvent(adobeAnalyticsTags?.USER_CLICK_ON_PET_TOGGLE_V2); }}
              data-testid={petType}
            >
              {
                toggles?.ENABLE_UNIFIED_MEMBERSHIP ? (
                  <div
                    className={`PetListGrid__IconWrapper sellScreenIcon_wrapper ${selectedTab === petType && 'PetListGrid__IconWrapper--Marked UM_IconWrapper'}`}
                  >
                    {handleSellScreenPetIcon(petType, selectedTab)}
                  </div>
                ) : (
                  <div
                    className={`PetListGrid__IconWrapper sellScreenIcon_wrapper ${selectedTab === petType && 'PetListGrid__IconWrapper--Marked'}`}
                  >
                    {handleSellScreenPetIcon(petType, selectedTab)}
                  </div>
                )
              }
              <h2 className="petNameHeader-v2">{petType.toUpperCase()}</h2>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default connect(() => ({}))(PetListGrid);

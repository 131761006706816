import React from 'react';
import './container.css';

const Container = ({ children, className, ...rest }) => {
  let classNames = 'Container';

  if (className) classNames += ` ${className}`;

  return (
    <div className={classNames} {...rest}>{children}</div>
  );
};

export default Container;

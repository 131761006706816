/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';

import { closestToCurrentView } from 'utils';
import { navigationCategories } from 'utils/constCategories';
import { navigationSubcategories } from 'utils/constNavigationSubcategories';

// key must be the same as the objects variable name
export const VIEW_INDEX = {
  // TODO: pet selection screen equals to pro care pet selection
  // need to change that to just pet selection after v1 is gone
  // right now v1 and v2 uses the same navigation object, don't
  // want to touch that
  PET_SELECTION: {
    step: 0,
    name: '',
    category: navigationCategories.REGISTRATION,
    subcategory: '',
    key: 'PET_SELECTION',
  },
  PET_ADDITION_FORM: {
    step: 30,
    name: '',
    category: navigationCategories.REGISTRATION,
    subcategory: '',
    key: 'PET_ADDITION_FORM',
  },
  NAME: {
    step: 1,
    name: 'Pet Name',
    category: navigationCategories.REGISTRATION,
    subcategory: navigationSubcategories.PET_CREATION,
    key: 'NAME',
  },
  TYPE: {
    step: 2,
    name: 'Pet Type',
    category: navigationCategories.REGISTRATION,
    subcategory: navigationSubcategories.PET_CREATION,
    key: 'TYPE',
  },
  BREED: {
    step: 3,
    name: 'Pet Breed',
    category: navigationCategories.REGISTRATION,
    subcategory: navigationSubcategories.PET_CREATION,
    key: 'BREED',
  },
  BIRTHDAY: {
    step: 4,
    name: 'Birthday',
    category: navigationCategories.REGISTRATION,
    subcategory: navigationSubcategories.PET_CREATION,
    key: 'BIRTHDAY',
  },
  TOTAL_PET_STEPS: 4,
  SELL_SCREEN: {
    step: 6,
    name: '',
    category: navigationCategories.REGISTRATION,
    subcategory: '',
    key: 'SELL_SCREEN',
  },
  CHOOSE_YOUR_PLAN: {
    step: 25,
    name: 'Choose Your Plan',
    category: navigationCategories.REGISTRATION,
    subcategory: '',
    key: 'CHOOSE_YOUR_PLAN',
  },
  COMPLETE_YOUR_PURCHASE: {
    step: 12,
    name: 'Complete Your Purchase',
    category: navigationCategories.REGISTRATION,
    subcategory: '',
    key: 'COMPLETE_YOUR_PURCHASE',
  },
  PURCHASE_SUCCESS: {
    step: 13,
    name: 'Puchase Success',
    category: navigationCategories.REGISTRATION,
    subcategory: '',
    key: 'PURCHASE_SUCCESS',
  },

  REDEMPTION_DASHBOARD: {
    step: 14,
    name: 'Redemption Dashboard',
    category: navigationCategories.REDEMPTION,
    subcategory: '',
    key: 'REDEMPTION_DASHBOARD',
  },

  INITIAL_LOAD: {
    step: 15,
    name: 'CHECKING FOR MEMBERSHIPS',
    category: '',
    subcategory: '',
    key: 'INITIAL_LOAD',
  },

  REDEEM_BENEFITS: {
    step: 16,
    name: 'Redeem Benefit',
    category: navigationCategories.REDEMPTION,
    subcategory: '',
    key: 'REDEEM_BENEFITS',
  },
  BENEFIT_USAGE_HISTORY: {
    step: 17,
    name: 'Benefit Usage History',
    category: navigationCategories.REDEMPTION,
    subcategory: '',
    key: 'BENEFIT_USAGE_HISTORY',
  },
  MEMBERSHIP_DETAILS: {
    step: 18,
    name: 'MEMBERSHIP_DETAILS',
    category: navigationCategories.REDEMPTION,
    subcategory: '',
    key: 'MEMBERSHIP_DETAILS',
  },
  CONTACT_CUSTOMER_CARE: {
    step: 19,
    name: 'Contact Customer Care',
    category: navigationCategories.REDEMPTION,
    subcategory: '',
    key: 'CONTACT_CUSTOMER_CARE',
  },
  CLAIMS_CLINIC_SELECTION: {
    step: 20,
    name: 'CLAIMS_CLINIC_SELECTION',
    category: navigationCategories.CLAIM,
    subcategory: '',
    key: 'CLAIMS_CLINIC_SELECTION',
  },
  CLAIMS_DATE: {
    step: 21,
    name: 'claims date',
    category: navigationCategories.CLAIM,
    subcategory: '',
    key: 'CLAIMS_DATE',
  },
  CLAIMS_VET_INVOICE: {
    step: 22,
    name: 'claims vet invoice',
    category: navigationCategories.CLAIM,
    subcategory: '',
    key: 'CLAIMS_VET_INVOICE',
  },
  CLAIMS_REVIEW: {
    step: 23,
    name: 'review claim',
    category: navigationCategories.CLAIM,
    subcategory: '',
    key: 'CLAIMS_REVIEW',
  },
  CLAIMS_THANK_YOU: {
    step: 24,
    name: 'CLAIMS_THANK_YOU',
    category: navigationCategories.CLAIM,
    subcategory: '',
    key: 'CLAIMS_THANK_YOU',
  },
};

const getViewState = (key) => ({
  ...VIEW_INDEX[key],
  key,
});

const navigateTo = (key, replace = false) => (state) => {
  const { current, history } = state;
  if (key === history[current].key) return state;

  let newCurrent = current;
  newCurrent += replace ? 0 : 1;

  window.history[replace
    ? 'replaceState'
    : 'pushState'
  ]({ current: newCurrent, key }, `Membership page #${key}`, `#${key}`);

  window.parent.postMessage({ action: 'scrollTop' }, '*');
  return {
    current: newCurrent,
    history: [
      ...history.slice(0, newCurrent),
      getViewState(key),
    ],
  };
};

const navigateBackTo = (to) => (state) => {
  const { history } = state;
  const closestTargetView = closestToCurrentView(to, state);
  console.log(`History Debug: ${JSON.stringify(closestTargetView)}`);

  const newCurrent = closestTargetView.index;
  const newHistory = [...history.slice(0, newCurrent + 1)]; // +1 so it is inclusive
  console.log(`History Debug: ${JSON.stringify(newHistory)}`);

  return {
    current: newCurrent,
    history: newHistory,
  };
};

// Takes categories or keys and remove all items that have either of those
const purgeFromHistory = (keys) => (state) => {
  const { current, history } = state;

  const currentView = history[current];

  const newHistory = history.filter((historyItem) => !keys.includes(historyItem.category)
    && !keys.includes(historyItem.key));

  const newCurrent = newHistory.findIndex((historyItem) => historyItem === currentView);

  // if current has changed we need to update window history
  if (newCurrent !== current) {
    window.history.replaceState(
      { current: newCurrent, key: currentView.key },
      `Membership page #${currentView.key}`, `#${currentView.key}`,
    );
  }

  return {
    current: newCurrent,
    history: newHistory,
  };
};

const navigationHistory = createSlice({
  name: 'navigationHistory',
  initialState: {
    current: 0,
    history: [getViewState('INITIAL_LOAD')],
  },
  reducers: {
    setCurrent: (state, action) => ({
      ...state,
      current: action.payload,
    }),
    navigateTo: (state, action) => navigateTo(action.payload)(state, action),
    navigateBackTo: (state, action) => navigateBackTo(action.payload)(state, action),
    purgeFromHistory: (state, action) => purgeFromHistory(action.payload)(state, action),
    goToSellScreen: navigateTo(VIEW_INDEX.SELL_SCREEN.key, true),
    goToPetSelection: navigateTo(VIEW_INDEX.PET_SELECTION.key),
    goToPetCreationPage: navigateTo(VIEW_INDEX.PET_ADDITION_FORM.key),
    goBackToPetSelection: navigateBackTo(VIEW_INDEX.PET_SELECTION.key),
    purgePetSelection: purgeFromHistory([VIEW_INDEX.PET_SELECTION.key]),
    purgePetCreate: purgeFromHistory(
      [VIEW_INDEX.NAME.key,
        VIEW_INDEX.TYPE.key,
        VIEW_INDEX.BREED.key,
        VIEW_INDEX.BIRTHDAY.key,
      ],
    ),
    purgeRegistration: purgeFromHistory([VIEW_INDEX.PET_SELECTION.category]),
    purgeRegistrationExceptCompletion: purgeFromHistory([VIEW_INDEX.PET_SELECTION.key,
      VIEW_INDEX.CHOOSE_YOUR_PLAN.key, VIEW_INDEX.COMPLETE_YOUR_PURCHASE.key]),
    purgeClaim: purgeFromHistory([VIEW_INDEX.CLAIMS_CLINIC_SELECTION.category]),
    purgeClaimExceptThanks: purgeFromHistory(
      [VIEW_INDEX.CLAIMS_CLINIC_SELECTION.key,
        VIEW_INDEX.CLAIMS_DATE.key,
        VIEW_INDEX.CLAIMS_VET_INVOICE.key,
        VIEW_INDEX.CLAIMS_REVIEW.key,
      ],
    ),
    goToName: navigateTo(VIEW_INDEX.NAME.key),
    goBackToName: navigateBackTo(VIEW_INDEX.NAME.key),
    goToType: navigateTo(VIEW_INDEX.TYPE.key),
    goToBreed: navigateTo(VIEW_INDEX.BREED.key),
    goToBirthday: navigateTo(VIEW_INDEX.BIRTHDAY.key),
    goToChooseYourPlan: navigateTo(VIEW_INDEX.CHOOSE_YOUR_PLAN.key),
    goToCompleteYourPurchase: navigateTo(VIEW_INDEX.COMPLETE_YOUR_PURCHASE.key),
    goToPurchaseSuccess: navigateTo(VIEW_INDEX.PURCHASE_SUCCESS.key, true),
    goToRedemptionDashboard: navigateTo(VIEW_INDEX.REDEMPTION_DASHBOARD.key, true),
    goBackToRedemptionDashboard: navigateBackTo(VIEW_INDEX.REDEMPTION_DASHBOARD.key),
    goToRedeemBenefits: navigateTo(VIEW_INDEX.REDEEM_BENEFITS.key),
    goBackToRedeemBenefits: navigateBackTo(VIEW_INDEX.REDEEM_BENEFITS.key),
    goToBenefitUsageHistory: navigateTo(VIEW_INDEX.BENEFIT_USAGE_HISTORY.key),
    goToMembershipDetails: navigateTo(VIEW_INDEX.MEMBERSHIP_DETAILS.key),
    goToContactCustomerCare: navigateTo(VIEW_INDEX.CONTACT_CUSTOMER_CARE.key),
    goToClaimsClinicSelection: navigateTo(VIEW_INDEX.CLAIMS_CLINIC_SELECTION.key),
    goToClaimsDate: navigateTo(VIEW_INDEX.CLAIMS_DATE.key),
    goToClaimsVetInvoice: navigateTo(VIEW_INDEX.CLAIMS_VET_INVOICE.key),
    goToClaimsReview: navigateTo(VIEW_INDEX.CLAIMS_REVIEW.key),
    goToClaimsThankYou: navigateTo(VIEW_INDEX.CLAIMS_THANK_YOU.key),
    goToInitialDecision: navigateTo(VIEW_INDEX.INITIAL_LOAD.key, true),
  },
});

export default navigationHistory;

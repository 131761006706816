import React from 'react';
import './toggleable-checkbox.css';

const ToggleableCheckbox = ({
  id, checked, onClick, onChange, enabled, annualPaidUpFront, ...rest
}) => (
  <div className="ToggleableCheckbox">
    <span className={`checkbox-label ${checked ? 'checked' : 'unchecked'}`}>{checked ? 'ON' : 'OFF'}</span>
    <label
      htmlFor={id}
      className="switch"
      aria-label={checked ? 'Auto Renew plan activated' : 'Activate Auto Renew Plan'}
    >
      <input id={id} type="checkbox" checked={checked} onClick={onClick} onChange={onChange} {...rest} />
      <span className={`slider round ${!enabled ? 'inactive-toggle' : ''} ${checked ? 'checked' : 'unchecked'} `}>
        {checked && <img src={annualPaidUpFront ? '/assets/icon-checked-bold.svg' : '/assets/icon-checked.svg'} alt="" />}
      </span>
    </label>
  </div>
);

export default ToggleableCheckbox;

import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';

import { Button } from 'components-v2/shared/Button';
import ComboBox from 'components-v2/shared/ComboBox/ComboBox';
import { Input, DateInput2 } from 'components-v2/shared/Elements';

import { getBirthdate } from 'utils';
import { validatePetName } from 'utils/validation';
import './pet-form.css';
import Icon from '../Icon/Icon';

const PetForm = ({
  petTypes, onSubmit, afterSubmitErrorMessage, toggles, companionAnimalFeature,
}) => {
  const handleSubmit = async ({
    name,
    type,
    breed,
    date,
  }) => {
    const dateTime = DateTime.fromJSDate(date);
    const birthdate = getBirthdate(dateTime.month, dateTime.year, dateTime.day);
    const data = {
      name,
      type,
      breed,
      birthdate,
    };
    onSubmit(data);
  };

  const BUTTON_LABEL = 'CREATE PET PROFILE';

  const [displayCompanion, setDisplayCompanion] = useState(false);
  const [filteredPetTypes, setFilteredPetTypes] = useState([]);

  const getBreedsValue = (selectedType) => {
    if (!selectedType) return [];

    const retrievedTypeObject = filteredPetTypes.find((type) => type.value === selectedType);
    // const breeds = retrievedTypeObject.breeds;

    /* NOTE: This is just a temporary fix of updating the Unknown/Other - Small Animal to Small Pet,
    and Other Reptile to Other - Reptile.
    in the breeds section until PGR updates the breed to Unknown/Other - Small Pet from their end
    Make sure to remove the code of breeds and uncomment line #45 */
    const breeds = retrievedTypeObject.breeds.map((obj) => {
      if (obj.value.includes('Small Animal')) {
        const newObj = { ...obj };
        const updatedString = obj.value.replace('Animal', 'Pet');
        newObj.value = updatedString;
        return newObj;
      }
      if (obj.value.includes('Other Reptile')) {
        const newObj = { ...obj };
        const updatedString = obj.value.replace(' ', ' - ');
        newObj.value = updatedString;
        return newObj;
      }
      return obj;
    });


    const formattedBreedsOptions = breeds.map((breed) => (
      { key: breed.code, value: breed.value, label: breed.value }
    ));

    return formattedBreedsOptions;
  };

  const reactSelectStyleOverrides = {
    control: (provided) => {
      const styles = {
        border: '1px solid #656565',
        borderRadius: '5px',
        backgroundColor: 'unset',
      };

      return { ...provided, ...styles };
    },
    input: (provided) => {
      const styles = {
        marginTop: '0rem',
      };

      return { ...provided, ...styles };
    },
    menu: (provided) => {
      const styles = {
        zIndex: 2,
      };

      return { ...provided, ...styles };
    },
    valueContainer: (provided) => {
      const styles = {
        marginLeft: '0.375rem',
      };

      return { ...provided, ...styles };
    },
    singleValue: (provided) => {
      const styles = {
        paddingTop: '1rem',
        color: '#333333',
        marginLeft: '1px',
      };

      return { ...provided, ...styles };
    },
  };

  useEffect(() => {
    if (companionAnimalFeature === true || toggles?.ENABLE_COMPANION_ANIMAL_UI) {
      setDisplayCompanion(true);
      setFilteredPetTypes(petTypes);
    } else {
      const filteredTypes = petTypes.filter((pet) => pet.value === 'Dog' || pet.value === 'Cat');
      setFilteredPetTypes(filteredTypes);
    }
  }, [companionAnimalFeature, toggles?.ENABLE_COMPANION_ANIMAL_UI, petTypes]);

  return (
    <div className="NewPetForm">
      <Formik
        initialValues={{
          name: '',
          date: '',
          type: '',
          breed: '',
        }}
        validationSchema={
          Yup.object().shape({
            name: Yup.string()
              .test('Validate Pet Name', 'Please enter a valid pet name.', (petName) => validatePetName(petName))
              .min(2, 'Too short! Min 2 characters.')
              .max(10, 'Too Long! Max 10 characters.')
              .required('Pet name is required'),
            type: Yup.string()
              .required('Pet Type is required'),
            breed: Yup.string().when('type', {
              is: value => value === 'Fish' || value === 'Bird'
                || value === 'Reptile' || value === 'Small Pet',
              then: () => Yup.string().required('Species is required'),
              otherwise: () => Yup.string().required('Pet breed is required'),
            }),
            date: Yup.string()
              .required('Pet Birthday is required'),
          })
        }
        validateOnMount
        onSubmit={handleSubmit}
      >
        {(props) => (
          <>
            <Form>
              <div className="form-field-wrapper">
                <div className="form-field-col">
                  <div className="input-container">
                    {props.values.type && (
                      <label htmlFor="type">Pet Type</label>
                    )}
                    <ComboBox
                      id="type"
                      reactSelectStyleOverrides={reactSelectStyleOverrides}
                      ariaLabel="Select Pet Type"
                      name="type"
                      text="Select Pet Type *"
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      options={filteredPetTypes?.map((type) => (
                        { key: type.code, value: type.value, label: type.value }
                      ))}
                      selectedOption={props.values.type}
                      className="pet-type"
                      ariaRequired="true"
                    />
                    {!props.values.type && props.errors.type && props.touched.type && (
                      <div className="input-error" role="alert" aria-label={`${props.errors.type}`}>
                        {props.errors.type}
                      </div>
                    )}
                  </div>
                  {displayCompanion && (
                    <div>
                      {props.values.type === 'Reptile' && (
                        <div className="selected-PetType-Group">
                          {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <Icon name="pet-selection-reptile-small-UM" /> : <Icon name="pet-selection-reptile-small" />}
                          <p className="selected-PetType-Text">Have multiple scaly friends? Pick one to represent the team!</p>
                        </div>
                      )}
                      {props.values.type === 'Small Pet' && (
                        <div className="selected-PetType-Group">
                          {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <Icon name="pet-smallAnimal-small-UM" /> : <Icon name="pet-smallAnimal-small" />}
                          <p className="selected-PetType-Text">Have multiple small pets? Pick one to represent the team!</p>
                        </div>
                      )}
                      {props.values.type === 'Fish' && (
                        <div className="selected-PetType-Group">
                          {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <Icon name="pet-selection-fish-small-UM" /> : <Icon name="pet-selection-fish-small" />}
                          <p className="selected-PetType-Text">Have a tank of fish? Pick one to represent the team!</p>
                        </div>
                      )}
                      {props.values.type === 'Bird' && (
                        <div className="selected-PetType-Group">
                          {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <Icon name="pet-selection-bird-small-UM" /> : <Icon name="pet-selection-bird-small" />}
                          <p className="selected-PetType-Text">Have a flock of birds? Pick one to represent the team!</p>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="input-container">
                    {props.values.name && (
                      <label htmlFor="name">Pet Name</label>
                    )}
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter Pet Name *"
                      className="name"
                      aria-required="true"
                    />
                    {props.errors.name && props.touched.name && (
                      <div className="input-error" role="alert" aria-label={`${props.errors.name}`}>
                        {props.errors.name}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-field-col">
                  <div className="input-container">
                    {props.values.breed && (
                      props.values.type === 'Fish' || props.values.type === 'Bird'
                        || props.values.type === 'Reptile' || props.values.type === 'Small Pet'
                        ? <label htmlFor="breed">Species</label> : <label htmlFor="breed">Breed</label>
                    )}
                    <ComboBox
                      isDisabled={!props.values.type}
                      id="breed"
                      ariaLabel="Select Breed"
                      name="breed"
                      text={props.values.type === 'Fish' || props.values.type === 'Bird'
                        || props.values.type === 'Reptile' || props.values.type === 'Small Pet'
                        ? 'Select Species *' : 'Select Breed *'}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      options={getBreedsValue(props.values.type)}
                      selectedOption={props.values.breed}
                      className="pet-breed"
                      reactSelectStyleOverrides={reactSelectStyleOverrides}
                      ariaRequired="true"
                    />
                    {!props.values.breed && props.errors.breed && props.touched.breed && (
                      <div className="input-error" role="alert" aria-label={`${props.errors.breed}`}>
                        {props.errors.breed}
                      </div>
                    )}
                  </div>

                  <div className="input-container" role="combobox" aria-haspopup="dialog" aria-expanded="true">
                    {props.values.date && (
                      <label htmlFor="date">Birthday</label>
                    )}
                    <DatePicker
                      id="date"
                      name="date"
                      placeholderText="Enter Birthday (MM/DD/YYYY) *"
                      maxDate={new Date()}
                      selected={props.values.date}
                      // onFocus={(e) => e.target.readOnly = true}
                      onChange={(dateValue) => {
                        props.setFieldValue('date', dateValue);
                      }}
                      autoComplete="off"
                      ariaRequired="true"
                      ariaDescribedBy="dateDescription"
                      enableTabLoop={false}
                      tabIndex="0"
                      customInput={<DateInput2 />}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      formatWeekDay={nameOfDay => <span aria-label={nameOfDay} tabIndex={0}>{nameOfDay.substring(0, 2)}</span>}
                    />
                    {!props.values.date && props.touched.date && (
                      <div className="input-error" role="alert" aria-label="Pet Birthday is required">
                        Pet Birthday is required
                      </div>
                    )}
                    <p id="dateDescription" className="msg-field-wrapper">Not Sure? Estimate or enter the day you adopted your pet! </p>
                  </div>
                </div>

              </div>

              {afterSubmitErrorMessage && (
                <div className="afterSubmitErrorMessage">
                  <p>{afterSubmitErrorMessage}</p>
                </div>
              )}

              <Button
                type="submit"
                className={props.isValid ? 'button-active' : ''}
                disabled={!props.isValid}
                label={BUTTON_LABEL}
                aria-label={BUTTON_LABEL}
                id="pet-form-submit-button"
              />

            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default PetForm;

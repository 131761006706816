import { createSlice } from '@reduxjs/toolkit';

const fatalError = createSlice({
  name: 'fatalError',
  initialState: false,
  reducers: {
    set: (store, action) => action.payload,
  },
});

export default fatalError;

import { createSelector } from 'reselect';

import { VIEW_INDEX } from 'store/slices';
import { currentViewStateSelector } from 'store';

export const petCreationViewModel = createSelector(
  currentViewStateSelector,
  (state) => state.registrationInfo.onPetNameRetry,
  (state) => state.toggles,
  (state) => state.petTypes,
  (currentViewState, onPetNameRetry, toggles, petTypes) => {
    const totalPetSteps = VIEW_INDEX.TOTAL_PET_STEPS;

    const stepToUseForPercentBar = onPetNameRetry.onError ? totalPetSteps : currentViewState.step;
    const percentage = (stepToUseForPercentBar / totalPetSteps) * 100;

    const petTypesMapped = petTypes?.map((petType) => ({
      code: petType.code,
      value: petType.value,
      breeds: petType.breeds?.map((petBreed) => ({
        code: petBreed.code,
        value: petBreed.value,
      })),
    }));

    return {
      step: currentViewState.step,
      stepName: currentViewState.name,
      stepToUseForPercentBar,
      percentage,
      totalPetSteps,
      petTypes: petTypesMapped,
      onGoingErrorOnPetName: onPetNameRetry.onError,
    };
  },
);

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  navigationHistory, registrationInfo,
} from 'store/slices';

import { Button, ButtonLink } from 'components-v2/shared/Button';
import Container from 'components-v2/shared/Container';
import { Title, Text } from 'components-v2/shared/Elements';
import '../../../css/pet.css';
import './choose-your-plan.css';
import NeedHelp from 'components-v2/shared/NeedHelp';
import VitalCareTip from 'components-v2/shared/VitalCareTip';
import ToggleSwitch from 'components-v2/shared/ToggleSwitch';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';
import { adobeAnalyticsTags, adobeAnalyticsTagsAPU, globalConsts } from 'utils/constGlobal';
import BackButton from 'components-v2/shared/BackButton/BackButton';
import { trackEvent } from 'utils';
import PlanSavingsModal from './PlanSavingsModal';
import Icon from 'components-v2/shared/Icon/Icon';
import { fetchPlansDetail } from 'store/actions';

const ChooseYourPlan = ({
  deepLinking,
  toggles,
  companionAnimalFeature,
  enrolledPets,
  petAlreadyEnrolled,
  uiText,
  membershipId,
  paymentPlansDetail,
  wcsId,
}) => {
  const dispatch = useDispatch();
  const selectedPets = useMemo(() => enrolledPets?.filter((pet) => pet?.proCareInfo) || [], [enrolledPets]);
  const [displayForm, setDisplayForm] = useState(false);
  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));
  const [displayCompanion, setDisplayCompanion] = useState(false);
  const [selectedTenure, setSelectedTenure] = useState(toggles?.DEFAULT_PAYMENT_TENURE);
  const [petPlandetails, setPetPlanDetails] = useState(paymentPlansDetail?.[toggles?.DEFAULT_PAYMENT_TENURE]?.petData);

  const [isOpenPlanSavingModal, setIsOpenPlanSavingModal] = useState(false);

  const togglePlanSavingsModal = () => {
    setIsOpenPlanSavingModal(!isOpenPlanSavingModal);
    if (isOpenPlanSavingModal === false) {
      toggles?.APU_ADOBE_ANALYTICS_TAG && trackEvent(adobeAnalyticsTagsAPU?.USER_CLICK_ON_WHAT_IS_THIS_LINK);
    }
  };

  const planSavings = paymentPlansDetail?.yearly?.planSavingText.map((plan, i) => (
    <div key={plan?.heading}>
      <h5 className="Dialog-title">
        {plan?.heading}
      </h5>
      <p className="Dialog-message">
        {plan?.text}
      </p>
    </div>
  ));

  const displaySavingBanner = paymentPlansDetail?.displayPlanSavingBanner;
  const tenure = selectedTenure === 'monthly' ? '/mo' : '/yr';
  useEffect(() => {
    dispatch(fetchPlansDetail(selectedPets, membershipId, wcsId));
    // Because this fetch has a parameter of derived state that is passed in from the store (enrolledPets),
    // *calling* the store causes an infinite loop in this case if you were to add the derived state as a dependency.
    // While a code smell, anything I can think of to fit in the current system would be pretty crazy, and this way just "works".
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companionAnimalFeature === true || toggles?.ENABLE_COMPANION_ANIMAL_UI) {
      setDisplayCompanion(true);
    }
  }, [companionAnimalFeature, toggles?.ENABLE_COMPANION_ANIMAL_UI]);

  useEffect(() => {
    dispatch(registrationInfo.actions.setSelectedTenureName(selectedTenure));
    localStorage.setItem('selectedTenure', selectedTenure);
    if (selectedTenure === 'monthly') {
      setPetPlanDetails(paymentPlansDetail?.monthly?.petData);
    } else {
      setPetPlanDetails(paymentPlansDetail?.yearly?.petData);
    }
  }, [selectedTenure, paymentPlansDetail, dispatch]);

  useEffect(() => {
    if (selectedTenure === 'monthly') {
      toggles?.APU_ADOBE_ANALYTICS_TAG && trackEvent(adobeAnalyticsTagsAPU?.USER_CLICK_ON_MONTHLY_PLAN_TAB);
    } else {
      toggles?.APU_ADOBE_ANALYTICS_TAG && trackEvent(adobeAnalyticsTagsAPU?.USER_CLICK_ON_YEARLY_PLAN_TAB);
    }
  }, [selectedTenure, toggles?.APU_ADOBE_ANALYTICS_TAG]);

  const toggleButtons = paymentPlansDetail?.uiTextData?.map(btnData => (
    {
      name: (
        <>
          <span className="toggle-switch-v1">{btnData?.btnLabel}</span>
          <span className="toggle-switch-v2">{btnData?.btnText}</span>
        </>
      ),
      value: btnData?.btnName,
    }));

  // returning petIcons based on petType
  const IconOfpet = (petType) => {
    if (petType === 'Dog') {
      return <Icon name="choose-plan-dog" />;
    } else if (petType === 'Cat') {
      return <Icon name="cat" className="cat-icon" />;
    } else if (petType === 'Fish') {
      return <Icon name="fish" />;
    } else if (petType === 'Bird') {
      return <Icon name="choose-plan-bird" className="bird-icon" />;
    } else if (petType === 'Reptile') {
      return <Icon name="reptile" />;
    } else if (petType === 'Small Animal') {
      return <Icon name="smallAnimal" />;
    }
  };

  return (
    !deepLinking && (
      <div className="choose-your-plan-page-v2">
        <Container>
          {!isMobile && (
            <PetcoLogoSharedComponent alignCenter />
          )}
          <div className="content-wrapper">
            <Title>{uiText?.selectApaymentPlanTxt}</Title>
            <Text>{uiText?.annualCommitmentRequiredTxt}</Text>

            {toggleButtons && (
              <ToggleSwitch
                labelNames={toggleButtons}
                selectedButton={selectedTenure === 'yearly' ? 1 : 0}
                setSelectedButton={setSelectedTenure}
              />
            )}

            {selectedTenure === 'monthly' ? <h4 className="plan-heading">{uiText?.selectedTenureMonthly}</h4> : <h4 className="plan-heading">{uiText?.selectedTenureYearly}</h4>}

            <div className="cardList-chooseYourPlan">
              {petPlandetails?.length >= 0 && petPlandetails.map((pet, i) => (
                <div className="Card-Item-Container" key={pet?.petName}>
                  <div className="Card_Item-v2">
                    <div className={selectedTenure === 'monthly' ? 'card-Name' : 'card-Name yearly-Plan'}>
                      {IconOfpet(pet?.petType)}
                      <Title>{pet?.petName}</Title>
                    </div>
                    <div className={selectedTenure === 'monthly' ? 'card-info monthly-Plan' : 'card-info'}>
                      <div className="card-info-row">
                        {pet?.actualPrice !== pet?.discountedPrice
                          && <div className="price price--pre-discount">${pet?.actualPrice}</div>}
                        <div>
                          <span className=" price">${pet?.discountedPrice}</span>
                          <span className="tenure">{tenure}</span>
                        </div>
                      </div>
                      {selectedTenure === 'yearly' && <div className="perMonth-price">(${pet?.perMonthPrice}/mo)</div>}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {displaySavingBanner && selectedTenure === 'yearly' && (
              <div className={toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'savings-banner UM' : 'savings-banner'}>
                <div className="inner-banner">
                  {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <Icon name="SavingBannerIconUM" /> : <Icon name="viewMembershipDetails" />}
                  <div className="d-flex justify-content-between flex-wrap">
                    <span>{uiText?.totalYearlyPlanSavingsTxt}:</span><span className="savingAmmount">${paymentPlansDetail?.yearly?.totalYearlySaving}</span>
                    <ButtonLink
                      className="button-link"
                      label={uiText?.whatIsThisText}
                      variant="textLink"
                      onClick={togglePlanSavingsModal}
                    />
                  </div>
                </div>
              </div>
            )}

            {!displayForm && (
              <Button
                label="CONTINUE"
                onClick={() => {
                  dispatch(navigationHistory.actions.goToCompleteYourPurchase());
                  if (selectedTenure === 'monthly') {
                    toggles?.APU_ADOBE_ANALYTICS_TAG && trackEvent(adobeAnalyticsTagsAPU?.USER_CLICK_ON_CONTINUE_MONTHLY);
                  } else {
                    toggles?.APU_ADOBE_ANALYTICS_TAG && trackEvent(adobeAnalyticsTagsAPU?.USER_CLICK_ON_CONTINUE_YEARLY);
                  }
                }}
              />
            )}

            {!displayForm && (
              <BackButton tagEvent={toggles?.APU_ADOBE_ANALYTICS_TAG && adobeAnalyticsTagsAPU?.USER_CLICK_ON_BACK} />
            )}
          </div>
          <VitalCareTip
            displayCompanion={displayCompanion}
            selectedPets={selectedPets}
            petAlreadyEnrolled={petAlreadyEnrolled}
            toggles={toggles}
            uiText={uiText}
          />
          <NeedHelp
            uiText={uiText}
          />
        </Container>

        {/* multi-pet savings popup below */}
        <Container>
          <div className="savings-modal-wrapper">
            {isOpenPlanSavingModal
              && (
                <PlanSavingsModal
                  title={uiText.planSavingsModalTitle}
                  handleClose={togglePlanSavingsModal}
                >
                  {planSavings}
                </PlanSavingsModal>
              )}
          </div>
        </Container>
      </div>
    ));
};

export default ChooseYourPlan;

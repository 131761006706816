import { createSlice } from '@reduxjs/toolkit';

const tokenState = createSlice({
  name: 'tokenState',
  initialState: false,
  reducers: {
    tokenSet: () => true,
  },
});

export default tokenState;

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  DEEPLINK_INDEX,
  deepLink,
  navigationHistory,
} from 'store/slices';
import {
  fetchPets,
  fetchTypes,
  createPetV2,
  validateReferralCode,
} from 'store/actions';

import { Button } from 'components-v2/shared/Button';
import Container from 'components-v2/shared/Container';
import PetCardList from 'components-v2/shared/PetCardList/PetCardList';
import { Title } from 'components-v2/shared/Elements';
import PetForm from 'components-v2/shared/PetForm/PetForm';
import ReferralCode from '../ReferralCode/ReferralCode';
import '../../../css/pet.css';
import './pet-selection.css';
import NeedHelp from 'components-v2/shared/NeedHelp';
import VitalCareTip from 'components-v2/shared/VitalCareTip';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';
import {
  trackEvent, getAgeInString, getMonth, getYear, getDay,
} from 'utils';
import {
  adobeAnalyticsTags, backToDestinations, globalConsts,
} from 'utils/constGlobal';
import BackTo from 'components-v2/shared/BackTo/BackTo';
import BackButton from 'components-v2/shared/BackButton/BackButton';
import PetErrorModal from './PetErrorModal/PetErrorModal';
import { temporaryPGRTextValueCleanUp } from 'utils/temporaryFunctions';

const PetSelection = ({
  annualPrice,
  annualPriceWithDiscount,
  hasMultipetDiscount,
  petAlreadyEnrolled,
  pets,
  membershipPlans,
  hasUserMembershipPlans,
  enrolledPets,
  onSelectedPetsUpdate,
  deepLinking,
  isLoading,
  deepLinkInfo,
  loadingMemberships,
  petTypes,
  registrationInfoData,
  affiliateReferralInfo,
  petSelectionComplete,
  toggles,
  companionAnimalFeature,
  uiText,
  affiliate,
}) => {
  const dispatch = useDispatch();
  const [petsWithoutMembership, setPetsWithoutMembership] = useState([]);
  const [processedPetsWithoutMemberships, setProcessedPetsWithoutMemberships] = useState(false);
  // changed this to coalesce so test will work... May need to update the actual component.
  const selectedPets = useMemo(() => enrolledPets?.filter((pet) => pet?.proCareInfo) || [], [enrolledPets]);
  const [displayForm, setDisplayForm] = useState(false);
  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));
  const [displayCompanion, setDisplayCompanion] = useState(false);
  const [showError, setShowError] = useState(false); // State value of modal

  // make pets without membership available on the page
  useEffect(() => {
    const petsWithNoMembership = pets?.filter(
      (pet) => membershipPlans?.every((membershipPlan) => membershipPlan?.pet !== pet?.petMasterId),
    ).filter((pet) => pet.deceased === 'N').filter((pet) => toggles?.ALLOWED_PETS?.includes(pet?.petType));

    const transformedPetsWithoutMembership = petsWithNoMembership?.map((pet) => ({
      petMasterId: pet?.petMasterId,
      pgrMasterId: pet?.pgrMasterId,
      name: pet?.name,
      type: pet?.petType,
      breed: pet?.primaryBreed,
      birthDate: pet?.birthDate,
    }));

    if (companionAnimalFeature || toggles?.ENABLE_COMPANION_ANIMAL_UI) {
      setPetsWithoutMembership(transformedPetsWithoutMembership);
    } else {
      const filteredCAPetTypes = transformedPetsWithoutMembership?.filter((pet) => pet.type === 'Dog' || pet.type === 'Cat');
      setPetsWithoutMembership(filteredCAPetTypes);
    }

    // setPetsWithoutMembership(transformedPetsWithoutMembership);
    setProcessedPetsWithoutMemberships(true);
  }, [pets, membershipPlans, setPetsWithoutMembership, setProcessedPetsWithoutMemberships]);

  useEffect(() => {
    dispatch(fetchPets(true));
    dispatch(fetchTypes());
    if (affiliate) {
      dispatch(validateReferralCode(null, affiliate));
    }
  }, [dispatch]);

  // This useEffect will run conditionally to check whether the selected pets is greater then max pets to be enrolled and open the modal

  useEffect(() => {
    if (selectedPets.length > toggles?.MAX_PETS_TO_BE_ENROLLED) {
      setShowError(true);
    }
  }, [toggles?.ENABLE_RESTRICTED_PETS_UI, selectedPets]);

  useEffect(() => {
    if (showError) {
      trackEvent(adobeAnalyticsTags?.USER_SELECTS_MORE_THAN_SIX_PETS_ERROR_MODAL);
    }
  }, [showError]);

  useEffect(() => {
    adobeAnalyticsTags.USER_LANDS_ON_PET_ENROLL_PAGE.page.buildVersion = toggles?.FRONTEND_VERSION;
    trackEvent(adobeAnalyticsTags?.USER_LANDS_ON_PET_ENROLL_PAGE);
  }, []);

  const addNewPet = () => {
    trackEvent(adobeAnalyticsTags?.SIGNUP_ADD_PET_STEP_1_V2);
    setDisplayForm(true);
  };

  const onSubmit = async ({
    name, type, breed, birthdate,
  }) => {
    const { actualPetType, actualBreed } = temporaryPGRTextValueCleanUp(type, breed);
    let addPetAdobe = adobeAnalyticsTags?.SIGNUP_ADD_PET_V2;
    const petInfoAdobe = {
      petInfo: [{
        petName: name,
        petBreed: actualBreed,
        petSpecies: actualPetType,
        petAge: {
          year: getYear(birthdate),
          month: getMonth(birthdate),
          day: getDay(birthdate),
          age: getAgeInString(birthdate),
        },
      }],
    };
    addPetAdobe = { ...addPetAdobe, ...petInfoAdobe };

    const successCallback = () => {
      dispatch(fetchPets(true));
      setDisplayForm(false);
    };

    trackEvent(addPetAdobe);
    await dispatch(createPetV2(name, actualPetType, actualBreed, birthdate, successCallback));
  };

  const onSubmitReferralCodeValidate = ({ referralCode, customerCareCode }) => {
    customerCareCode ? dispatch(validateReferralCode({ customerCareCode })) : dispatch(validateReferralCode({ referralCode }));
  };

  const selectedPetsInfo = () => {
    const selectedPetsAdobe = selectedPets?.length > 1 ? adobeAnalyticsTags?.JOIN_NOW_MULTIPLE_V2 : adobeAnalyticsTags?.JOIN_NOW_SINGLE_V2;
    const selectedPetsAdobeTag = [];
    selectedPets.map((e) => selectedPetsAdobeTag.push({
      petName: e.name,
      petSpecies: e.type,
      petBreed: e.breed,
      petAge: {
        year: getYear(e.birthDate),
        month: getMonth(e.birthDate),
        day: getDay(e.birthDate),
        age: getAgeInString(e.birthDate),
      },
    }));
    selectedPetsAdobe.petInfo = selectedPetsAdobeTag;
    trackEvent(selectedPetsAdobe);
  };

  const petClicked = useCallback((petMasterId) => {
    const keyInfo = 'proCareInfo';
    const selectedPetsExceptChosen = selectedPets?.filter((pet) => pet.id !== petMasterId);
    const wasClickedPetAlreadySelected = selectedPetsExceptChosen?.length < selectedPets?.length;
    if (wasClickedPetAlreadySelected) {
      onSelectedPetsUpdate(selectedPetsExceptChosen, keyInfo);
    } else {
      onSelectedPetsUpdate([...selectedPets, { id: petMasterId }], keyInfo);
    }
  }, [selectedPets, onSelectedPetsUpdate]);

  const selectMultiplePets = useCallback((deepLinkedPets) => {
    const keyInfo = 'proCareInfo';
    const petIds = deepLinkedPets?.map((pet) => ({
      id: pet.petMasterId,
    }));
    onSelectedPetsUpdate(petIds, keyInfo);
  }, [onSelectedPetsUpdate]);

  // handles deep linking into the app for this page
  useEffect(() => {
    if (!isLoading && !loadingMemberships && deepLinking && processedPetsWithoutMemberships) {
      const deepLinkedPets = [];
      const deepLinkedPet = petsWithoutMembership?.find((pet) => pet.pgrMasterId === deepLinkInfo.petId);
      if (deepLinkedPet) {
        deepLinkedPets?.push(deepLinkedPet);
      }

      deepLinkInfo.petIds.forEach((petId) => {
        const deepLinkPet = petsWithoutMembership?.find((pet) => pet.pgrMasterId === petId);
        if (deepLinkPet) {
          deepLinkedPets?.push(deepLinkPet);
        }
      });

      switch (deepLinkInfo.destination) {
        case DEEPLINK_INDEX.PET_CREATION.key:
          addNewPet();
          dispatch(deepLink.actions.resetDeepLink());
          break;
        case DEEPLINK_INDEX.SELL_SCREEN.key:
          dispatch(navigationHistory.actions.goToSellScreen());
          dispatch(deepLink.actions.resetDeepLink());
          break;

        case DEEPLINK_INDEX.ENROLLMENT_CONFIRMATION.key:
        case DEEPLINK_INDEX.ENROLLMENT_CONFIRMATION_WALLET.key:
          if (deepLinkedPets?.length > 0) {
            selectMultiplePets(deepLinkedPets);
            petSelectionComplete();
          } else {
            dispatch(deepLink.actions.resetDeepLink());
          }
          break;

        default:
          if (deepLinkedPets?.length > 0) {
            selectMultiplePets(deepLinkedPets);
          }
          dispatch(deepLink.actions.resetDeepLink());
          break;
      }
    }
  }, [dispatch,
    deepLinkInfo,
    isLoading,
    deepLinking,
    petsWithoutMembership,
    processedPetsWithoutMemberships,
    addNewPet,
    loadingMemberships,
    petClicked,
    selectMultiplePets]);

  useEffect(() => {
    if (companionAnimalFeature === true || toggles?.ENABLE_COMPANION_ANIMAL_UI) {
      setDisplayCompanion(true);
    }
  }, [companionAnimalFeature, toggles?.ENABLE_COMPANION_ANIMAL_UI]);

  const joinNowToggleClass = () => {
    if (toggles?.ENABLE_AFFILIATE_UI) {
      if (selectedPets?.length) {
        return ('button-active join-now-button mt-24');
      } else {
        return ('join-now-button mt-24');
      }
    } else {
      if (selectedPets?.length) {
        return ('button-active join-now-button mt-34');
      }
      return ('join-now-button mt-34');
    }
  };

  return (!deepLinking && (
    <div className="pet-selection-page-v2">
      <Container>
        {!isMobile && (
          <PetcoLogoSharedComponent alignCenter />
        )}
        {!isMobile && (
          hasUserMembershipPlans ? (
            <BackTo where={backToDestinations.DASHBOARD} desktop />
          ) : (
            <BackTo where={backToDestinations.SELL_SCREEN} desktop />
          )
        )}
        {isMobile && (
          hasUserMembershipPlans ? (
            <BackTo where={backToDestinations.DASHBOARD} />
          ) : (
            <BackTo where={backToDestinations.SELL_SCREEN} />
          )
        )}
        {toggles?.ENABLE_UNIFIED_MEMBERSHIP
          ? <Title>{uiText?.petSelectionHeadingTxt1}</Title>
          : <Title>{uiText?.petSelectionHeadingTxt2}</Title>}
      </Container>
      <Container>

        <PetCardList
          pets={petsWithoutMembership}
          selectedPets={selectedPets}
          choosePet={petClicked}
          addNewPet={addNewPet}
          annualPrice={annualPrice}
          annualPriceWithDiscount={annualPriceWithDiscount}
          hasMultipetDiscount={hasMultipetDiscount}
          petAlreadyEnrolled={petAlreadyEnrolled}
          displayForm={displayForm}
          toggles={toggles}
          companionAnimalFeature={companionAnimalFeature}
        />


        {!displayForm && (
          <>
            {toggles?.ENABLE_AFFILIATE_UI && (
              <ReferralCode onSubmit={onSubmitReferralCodeValidate} affiliateReferralInfo={affiliateReferralInfo} toggles={toggles} uiText={uiText} />
            )}
            <Button
              label="JOIN NOW"
              className={joinNowToggleClass()}
              onClick={() => {
                toggles?.ANNUAL_PAID_UPFRONT_ONLINE ? dispatch(navigationHistory.actions.goToChooseYourPlan())
                  : dispatch(navigationHistory.actions.goToCompleteYourPurchase());
                selectedPetsInfo();
              }}
              disabled={selectedPets?.length === undefined || selectedPets?.length === 0}
            />
          </>
        )}

        {displayForm && <div aria-label="Add a pet button expanded" role="alert" />}
        {displayForm && (
          <>
            <div className="add-new-pet-container">
              <span className="add-new-pet-spans"><hr /></span>
              <h2 className="add-new-pet-text">ADD NEW PET</h2>
              <span className="add-new-pet-spans"><hr /></span>
            </div>
            <PetForm
              petTypes={petTypes}
              onSubmit={onSubmit}
              afterSubmitErrorMessage={registrationInfoData?.onPetNameRetry?.message}
              toggles={toggles}
              companionAnimalFeature={companionAnimalFeature}
            />
            <Button
              type="button"
              label="CANCEL"
              aria-expanded={!displayForm ? 'true' : 'false'}
              className="secondary"
              onClick={() => setDisplayForm(false)}
            />
          </>
        )}

        {!displayForm && toggles?.ENABLE_RESTRICTED_PETS_UI ? (
          <PetErrorModal
            show={showError}
            title={toggles?.ERROR_MSG_HEADING_FOR_MAX_PETS_ENROLLED}
            message={toggles?.ERROR_MSG_FOR_MAX_PETS_ENROLLED}
          >
            <button
              className="btn btn-primary="
              onClick={() => {
                const keyInfo = 'proCareInfo';
                const petsThatCanEnrolled = selectedPets?.slice(0, toggles?.MAX_PETS_TO_BE_ENROLLED);
                onSelectedPetsUpdate(petsThatCanEnrolled, keyInfo);
                setShowError(false);
              }}
            >
              Cancel
            </button>
          </PetErrorModal>
        ) : (null)}


        {!displayForm && (
          <BackButton toggles={toggles} />
        )}

        <VitalCareTip
          displayCompanion={displayCompanion}
          selectedPets={selectedPets}
          petAlreadyEnrolled={petAlreadyEnrolled}
          toggles={toggles}
          uiText={uiText}
        />
      </Container>
      <NeedHelp
        uiText={uiText}
      />
    </div>
  ));
};

export default PetSelection;

import React, { useEffect, useCallback } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { trackEvent } from 'utils';

import { Button } from 'components-v2/shared/Button-v1';
import Container from 'components-v2/shared/Container';
import { Input, Title } from 'components-v2/shared/Elements';

import 'css/base.css';
import './add-pet-name.css';

const AddPetName = ({ petName, onGoingErrorOnPetName, petNameEntered }) => {
  const titleText = onGoingErrorOnPetName
    ? 'Your pet name is invalid. Please enter a new one.'
    : 'Who is this for?';
  const buttonText = onGoingErrorOnPetName ? 'Create Pet' : 'Next';

  const onPetNameRefChanged = useCallback((ref) => {
    if (ref !== null) {
      ref.focus();
      const navBarOffSetForMobile = 300;
      const top = Math.round(ref.getBoundingClientRect().top) + navBarOffSetForMobile;
      const message = `focusing on comment top:${top}`;
      window.parent.postMessage({ action: 'consoleMessage', message }, '*');
    }
  }, []);

  function getErrorClassName(className, name, errors, touched) {
    if (errors[name] && touched[name]) {
      return `${className} error`;
    }
    return className;
  }

  useEffect(() => {
    trackEvent({
      formname: 'Membership Signup',
      formstepname: 'Add or select a pet',
      formstep: 1,
      subformname: 'Add a pet',
      subformstepdetails: 'Pet name',
    });
  }, []);

  const handleSubmit = (values) => {
    petNameEntered(values.petName);
  };

  return (
    <div className="AddPetNamePage">
      <Container>
        <Formik
          initialValues={{ petName: petName ?? '' }}
          validateOnMount
          validationSchema={
            Yup.object().shape({
              petName: Yup.string()
                .min(2, 'Too short! Min 2 characters.')
                .max(10, 'Too Long! Max 10 characters.')
                .required('Required'),
            })
          }
          onSubmit={(values) => handleSubmit(values)}
        >
          {(props) => (
            <Form data-testid="add-pet-name-form">
              <fieldset className="registration-field-set">
                <div className="row field-columns">
                  <div
                    data-testid="add-pet-name-wrapper"
                    className={
                    getErrorClassName('col-6 form-group', 'petName', props.errors, props.touched)
}
                  >
                    <Title>
                      {titleText}
                    </Title>

                    <label htmlFor="petName">
                      <span>*</span>
                      Pet Name
                    </label>

                    <Input
                      data-testid="petName"
                      name="petName"
                      placeholder="Enter Pet Name"
                      id="petName"
                      ref={onPetNameRefChanged}
                    />

                    {props.errors.petName && props.touched.petName && (
                      <div className="input-error" role="alert" aria-label={`${props.errors.petName}`}>
                        {props.errors.petName}
                      </div>
                    )}

                    <br />

                    <Button
                      data-testid="next-button"
                      type="submit"
                      className={props.isValid ? 'button-active' : ''}
                      disabled={!props.isValid}
                      label={buttonText}
                      aria-label={!props.isValid ? 'Enter pet name to enable this button' : 'Next'}
                    />

                    <p className="registration-note">
                      If you have multiple pets, not to worry!
                      <br />
                      You can add them later.
                    </p>
                  </div>
                </div>
              </fieldset>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default AddPetName;

import React from 'react';
import './list.css';

// TODO: Move to Helpers or Utils
// TODO: Improve this with TypeScript
const formatClassNames = (defaultClassName, className) => {
  let classNames = defaultClassName;

  if (className) { classNames += ` ${className}`; }

  return classNames;
};

const List = ({ children, className, ...rest }) => {
  const classNames = formatClassNames('List', className);

  return (
    <div className={classNames} {...rest}>{children}</div>
  );
};

List.Item = ({
  as, children, className, ...rest
}) => {
  const classNames = formatClassNames('List__Item', className);

  switch (as) {
    case 'button':
      return <button className={classNames} {...rest}>{children}</button>;
    default:
      return <div className={classNames} {...rest}>{children}</div>;
  }
};

List.ItemLeftWrapper = ({ children, className, ...rest }) => (
  <div className={formatClassNames('ItemLeftWrapper', className)} {...rest}>
    {children}
  </div>
);

List.ItemRightWrapper = ({ children, className, ...rest }) => (
  <div className={formatClassNames('ItemRightWrapper', className)} {...rest}>
    {children}
  </div>
);

List.ItemTitle = ({ children, className, ...rest }) => (
  <div className={formatClassNames('ItemTitle', className)} {...rest}>
    {children}
  </div>
);

List.ImageWrapper = ({ children, className, ...rest }) => (
  <div className={formatClassNames('ImageWrapper', className)} {...rest}>
    {children}
  </div>
);

List.Image = ({ alt, src, ...rest }) => <img src={src} alt={alt} {...rest} />;

List.SubItem = ({ children, ...rest }) => (
  <div {...rest}>
    {children}
  </div>
);

List.Header = ({ children, className, ...rest }) => (
  <div className={formatClassNames('List__Header', className)} {...rest}>
    {children}
  </div>
);

List.Footer = ({ children, className, ...rest }) => (
  <div className={formatClassNames('List__Footer', className)} {...rest}>
    {children}
  </div>
);

export default List;

/* eslint-disable no-param-reassign */
const chart = (data) => {
  const canvas = document.querySelector('#canvas');
  const context = canvas.getContext('2d');

  canvas.width = 200;
  canvas.height = 200;

  let total = data.reduce((totalParam, item) => totalParam + item.totalSpentValue, 0);

  let startAngle = 0;
  const radius = 100;
  const cx = canvas.width / 2;
  const cy = canvas.height / 2;

  if (total === 0 && data.length > 0) {
    data[0] = {
      color: '#D3D3D3',
      totalSpentValue: 10,
    };
    total = 10;
  }

  data.forEach((item) => {
    context.lineWidth = 15;
    context.fillStyle = item.color;
    context.strokeStyle = '#ffffff00';
    context.beginPath();

    const endAngle = ((item.totalSpentValue / total) * Math.PI * 2) + startAngle;

    context.moveTo(cx, cy);
    context.arc(cx, cy, radius, startAngle, endAngle, false);
    context.lineTo(cx, cy);
    context.fill();
    context.stroke();
    context.closePath();

    startAngle = endAngle;
  });
};

export default chart;

import React, { useState } from 'react';
import './PetPaymentDetails.css';

const PetPaymentDetails = (props) => {
  const {
    title,
    subTitle,
    highlightTitle,
    actualPrice,
    discountedPrice,
    perMonthPrice,
    suffix,
    selectedPayment,
    setSelectedPayment,
    value,
    id,
    screen,
    toggles,
    displayPlanSavingBanner,
  } = props;

  const toggleSelectedPayment = (i, name) => {
    if (screen !== 'switchToYearlyFromDashboard') {
      setSelectedPayment(i, name);
    }
  };

  return (
    <div
      className={`${highlightTitle ? 'PetPaymentDetailsContainer PetPaymentDetailsContainerWithHighlightTitle' : 'PetPaymentDetailsContainer'} ${selectedPayment === value ? 'SelectedPaymentDetail' : ''}`}
      onClick={() => toggleSelectedPayment(id, value)}
    >
      {displayPlanSavingBanner && highlightTitle && (
        <div className={toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'highlightTitle highlightTitle_UM' : 'highlightTitle'}>
          <span className="highlightTitleText">{highlightTitle}</span>
        </div>
      )}
      <div className={highlightTitle ? 'PetPriceDetailsContainer' : 'PetPriceDetailsContainerWithNoHighlightTitle'}>
        <span className="PetDetailsTitle">{title}</span>
        <div className={highlightTitle ? 'PetPriceDetails' : 'PetPriceDetailsWithNoHighlightTitle'}>
          <div>
            {actualPrice !== discountedPrice && <span className="ActualPriceStrikeThroughText">${actualPrice}</span>}
            <span className="DiscountedPrice">${actualPrice === discountedPrice ? actualPrice : discountedPrice}{suffix}</span>
          </div>
          {perMonthPrice && (
            <div className="PerMonthPrice">
              (${perMonthPrice}/mo)
            </div>
          )}
        </div>
      </div>
      {subTitle && (
        <div className="PetDetailsSubTitle">
          <span className="PetDetailsSubTitleText">{subTitle}</span>
        </div>
      )}

    </div>
  );
};

export default PetPaymentDetails;

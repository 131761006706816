/* eslint-disable */
import React, { createRef } from 'react';
import { connect } from 'react-redux';

import { globalConsts } from 'utils/constGlobal';
import { trackEvent } from 'utils';
import PetcoLoader from './components-v2/PetcoLoader';
import Registration from './components-v2/registration/Registration';
import Redemption from './components-v2/redemption/Redemption';
import Claim from './components-v2/claim/Claim';
import { isLoading } from './store';

import { getAppViewModel } from './viewModel-v2';

import {
  fetchMembershipPlans, fetchCustomer, fetchPets,
} from './store/actions';
import { navigationHistory, VIEW_INDEX } from './store/slices';
import './css/css-includes.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calledFetchMembership: false,
    };
    this.ref = createRef();
    this.upperLevelGoToRedeemBenefits = this.upperLevelGoToRedeemBenefits.bind(this);

    const popStateListener = (e) => {
      if (e.state !== null && e.state.current !== null) {
        if (
          this.props.viewModel.navigationHistory.history.length > e.state.current
          && this.props.viewModel.navigationHistory.history[e.state.current].key === e.state.key
        ) {
          this.props.dispatch(navigationHistory.actions.setCurrent(e.state.current));
        } else {
          // if the current key either does not match or is beyond our current history then we asked for a page that was trimmed
          // so keep going back till we find a page that was not
          window.history.back();
        }
      }
    };

    window.addEventListener('popstate', popStateListener);
  }

  // TODO: ENT-576 iOS ran fine with the button placed at the bottom of the site that wraps membership iframe
  // but on androis ones, there is a bigger blank space below our content and the button appear way below on
  // the page. The native app menu sidebar appears fine at all times but our button is way below the page
  // just on android devices.

  componentDidMount() {
    const self = this;

    window.addEventListener('message', (e) => {
      const { action } = e.data;
      if (action === 'goToRedeemBenefits') {
        self.upperLevelGoToRedeemBenefits();
      }
    });
  }

  componentDidUpdate() {
    this.postMessageToParentPageAboutScanButton();
    const { calledFetchMembership } = this.state;
    const {
      dispatch,
      isLoading,
      viewModel: {
        tokenLoaded,
        isInitialLoad,
        hasMembershipPlans,
        hasPets,
        isRequestFromExternalPetcoLandingPage,
      },
    } = this.props;
    if (tokenLoaded) {
      if (!calledFetchMembership) {
        dispatch(fetchMembershipPlans());
        dispatch(fetchCustomer());
        dispatch(fetchPets());
        this.setState({ calledFetchMembership: true });
      } else if (!isLoading && isInitialLoad) {
        if (hasMembershipPlans) {
          dispatch(navigationHistory.actions.goToRedemptionDashboard());
        } else if (isRequestFromExternalPetcoLandingPage && !hasPets) {
          dispatch(navigationHistory.actions.goToPetCreationPage());
        } else if (isRequestFromExternalPetcoLandingPage && hasPets) {
          dispatch(navigationHistory.actions.goToPetSelection());
        } else {
          dispatch(navigationHistory.actions.goToSellScreen());
        }
      }
    }
  }

  upperLevelGoToRedeemBenefits() {
    this.props.dispatch(navigationHistory.actions.goToRedeemBenefits());
    trackEvent({
      formname: 'Membership Dashboard',
      formstepname: 'Redeem benefits',
      formstep: 2,
    });
  }

  postMessageToParentPageAboutScanButton() {
    const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));
    const {
      SCAN_AT_REGISTER_DISPLAY_ENABLED,
      SCAN_AT_REGISTER_DYNAMIC_POSITION_ENABLED,
    } = this.props.viewModel.toggles;


    const shouldAppear = isMobile
      && this.props.viewModel.step !== VIEW_INDEX.REDEEM_BENEFITS.step
      && SCAN_AT_REGISTER_DISPLAY_ENABLED;

    const appearanceData = {
      shouldAppear,
      dynamicPositionImplementation: SCAN_AT_REGISTER_DYNAMIC_POSITION_ENABLED,
    };

    window.parent.postMessage({ action: 'evaluateButtonPlacement', appearanceData }, '*');
  }

  render() {
    if (!this.props.viewModel.tokenLoaded) return '';
    return (
      <div className="main-body" ref={this.ref}>
        <PetcoLoader />
        {this.props.viewModel.isRegistering && <Registration />}
        {this.props.viewModel.isRedeemingBenefits && <Redemption />}
        {this.props.viewModel.isClaim && <Claim />}
      </div>
    );
  }
}

export default connect((state) => ({
  viewModel: getAppViewModel(state),
  isLoading: isLoading(state),
}))(App);

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { fetchTypes } from 'store/actions';
import { trackEvent } from 'utils';

import { Button } from 'components-v2/shared/Button-v1';
import Container from 'components-v2/shared/Container';
import { Title } from 'components-v2/shared/Elements';

import './pet-type-selection.css';

const TypeSelection = ({ petTypes, petName, setPetType }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTypes());
    trackEvent({
      formname: 'Membership Signup',
      formstepname: 'Add or select a pet',
      formstep: 2,
      subformname: 'Add a pet',
      subformstepdetails: 'Pet type',
    });
  }, [dispatch]);

  // TODO: Not sure these are really valid given the way this screen is handled. I'm not sure the User can put the screen into either state, but may
  // need to validate the mobile side.
  function getErrorClassName(className, name, errors, touched) {
    if (errors[name] && touched[name]) {
      return `${className} error`;
    }

    return className;
  }

  function setSelectedValue(typeCode) {
    const fullType = petTypes?.find((type) => type.code === typeCode);
    setPetType(fullType);
  }

  return (
    <div className="TypeSelectionPage">
      <Container>
        <Formik
          initialValues={{
            selectedPet: '',
            ...({ type: '' }),
          }}
          validationSchema={Yup.object().shape({
            ...({
            }),
          })}
        >
          {(props) => (
            <Form>
              <fieldset className="registration-field-set">
                <div className="row field-columns">
                  <div className={
                      getErrorClassName('col-6 form-group', 'type', props.errors, props.touched)
                    }
                  >
                    <Title>
                      {petName} is a:
                    </Title>

                    <div className="button-container">
                      {petTypes?.map((type) => (
                        <Button
                          key={type.code}
                          className="type-button"
                          type="button"
                          onClick={() => setSelectedValue(type.code)}
                          label={type.value}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </fieldset>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default TypeSelection;

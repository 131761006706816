import React from 'react';
import { Modal } from 'react-bootstrap';
import { handleKeyUp } from 'utils';
import { Button } from '../Button';
import './confirmation-modal.css';

const ConfirmationModal = (props) => {
  const {
    show, title, onHide, children, onAction, primaryButtonLabel = 'Yes',
    secondaryButtonLabel = 'No', navigateTo, enableVersion,
  } = props;
  const testV2 = true;
  return (
    <Modal show={show} onHide={onHide}>
      <div className="ConfirmationModal">
        <Modal.Title className={enableVersion ? 'modalTitle' : ''}>
          {title}
        </Modal.Title>

        <div
          className="CloseButton"
          role="button"
          tabIndex="0"
          aria-label="Close"
          onClick={enableVersion ? onAction : onHide}
          onKeyUp={(e) => handleKeyUp(e, onHide)}
        >
          <span>✖</span>
        </div>

        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => onAction() && onHide()}
            label={primaryButtonLabel}
            className="confirmButton"
          />
          <Button
            onClick={enableVersion ? navigateTo : onHide}
            label={secondaryButtonLabel}
            className="secondary"
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

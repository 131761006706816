import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'components-v2/shared/Button-v1';
import { handleKeyUp } from 'utils';
import './UpdatePlansCCModal.css';
import Icon from 'components-v2/shared/Icon/Icon';


const UpdatePlansCCModal = (props) => {
  const {
    show, title, onHide, children, onAction, successMessage, toggles,
  } = props;
  const umSuccessMessageClass = toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'UM_successMessage' : '';

  return (
    <Modal show={show} onHide={onHide} className="customModal UpdatePlansCCModal">
      <div className="UpdatePlansCCModal">        
        <div className={`successMessage flashMessage ${umSuccessMessageClass}`}>
          <span><Icon name="successMessageCheck" /></span>
          {successMessage}
        </div>
        <div
          className="CloseButton"
          role="button"
          tabIndex="0"
          aria-label="Close"
          onClick={onHide}
          onKeyUp={(e) => handleKeyUp(e, onHide)}
        >
          <span><Icon name="closeBig" className="UpdatePlansCCCloseIcon" /></span>
        </div>

        <Modal.Title className="UpdatePlansCCTitle">
          {title}
        </Modal.Title>

        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => onAction() && onHide()}
            label="CONFIRM"
            className="confirmButton"
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default UpdatePlansCCModal;

import React from 'react';
import Icon from '../Icon/Icon';
import './logo-header.css';

const LogoHeader = ({ center }) => {
  const className = `LogoHeader${center ? ' center' : ''}`;

  return (
    <div className={className} data-testid="logo-header">
      <Icon name="petcoVitalCareLogo" />
    </div>
  );
};

export default LogoHeader;

import { Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';

import { Button } from 'components-v2/shared/Button';
import Checkbox from 'components-v2/shared/Checkbox';
import { Text } from 'components-v2/shared/Elements';

import { getUniqueReferenceId, trackEvent } from 'utils';
import { adobeAnalyticsTags, globalConsts, transactionType } from 'utils/constGlobal';

import './finish-your-purchase-step.css';

import Subtotal from './Subtotal';
import { useSelector } from 'react-redux';
import viewModel from 'components-v2/shared/CreditCardSelectionNonModal/viewModel';

const FinishYourPurchaseStep = ({
  isLoading,
  enrolledPets,
  termsChecked,
  totalTax,
  membershipTotalPrice,
  membershipTotalPriceWithoutTax,
  selectedStore,
  submitEnabled,
  showTax,
  createMemberships,
  setTermsChecked,
  isAffiliate,
  monthlyAgreementChecked,
  setMonthlyAgreementChecked,
  toggles,
  paymentTenure,
  disclaimerMessage,
  wsConnectionId,
  petCostsByMembershipId,
  paymentAuthCCInfo,
  wcsUserId,
  isMonthlyCommitment,
  prevMonthlyAgreementCheckedRef,
  prevTermsCheckedRef,
}) => {
  // CAPTRAY: I'd like to rethink this... Instead of using localStorage, shouldn't this be passed in
  // via the injector? Can't we assume that it will effectively come in from the caller?
  // If so, why don't we store this up higher and pass it down via a store slice/state, or on the App component?
  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));
  const [membershipPlanData, setMembershipPlanData] = React.useState();
  const [referenceId, setReferenceId] = React.useState();

  const { uiText } = useSelector(viewModel);
  // CAPTRAY: This will work for now, but based on the above comment, may want to reduce this all down and
  // create a URL formatting function to handle the channel parameter or anything else that may come up in the future.
  const termsAndConditionsUMURL = `${process.env.REACT_APP_TERMS_AND_CONDITIONS_URL_UM}${isMobile ? '?channel=mobile' : ''}`;
  const termsOfUseURL = `${process.env.REACT_APP_TERMS_OF_USE_URL}${isMobile ? '?channel=mobile' : ''}`;
  const privacyPolicyURL = `${process.env.REACT_APP_PRIVACY_POLICY_URL}${isMobile ? '?channel=mobile' : ''}`;
  const californiaPrivacyPolicyURL = `${process.env.REACT_APP_CALIFORNIA_PRIVACY_POLICY_URL}${isMobile ? '?channel=mobile' : ''}`;
  const tenure = toggles?.ANNUAL_PAID_UPFRONT_ONLINE && paymentTenure === 'yearly' ? '/yr' : '/mo';

  /*
** Below function is used to return membershipData object based on ENABLEVCVERSION2 flag(related to architecural implementation).
*/
  const createMembershipsPlanData = useCallback(() => {
    if (!toggles?.ENABLEVCVERSION2) {
      return {
        storeId: selectedStore?.id,
        paymentTenure,
      };
    } else {
      return {
        storeId: selectedStore?.id,
        paymentTenure,
        totalCharges: membershipTotalPrice,
        wsConnectionId,
        transactionType: transactionType.SIGNUP,
        referenceId,
        petCostsByMembershipId,
        authKey: paymentAuthCCInfo?.paymentAuthCCData?.authKey,
        wcsUserId,
      };
    }
  }, [membershipTotalPrice, paymentAuthCCInfo?.paymentAuthCCData?.authKey, paymentTenure, petCostsByMembershipId, referenceId, selectedStore?.id, toggles?.ENABLEVCVERSION2, wcsUserId, wsConnectionId]);

  /*
** Below function is used to createMembershipsPlanData and set state for membershipData object based on ENABLEVCVERSION2 flag(related to architecural implementation).
*/
  const getMembershipPlanData = useCallback(() => {
    let newMembershipPlanData;
    switch (true) {
      case toggles?.ENABLEVCVERSION2:
        newMembershipPlanData = createMembershipsPlanData();
        setMembershipPlanData(newMembershipPlanData);
        break;
      default:
        newMembershipPlanData = createMembershipsPlanData();
        setMembershipPlanData(newMembershipPlanData);
        break;
    }
  }, [createMembershipsPlanData, toggles?.ENABLEVCVERSION2]);


  if (toggles?.ENABLEVCVERSION2 && referenceId === undefined) {
    setReferenceId(getUniqueReferenceId(enrolledPets[0].id));
  }
  React.useEffect(() => {
    getMembershipPlanData();
  }, [getMembershipPlanData, paymentAuthCCInfo?.paymentAuthCCData]);

  useEffect(() => {
    if (prevMonthlyAgreementCheckedRef?.current && !monthlyAgreementChecked) {
      trackEvent(adobeAnalyticsTags?.USER_DESELECTS_12_MONTH_COMMITMENT_CHECKBOX_ON_FINISH_YOUR_PURCHASE_STEP);
    } else if (monthlyAgreementChecked) {
      trackEvent(adobeAnalyticsTags?.USER_SELECTS_12_MONTH_COMMITMENT_CHECKBOX_ON_FINISH_YOUR_PURCHASE_STEP);
    }
  }, [monthlyAgreementChecked]);

  useEffect(() => {
    if (prevTermsCheckedRef?.current && !termsChecked) {
      trackEvent(adobeAnalyticsTags?.USER_DESELECTS_VITAL_CARE_PREMIER_TERMS_AND_CONDITIONS_CHECKBOX_ON_FINISH_YOUR_PURCHASE_STEP);
    } else if (termsChecked) {
      trackEvent(adobeAnalyticsTags?.USER_SELECTS_VITAL_CARE_PREMIER_TERMS_AND_CONDITIONS_CHECKBOX_ON_FINISH_YOUR_PURCHASE_STEP);
    }
  }, [termsChecked]);

  return (
    <div>
      {!isLoading && (
        <div className="FinishYourPurchase-v2">
          <div className="TermsSection">
            <Formik>
              <>
                <div className="terms-checkbox-row-section">
                  <Checkbox
                    id="termsChecked"
                    aria-label={termsChecked
                      ? 'You checked the box and agreed with Petco Vital Care '
                      + 'Terms and Conditions, Terms of Use and Privacy Policy'
                      : 'Check the box if you agree with Petco Vital Care '
                      + 'Terms and Conditions, Terms of Use and Privacy Policy'}
                    handleCheck={() => {
                      setTermsChecked(!termsChecked);
                    }}
                    defaultChecked={termsChecked}
                  />
                  <div>
                    <Text>
                      I agree to Petco's
                      {' '}
                      <a
                        href={termsAndConditionsUMURL}
                        name="termsConditions"
                        className="button-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'Vital Care Premier Terms & Conditions' : 'Vital Care Terms & Conditions'}
                      </a>
                      ,
                      {' '}
                      <a
                        href={termsOfUseURL}
                        name="termsUse"
                        className="button-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>
                      {' '}
                      and
                      {' '}
                      <a
                        href={privacyPolicyURL}
                        name="privacePolicy"
                        className="button-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>. California residents can review our Notice of Financial Incentives&nbsp;
                      <a
                        href={californiaPrivacyPolicyURL}
                        name="californiaPrivacyPolicy"
                        className="button-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Here. Link to Notice of Financial Incentives"
                      >here
                      </a>.
                    </Text>
                  </div>
                </div>
                {isMonthlyCommitment && paymentTenure !== 'yearly'
                  ? (
                    <div className="terms-checkbox-row-section">
                      <Checkbox
                        id="monthlyCheckbox"
                        aria-label={monthlyAgreementChecked
                          ? 'You understand that by paying monthly, you are committing to 12 monthly recurring payments. '
                          : 'Check the box if you understand that by paying monthly, you are committing to 12 monthly recurring payments.'}
                        handleCheck={() => {
                          setMonthlyAgreementChecked(!monthlyAgreementChecked);
                        }}
                        defaultChecked={monthlyAgreementChecked}
                      />
                      <Text>
                        {uiText?.monthlyCommitment}
                      </Text>
                    </div>
                  ) : null}
              </>
            </Formik>
          </div>

          <div className="PaymentInformation">
            <div className="PaymentInformation__Items">
              {isAffiliate && <Text className="weight">Discounted Amount (first 3 months)</Text>}
              {enrolledPets?.map(({
                petMasterId,
                name,
                info,
                petCost,
              }) => (
                info?.map((infoItem) => (
                  <div className="PaymentInformation__Item membershipListPrices" key={petMasterId}>
                    <div className="membershipType">
                      <Text>
                        {name}'s {infoItem}
                      </Text>
                      <Text data-testid="payment-info-section-plan-price">
                        ${petCost?.subtotal}{tenure}
                      </Text>
                    </div>
                  </div>
                ))
              ))}


              <div className="membershipTax">
                <Text>Taxes</Text>
                {showTax
                  ? (
                    <Text>
                      ${totalTax?.toFixed(2)}{tenure}
                    </Text>
                  ) : (
                    <Text>--</Text>
                  )}
              </div>
            </div>
          </div>
        </div>

      )}

      {showTax
        && (
          <Subtotal
            title="Total Charge:"
            value={membershipTotalPrice}
            customStyle
            asterisk={isAffiliate}
            tenure={tenure}
          />
        )}
      {isAffiliate && <Text className="special-price">*Special pricing valid for first 3 months</Text>}
      <Text className="submit-copy">
        {toggles?.DOLLOR_ZERO_PRE_AUTH ? disclaimerMessage : `By clicking Submit Payment, you are enrolling in automatic payments of ${membershipTotalPriceWithoutTax} (plus applicable taxes) that will continue until you cancel. You can cancel at any time, effective at the end of the annual billing period, and details of how to cancel along with other information can be found in the `}
        <a className="button-link" href={termsAndConditionsUMURL} target="_blank" rel="noopener noreferrer">
          {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'Vital Care Premier Terms and Conditions.' : 'Vital Care Terms and Conditions.'}
        </a>
      </Text>

      <div className="submit-payment">
        <Button
          onClick={() => {
            createMemberships(membershipPlanData);
          }}
          label="Submit Payment"
          type="submit"
          className={!submitEnabled ? 'invalid-button' : 'active-button'}
          disabled={!submitEnabled}
          aria-disabled={!submitEnabled}
        />

        <p className="submit-info">A receipt will be emailed to you after checkout.</p>
      </div>
    </div>
  );
};

export default FinishYourPurchaseStep;

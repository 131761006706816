import React from 'react';
import Select from 'react-select';
import { globalConsts } from 'utils/constGlobal';
import './combo-box.css';

const ComboBox = (props) => {
  const {
    ariaLabel,
    options, 
    text, 
    id, 
    isSearchable = true, 
    isDisabled, 
    selectedOption,
    className,
    reactSelectStyleOverrides,
    ariaRequired,
    inputId,
  } = props;

  const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));

  const classNames = `ComboBoxWrapper${className ? ` ${className}` : ''}`;

  const handleChange = (option) => {
    if (isMobile) {
      // option is actualy the event
      props.onChange(props.name, option.target.value);
    } else {
      props.onChange(props.name, option.value);
    }
  };

  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur(props.name, true);
    }
  };

  const selectedOptionEvaluated = options?.find((op) => {
    // avoid breaking Selects that aren't set to have an initial value
    if (selectedOption !== undefined) {
      if (typeof op.value === 'string') {
        return op.value.toUpperCase() === selectedOption.toUpperCase();
      }
      return op.value === selectedOption;
    } else {
      return undefined;
    }
  });

  const selectedValue = selectedOptionEvaluated ? selectedOptionEvaluated.value : text;

  return (
    <div className={classNames}>
      {isMobile
        && (
          <select
            id={id}
            value={selectedValue}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isDisabled}
            aria-label={ariaLabel}
            aria-required={ariaRequired}
            tabIndex="0"
            className="mobile-select"
          >
            <option value={text} disabled>{text}</option>
            {
              options?.map((option) => (
                <option tabIndex="0" value={option.value} key={option.key}>{option.label}</option>
              ))
            }
          </select>
        )}
      {!isMobile
        && (
          <Select
            id={id}
            inputId={inputId}
            className="react-select"
            aria-label={ariaLabel}
            isSearchable={isSearchable}
            placeholder={text}
            onChange={handleChange}
            onBlur={handleBlur}
            options={options}
            isDisabled={isDisabled}
            // from the docs https://github.com/JedWatson/react-select#further-options,
            // on installation and usage, if you want to set an initial value on a
            // Select from react-select, use value, not defaultValue
            value={selectedOptionEvaluated || ''}
            styles={reactSelectStyleOverrides}
          />
        )}
    </div>
  );
};

export default ComboBox;

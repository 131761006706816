import { createSelector } from 'reselect';
import { fetchPaymentMethods } from 'store/actions';
import { useMobileWalletSelector } from 'store/slices';
import { globalConsts } from 'utils/constGlobal';

const viewModel = createSelector(
  (state) => state.customer,
  (state) => state.toggles,
  useMobileWalletSelector,
  (state) => state.uiText,
  (customer, toggles, useMobileWallet, uiText) => ({
    paymentMethods: customer.paymentMethods.data,
    paymentMethodsError: customer.paymentMethods.error,
    fetchPaymentMethods,
    isMobile: JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY)),
    useNativeWallet: toggles.IN_APP_USE_NATIVE_WALLET && useMobileWallet,
    useAciPayment: toggles.USE_ACI_PAYMENT,
    customerInfo: customer.user,
    uiText,
  }),
);

export default viewModel;

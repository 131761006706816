import { createSelector } from 'reselect';
import moment from 'moment';

import { VIEW_INDEX } from 'store/slices';

export const getPurchaseSuccessViewModel = createSelector(
  (state) => state.customer,
  (state) => state.customer.pets,
  (state) => state.registrationInfo,
  (state) => state.navigationHistory,
  (customer, pets, registrationInfo, navigationHistory) => {
    const petInfo = {};

    // Put each pet name in object keyed by masterId
    pets.data.forEach((pet) => {
      registrationInfo.createdMembershipPlans.forEach((plan) => {
        if (plan.pet === pet.petMasterId) {
          petInfo[pet.petMasterId] = { 
            name: pet.name, petType: pet.petType, petBreed: pet.primaryBreed, birthdate: pet.birthDate,  
          };
        }
      });
    });

    const addTheSameTimeZoneInfoFromStartDateToEndDate = ({ createDt, endDate }) => {
      const timeStamp = createDt.substring(10, createDt.length);
      const enhancedEndDate = endDate + timeStamp;
      return enhancedEndDate;
    };

    const plans = registrationInfo.createdMembershipPlans.map((plan) => {
      const obj = {
        ...plan,
        endDate: addTheSameTimeZoneInfoFromStartDateToEndDate(plan),
      };

      return obj;
    });

    const dashboardInHistory = navigationHistory
      .history
      .includes((historyItem) => historyItem.key === VIEW_INDEX.REDEMPTION_DASHBOARD.key);

    const createdMembershipPlansIds = plans && plans.map(plan => plan.id).join(', ');

    return {
      palsId: customer ? customer.user.data.palsId : '',
      createdMembershipPlans: plans,
      createdMembershipPlansIds,
      petInfo,
      dashboardInHistory,
    };
  },
);

import React from 'react';
import './button.css';

export const ButtonLink = ({
  children, className, href, label, target, variant, ...rest
}) => {
  let classNames = 'ButtonLink';

  if (className) classNames += ` ${className}`;

  if (variant) {
    if (variant === 'textLink') classNames += ' TextLink';
    else classNames += ` ${variant}`;
  }

  return (
    <a
      role="button"
      className={classNames}
      href={href}
      target={target}
      {...rest}
    >
      {label}
      {children}
    </a>
  );
};

export const Button = ({
  children, className, disabled, onClick, label, ...rest
}) => {
  let classNames = 'Button';

  if (className) classNames += ` ${className}`;

  if (disabled) classNames += ' disabled';

  return (
    <button className={classNames} disabled={disabled} onClick={onClick} {...rest}>
      {label}
      {children}
    </button>
  );
};

export const ButtonGroup = ({ children }) => <div className="ButtonGroup">{children}</div>;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const dashboardInfo = createSlice({
  name: 'dashboardInfo',
  initialState: {
    petMasterId: null,
    planBenefitUsageHistory: null,
    onMembershipReactivationError: { occurred: false, message: null },
    onMembershipRenewalError: { occurred: false, message: null },
    onMembershipPaymentMethodUpdateError: { occurred: false, message: null },
    transitionModalOnCooldown: false,
  },
  reducers: {
    setPetMasterId: (state, action) => {
      state.petMasterId = action.payload;
    },
    setPlanBenefitUsageHistory: (state, action) => {
      state.planBenefitUsageHistory = action.payload;
    },
    setOnMembershipReactivationError: (state, action) => {
      state.onMembershipReactivationError = action.payload;
    },
    clearReactivationError: (state, action) => {
      state.onMembershipReactivationError = action.payload;
    },
    setOnMembershipReactivationSuccessMessage: (state, action) => {
      state.onMembershipReactivationSuccessMessage = action.payload;
    },
    clearReactivationSuccessMessage: (state, action) => {
      state.onMembershipReactivationSuccessMessage = action.payload;
    },
    setOnMembershipRenewalError: (state, action) => {
      state.onMembershipRenewalError = action.payload;
    },
    clearRenewalError: (state, action) => {
      state.onMembershipRenewalError = action.payload;
    },
    setOnMembershipRenewalSuccessMessage: (state, action) => {
      state.onMembershipRenewalSuccessMessage = action.payload;
    },
    clearRenewalSuccessMessage: (state, action) => {
      state.onMembershipRenewalSuccessMessage = action.payload;
    },
    setOnMembershipAutoRenewSuccessMessage: (state, action) => {
      state.onMembershipAutoRenewSuccessMessage = action.payload;
    },
    clearAutoRenewSuccessMessage: (state, action) => {
      state.onMembershipAutoRenewSuccessMessage = action.payload;
    },
    setOnMembershipPaymentMethodUpdateError: (state, action) => {
      state.onMembershipPaymentMethodUpdateError = action.payload;
    },
    clearOnMembershipPaymentMethodUpdateError: (state, action) => {
      state.onMembershipPaymentMethodUpdateError = action.payload;
    },
    setSwitchMembershipSuccessMessage: (state, action) => {
      state.switchMembershipSuccessMessage = action.payload;
    },
    clearSwitchMembershipSuccessMessage: (state, action) => {
      state.switchMembershipSuccessMessage = action.payload;
    },
    setTransitionModalOnCooldown: (state, action) => {
      state.transitionModalOnCooldown = action.payload;
    },
    setShowTransitionMessage: (state, action) => {
      state.showTransitionMessage = action.payload;
    },
    setShowTransitionModal: (state, action) => {
      state.showTransitionModal = action.payload;
    },
    setTransitionMessage: (state, action) => {
      state.transitionMessage = action.payload;
    },
    setThirdPartyErrorMessage: (state, action) => {
      state.thirdPartyErrorMessage = action.payload;
    },
    clearThirdPartyErrorMessage: (state, action) => {
      state.thirdPartyErrorMessage = action.payload;
    },
    setRedeemBenefitsCouponErrorMessage: (state, action) => {
      state.redeemBenefitsCouponErrorMessage = action.payload;
    },
  },
});

export default dashboardInfo;

import React, { Component } from 'react';
import { Button } from 'components-v2/shared/Button-v1';
import { trackEvent } from 'utils';
import { adobeAnalyticsTags } from 'utils/constGlobal';

import './file-dialog-input.css';

class FileDialogInput extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  onChangeFiles(e) {
    const { onFileAdded, disabled } = this.props;
    if (disabled) return;
    const { files } = e.target;
    onFileAdded(files);
  }

  openFileDialog(e, disabled) {
    if (disabled) return;
    trackEvent(adobeAnalyticsTags?.VETEXAM_UPLOAD);
    document.getElementById('hidden-file-input-button').value = '';

    this.fileInputRef.current.click();
  }

  render() {
    const { showContinue, disabled } = this.props;
    return (
      <div className={`FileDialogInput ${showContinue === true ? 'Justify' : ''}`} data-testid="file-dialog-container">
        <Button
          label="Upload"
          onClick={(e) => this.openFileDialog(e, disabled)}
          style={{ cursor: disabled ? 'default' : 'pointer' }}
          id="upload-another"
        />

        <input
          data-testid="file-input"
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          accept="jpg, png, jpeg"
          id="hidden-file-input-button"
          onChange={(e) => this.onChangeFiles(e)}
          hidden
        />
      </div>
    );
  }
}

export default FileDialogInput;

import React from 'react';

import { Button } from 'components-v2/shared/Button-v1';
import Container from 'components-v2/shared/Container';
import Icon from 'components-v2/shared/Icon/Icon';
import { Title, Text } from 'components-v2/shared/Elements';

import './claims-thank-you.css';

const ClaimsThankYou = ({ palsRewardsValue, backToRedeemBenefits }) => (
  <div className="ClaimsThankYouPage">
    <Container>
      <Icon name="checkThankYou" />

      <Title>Thanks!</Title>

      <Text>
        Your vet exam reward has been submitted. You will receive an email
        when your ${palsRewardsValue} Pals Rewards are available to load in the next 48 hours.
      </Text>

      <Button
        label="Return to Dashboard"
        onClick={backToRedeemBenefits}
      />
    </Container>
  </div>
);

export default ClaimsThankYou;

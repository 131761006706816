import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components-v2/shared/Button-v1';
import Card from 'components-v2/shared/Card-v1';
import Container from 'components-v2/shared/Container';
import List from 'components-v2/shared/List-v1';
import PlanTransitionMessage from 'components-v2/shared/PlanTransitionMessage';
import { Title } from 'components-v2/shared/Elements';

import Icon from 'components-v2/shared/Icon/Icon';
import BackToButton from 'components-v2/shared/BackToButton';
import { getMonthYear, handleKeyUp } from 'utils';
import chart from './utils/chart';
import DashboardHeader from '../DashboardHeader/DashboardHeader';

import './benefit-usage-history.css';

function BenefitUsageHistory({
  plansWithPets,
  selectedPlanWithPet,
  showBenefitValues,
  usageDataByCategory,
  choosePet,
  loadedPlansBenefitUsageHistory,
  totalBenefitUsage,
  loadBenefitsForPlanWithPet,
  showTransitionMessage,
  transitionMessage,
}) {
  const [lastPetMasterId, setLastPetMasterId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showBenefitValues) {
      chart(usageDataByCategory);
    }
  }, [showBenefitValues, usageDataByCategory]);

  const [categoriesCountState, setCategoryCountState] = useState({});

  const loadMoreUsages = (e, category) => {
    e.preventDefault();
    setCategoryCountState({ ...categoriesCountState, [category.id]: (categoriesCountState[category.id] || 0) + 1 });
  };

  useEffect(() => {
    if (selectedPlanWithPet && lastPetMasterId !== selectedPlanWithPet.petMasterId) {
      loadBenefitsForPlanWithPet(selectedPlanWithPet);
      setLastPetMasterId(selectedPlanWithPet.petMasterId);
    }
  }, [dispatch, selectedPlanWithPet, lastPetMasterId, setLastPetMasterId, loadBenefitsForPlanWithPet]);

  return (
    <div className="BenefitUsageHistoryPage">
      <Container>
        <BackToButton label="Back to Dashboard" />
        <Title>Benefit Usage History</Title>
      </Container>

      <DashboardHeader
        title="Benefits Usage History"
        showBackToDashboard
        selectedPlanWithPet={selectedPlanWithPet}
        plansWithPets={plansWithPets}
        choosePet={choosePet}
      />

      {showTransitionMessage && (
        <PlanTransitionMessage
          transitionMessage={transitionMessage}
        />
      )}

      <Container>
        {loadedPlansBenefitUsageHistory && totalBenefitUsage > 0 && (
          <Title as="h5">Congrats On Your Redemptions!</Title>
        )}

        {!loadedPlansBenefitUsageHistory
          && (
            <div className="error-container">
              <span className="error-title">Benefits failed to load</span>
              <Button
                class="try-again-button"
                label="Try Again"
                onClick={() => loadBenefitsForPlanWithPet(selectedPlanWithPet)}
              />
            </div>
          )}

        {showBenefitValues && (
          <Card className="card-graph">
            <div className="BenefitUsageHistoryPage__Graph">
              <div
                className="innerCircleGraph"
                aria-label={`$${totalBenefitUsage} in Rewards Value
                Since ${getMonthYear(selectedPlanWithPet.plan.createDt)}`}
              >
                <Icon className="iconReward" name="reward" />

                <div className="rewards">
                  <div className="rewards-value">
                    <span role="heading" aria-level="5">
                      <sup>$</sup>
                      {totalBenefitUsage}
                    </span>
                  </div>
                  <p>
                    in Rewards Value Since {getMonthYear(selectedPlanWithPet.plan.createDt)}
                  </p>
                </div>
              </div>

              <canvas id="canvas" />
            </div>
          </Card>
        )}

        {usageDataByCategory.map((category) => {
          const step = categoriesCountState[category.id] * 5 || 0;
          const numberOfUsagesToShow = category.currentDisplaySize + step;
          const isAtAVetCategory = category.id === 3;
          return (
            <Card key={category.id}>
              <List>
                <List.Header>
                  <div
                    className="ListHeaderWrapper"
                    aria-label={`${category.name} category ${category.totalSpentValue.toFixed(2)} redeemed.`}
                  >
                    <span
                      role="heading"
                      aria-level="3"
                      className={isAtAVetCategory ? 'white-color' : ''}
                    >{category.name}
                    </span>
                    {showBenefitValues && (
                      <span
                        className={
                          isAtAVetCategory
                            ? 'benefit-total-spent-value white-color'
                            : 'benefit-total-spent-value'
                        }
                      >
                        $
                        {parseFloat(category.totalSpentValue).toFixed(2)}
                      </span>
                    )}
                  </div>
                </List.Header>
                {category.usages && (category.usages.length > 0
                  ? category.usages.map((usage, index) => {
                    if (numberOfUsagesToShow >= index + 1) {
                      return (
                        <List.Item key={usage.benefitUsageId}>
                          <div className="benefit-name-date">
                            <span role="heading" aria-level="4" className="benefit-name">{usage.benefitName}</span>
                            <span className="gray-color">{usage.benefitUsageUpdateDt}</span>
                          </div>
                          {showBenefitValues && (
                            <div className="status">
                              <span className="gray-color status-header">VALUE</span>
                              <span className="benefit-price">
                                $
                                {parseFloat(usage.benefitValue).toFixed(2)}
                              </span>
                            </div>
                          )}
                        </List.Item>
                      );
                    }
                    return null;
                  })
                  : (
                    <List.Item>
                      <div className="no-usages">
                        <span className="gray-color">No usages have been recorded yet.</span>
                      </div>
                    </List.Item>
                  ))}
                {category.totalUsagesCount >= 6 && numberOfUsagesToShow < category.totalUsagesCount
                  && (
                    <List.Footer className="">
                      <span
                        role="button"
                        tabIndex="0" 
                        onClick={(e) => loadMoreUsages(e, category)}
                        onKeyUp={(e) => handleKeyUp(e, () => loadMoreUsages(e, category))}
                      >
                        Load more
                      </span>
                    </List.Footer>
                  )}
              </List>
            </Card>
          );
        })}
      </Container>
    </div>
  );
}

export default BenefitUsageHistory;

import Icon from 'components-v2/shared/Icon/Icon';
import React from 'react';
import './savings-modal.css';

const PlanSavingsModal = (props) => (
  <>
    <div className="modal-popup savings-modal" tabIndex="-1">
      <Icon name="closeBig" onClick={props.handleClose} className="close-icon" />
      <div className="modal-content">
        <div className="modal-header">
          {props.title && <h4 className="modal-title">{props.title}</h4>}
        </div>
        <div className="modal-body">
          {props.children && props.children}
        </div>
        <div className="modal-footer">
          {props.btn1_name
            && <button type="button" className="Button-v2">{props.btn1}</button>}
          {props.btn2_name
            && <button type="button" className="Button-v2 secondary" data-dismiss="modal" onClick={props.handleClose}>{props.btn2}</button>}
        </div>
      </div>
    </div>
    {/* background layer */}
    <div className="bg-layer" />
  </>
);

export default PlanSavingsModal;

import React, { useEffect, useState } from 'react';
import { Title } from '../Elements';
import { handleKeyUp } from '../../../utils';
import Icon from '../Icon/Icon';
import './pet-card-list.css';

const PetCardList = (props) => {
  const {
    selectedPets = [],
    choosePet,
    pets = [],
    addNewPet,
    displayForm,
    toggles,
    companionAnimalFeature,
  } = props;

  const selectedPetIds = selectedPets.map((pet) => pet.id);

  const [displayCompanion, setDisplayCompanion] = useState(false);

  const handleChoosePet = (petMasterId) => {
    if (choosePet) {
      choosePet(petMasterId);
    }
  };

  const handlePetIcon = (petType) => {
    if (petType === 'Dog') {
      return <Icon name="pet-selection-dog-head" size={43} />;
    } else if (petType === 'Bird') {
      return <Icon name="pet-selection-bird" size={43} />;
    } else if (petType === 'Reptile') {
      return <Icon name="pet-selection-reptile" size={43} />;
    } else if (petType === 'Fish') {
      return <Icon name="pet-selection-fish" size={43} />;
    } else if (petType === 'Small Animal') {
      return <Icon name="pet-selection-small-pet" size={43} />;
    } else {
      return <Icon name="pet-selection-cat-head" size={40} />;
    }
  };

  useEffect(() => {
    if (companionAnimalFeature === true || toggles?.ENABLE_COMPANION_ANIMAL_UI) {
      setDisplayCompanion(true);
    }
  }, [companionAnimalFeature, toggles?.ENABLE_COMPANION_ANIMAL_UI]);

  return (
    <ul className="PetCardList-v2" role="group">
      {pets.map((pet) => (
        <li key={pet.petMasterId}>
          <div
            // eslint-disable-next-line react/no-unknown-property
            as="button"
            role="checkbox"
            tabIndex="0"
            aria-label={`Pet ${pet.name}`}
            aria-checked={selectedPetIds.includes(pet.petMasterId) ? 'true' : 'false'}
            key={pet.petMasterId}
            className={`PetCardList__Item-v2 ${selectedPetIds.includes(pet.petMasterId) && 'PetCardList__Item-v2--Marked'}`}
            onClick={() => handleChoosePet(pet.petMasterId)}
            onKeyUp={(e) => handleKeyUp(e, () => handleChoosePet(pet.petMasterId))}
          >
            <div className="PetCardList__IconWrapper-v2">
              {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? (
                <div className={`PetCardList__Coin-v2 ${selectedPetIds.includes(pet.petMasterId) && 'PetCardList__Coin-v2--Marked PetCardList__Coin-v2--UM'}`} data-testid="coin">
                  {selectedPetIds.includes(pet.petMasterId) && (
                    <span className="value">
                      <Icon name="checked-UM" />
                    </span>
                  )}
                </div>
              ) : (
                <div className={`PetCardList__Coin-v2 ${selectedPetIds.includes(pet.petMasterId) && 'PetCardList__Coin-v2--Marked'}`} data-testid="coin">
                  {selectedPetIds.includes(pet.petMasterId) && (
                    <span className="value">
                      <Icon name="checked" />
                    </span>
                  )}
                </div>
              )}
              {handlePetIcon(pet.type)}
            </div>

            <div className="PetCardList__Name-v2">
              <Title>{pet.name}</Title>
            </div>
          </div>
        </li>
      ))}

      {addNewPet && !displayForm && (
        <li>
          <div
            // TODO: Eliminate these
            // eslint-disable-next-line react/no-unknown-property
            as="button"
            role="button"
            aria-label="add a pet"
            aria-expanded={!displayForm ? 'true' : 'false'}
            tabIndex="0"
            onClick={addNewPet}
            onKeyUp={(e) => handleKeyUp(e, addNewPet)}
            className="PetCardList__Item-v2 PetCardList__Item-v2--AddNewPet"
            data-testid="add-a-pet"
          >
            {displayCompanion ? (
              <>
                <div className="PetCardList__IconWrapper-v2 PetCardList__IconWrapper-v2-Group addPetV2">
                  <span className="plus-sign">+</span>
                  <Icon name="pet-companionV2-addpet" />
                </div>
                <div className="PetCardList__Name-v2">
                  <Title>
                    Add A Pet
                  </Title>
                </div>
              </>
            ) : (
              <>
                <div className="PetCardList__IconWrapper-v2">
                  <span className="plus-sign">+</span>
                  <Icon name="addNewPet" />
                </div>

                <div className="PetCardList__Name-v2">
                  <Title>
                    Add A Pet
                  </Title>
                </div>
              </>
            )}

          </div>
        </li>
      )}
    </ul>
  );
};

export default PetCardList;

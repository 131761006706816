/* eslint-disable no-param-reassign */
import Logger from 'utils/logger';
import {
  fetchJSON,
} from '.';

import {
  affiliateReferralCode,
  customer, dashboardInfo, fatalError, loadingCount, memberships,
  membershipsV2, navigationHistory, petTypes, registrationInfo, toggles,
} from './slices';

import {
  checkToggles,
  getRequestHeaders,
  timeAgoInSeconds,
} from '../utils';
import paymentPlansDetail from './slices/paymentPlansDetail';
import uiText from './slices/uiText';
import moment from 'moment';


const LOGGER = Logger.getLogger('store_actions');

const PETS_URL = `${process.env.REACT_APP_API_URL}pet/`;
const PAYMENT_METHOD_URL = `${process.env.REACT_APP_API_URL}payment/wallet/`;
const STORE_LOCATOR_URL = (zipCode) => `${process.env.REACT_APP_API_URL}common/store/?query=${zipCode}`;
const VET_CLINIC_SERVICE_URL = (query) => `${process.env.REACT_APP_API_URL}clinic/?q=${query}`;
const INITIALIZE_SESSION_URL = `${process.env.REACT_APP_API_URL}payment/wallet/init-session/`;
const CUSTOMER_URL = `${process.env.REACT_APP_API_URL}customer/`;
const CUSTOMER_ADDRESS_URL = `${process.env.REACT_APP_API_URL}customer/address/`;
const MEMBERSHIPS_URL = `${process.env.REACT_APP_API_URL}membership/`;
const MEMBERSHIP_PLAN_URL = `${process.env.REACT_APP_API_URL}membership/plan/`;
const CANCELLATION_REASON_URL = `${process.env.REACT_APP_API_URL}membership/plan/cancellation_reason/`;
const FETCH_PRESIGNED_POST_URL = `${process.env.REACT_APP_API_URL}common/presigned-post/`;
const SELL_SCREEN_DETAILS_URL = `${process.env.REACT_APP_API_URL}membership/sell-screen/`;
const CHOOSE_PLAN_DETAILS_URL = `${process.env.REACT_APP_API_URL}membership/pet_plans_details/`;

// const MEMBERSHIP_DISCOUNT_URL = (membershipId, petCount, referralCode = '') => `${process.env.REACT_APP_API_URL}membership/${membershipId}/pretax/?pet_count=${petCount}&referral_code=${referralCode}`;
// const MEMBERSHIP_DISCOUNT_URL = (membershipId, referralCode = '') => `${process.env.REACT_APP_API_URL}membership/${membershipId}/pretax/?pet_count=1&referral_code=${referralCode}`;
const MEMBERSHIP_MULTI_DISCOUNT_URL = (membershipIds, referralCode = '', paymentTenure) => `${process.env.REACT_APP_API_URL}membership/multi_pretax/?membership_ids=${membershipIds}&referral_code=${referralCode}&paymentTenure=${paymentTenure}`;
// const MEMBERSHIP_TAX_CALC_URL = (membershipId) => `${process.env.REACT_APP_API_URL}membership/${membershipId}/taxes/`;
const MEMBERSHIP_MULTI_TAX_CALC_URL = `${process.env.REACT_APP_API_URL}membership/multiplan-taxes/`;
const MEMBERSHIP_PLAN_TAX_CALC_URL = (membershipId) => `${process.env.REACT_APP_API_URL}membership/plan/${membershipId}/taxes/`;
const MEMBERSHIP_PLAN_LAST_CLINIC_SELECTED = (membershipId) => `${process.env.REACT_APP_API_URL}membership/plan/${membershipId}/last_clinic_claim/`;
const BENEFIT_REDEEM = (membershipPlanId, benefitId) => `${process.env.REACT_APP_API_URL}membership/plan/${membershipPlanId}/benefit/${benefitId}/redeem/`;
const BENEFIT_USAGE_HISTORY_URL = (membershipPlanId) => `${process.env.REACT_APP_API_URL}membership/plan/${membershipPlanId}/benefit/history/`;

const DISABLE_AUTO_RENEW_URL = (membershipPlanId) => `${process.env.REACT_APP_API_URL}membership/plan/${membershipPlanId}/cancel/`;

const REACTIVATE_MEMBERSHIP_PLAN_URL = (membershipPlanId) => `${process.env.REACT_APP_API_URL}membership/plan/${membershipPlanId}/reactivate/`;

const RENEW_MEMBERSHIP_PLAN_URL = `${process.env.REACT_APP_API_URL}membership/plan/renew/`;

const MEMBERSHIP_PLAN_UPDATE_PAYMENT_METHOD = (id) => `${process.env.REACT_APP_API_URL}membership/plan/${id}/payment/`;

const SWITCH_PLAN_PAYMENT = (id) => `${process.env.REACT_APP_API_URL}membership/plan/${id}/switch_membership_plan/`;

const NCR_RETURN_URL = process.env.REACT_APP_NCR_RETURN_URL;

const MEMBERSHIP_PLAN_CHANGE_SUBSCRIPTION_URL = (membershipPlanId) => `${process.env.REACT_APP_API_URL}membership/plan/${membershipPlanId}/change_subscription/`;
const UPDATE_PLANS_CC_URL = `${process.env.REACT_APP_API_URL}membership/plan/updateplanscc/`;
const TYPES_URL = `${process.env.REACT_APP_API_URL}pet/types/`;
const TOGGLES_URL = `${process.env.REACT_APP_API_URL}common/config/`;
const UI_TEXT_URL = `${process.env.REACT_APP_API_URL}common/uitext/`;

const AFFILIATE_REFERRAL_URL = (referralCode) => `${process.env.REACT_APP_API_URL}affiliate/validate/?referral_code=${referralCode}`;
const CUSTOMER_CARE_CODE_REFERRAL_URL = (customerCareCode) => `${process.env.REACT_APP_API_URL}affiliate/validate/?referral_code=${customerCareCode}&ccr=true`;
const AFFILIATE_URL = (affiliate) => `${process.env.REACT_APP_API_URL}affiliate/validate/?affiliate=${affiliate}`;

const AUTH_CC_PAYMENT_METHOD_URL = `${process.env.REACT_APP_API_URL}v2/payment/auth_cc/`;
const V2GATEWAY_METHOD_URL = `${process.env.REACT_APP_API_URL}v2/enrollment_receiver/push_to_queue/`;
const CACHE_SECONDS = 30;
// const { selectedTenureName } = useSelector(state => state.registrationInfo);
export const fetchMembershipPlans = (forceRefresh) => (dispatch, getState) => {
  // make fetch a no-op if the cache has not expired
  const store = getState();
  if (
    !store.customer.membershipPlans.cacheTime
    || timeAgoInSeconds(store.customer.membershipPlans.cacheTime) > CACHE_SECONDS
    || forceRefresh
  ) {
    return fetchJSON(
      MEMBERSHIP_PLAN_URL,
      (items) => {
        dispatch(customer.actions.setMembershipPlans(items));
      },
      dispatch,
    );
  }
};

export const changeMembershipPlanSubscription = (
  planId,
  choiceId,
  transitionSuccessHandler,
  transitionErrorHandler,
) => (dispatch) => {
  const payload = {
    membershipId: choiceId,
  };

  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  return fetchJSON(
    MEMBERSHIP_PLAN_CHANGE_SUBSCRIPTION_URL(planId),
    (response) => {
      transitionSuccessHandler(response);
      return response;
    },
    dispatch,
    transitionErrorHandler,
    options,
  );
};

export const updatePlansCC = (planIds, walletId) => (dispatch) => {
  const payload = { walletId, planIds };

  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  return fetchJSON(
    UPDATE_PLANS_CC_URL,
    (items) => items,
    dispatch,
    undefined,
    options,
  );
};

export const fetchPaymentMethods = (forceRefresh, selectedWalletId) => (dispatch, getState) => {
  // make fetch a no-op if the cache has not expired
  const store = getState();
  if (
    !store.customer.paymentMethods.cacheTime
    || timeAgoInSeconds(store.customer.paymentMethods.cacheTime) > CACHE_SECONDS
    || forceRefresh
  ) {
    return fetchJSON(
      PAYMENT_METHOD_URL,
      (items) => {
        dispatch(customer.actions.setPaymentMethods(items));
        if (selectedWalletId) {
          dispatch(customer.actions.setSelectedPayment(selectedWalletId));
        }
      },
      dispatch,
    );
  } else if (selectedWalletId) {
    dispatch(customer.actions.setSelectedPayment(selectedWalletId));
  }
};

export const fetchStoreLocations = (zipCode, errorHandler) => (dispatch) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = STORE_LOCATOR_URL(zipCode);
  return fetchJSON(
    url,
    (stores) => stores,
    dispatch,
    errorHandler,
    options,
  );
};

export const fetchClinicList = (query, successHandler, errorHandler) => (dispatch) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = VET_CLINIC_SERVICE_URL(query);
  return fetchJSON(
    url,
    (clinicList) => {
      successHandler(clinicList);
    },
    dispatch,
    (response) => response?.json()?.then(errorHandler),
    options,
  );
};

export const fetchLastClinicSelected = (membershipId, successHandler, errorHandler) => (dispatch) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = MEMBERSHIP_PLAN_LAST_CLINIC_SELECTED(membershipId);
  return fetchJSON(
    url,
    (clinic) => {
      successHandler(clinic);
    },
    dispatch,
    (response) => {
      errorHandler(response);
    },
    options,
  );
};

export const createWallet = (SessionID, walletCallback) => (dispatch, getState) => {
  const sucessHandler = async ({ walletId }) => {
    await dispatch(fetchPaymentMethods(true, walletId));
    if (walletCallback && typeof walletCallback === 'function') {
      walletCallback(walletId);
    }
  };

  const errorHandler = async (error) => {
    console.error('error', error);
    const body = await error.json();
    console.error('error body:', body);
    if (walletCallback && typeof walletCallback === 'function') {
      walletCallback(null, body);
    }
    const store = getState();
    const customerWithError = {
      ...store.customer,

      paymentMethods: {
        ...store.customer.paymentMethods,
        error: (error && error[0]) || 'Unable to create a credit card',
      },
    };
    customerWithError.cacheTime = moment().format();

    dispatch(customer?.actions?.setCustomer(customerWithError));
  };

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ SessionID }),
  };

  return fetchJSON(
    PAYMENT_METHOD_URL,
    sucessHandler,
    dispatch,
    errorHandler,
    options,
  );
};

export const createPet = (name, petType, primaryBreed, birthDate) => async (dispatch, getState) => {
  const currentState = getState();
  const data = {
    pets: [
      {
        name,
        petType: petType.value,
        primaryBreed: primaryBreed.value,
        birthDate,
      },
    ],
  };
  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 500) {
      // normal error handling path
      dispatch(fatalError.actions.set(true));
      dispatch(navigationHistory.actions.goBackToName());
    }
  };
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  const statusActions = {
    400: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      if (responseMessage.includes('Ensure this field has no more than 100 characters')) {
        dispatch(registrationInfo.actions.setOnPetNameRetry(true));
      } else {
        // normal error handling path
        dispatch(fatalError.actions.set(true));
        dispatch(navigationHistory.actions.goBackToName());
      }
      dispatch(loadingCount.actions.decrement());
    },
    500: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      if (responseMessage.includes('Invalid pet name.')) {
        dispatch(registrationInfo.actions.setOnPetNameRetry(true));
      } else {
        // normal error handling path
        dispatch(fatalError.actions.set(true));
        dispatch(navigationHistory.actions.goBackToName());
      }
      dispatch(loadingCount.actions.decrement());
    },
  };
  return fetchJSON(
    PETS_URL,
    (newPets) => {
      dispatch(customer.actions.clearPetsCacheTimer());
      dispatch(registrationInfo.actions.setCreatedPets(newPets));

      const newPetsToAdd = newPets.map((pet) => ({
        id: pet.petMasterId,
        proCareInfo: true,
        annualPlan: true,
      }));
      const currentSelectedPets = currentState.registrationInfo.enrolledPets;
      const newSelectedPets = [...currentSelectedPets, ...newPetsToAdd];
      dispatch(registrationInfo.actions.setSelectedPetsToEnroll(newSelectedPets));
      dispatch(registrationInfo.actions.setOnPetNameRetry(false));
    },
    dispatch,
    onError,
    options,
    statusActions,
  );
};

export const createPetV2 = (
  name,
  petType,
  primaryBreed,
  birthDate,
  successCallback,
) => async (dispatch, getState) => {
  const currentState = getState();

  dispatch(registrationInfo.actions.setPetNameErrorMessage(null));

  const data = {
    pets: [
      {
        name,
        petType,
        primaryBreed,
        birthDate,
      },
    ],
  };

  const statusActions = {
    400: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      const responseObject = JSON.parse(responseMessage);
      dispatch(registrationInfo.actions.setPetNameErrorMessage(responseObject[0]?.name[0]));
      dispatch(loadingCount.actions.decrement());
    },
    500: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      dispatch(registrationInfo.actions.setPetNameErrorMessage(responseMessage));
      dispatch(loadingCount.actions.decrement());
    },
  };

  const prioritizeErrorHandlingThroughStatusActions = async () => { };

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetchJSON(
    PETS_URL,
    (newPets) => {
      dispatch(customer.actions.clearPetsCacheTimer());
      dispatch(registrationInfo.actions.setCreatedPets(newPets));

      const newPetsToAdd = newPets.map((pet) => ({
        id: pet.petMasterId,
        proCareInfo: true,
        annualPlan: true,
      }));
      const currentSelectedPets = currentState.registrationInfo.enrolledPets;
      const newSelectedPets = [...currentSelectedPets, ...newPetsToAdd];
      dispatch(registrationInfo.actions.setSelectedPetsToEnroll(newSelectedPets));
      dispatch(registrationInfo.actions.setOnPetNameRetry(false));
      successCallback();
    },
    dispatch,
    prioritizeErrorHandlingThroughStatusActions,
    options,
    statusActions,
  );
};

export const createMembershipPlans = (storeId, completionCallback) => (dispatch, getState) => {
  const store = getState();
  const data = {
    pets: store.registrationInfo.enrolledPets.filter((pet) => pet.proCareInfo === true).map((pet) => ({
      petId: pet.id,
      membershipId: store.memberships.details.id,
    })),
    walletId: store.registrationInfo.paymentMethod,
  };
  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 500) {
      // normal error handling path
      dispatch(fatalError.actions.set(true));
    }
  };

  const headers = { 'Content-Type': 'application/json' };

  if (storeId) headers.store_number = storeId; // eslint-disable-line

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  };

  const statusActions = {
    500: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      const errorBody = JSON.parse(responseMessage);
      dispatch(registrationInfo.actions.setOnMembershipCreateError(
        {
          error: true,
          message: errorBody.message,
          errors: errorBody.errors,
        },
      ));
      dispatch(loadingCount.actions.decrement());
      completionCallback();
    },
  };

  return fetchJSON(
    MEMBERSHIP_PLAN_URL,
    (createdMembershipPlans) => {
      dispatch(customer.actions.clearMembershipPlansCacheTimer());
      dispatch(registrationInfo.actions.setCreatedMembershipPlans(createdMembershipPlans));
      dispatch(dashboardInfo.actions.setPetMasterId(createdMembershipPlans[0]?.pet));
      completionCallback();
    },
    dispatch,
    onError,
    options,
    statusActions,
  );
};

export const createMembershipPlansV2 = (storeId, paymentTenure, completionCallback) => (dispatch, getState) => {
  const store = getState();

  const data = {
    pets: store.registrationInfo.enrolledPets
      .filter((pet) => pet.proCareInfo === true)
      .map(pet => {
        const customerPet = store.customer.pets.data.find(p => p.petMasterId === pet.id);
        const membershipByPetType = store.membershipsV2.memberships.find(m => m.petType === customerPet.petType);

        return {
          petId: pet.id,
          membershipId: membershipByPetType.id,
        };
      }),
    walletId: store.registrationInfo.paymentMethod,
    referralCode: store?.affiliateReferralCode?.referral_code,
    customerCareCode: store?.affiliateReferralCode?.customer_care_code,
    paymentTenure,
  };

  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 500) {
      // normal error handling path
      dispatch(fatalError.actions.set(true));
    }
  };

  const headers = { 'Content-Type': 'application/json' };

  if (storeId) headers.store_number = storeId; // eslint-disable-line

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  };

  const statusActions = {
    500: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      const errorBody = JSON.parse(responseMessage);
      dispatch(registrationInfo.actions.setOnMembershipCreateError(
        {
          error: true,
          message: errorBody.message,
          errors: errorBody.errors,
        },
      ));
      dispatch(loadingCount.actions.decrement());
      completionCallback();
    },
    201: async () => {
      dispatch(loadingCount.actions.increment());
      dispatch(registrationInfo.actions.setOnMembershipCreateError(
        {
          error: false,
          message: null,
          errors: [],
        },
      ));
      dispatch(loadingCount.actions.decrement());
      completionCallback();
    },
  };

  return fetchJSON(
    MEMBERSHIP_PLAN_URL,
    (createdMembershipPlans) => {
      dispatch(customer.actions.clearMembershipPlansCacheTimer());
      dispatch(registrationInfo.actions.setCreatedMembershipPlans(createdMembershipPlans));
      dispatch(dashboardInfo.actions.setPetMasterId(createdMembershipPlans[0]?.pet));
      completionCallback();
    },
    dispatch,
    onError,
    options,
    statusActions,
  );
};
/*
  ** Below function is used to trigger POST request to createMembershipPlans(related to architecural implementation).
  */
export const createMembershipPlansVcVersion2 = (membershipPlanData, completionCallback) => (dispatch, getState) => {
  const store = getState();

  const data = {
    pets: store.registrationInfo.enrolledPets
      .filter((pet) => pet.proCareInfo === true)
      .map(pet => {
        const customerPet = store.customer.pets.data.find(p => p.petMasterId === pet.id);
        const membershipByPetType = store.membershipsV2.memberships.find(m => m.petType === customerPet.petType);

        return {
          petId: pet.id,
          membershipId: membershipByPetType.id,
        };
      }),
    walletId: store.registrationInfo.paymentMethod,
    referralCode: store?.affiliateReferralCode?.referral_code,
    paymentTenure: membershipPlanData.paymentTenure,
    totalCharges: membershipPlanData.totalCharges,
    transactionType: membershipPlanData.transactionType,
    wsConnectionId: membershipPlanData.wsConnectionId,
    referenceId: membershipPlanData.referenceId,
    petCosts: membershipPlanData.petCostsByMembershipId,
    paymentAuthCode: membershipPlanData.authKey,
    wcsUserId: membershipPlanData.wcsUserId,

  };
  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== undefined && statusCode !== 500) {
      dispatch(fatalError.actions.set(true));
    }
  };

  const headers = { 'Content-Type': 'application/json' };

  if (membershipPlanData.storeId) headers.store_number = membershipPlanData.storeId; // eslint-disable-line

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  };

  const statusActions = {
    500: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      const errorBody = JSON.parse(responseMessage);
      dispatch(registrationInfo.actions.setOnMembershipCreateError(
        {
          error: true,
          message: errorBody.message,
          errors: errorBody.errors,
        },
      ));
      dispatch(loadingCount.actions.decrement());
      completionCallback();
    },
    201: async (response) => {
      dispatch(loadingCount.actions.increment());
      completionCallback();
    },
  };

  return fetchJSON(
    V2GATEWAY_METHOD_URL,
    (createdMembershipPlans) => {
      dispatch(customer.actions.clearMembershipPlansCacheTimer());
      dispatch(customer.actions.setLoadingPets(true));
      completionCallback();
    },
    dispatch,
    onError,
    options,
    statusActions,
  );
};

export const fetchCancellationReasons = (reasonsCallback) => (dispatch) => fetchJSON(
  CANCELLATION_REASON_URL,
  reasonsCallback,
  dispatch,
  undefined,
);

export const disableAutoRenew = (membershipPlanId, cancellationReasonId, comments) => (dispatch) => {
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ cancellationReasonId, cancellationComments: comments }),
  };

  return fetchJSON(
    DISABLE_AUTO_RENEW_URL(membershipPlanId),
    () => dispatch(fetchMembershipPlans(true)),
    dispatch,
    undefined,
    options,
  );
};

export const reactivateMembershipPlan = (membershipPlanId, walletId, isNCRtrue, selectedPaymentType, isAutoRenew) => (dispatch, getState) => {
  const payload = { walletId, selectedPaymentType };
  const store = getState();

  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: walletId ? JSON.stringify(payload) : null,
  };

  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 400) {
      // normal error handling path
      dispatch(fatalError.actions.set(true));
    }
  };

  const statusActions = {
    400: async () => {
      dispatch(loadingCount.actions.increment());
      const errorMessage = 'There is an issue with the payment method selected. Please select a different payment method to proceed.';
      dispatch(dashboardInfo.actions.setOnMembershipReactivationError(
        {
          occurred: true,
          message: errorMessage,
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
    202: async () => {
      dispatch(loadingCount.actions.increment());
      if (!isAutoRenew) {
        // related to auto-renew of plans where automatic renew for yearly should get enabled
        const responseMessage = store?.uiText?.autoRenewSuccessMessage;
        const successMessage = responseMessage;
        dispatch(dashboardInfo.actions.setOnMembershipAutoRenewSuccessMessage(
          successMessage,
        ));
      } else {
        // related to reactivation of cancelled plans
        const responseMessage = 'Your plan has successfully been reactivated!';
        const paymentUpdateResponseMessage = 'Payment method updated. Your plan has successfully been reactivated!';
        const successMessage = isNCRtrue ? paymentUpdateResponseMessage : responseMessage;
        dispatch(dashboardInfo.actions.setOnMembershipReactivationSuccessMessage(
          successMessage,
        ));
      }
      dispatch(dashboardInfo.actions.setOnMembershipReactivationError(
        {
          occurred: false,
          message: null,
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
  };

  return fetchJSON(
    REACTIVATE_MEMBERSHIP_PLAN_URL(membershipPlanId),
    () => dispatch(fetchMembershipPlans(true)),
    dispatch,
    onError,
    options,
    statusActions,
  );
};

export const renewMembershipPlan = (membershipPlanId) => (dispatch) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ plan_id: membershipPlanId }),
  };

  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 400) {
      // normal error handling path
      dispatch(fatalError.actions.set(true));
    }
  };

  const statusActions = {
    400: async () => {
      dispatch(loadingCount.actions.increment());
      const errorMessage = 'There is an issue with the payment method selected. Please select a different payment method to proceed.';
      dispatch(dashboardInfo.actions.setOnMembershipRenewalError(
        {
          occurred: true,
          message: errorMessage,
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
    201: async () => {
      dispatch(loadingCount.actions.increment());
      const successMessage = 'Payment method updated.';
      dispatch(dashboardInfo.actions.setOnMembershipRenewalSuccessMessage(
        successMessage,
      ));
      dispatch(dashboardInfo.actions.setOnMembershipRenewalError(
        {
          occurred: false,
          message: null,
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
  };

  return fetchJSON(
    RENEW_MEMBERSHIP_PLAN_URL,
    () => dispatch(fetchMembershipPlans(true)),
    dispatch,
    onError,
    options,
    statusActions,
  );
};

export const fetchPlanBenefitUsageHistory = (membershipPlanId, blocking = false) => (dispatch) => {
  let errorHandler;
  if (!blocking) {
    errorHandler = () => {
      // purposelly nothing to swallow errors, app can keep behaving
    };
  }

  fetchJSON(
    BENEFIT_USAGE_HISTORY_URL(membershipPlanId),
    (items) => {
      const payload = {
        membershipPlanId,
        benefitUsages: items.benefits,
      };
      dispatch(dashboardInfo.actions.setPlanBenefitUsageHistory(payload));
    },
    dispatch,
    errorHandler,
    undefined,
    undefined,
    blocking,
  );
};

export const paymentMethodUpdate = (planId, walletId, status) => (dispatch) => {
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ walletId }),
  };
  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 400) {
      // normal error handling path
      dispatch(fatalError.actions.set(true));
    }
  };

  const statusActions = {
    400: async () => {
      dispatch(loadingCount.actions.increment());
      const errorMessage = 'There is an issue with the payment method selected. Please select a different payment method to proceed.';
      dispatch(dashboardInfo.actions.setOnMembershipPaymentMethodUpdateError(
        {
          occurred: true,
          message: errorMessage,
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
    202: async () => {
      dispatch(loadingCount.actions.increment());
      dispatch(dashboardInfo.actions.clearOnMembershipPaymentMethodUpdateError({ occurred: false, message: null }));
      dispatch(customer.actions.clearPaymentMethodError());
      dispatch(loadingCount.actions.decrement());
      if (status === 'expired') {
        await dispatch(renewMembershipPlan(planId));
      }
    },
  };

  return fetchJSON(
    MEMBERSHIP_PLAN_UPDATE_PAYMENT_METHOD(planId),
    (items) => items,
    dispatch,
    onError,
    options,
    statusActions,
  );
};

export const switchToYearlyPayment = (planId, walletId) => (dispatch) => {
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ wallet_card_id: walletId }),
  };

  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 400) {
      // normal error handling path
      // dispatch(fatalError.actions.set(true));
    }
  };

  const statusActions = {
    400: async () => {
      dispatch(loadingCount.actions.increment());
      const errorMessage = 'There is an issue with the payment method selected. Please select a different payment method to proceed.';
      dispatch(dashboardInfo.actions.setOnMembershipPaymentMethodUpdateError(
        {
          occurred: true,
          message: errorMessage,
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
    202: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      const successMessage = JSON.parse(responseMessage);
      dispatch(dashboardInfo.actions.setSwitchMembershipSuccessMessage(
        successMessage?.message,
      ));
      dispatch(loadingCount.actions.decrement());
    },
  };

  return fetchJSON(
    SWITCH_PLAN_PAYMENT(planId),
    (items) => items,
    dispatch,
    onError,
    options,
    statusActions,
  );
};

/**
   * this paymentMethodUpdateForAuthCC is used for AuthCC process in new architectural changes .
   */
export const paymentMethodUpdateForAuthCC = (walletId, membershipTotalPrice, wcsUserId, trasactionType) => (dispatch) => {
  const options = {
    method: 'POST',
    mode: 'cors',
    preflightContinue: 'false',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(
      {
        walletId,
        txnAmount: membershipTotalPrice,
        txnType: trasactionType,
        wcs_user_id: wcsUserId,
      },
    ),
  };
  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 400) {
      // normal error handling path
      dispatch(fatalError.actions.set(true));
    }
  };

  const statusActions = {
    400: async () => {
      dispatch(loadingCount.actions.increment());
      const errorMessage = 'There is an issue with the payment method selected. Please select a different payment method to proceed.';
      dispatch(registrationInfo.actions.setOnMembershipPaymentMethodError(
        {
          occurred: true,
          message: errorMessage,
          statusCode: 'error',
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
    200: async () => {
      dispatch(loadingCount.actions.increment());
      dispatch(registrationInfo.actions.clearOnMembershipPaymentMethodError({ occurred: false, message: null, statusCode: 'success' }));
      dispatch(loadingCount.actions.decrement());
    },
  };

  return fetchJSON(
    AUTH_CC_PAYMENT_METHOD_URL,
    (items) => {
      dispatch(registrationInfo.actions.setOnMembershipPaymentAuthCC({ authCCResponse: items }));
    },
    dispatch,
    onError,
    options,
    statusActions,
  );
};

export const fetchTypes = () => (dispatch) => {
  fetchJSON(
    TYPES_URL,
    (items) => {
      const actualPetTypes = items;

      //  NOTE: Temporarily updating the Small Animal to Small Pet in future when PGR updates
      //  the type to Small Pet remove/delete this piece and other related part of code
      const filteredPetTypes = actualPetTypes?.map(petType => {
        if (petType?.value === 'Small Animal') {
          return { ...petType, value: 'Small Pet' };
        }
        return petType;
      });

      dispatch(petTypes.actions.set(filteredPetTypes));

      // NOTE: Once you remove the above piece of code post PGR fixes uncomment the below line(688)
      // dispatch(petTypes.actions.set(items));
    },
    dispatch,
  );
};

export const fetchCustomer = () => (dispatch, getState) => {
  // make fetch a no-op if the cache has not expired
  const store = getState();
  if (!store.customer.cacheTime || timeAgoInSeconds(store.customer.cacheTime) > CACHE_SECONDS) {
    fetchJSON(
      CUSTOMER_URL,
      (data) => {
        dispatch(customer.actions.setUser(data.user));
      },
      dispatch,
    );
  }
};

export const fetchCustomerAddress = () => (dispatch) => fetchJSON(
  CUSTOMER_ADDRESS_URL,
  (data) => {
    dispatch(customer.actions.setCustomerAddress(data));
  },
  dispatch,
);

export const fetchMemberships = (successCallback) => (dispatch, getState) => {
  // make fetch a no-op if the cache has not expired
  const store = getState();

  const guid = new URLSearchParams(store.url.locationSearch).get('webguid');
  const options = guid ? { headers: { 'Web-GUID': guid } } : {};

  if (!store.memberships.cacheTime || timeAgoInSeconds(store.memberships.cacheTime) > CACHE_SECONDS) {
    fetchJSON(
      MEMBERSHIPS_URL,
      (items) => {
        /* eslint-disable */
        dispatch(memberships.actions.setDetail(items));
        successCallback();
      },
      dispatch,
      // need to add this log because due to a back-end change we were facing a
      // `TypeError: Cannot read property 'toLowerCase' of undefined` and nothing
      //  was displayed but the Uh-oh page.
      (response) => {
        dispatch(fatalError.actions.set(true));
        LOGGER.info('Error fetching Memberships', response);
      },
      options,
    );
  }
};

export const fetchMembershipsV2 = (successCallback) => (dispatch, getState) => {
  // make fetch a no-op if the cache has not expired
  const store = getState();

  const guid = new URLSearchParams(store.url.locationSearch).get('webguid');
  const options = guid ? { headers: { 'Web-GUID': guid } } : {};

  if (!store.memberships.cacheTime || timeAgoInSeconds(store.memberships.cacheTime) > CACHE_SECONDS) {
    fetchJSON(
      MEMBERSHIPS_URL,
      (items) => {
        /* eslint-disable */
        // TODO: Come back and change this after the backend changes are done.
        dispatch(membershipsV2.actions.setDetail(items));
        successCallback();
      },
      dispatch,
      // need to add this log because due to a back-end change we were facing a
      // `TypeError: Cannot read property 'toLowerCase' of undefined` and nothing
      //  was displayed but the Uh-oh page.
      (response) => {
        dispatch(fatalError.actions.set(true));
        LOGGER.info('Error fetching Memberships', response);
      },
      options,
    );
  }
};

export const fetchPets = (blocking = false) => (dispatch, getState) => {
  // make fetch a no-op if the cache has not expired
  const store = getState();
  if (!store.customer.pets.loadingPets
    && (!store.customer.pets.cacheTime || timeAgoInSeconds(store.customer.pets.cacheTime) > CACHE_SECONDS)) {
    let errorHandler;
    if (!blocking) {
      errorHandler = () => {
        // purposelly nothing to swallow errors, app can keep behaving
      };
    }

    try {
      return new Promise(() => {
        dispatch(customer.actions.setLoadingPets(true));
        return fetchJSON(
          PETS_URL,
          (items) => { dispatch(customer.actions.setPets(items)); },
          dispatch,
          errorHandler,
          undefined,
          undefined,
          blocking,
        );
      });
    } catch (error) {
      throw new Error(`An error occured when fetching pets: ${error}`)
    }
  }
};

export const fetchToggles = () => (dispatch, getState) => {
  const store = getState();
  const apuKey = store.url.apuKey;
  return fetchJSON(
    TOGGLES_URL,
    (result) => {
      const filteredToggles = checkToggles(apuKey, store?.uiText?.ApuKey, result);
      dispatch(toggles.actions.set(filteredToggles));
    },
    dispatch,
    undefined,
  );
}

export const fetchUiText = () => (dispatch) => {
  return fetchJSON(
    UI_TEXT_URL,
    (result) => {
      dispatch(uiText.actions.set(result));
    },
    dispatch,
    undefined,
  );
}

export const getSellScreenDetails = (blocking = false) => (dispatch) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = SELL_SCREEN_DETAILS_URL;
  return fetchJSON(
    url,
    (sellScreenData) => {
      const data = {};
      const benefitsObj = {};
      data.sellScreenData = sellScreenData;

      for (let i = 0; i < sellScreenData.length; i++) {
        const benefitsArr = sellScreenData[i].benefits.map(entry => {
          benefitsObj[Object.keys(entry)[0]] = Object.values(entry)[0];
          return { Title: Object.keys(entry)[0], Value: Object.values(entry)[0] };
        });
        data.sellScreenData[i].benefits = benefitsArr;
      }

      /* NOTE: Temporarily updating the Small Animal to Small Pet in future when PGR updates
      the type to Small Pet remove/delete this piece and other related part of code */

      const filteredData = sellScreenData?.map(obj => {
        if (obj?.pet_type === 'Small Animal') {
          return { ...obj, pet_type: 'Small Pet' };
        }
        return obj;
      });

      data.sellScreenData = filteredData;

      dispatch(registrationInfo.actions.setSellScreenDetails(data));
    },
    dispatch,
    undefined,
    options,
    undefined,
    blocking,
  );
};

// FYI fetchAllDiscounts and fetchAllTaxes are storing into the same redux store
// The responses are different but there is a shared subset of the API response we use
// Those keys are subtotal, tax, total, discoutns and petCosts. The rest are not guaranteed to work if accessed
export const fetchAllDiscounts = (membershipIds) => (dispatch, getState) => {
  const store = getState();
  const referralCode = store?.affiliateReferralCode?.referral_code;
  // made change to the below line to get the selected tenure from local storage now, because in mobile app we are not able to get the selected tenure
  // from the store, if the user is a new user and try to add a new card in signup flow. This is because in mobile app the aci form is opening as a separate
  // webview and we are not able to get the selected tenure from the store. So, we are storing the selected tenure in local storage and getting it from there.
  const paymentTenure = localStorage.getItem('selectedTenure');
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  fetchJSON(
    MEMBERSHIP_MULTI_DISCOUNT_URL(membershipIds, referralCode, paymentTenure),
    (discountInfo) => {
      let nextDiscountInfo;
      let discounts = [];
      if (discountInfo.length > 0) {
        discountInfo.forEach((pretax) => {
          const subtotal = pretax?.total ? pretax?.total : pretax?.subtotal;
          nextDiscountInfo = {
            ...pretax,
            tax: 0,
            total: 0,
            subtotal: parseFloat(subtotal),
          };
          discounts.push(nextDiscountInfo)
        });
        dispatch(registrationInfo.actions.setTaxAndDiscountInfo(discounts));
      } else {
        nextDiscountInfo = {
          ...discountInfo,
          tax: 0,
          total: 0,
          subtotal: parseFloat(discountInfo.subtotal),
        };
        dispatch(registrationInfo.actions.setTaxAndDiscountInfo(nextDiscountInfo));
      }
    },
    dispatch,
    undefined,
    options,
  );
};

export const fetchAllTaxes = (walletId, petCount, membershipIds) => (dispatch, getState) => {
  const currentState = getState();
  const referralCode = currentState?.affiliateReferralCode?.referral_code;
  // made change to the below line to get the selected tenure from local storage now, because in mobile app we are not able to get the selected tenure
  // from the store, if the user is a new user and try to add a new card in signup flow. This is because in mobile app the aci form is opening as a separate
  // webview and we are not able to get the selected tenure from the store. So, we are storing the selected tenure in local storage and getting it from there.
  const paymentTenure = localStorage.getItem('selectedTenure');
  const data = { walletId, petCount, referralCode, membership_ids: membershipIds, paymentTenure };

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  fetchJSON(
    MEMBERSHIP_MULTI_TAX_CALC_URL,
    (annualTaxInfo) => {
      let nextDiscountInfo;
      let discounts = [];
      if (annualTaxInfo.length > 0) {
        annualTaxInfo.forEach((annualTax) => {
          nextDiscountInfo = {
            ...annualTax,
            tax: parseFloat(annualTax.tax),
            taxRate: parseFloat(annualTax.taxRate),
            total: parseFloat(annualTax.total),
            subtotal: parseFloat(annualTax.subtotal),
          };
          discounts.push(nextDiscountInfo)
        });
        dispatch(registrationInfo.actions.setTaxAndDiscountInfo(discounts));
        dispatch(registrationInfo.actions.setOnMembershipPaymentAuthCC({ isTotalPrice: true }));
      } else {
        const nextAnnualTaxInfo = {
          ...annualTaxInfo,
          tax: parseFloat(annualTaxInfo.tax),
          taxRate: parseFloat(annualTaxInfo.taxRate),
          total: parseFloat(annualTaxInfo.total),
          subtotal: parseFloat(annualTaxInfo.subtotal),
        };

        dispatch(registrationInfo.actions.setTaxAndDiscountInfo(nextAnnualTaxInfo));
        dispatch(registrationInfo.actions.setOnMembershipPaymentAuthCC({ isTotalPrice: true }));
      }
    },
    dispatch,
    undefined,
    options,
  );
};

// fetch plan details from api for select payment plan screen
export const fetchPlansDetail = (selectedPets, membershipId, wcsId, screenType, planId) => (dispatch, getState) => {
  const payload = [];
  if (screenType === 'Reactivation' | screenType === 'Dashboard') {
    payload.push({ pet_name: selectedPets, membership_id: membershipId });
  } else {
    const idArr = membershipId.split(',');
    selectedPets.forEach((pet, index) => {
      payload.push({ pet_name: pet.name, membership_id: parseInt(idArr[index]) });
    });
  }
  const body = screenType === 'Dashboard' && planId ? JSON.stringify({ wcs_user_id: parseInt(wcsId), plan_id: planId, pet_items: payload }) :
    JSON.stringify({ wcs_user_id: parseInt(wcsId), pet_items: payload });

  const store = getState();
  const apuKey = store.url.apuKey;
  const headers = getRequestHeaders(apuKey, store?.uiText?.ApuKey);

  const options = {
    method: 'POST',
    headers,
    body: body,
  };

  fetchJSON(
    CHOOSE_PLAN_DETAILS_URL,
    (result) => {
      let petPaymentPlanDetails = [];
      if (screenType === 'Reactivation') {
        petPaymentPlanDetails.push(result?.monthly?.petData[0]);
        petPaymentPlanDetails.push(result?.yearly?.petData[0]);
        for (let i = 0; i < petPaymentPlanDetails.length; i++) {
          petPaymentPlanDetails[i].btnTitle = result?.uiTextData[i].btnLabel;
          if (result?.uiTextData[i].btnName === 'monthly') {
            petPaymentPlanDetails[i].btnSubTitle = result?.uiTextData[i].btnText;
          } else {
            petPaymentPlanDetails[i].btnHighlightTitle = store?.uiText?.greenHighlightedDiscountText;
            petPaymentPlanDetails[i].displayPlanSavingBanner = result?.displayPlanSavingBanner;
          }
          petPaymentPlanDetails[i].btnValue = result?.uiTextData[i].btnName;
        }
        dispatch(paymentPlansDetail.actions.set(petPaymentPlanDetails));
      } else {
        dispatch(paymentPlansDetail.actions.set(result));
      }
    },
    dispatch,
    undefined,
    options,
  )
}

export const fetchATax = (
  membershipPlanId,
  taxCallback,
  errorCallback,
) => (dispatch) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  fetchJSON(
    MEMBERSHIP_PLAN_TAX_CALC_URL(membershipPlanId),
    taxCallback,
    dispatch,
    errorCallback,
    options,
  );
};

export const fetchCoupons = (planId, benefit, couponType = null, couponCallback) => (dispatch, getState) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  };

  const store = getState();

  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode === 500) {
      // normal error handling path
      dispatch(fatalError.actions.set(false));
    }
  };

  const statusActions = {
    500: async () => {
      dispatch(loadingCount.actions.increment());
      dispatch(dashboardInfo.actions.setRedeemBenefitsCouponErrorMessage(
        {
          occurred: true,
          message: store.uiText.redeemBenefitsCouponErrorMessage,
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
    201: () => {
      if (couponType === 'redeemBenefitsCoupons') {
        dispatch(loadingCount.actions.increment());
        dispatch(dashboardInfo.actions.setRedeemBenefitsCouponErrorMessage());
        dispatch(loadingCount.actions.decrement());
      }
    }
  };

  fetchJSON(
    BENEFIT_REDEEM(planId, benefit.id),
    (coupons) => {
      coupons[0].benefitId = benefit.id;
      coupons[0].planId = planId;
      couponCallback(coupons);
    },
    dispatch,
    onError,
    options,
    statusActions,
  );
};

export const redeemCouponV2 = (benefit, couponCallback) => (dispatch) => {
  const { id: benefitId, planId, target } = benefit;

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      target,
    }),
  };

  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 400) {
      // normal error handling path
      dispatch(fatalError.actions.set(true));
    }
  };

  const statusActions = {
    400: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      const errorMessage = JSON.parse(responseMessage);
      dispatch(dashboardInfo.actions.setThirdPartyErrorMessage(
        {
          occurred: true,
          message: errorMessage[0],
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
  };

  fetchJSON(
    BENEFIT_REDEEM(planId, benefitId),
    (coupons) => {
      coupons[0].benefitId = benefit.id;
      coupons[0].planId = planId;
      couponCallback(coupons);
    },
    dispatch,
    onError,
    options,
    statusActions
  );
};

export const redeemCoupon = (benefit, couponCallback) => (dispatch) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  };
  const { id: benefitId, planId } = benefit;
  fetchJSON(
    BENEFIT_REDEEM(planId, benefitId),
    (coupons) => {
      couponCallback(coupons);
    },
    dispatch,
    undefined,
    options,
  );
};

export const submitClaim = (planId, benefitId, claimClinic, claimDate, claimFileKey) => (dispatch) => {
  const payload = {
    claimData: {
      clinic: claimClinic,
    },
    claimDate,
    imageKey: claimFileKey,
  };
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  return fetchJSON(
    BENEFIT_REDEEM(planId, benefitId),
    (response) => response,
    dispatch,
    undefined,
    options,
  );
};

export const fetchNCRIframe = () => (dispatch) => {
  fetchJSON(
    INITIALIZE_SESSION_URL,
    (items) => {
      dispatch(registrationInfo.actions.setNCRIframe(items));
    },
    dispatch,
    () => {
      dispatch(fatalError.actions.set(true));
    },
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ReturnURL: NCR_RETURN_URL }),
    },
  );
};

export const resetNCRIframe = () => (dispatch) => {
  dispatch(registrationInfo.actions.setNCRIframe({ SessionId: null, RequestURL: null }));
};

export const fetchPresignedPostURL = (file, successCallback, errorCallback) => (dispatch) => {
  const body = {
    fileName: file.name,
  };
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  fetchJSON(
    FETCH_PRESIGNED_POST_URL,
    ({ presignedPost }) => {
      successCallback(presignedPost, file);
    },
    dispatch,
    errorCallback,
    options,
  );
};

export const validateReferralCode = (referral_code_data, affiliate) => (dispatch) => {
  const referral_code = referral_code_data?.referralCode?.toUpperCase().trim();
  const customer_care_code = referral_code_data?.customerCareCode?.toUpperCase().trim();

  if (customer_care_code) {
    affiliateReferralCode.actions.setOnCustomerCareErrorMessage({ occurred: false, message: '' })
  } else {
    affiliateReferralCode.actions.setOnAffiliateErrorMessage({ occurred: false, message: '' })
  }
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  let url;

  if (affiliate) {
    url = AFFILIATE_URL(affiliate);
  } else {
    customer_care_code ? url = CUSTOMER_CARE_CODE_REFERRAL_URL(customer_care_code) : url = AFFILIATE_REFERRAL_URL(referral_code);
  }


  const onError = async (response) => {
    const statusCode = response.status;
    if (statusCode !== 400) {
      // normal error handling path
      //  dispatch(fatalError.actions.set(true));
    }
  };

  const affiliateStatusActions = {
    400: async (response) => {
      const responseMessage = await response.text();
      const errorMessage = JSON.parse(responseMessage);
      dispatch(loadingCount.actions.increment());
      dispatch(affiliateReferralCode.actions.setOnAffiliateErrorMessage(
        {
          occurred: true,
          message: errorMessage?.message,
        },
      ));
      dispatch(loadingCount.actions.decrement());
    },
    200: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      const success = JSON.parse(responseMessage);
      dispatch(affiliateReferralCode.actions.setOnAffiliateErrorMessage(
        {
          occurred: false,
          message: null,
        },
      ));
      dispatch(affiliateReferralCode.actions.setReferralCode(success?.referralCode));
      dispatch(affiliateReferralCode.actions.setOnAffiliateSuccessMessage(
        success?.message,
      ));
      dispatch(loadingCount.actions.decrement());
    },
  };

  const referralStatusActions = {
    400: async (response) => {
      const responseMessage = await response.text();
      const errorMessage = JSON.parse(responseMessage);
      dispatch(loadingCount.actions.increment());
      if (customer_care_code) {
        dispatch(affiliateReferralCode.actions.setOnCustomerCareErrorMessage(
          {
            occurred: true,
            message: errorMessage?.message,
          }))
        dispatch(affiliateReferralCode.actions.setCustomerCareCode(''))
      } else {
        dispatch(affiliateReferralCode.actions.setOnAffiliateErrorMessage(
          {
            occurred: true,
            message: errorMessage?.message,
          },
        ))
        dispatch(affiliateReferralCode.actions.setReferralCode(''))
      }

      dispatch(loadingCount.actions.decrement());
    },
    200: async (response) => {
      dispatch(loadingCount.actions.increment());
      const responseMessage = await response.text();
      const success = JSON.parse(responseMessage);
      if (success?.isValid) {
        referral_code ? dispatch(affiliateReferralCode.actions.setOnAffiliateErrorMessage(
          {
            occurred: false,
            message: null,
          },
        )) : dispatch(affiliateReferralCode.actions.setOnCustomerCareErrorMessage(
          {
            occurred: false,
            message: null,
          }));
        if (referral_code) {
          dispatch(affiliateReferralCode.actions.setReferralCode(referral_code))
          dispatch(affiliateReferralCode.actions.setOnAffiliateSuccessMessage(
            success?.message,
          ));
        } else {
          dispatch(affiliateReferralCode.actions.setCustomerCareCode(customer_care_code))
          dispatch(affiliateReferralCode.actions.setOnCustomerCareSuccessMessage(
            success?.message,
          ));
        }
      } else {
        referral_code ? dispatch(affiliateReferralCode.actions.setReferralCode(referral_code)) : dispatch(affiliateReferralCode.actions.setCustomerCareCode(customer_care_code))
        customer_care_code ? dispatch(affiliateReferralCode.actions.setOnCustomerCareErrorMessage(
          {
            occurred: true,
            message: success?.message,
          },
        )) : dispatch(affiliateReferralCode.actions.setOnAffiliateErrorMessage(
          {
            occurred: true,
            message: success?.message,
          },
        ))
      }
      dispatch(loadingCount.actions.decrement());
    },
  };
  return fetchJSON(
    url,
    (successMessage) => {
      successMessage;
    },
    dispatch,
    onError,
    options,
    affiliate ? affiliateStatusActions : referralStatusActions,
  );
};

import React from 'react';
import Icon from '../Icon/Icon';
import './vital-care-tip.css';


const VitalCareTip = ({
  displayCompanion,
  selectedPets,
  petAlreadyEnrolled,
  toggles,
  uiText,
}) => {
  const singlePetMessage = toggles?.ENABLE_UNIFIED_MEMBERSHIP
    ? (
      <p className="tip tip-um">
        <span>{uiText?.vitalCareTipHeadingUmTxt}: </span>
        {uiText?.vitalCareTipUmTxt}
      </p>
    )
    : (
      <p className="tip">
        <span>{uiText?.vitalCareTipHeadingNonUmTxt}: </span>
        {uiText?.vitalCareTipNonUmTxt}
      </p>
    );
    
  // function to get tip data for selecting multiple pet at a time.
  const getMultipetTip = () => {
    if ((!petAlreadyEnrolled && selectedPets.length === 1) || (selectedPets.length === 0)) {
      return singlePetMessage;
    } else {
      return (
        <p className={`${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'tip tip-um' : 'tip'}`}>
          <span>{uiText?.congratulationsMultiPetMessage}</span>
        </p>
      );
    }
  };

  // function to get tip data.
  const getTipData = () => {
    if (toggles?.ENABLE_MULTIPET_TIP_EXPERIENCE) {
      return getMultipetTip();
    } else {
      return singlePetMessage;
    }
  };

  return (
    <>
      <div className={`${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'Vital-care-tip-page-v2 vital-care-tip vital-care-tip-um' : 'Vital-care-tip-page-v2 vital-care-tip'}`}>
        <div className="icons-wrapper">
          {displayCompanion ? (
            toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <Icon name="multi-pet-blue" size="43" /> : <Icon name="multi-pet-green" size="43" />
          ) : (
            <>
              <Icon id="icon-dog" name="schnauzer" size="43" />
              <Icon id="icon-cat" name="cat-1" size="40" />
            </>
          )}
        </div>
        <div className="tip-container">
          {getTipData()}
        </div>
      </div>
    </>
  );
};

export default VitalCareTip;

import './icon.css';
import React from 'react';

interface IconProps {
  alt?: string;
  className?: string;
  color?: string;
  name?: string;
  size?: string;
  style?: React.CSSProperties;
  url?: string;
}

const Icon = ({
  alt, className, url, name, color, size, ...props
}: IconProps) => {
  const iconClassName = `Icon${className ? ` ${className}` : ''}`;
  return (
    url
      ? ( // @ts-ignore
        <div className={iconClassName} color={color} size={size} {...props}>
          <img alt={alt} src={url} />
        </div>
      )
      : ( // @ts-ignore
        <div className={iconClassName} name={name || 'wellnessWelcome'} color={color} size={size} {...props}>
          {name === 'cat-1'
            && (
              <img
                src="/assets/icon-pet-cat-1.svg"
                alt="Icon with a checked sign figure"
              />
            )}
          {name === 'schnauzer'
            && (
              <img
                src="/assets/icon-pet-dog-schnauzer-1.svg"
                alt="Icon with a checked sign figure"
              />
            )}
          {name === 'checked'
            && (
              <img
                src="/assets/icon-checked.svg"
                alt="Icon with a checked sign figure"
              />
            )}
          {name === 'backToArrowLeft' && (
            <img
              src="/assets/icon-back-to-arrow-left.svg"
              alt=""
            />
          )}
          {name === 'checked-UM'
            && (
              <img
                src="/assets/icon-checked-white.svg"
                alt="Icon with a white checked sign figure"
              />
            )}
          {name === 'checkedInsuranceWhatsCovered'
            && (
              <img
                src="/assets/icon-checked-insurance-whats-covered.svg"
                alt="Icon with a checked sign figure"
              />
            )}
          {name === 'call'
            && (
              <img
                src="/assets/icon-call.svg"
                alt="Icon with a figure of a phone"
              />
            )}
          {name === 'review'
            && (
              <img
                src="/assets/icon-review.svg"
                alt="Icon with a figure of clipboard"
              />
            )}
          {name === 'arrowRight'
            && (
              <img
                src="/assets/icon-arrow-right.svg"
                alt="Icon with a figure of a chevron pointed to the right"
              />
            )}
          {name === 'arrowLeft'
            && (
              <img
                src="/assets/icon-arrow-left.svg"
                alt="Icon with a figure of a chevron pointed to the left"
              />
            )}
          {name === 'dog'
            && (
              <img
                src="/assets/icon-dog.svg"
                alt=""
              />
            )}
          {name === 'cat'
            && (
              <img
                src="/assets/icon-cat.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-dog-head'
            && (
              <img
                src="/assets/icon-big-dog-head.svg"
                alt=""
              />
            )}
          {name === 'choose-plan-dog'
            && (
              <img
                src="/assets/dog-choose-plan.svg"
                alt=""
              />
            )}
          {name === 'choose-plan-bird'
            && (
              <img
                src="/assets/bird-choose-plan.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-cat-head'
            && (
              <img
                src="/assets/icon-big-cat-head.svg"
                alt=""
              />
            )}
          {name === 'addNewPet'
            && (
              <img
                src="/assets/icon-add-new-pet.svg"
                alt="Icon with a figure of a dog and a cat"
              />
            )}
          {name === 'addNewPetV2'
            && (
              <img
                src="/assets/icon-add-new-pet_v2.svg"
                alt="Icon with a figure of a dog and a cat"
              />
            )}
          {name === 'um_addNewPetV2'
            && (
              <img
                src="/assets/UM_Icon-Add-New-Pet_V2.svg"
                alt="Icon with a figure of a dog and a cat"
              />
            )}
          {name === 'iconVetStethoscope'
            && (
              <img
                src="/assets/icon-vet-stethoscope.svg"
                alt="Icon with a figure of a dog and a cat"
              />
            )}
          {name === 'iconVetStethoscope_UM'
            && (
              <img
                src="/assets/icon-vet-stethoscope-UM.svg"
                alt="Icon with a figure of a dog and a cat"
              />
            )}
          {name === 'annualLimit'
            && (
              <img
                src="/assets/icon-annual-limit.svg"
                alt="Icon with a calendar figure"
              />
            )}
          {name === 'reimbursementPercentage'
            && (
              <img
                src="/assets/icon-reimbursement-percentage.svg"
                alt="Icon with a figure of hands and a coin"
              />
            )}
          {name === 'annualDeductible'
            && (
              <img
                src="/assets/icon-annual-deductible-amount.svg"
                alt="Icon with a figure of a wallet"
              />
            )}
          {name === 'waitingPeriods'
            && (
              <img
                src="/assets/icon-waiting-periods.svg"
                alt="Icon with a figure of a Hourglass"
              />
            )}
          {name === 'errorIcon'
            && (
              <img
                src="/assets/error-icon.svg"
                alt="Icon with a warning symbol"
              />
            )}
          {name === 'closeSmall'
            && (
              <img
                src="/assets/icon-close.svg"
                alt="Icon with a X sign"
              />
            )}
          {name === 'closeBig'
            && (
              <img
                src="/assets/icon-close.svg"
                alt="Icon with a X sign"
              />
            )}
          {name === 'roverClose'
            && (
              <img
                src="/assets/rover-close.svg"
                alt="Icon with a X sign"
              />
            )}
          {name === 'whatsNotCovered'
            && (
              <img
                src="/assets/icon-whats-not-covered.svg"
                alt="Icon with a X sign"
              />
            )}
          {name === 'closeSmallInsuranceWhatsCovered'
            && (
              <img
                src="/assets/icon-close-small-insurance-whats-covered.svg"
                alt="Icon with a X sign"
              />
            )}
          {name === 'redeemBenefits'
            && (
              <img
                src="/assets/icon-store.svg"
                alt="Icon with a figure of a gift box"
              />
            )}
          {name === 'um_RedeemBenefits'
            && (
              <img
                src="/assets/UM_Icon-Store.svg"
                alt="Icon with a figure of a gift box"
              />
            )}
          {name === 'apu_DiscountIcon'
            && (
              <img
                src="/assets/APU_Discount_Icon_Big.svg"
                alt="Icon with a figure of a discount"
              />
            )}
          {name === 'congratulations'
            && (
              <img
                src="/assets/icon-congratulations.svg"
                alt="Icon with a figure of a trophy"
              />
            )}
          {name === 'benefitUsageHistory'
            && (
              <img
                src="/assets/icon-benefits-usage-history.svg"
                alt="Icon with a figure of a clock"
              />
            )}
          {name === 'checkClaimStatus'
            && (
              <img
                src="/assets/icon-check-claim-status.svg"
                alt="Icon with a figure of a opened folder"
              />
            )}
          {name === 'viewMembershipDetails'
            && (
              <img
                src="/assets/icon-heart.svg"
                alt="Icon with a figure of a book and a magnifying glass"
              />
            )}
          {name === 'SavingBannerIconUM'
            && (
              <img
                src="/assets/UM-savings-icon.svg"
                alt="saving banner icon for UM"
              />
            )}
          {name === 'um_ViewMembershipDetails'
            && (
              <img
                src="/assets/UM_Icon-Heart.svg"
                alt="Icon with a figure of a book and a magnifying glass"
              />
            )}
          {name === 'heartIcon'
            && (
              <img
                src="/assets/heart2.svg"
                alt="Icon with a figure of a book and a magnifying glass"
              />
            )}
          {name === 'petcoVitalCareLogo'
            && (
              <img
                className="app-logo"
                src="/assets/petco-vital-care.svg "
                alt="Logo Petco Vital Care"
              />
            )}
          {name === 'petcoVitalCareUMLogo'
            && (
              <img
                // className="app-logo"
                src="/assets/UM-logo.svg "
                alt="Logo Petco UM"
              />
            )}
          {name === 'backToDashboard'
            && (
              <img
                src="/assets/icon-back-to-dashboard.svg"
                alt="Icon with a figure of a house and a chevron pointed to the left"
              />
            )}
          {name === 'reward'
            && (
              <img
                src="/assets/icon-rewards.svg"
                alt="Icon with a figure of a trophy"
              />
            )}
          {name === 'info'
            && (
              <img
                src="/assets/icon-more-info.svg"
                alt="Icon with the letter i to represent info"
              />
            )}
          {name === 'checkThankYou'
            && (
              <img
                src="/assets/icon-thank-you-check.svg"
                alt="Icon with a figure of a checked symbol"
              />
            )}
          {name === 'payment'
            && (
              <img
                src="/assets/icon-payment.svg"
                alt="Icon with a figure of a checked symbol"
              />
            )}
          {name === 'successCheck'
            && (
              <img
                src="/assets/icon-success-check_v2.svg"
                alt=""
              />
            )}
          {name === 'successCheck-UM'
            && (
              <img
                src="/assets/UM_Icon_Success_Check.svg"
                alt=""
              />
            )}
          {name === 'successMessageCheck'
            && (
              <img
                src="/assets/tickIcon.svg"
                alt="Icon with a figure of a checked symbol for success message"
              />
            )}
          {name === 'successMessageCheckMedium'
            && (
              <img
                src="/assets/Icon_Check_Success_Medium.svg"
                alt="Icon with a figure of a checked symbol for success message"
              />
            )}
          {name === 'icon-store-light-circle'
            && (
              <img
                src="/assets/icon-store-light-circle.svg"
                alt="Icon with a figure of a house inside a circle"
              />
            )}
          {name === 'icon-home-with-side-circle'
            && (
              <img
                src="/assets/icon-home-with-side-circle.svg"
                alt="Icon with a figure of a house with a green circle at the side"
              />
            )}
          {name === 'icon-finish-purchase'
            && (
              <img
                src="/assets/icon-finish-purchase.svg"
                alt="Icon with a check mark inside a green circle"
              />
            )}
          {name === 'icon-file'
            && (
              <img
                src="/assets/icon-file.svg"
                alt="Icon with a PDF File"
              />
            )}
          {name === 'steps-icon'
            && (
              <img
                className="steps-icon"
                alt="Icon with animal progressing"
              />
            )}
          {name === 'corgi'
            && (
              <img
                src="/assets/corgi.png"
                alt="Icon corgi"
              />
            )}
          {name === 'logo-petco-insurance'
            && (
              <img
                src="/assets/logo-petco-insurance.svg"
                alt="Icon with logo petco insurance"
              />
            )}
          {name === 'money-plan-icon'
            && (
              <img
                src="/assets/money-plan-icon.svg"
                alt="Icon with money plan"
              />
            )}
          {name === 'visit-vet-icon'
            && (
              <img
                src="/assets/visit-vet-icon.svg"
                alt="Icon with visit vet"
              />
            )}
          {name === 'submit-claim-icon'
            && (
              <img
                src="/assets/submit-claim-icon.svg"
                alt="Icon with submit a claim"
              />
            )}
          {name === 'icon-dropdown'
            && (
              <img
                src="/assets/icon-dropdown.svg"
                alt=""
                aria-hidden="true"
              />
            )}
          {name === 'icon-carousel-right'
            && (
              <img
                src="/assets/icon-carousel-right.svg"
                alt="Icon with a carousel arrow"
              />
            )}
          {name === 'bird'
            && (
              <img
                src="/assets/bird.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-bird'
            && (
              <img
                src="/assets/Icon_Bird.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-bird-small'
            && (
              <img
                src="/assets/Icon_Bird_small.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-bird-small-UM'
            && (
              <img
                src="/assets/UM_Icon_Bird.svg"
                alt=""
              />
            )}
          {name === 'fish'
            && (
              <img
                src="/assets/fish.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-fish'
            && (
              <img
                src="/assets/Icon_Fish.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-fish-small'
            && (
              <img
                src="/assets/Icon_Fish_small.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-fish-small-UM'
            && (
              <img
                src="/assets/UM_Icon_Fish.svg"
                alt=""
              />
            )}
          {name === 'reptile'
            && (
              <img
                src="/assets/reptile.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-reptile'
            && (
              <img
                src="/assets/Icon_Reptile.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-reptile-small'
            && (
              <img
                src="/assets/Icon_Reptile_small.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-reptile-small-UM'
            && (
              <img
                src="/assets/UM_Icon_Reptile.svg"
                alt=""
              />
            )}
          {name === 'pet-companionV2-addpet'
            && (
              <img
                src="/assets/add_pet.svg"
                alt=""
              />
            )}
          {name === 'smallAnimal'
            && (
              <img
                src="/assets/smallAnimal.svg"
                alt=""
              />
            )}
          {name === 'pet-smallAnimal-small'
            && (
              <img
                src="/assets/Icon_SmallAnimal_small.svg"
                alt=""
              />
            )}
          {name === 'pet-smallAnimal-small-UM'
            && (
              <img
                src="/assets/UM_Icon_SmallPet.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-small-pet'
            && (
              <img
                src="/assets/Icon_SmallPet.svg"
                alt=""
              />
            )}
          {name === 'pet-selection-ruff-mews'
            && (
              <img
                src="/assets/Ruff_Mews.svg"
                alt=""
                height="35"
                width="45"
              />
            )}
          {name === 'multi-pet-green'
            && (
              <img
                src="/assets/multipet-green.webp"
                alt=""
                height="89"
                width="89"
              />
            )}
          {name === 'multi-pet-blue'
            && (
              <img
                src="/assets/multipet-blue.png"
                alt=""
                height="89"
                width="89"
              />
            )}
          {name === 'sellScreenDog'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Dog.svg"
                alt=""
                className="sellScreenDog"
              />
            )}
          {name === 'sellScreenDogSelected'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Dog_Selected.svg"
                alt="Icon with a figure of a Dog selected"
                className="sellScreenDog"
              />
            )}
          {name === 'sellScreenCat'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Cat.svg"
                alt=""
              />
            )}
          {name === 'sellScreenCatSelected'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Cat_Selected.svg"
                alt="Icon with a figure of a Cat selected"
              />
            )}
          {name === 'sellScreenFish'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Fish.svg"
                alt=""
              />
            )}
          {name === 'sellScreenFishSelected'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Fish_Selected.svg"
                alt="Icon with a figure of a Fish selected"
              />
            )}
          {name === 'sellScreenSmallAnimal'
            && (
              <img
                src="/assets/Sell_Screen_Icon_SmallPet.svg"
                alt=""
              />
            )}
          {name === 'sellScreenSmallAnimalSelected'
            && (
              <img
                src="/assets/Sell_Screen_Icon_SmallPet_Selected.svg"
                alt="Icon with a figure of a Small Pet selected"
              />
            )}
          {name === 'sellScreenReptile'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Reptile.svg"
                alt=""
              />
            )}
          {name === 'sellScreenReptileSelected'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Reptile_Selected.svg"
                alt="Icon with a figure of a Reptile selected"
              />
            )}
          {name === 'sellScreenBird'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Bird.svg"
                alt=""
              />
            )}
          {name === 'sellScreenBirdSelected'
            && (
              <img
                src="/assets/Sell_Screen_Icon_Bird_Selected.svg"
                alt="Icon with a figure of a Bird selected"
              />
            )}
          {name === 'Dollar_Big'
            && (
              <img
                src="/assets/Dollar_Big.svg"
                alt="Icon with a figure of a Dollar"
              />
            )}
          {name === 'Dollar_Small'
            && (
              <img
                src="/assets/Dollar_Small.svg"
                alt="Icon with a figure of a Dollar"
              />
            )}
          {name === 'crossIcon'
            && (
              <img
                src="/assets/cross-icon.svg"
                alt="Icon Icon to close the modal"
              />
            )}
          {name === 'updatePlansCCDog'
            && (
              <img
                src="/assets/icon-dog-v2.svg"
                alt=""
              />
            )}
          {name === 'updatePlansCCCat'
            && (
              <img
                src="/assets/icon-cat-v2.svg"
                alt=""
              />
            )}
          {name === 'updatePlansCCFish'
            && (
              <img
                src="/assets/icon-fish-v2.svg"
                alt=""
              />
            )}
          {name === 'updatePlansCCBird'
            && (
              <img
                src="/assets/icon-bird-v2.svg"
                alt=""
              />
            )}
          {name === 'updatePlansCCReptile'
            && (
              <img
                src="/assets/icon-reptile-v2.svg"
                alt=""
              />
            )}
          {name === 'updatePlansCCSmallAnimal'
            && (
              <img
                src="/assets/icon-smallAnimal-v2.svg"
                alt=""
              />
            )}
          {name === 'closeModal'
            && (
              <img
                src="/assets/icon-modal-x.svg"
                alt=""
              />
            )}
          {name === 'defaultCreditCard'
            && (
              <img
                src="/assets/default-credit-card.svg"
                alt="default credit card"
                style={{ height: 30 }}
              />
            )}
          {name === 'visaCreditCard'
            && (
              <img
                src="/assets/credit-card-visa.svg"
                alt="default credit card"
                style={{ height: 30 }}
              />
            )}
          {name === 'mastercardCreditCard'
            && (
              <img
                src="/assets/credit-card-mastercard.svg"
                alt="default credit card"
                style={{ height: 30 }}
              />
            )}
          {name === 'amexCreditCard'
            && (
              <img
                src="/assets/credit-card-amex.svg"
                alt="default credit card"
                style={{ height: 30 }}
              />
            )}
          {name === 'discoverCreditCard'
            && (
              <img
                src="/assets/credit-card-discover.svg"
                alt="default credit card"
                style={{ height: 30 }}
              />
            )}
        </div>
      )
  );
};

Icon.defaultProps = {
  alt: undefined,
  className: undefined,
  color: undefined,
  name: undefined,
  size: undefined,
  url: undefined,
  style: undefined,
};

export default Icon;

// @ts-nocheck
import { Field, Form, Formik } from 'formik';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

import {
  getCardInfoForAdobeTracking,
  getUsStates,
  handleKeyUp, trackEvent,
} from 'utils';

import {
  createMembershipPlansV2, createMembershipPlansVcVersion2, fetchAllDiscounts, fetchAllTaxes, fetchCustomerAddress, fetchPaymentMethods, paymentMethodUpdateForAuthCC,
} from 'store/actions';

import {
  affiliateReferralCode, customer, dashboardInfo, deepLink, DEEPLINK_INDEX, loadingCount, navigationHistory,
  registrationInfo,
  registrationInfo as registrationInfoSlice,
} from 'store/slices';

import ErrorModal from 'components-v2/ErrorModal';
import { Button } from 'components-v2/shared/Button';
import ComboBox from 'components-v2/shared/ComboBox/ComboBox';
import { Text, Title } from 'components-v2/shared/Elements';
import Error from 'components-v2/shared/Error';
import Icon from 'components-v2/shared/Icon/Icon';
import NeedHelp from 'components-v2/shared/NeedHelp';
import { adobeAnalyticsTags, transactionType, webSocketStatusType } from 'utils/constGlobal';
import FinishYourPurchaseStep from './FinishYourPurchaseStep';
import NoPetPlans from './NoPetPlans';
import PaymentInformationStep from './PaymentInformationStep';
import PetCards from './PetCards';
import StoreLocator from './StoreLocator';

import ConfirmationModal from 'components-v2/shared/ConfirmationModal';
import webSocketConnection from 'helpers/webSocket';
import './complete-your-purchase.css';
import viewModel from 'components-v2/shared/CreditCardSelectionNonModal/viewModel';

const REVIEW_INDEX = 0;
const YOUR_ADDRESS_INDEX = 1;
const PAYMENT_INFORMATION_INDEX = 2;
const YOUR_STORE_INDEX = 3;
const FINISH_YOUR_PURCHASE = 4;
const COMPLETED_INDEX = 5;

const stepNames = {
  [REVIEW_INDEX]: 'Review',
  [YOUR_ADDRESS_INDEX]: 'Your address',
  [PAYMENT_INFORMATION_INDEX]: 'Payment info',
  [YOUR_STORE_INDEX]: 'Your Store',
  [COMPLETED_INDEX]: 'Submit payment',
  [FINISH_YOUR_PURCHASE]: 'Finish Your Purchase',
};

function CompleteYourPurchase({
  isLoading,
  enrolledPets,
  enrollmentInfo,
  membershipTotalPrice,
  membershipTotalPriceWithoutTax,
  membershipId,
  paymentMethod,
  showTax,
  totalTax,
  errorDuringCreation,
  errorModalTitle,
  errorModalBody,
  addressEnabled, address, // Todo wire up address when we add this feature back
  storeLocatorEnabled,
  discountInfo,
  hasAffiliateDiscount,
  affiliateDiscountInfo,
  onShowMobileAddNewForm,
  deepLinking,
  deepLinkInfo,
  toggles,
  paymentTenure,
  disclaimerMessage,
  webSocketNotification,
  wsConnectionId,
  petCostsByMembershipId,
  paymentMethodUpdateErrorInfo,
  wcsUserId,
  paymentAuthCCInfo,
  uiText,
}) {
  const [errorAddingPayment, setErrorAddingPayment] = useState(false);
  const [expandedSection, setExpandedSection] = useState(REVIEW_INDEX);
  const [everExpandedFinishSection, setEverExpandedFinishSection] = useState(false);
  const [calledCreatedMembership, setCalledCreateMembership] = useState(false);
  const [calledFetchTaxes, setCalledFetchTaxes] = useState(false);
  const [calledFetechDiscounts, setCalledFetchDiscounts] = useState(false);
  const [stepsChecked, setStepsChecked] = useState({
    [REVIEW_INDEX]: false,
    [YOUR_ADDRESS_INDEX]: !addressEnabled,
    [PAYMENT_INFORMATION_INDEX]: false,
    [YOUR_STORE_INDEX]: !storeLocatorEnabled,
    [FINISH_YOUR_PURCHASE]: false,
    [COMPLETED_INDEX]: false,
  });
  const [termsChecked, setTermsChecked] = useState(false);
  const [monthlyAgreementChecked, setMonthlyAgreementChecked] = useState(false);
  const [selectedStore, setSelectedStore] = useState('');
  const dispatch = useDispatch();
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [socketId, setSocketId] = useState(false);
  const [showPaymentMethod, setPaymentMethod] = useState('');

  const prevTermsCheckedRef = useRef(termsChecked);
  const prevMonthlyAgreementCheckedRef = useRef(monthlyAgreementChecked);

  const {
    useAciPayment,
  } = useSelector(viewModel);

  // Setting up the websocket connection

  useEffect(() => {
    if (toggles?.ENABLEVCVERSION2) {
      webSocketConnection();
    }
  }, [socketId, toggles?.ENABLEVCVERSION2]);

  useEffect(() => {
    if (calledFetechDiscounts && expandedSection === REVIEW_INDEX) {
      const userLandsOnCheckoutPageTag = adobeAnalyticsTags?.USER_LANDS_ON_CHECKOUT_PAGE;
      const userLandsOnCheckoutPagePetInfoTag = [];
      userLandsOnCheckoutPageTag.petInfo.splice(0, userLandsOnCheckoutPageTag.petInfo.length);
      enrolledPets.map((pet) => userLandsOnCheckoutPagePetInfoTag.push({
        petName: pet?.name,
        orignalPrice: pet?.petCost?.preDiscountPrice,
        listPrice: pet?.petCost?.subtotal,
      }));
      userLandsOnCheckoutPageTag.petInfo = userLandsOnCheckoutPagePetInfoTag;
      if (userLandsOnCheckoutPagePetInfoTag[0].listPrice !== 0) {
        userLandsOnCheckoutPageTag.page.buildVersion = toggles?.FRONTEND_VERSION;
        trackEvent(userLandsOnCheckoutPageTag);
      }
    }
  }, [calledFetechDiscounts, setCalledFetchDiscounts, enrolledPets, expandedSection, toggles?.FRONTEND_VERSION]);

  const showStep = useCallback((step, stepCondition) => {
    if (toggles?.USE_ACI_PAYMENT && step === PAYMENT_INFORMATION_INDEX) {
      setCalledCreateMembership(false);
      dispatch(registrationInfo.actions.setOnMembershipCreateError(
        {
          error: false,
          message: null,
          errors: [],
        },
      ));
    }
    if (stepCondition) {
      setExpandedSection(step);
      setStepsChecked({
        ...stepsChecked,
        [step]: false,
      });
    }
  }, [stepsChecked]);

  /**
  * callBackforMembershipV2 method is used to handle the callback in membership creation for new architectural changes.
  */
  const callBackforMembershipV2 = useCallback(() => {
    if (toggles?.ENABLEVCVERSION2 && webSocketNotification?.status_code !== undefined) {
      if (webSocketNotification?.status_code === webSocketStatusType.SUCCESS) {
        dispatch(customer.actions.clearMembershipPlansCacheTimer());
        dispatch(registrationInfoSlice.actions.setCreatedMembershipPlans(webSocketNotification?.data[0]));
        dispatch(dashboardInfo.actions.setPetMasterId(webSocketNotification?.data[0][0]?.pet));
        setCalledCreateMembership(true);
        dispatch(loadingCount.actions.decrement());
        //  toast(webSocketNotification?.message);  // Note: Once we have a clarity we will enable the toast
      }
      if (webSocketNotification?.status_code === webSocketStatusType.ERROR) {
        setShowRetryModal(true);
        dispatch(loadingCount.actions.decrement());
      }
      if (webSocketNotification?.status_code === webSocketStatusType.DENIEDERROR) {
        const errorMessage = transactionType.paymentErrorMessage;
        dispatch(registrationInfoSlice.actions.setOnMembershipPaymentMethodError(
          {
            occurred: true,
            message: errorMessage,
            statusCode: 'error',
          },
        ));
        showStep(PAYMENT_INFORMATION_INDEX, expandedSection !== PAYMENT_INFORMATION_INDEX && enrolledPets?.length > 0);
        setShowRetryModal(false);
        setEverExpandedFinishSection(false);
        dispatch(loadingCount.actions.decrement());
      }
    }
  }, [dispatch, enrolledPets?.length, expandedSection, showStep, toggles?.ENABLEVCVERSION2, webSocketNotification?.data, webSocketNotification?.status_code]);

  const completeStep = useCallback((step) => {
    const nextUncheckedStep = Object.keys(stepsChecked)
      .map((index) => parseInt(index, 10))
      .find((index) => step !== index && !stepsChecked[index]);

    setExpandedSection(nextUncheckedStep);

    if (nextUncheckedStep === FINISH_YOUR_PURCHASE) {
      setEverExpandedFinishSection(true);
    }

    setStepsChecked({
      ...stepsChecked,
      [step]: true,
    });

    // CAPTRAY: This is a hack. Needs more thought and a long-term fix for tracking once
    // the contracts are agreed upon.
  }, [stepsChecked, setExpandedSection, setStepsChecked]);

  useEffect(() => {
    if (toggles?.ENABLEVCVERSION2) {
      callBackforMembershipV2();
    }
  }, [callBackforMembershipV2, toggles?.ENABLEVCVERSION2, webSocketNotification, webSocketNotification?.status_code]);

  // Websocket

  useEffect(() => {
    if (!calledFetechDiscounts && enrolledPets?.length > 0) {
      // dispatch(fetchAllDiscounts(enrolledPets?.length, membershipId));
      dispatch(fetchAllDiscounts(membershipId));
      setCalledFetchDiscounts(true);
    }
  }, [dispatch, calledFetechDiscounts, setCalledFetchDiscounts, enrolledPets, membershipId]);

  useEffect(() => {
    if (!calledFetchTaxes && paymentMethod && enrolledPets?.length > 0) {
      dispatch(fetchAllTaxes(paymentMethod, enrolledPets?.length, membershipId));
      setCalledFetchTaxes(true);
      if (toggles?.ENABLEVCVERSION2) {
        setIsPaymentMethod(true);
      }
    }
  }, [dispatch, enrolledPets, membershipId, paymentMethod, calledFetchTaxes, toggles?.ENABLEVCVERSION2]);
  /**
    * this useEffect is used for PaymentAuthCC process in new architectural change and will work only this toggles?.ENABLEVCVERSION2 enabled.
    */
  useEffect(() => {
    if (toggles?.ENABLEVCVERSION2) {
      if (isPaymentMethod && paymentAuthCCInfo && paymentAuthCCInfo?.enablePaymentAuthCC && parseInt(membershipTotalPrice, 10) > 0 && paymentMethodUpdateErrorInfo?.status === '') {
        dispatch(paymentMethodUpdateForAuthCC(paymentMethod, membershipTotalPrice, wcsUserId, transactionType.SIGNUP));
      }
      if (paymentAuthCCInfo && paymentAuthCCInfo?.enablePaymentAuthCC && paymentMethodUpdateErrorInfo && !paymentMethodUpdateErrorInfo?.errorOccurred && paymentMethodUpdateErrorInfo?.status === 'success') {
        completeStep(PAYMENT_INFORMATION_INDEX);
      } else if (paymentMethodUpdateErrorInfo && paymentMethodUpdateErrorInfo?.errorOccurred && paymentMethodUpdateErrorInfo?.status === 'error') {
        adobeAnalyticsTags.USER_LANDS_ON_PAYMENT_ERROR_ON_SIGNUP_FLOW.page.buildVersion = toggles?.FRONTEND_VERSION;
        trackEvent(adobeAnalyticsTags?.USER_LANDS_ON_PAYMENT_ERROR_ON_SIGNUP_FLOW);
      }
    }
  }, [isPaymentMethod, paymentAuthCCInfo?.enablePaymentAuthCC, toggles?.ENABLEVCVERSION2, membershipTotalPrice, paymentMethodUpdateErrorInfo?.errorOccurred, paymentMethodUpdateErrorInfo?.status, paymentAuthCCInfo, paymentMethodUpdateErrorInfo, dispatch, paymentMethod, wcsUserId, completeStep]);

  useEffect(() => {
    if (calledCreatedMembership && !isLoading && !errorDuringCreation) {
      dispatch(navigationHistory.actions.goToPurchaseSuccess());
    }
  }, [calledCreatedMembership, isLoading, errorDuringCreation, dispatch]);
  /*
** Below function is responsible for handling membership creation by expecting data in object as membershipPlanData.
*/
  const createMemberships = (membershipPlanData) => {
    if (!toggles?.ENABLEVCVERSION2) {
      setCalledCreateMembership(false);
      dispatch(createMembershipPlansV2(membershipPlanData.storeId, membershipPlanData.paymentTenure,
        () => {
          setCalledCreateMembership(true);
        }));
    } else {
      setCalledCreateMembership(false);
      dispatch(createMembershipPlansVcVersion2(membershipPlanData,
        () => {
          callBackforMembershipV2();
          // setCalledCreateMembership(true);
        }));
    }
  };

  const navigateToDashBoard = () => {
    dispatch(navigationHistory.actions.goToRedemptionDashboard());
  };

  const hideRetryModal = () => {
    setShowRetryModal(false);
  };

  const adobeTrackerForGenericError = (errorModalTitle, errorModalBody) => {
    const adobeTrackerForGenericErrorTag = adobeAnalyticsTags?.USER_LANDS_ON_GENERIC_ERROR_MODAL;
    adobeTrackerForGenericErrorTag.message.title = errorModalTitle;
    adobeTrackerForGenericErrorTag.message.error = errorModalBody;
    trackEvent(adobeTrackerForGenericErrorTag);
  };

  useEffect(() => {
    async function fetchData() {
      if (addressEnabled) {
        await dispatch(fetchCustomerAddress());
      }

      if (deepLinking && deepLinkInfo.destination === DEEPLINK_INDEX.ENROLLMENT_CONFIRMATION_WALLET.key) {
        dispatch(fetchPaymentMethods(false, deepLinkInfo.walletId));
        dispatch(deepLink.actions.resetDeepLink());
        completeStep(REVIEW_INDEX);
      } else {
        dispatch(fetchPaymentMethods());
        dispatch(deepLink.actions.resetDeepLink());
      }
    }

    fetchData();
  }, [dispatch, addressEnabled, deepLinking, deepLinkInfo, completeStep]);

  const getCopyOfPet = (masterId) => {
    const selectedPets = enrolledPets || [];
    const selectedPet = selectedPets.find((pet) => pet.id === masterId);
    return { ...selectedPet };
  };

  const updateEnrolledPetsWithPet = (newPet) => {
    const selectedPets = enrolledPets || [];
    const otherPets = selectedPets.filter((pet) => newPet.id !== pet.id);
    // ToDo get rid of this hard coded flag for each membership type
    if (newPet.proCareInfo) {
      const newPetsArray = [...otherPets, newPet];
      dispatch(registrationInfoSlice.actions.setSelectedPetsToEnroll(newPetsArray));
    } else {
      if (otherPets?.length === 0) {
        dispatch(affiliateReferralCode.actions.set({ referral_code: '' }));
      }
      dispatch(registrationInfoSlice.actions.setSelectedPetsToEnroll(otherPets));
    }
  };


  const removeProCare = (oldPet) => {
    const selectedPet = getCopyOfPet(oldPet.petMasterId);
    selectedPet.proCareInfo = false;
    updateEnrolledPetsWithPet(selectedPet);
    dispatch(registrationInfoSlice.actions.setTaxAndDiscountInfo(null));
    setCalledFetchDiscounts(false);
    setCalledFetchTaxes(false);
  };
  /**
    * this selectNewPaymentMethod function is used for selecting new payment method based on architectural flag 'ENABLEVCVERSION2'.
    */

  const selectNewPaymentMethod = (newPaymentMethod) => {
    if (!toggles?.ENABLEVCVERSION2) {
      if (newPaymentMethod !== showPaymentMethod) {
        setPaymentMethod(newPaymentMethod);
        dispatch(
          registrationInfoSlice.actions.setSelectedPaymentMethod(newPaymentMethod),
        );
        setCalledFetchTaxes(false);
        completeStep(PAYMENT_INFORMATION_INDEX);
      } else {
        completeStep(PAYMENT_INFORMATION_INDEX);
      }
    } else {
      setIsPaymentMethod(false);
      dispatch(registrationInfoSlice.actions.clearOnMembershipPaymentAuthCC({ isTotalPrice: false, authCCResponse: {} }));
      dispatch(registrationInfoSlice.actions.clearOnMembershipPaymentMethodError({ occurred: false, message: null, statusCode: '' }));
      if (newPaymentMethod !== showPaymentMethod) {
        setPaymentMethod(newPaymentMethod);
        dispatch(
          registrationInfoSlice.actions.setSelectedPaymentMethod(newPaymentMethod),
        );
        setCalledFetchTaxes(false);
        // completeStep(PAYMENT_INFORMATION_INDEX);
      } else {
        completeStep(PAYMENT_INFORMATION_INDEX);
      }
    }
  };

  const monthlyCommitmentEnabled = toggles?.MONTHLY_COMMITMENT;
  const isMonthlyCommitment = paymentTenure !== 'yearly' && monthlyCommitmentEnabled;

  const submitEnabled = isMonthlyCommitment
    ? (stepsChecked[REVIEW_INDEX]
      && stepsChecked[YOUR_ADDRESS_INDEX]
      && stepsChecked[PAYMENT_INFORMATION_INDEX]
      && termsChecked
      && monthlyAgreementChecked) : (stepsChecked[REVIEW_INDEX]
        && stepsChecked[YOUR_ADDRESS_INDEX]
        && stepsChecked[PAYMENT_INFORMATION_INDEX]
        && termsChecked);

  const states = getUsStates();
  const zipCodeRegex = /^([0-9]{5})(-?[0-9]{4})?$/;

  useEffect(() => {
    if (checkErrorModel() && errorModalBody === 'Payment Declined') {
      trackEvent(adobeAnalyticsTags?.USER_LANDS_ON_PAYMENT_RELATED_ISSUE_MODAL);
    } else if (checkErrorModel()) {
      adobeTrackerForGenericError(errorModalTitle, errorModalBody);
    }
  }, [errorDuringCreation, errorModalBody, errorModalTitle]);

  useEffect(() => {
    if (toggles?.USE_ACI_PAYMENT && errorDuringCreation && ((errorModalBody === 'Payment Declined') || (errorModalBody === 'Payment Failure')) && calledCreatedMembership) {
      setEverExpandedFinishSection(false);
      setExpandedSection(PAYMENT_INFORMATION_INDEX);
      adobeAnalyticsTags.USER_SELECTS_EXISTING_CARD_AND_EXPERIENCES_ERROR_RELATED_TO_PAYMENT_DECLINED_OR_FAILURE_DURING_SIGNUP.message.error = uiText?.declinedPaymentMethodMessageForACI;
      trackEvent(adobeAnalyticsTags?.USER_SELECTS_EXISTING_CARD_AND_EXPERIENCES_ERROR_RELATED_TO_PAYMENT_DECLINED_OR_FAILURE_DURING_SIGNUP);
    } else if (toggles?.USE_ACI_PAYMENT && calledCreatedMembership && !errorDuringCreation && errorModalBody === null) {
      setEverExpandedFinishSection(true);
    }
  }, [toggles, errorDuringCreation, errorModalBody, setExpandedSection, calledCreatedMembership]);

  const checkErrorModel = () => {
    if (toggles?.USE_ACI_PAYMENT && errorDuringCreation && ((errorModalBody !== 'Payment Declined') || (errorModalBody !== 'Payment Failure'))) {
      return false;
    } else if (errorDuringCreation) {
      return true;
    }
  };

  // updating the prevTermsCheckedRef to have previous state value
  useEffect(() => {
    // assigning the latest rendered value of termsChecked to the prevTermsCheckedRef
    // so that, prevTermsCheckedRef.current is updated with the previous state value in the ref at the time of render
    prevTermsCheckedRef.current = termsChecked;
  }, [termsChecked]);

  // updating the prevMonthlyAgreementCheckedRef to have last
  useEffect(() => {
    // assigning the latest rendered value of termsChecked to the prevMonthlyAgreementCheckedRef
    // so that, prevMonthlyAgreementCheckedRef.current is updated with the previous state value in the ref at the time of render
    prevMonthlyAgreementCheckedRef.current = monthlyAgreementChecked;
  }, [monthlyAgreementChecked]);

  // updating the prevTermsCheckedRef to have previous state value
  useEffect(() => {
    // assigning the latest rendered value of termsChecked to the prevTermsCheckedRef
    // so that, prevTermsCheckedRef.current is updated with the previous state value in the ref at the time of render
    prevTermsCheckedRef.current = termsChecked;
  }, [termsChecked]);

  // updating the prevMonthlyAgreementCheckedRef to have last
  useEffect(() => {
    // assigning the latest rendered value of termsChecked to the prevMonthlyAgreementCheckedRef
    // so that, prevMonthlyAgreementCheckedRef.current is updated with the previous state value in the ref at the time of render
    prevMonthlyAgreementCheckedRef.current = monthlyAgreementChecked;
  }, [monthlyAgreementChecked]);

  return (
    <div className="CompleteYourPurchasePage-v2">
      <div className="CompleteYourPurchasePage__Container-v2">
        <Title>{uiText?.completeYourPurchaseHeadingTxt}</Title>

        <div className="Step-v2">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <div
            role="heading"
            aria-level={1}
            data-testid="id-Review-Section"
            className="Step__Header-v2 no-border"
            onClick={() => { showStep(REVIEW_INDEX, expandedSection !== REVIEW_INDEX); trackEvent(adobeAnalyticsTags?.EDIT_PAYMENT_REVIEW_V2); }}
            onKeyUp={(e) => handleKeyUp(e, () => { showStep(REVIEW_INDEX, expandedSection !== REVIEW_INDEX); trackEvent(adobeAnalyticsTags?.EDIT_PAYMENT_REVIEW_V2); })}
          >
            <Title tabIndex="0" as="h3" className="no-padding"> 1. Review</Title>
            {stepsChecked[REVIEW_INDEX] && expandedSection !== REVIEW_INDEX
              && <div className="Step__Edit-v2" aria-label="Edit Review Section">Edit</div>}
          </div>

          {expandedSection === REVIEW_INDEX && !isLoading && (
            <div className="Step__Content-v2">
              {enrolledPets?.length > 0
                && (
                  <PetCards
                    enrolledPets={enrolledPets}
                    enrollmentInfo={enrollmentInfo}
                    removeProCare={removeProCare}
                    affiliateDiscount={affiliateDiscountInfo}
                    isAffiliate={hasAffiliateDiscount}
                    toggles={toggles}
                    paymentTenure={paymentTenure}
                  />
                )}
              {enrolledPets?.length === 0 && (
                <div>
                  <NoPetPlans />
                </div>
              )}
              {addressEnabled
                ? (
                  <div>
                    <Button
                      onClick={() => completeStep(REVIEW_INDEX)}
                      label="Enter Your Address"
                      className={enrolledPets?.length ? 'active-button' : ''}
                      disabled={enrolledPets?.length === 0}
                    />
                  </div>
                )
                : (
                  <div className="enter-payment-information">
                    <Button
                      onClick={() => {
                        completeStep(REVIEW_INDEX);
                        trackEvent(adobeAnalyticsTags?.PAYMENT_REVIEW_V2);
                        window.parent.postMessage({ action: 'scrollTop' }, '*');
                      }}
                      label="Enter Payment Information"
                      type="button"
                      className={enrolledPets?.length ? 'active-button' : ''}
                      disabled={enrolledPets?.length === 0}
                    />
                    <div className="enter-payment-divide" />
                    {hasAffiliateDiscount ? (<Text>Taxes will be calculated at checkout.</Text>) : (<Text>You will be charged ${enrollmentInfo?.totalPriceWithoutTax?.toFixed(2)} total per {paymentTenure === 'yearly' ? 'year' : 'month for 12 months'} plus applicable taxes which will be calculated at checkout.</Text>)}
                  </div>
                )}
            </div>
          )}
        </div>

        {addressEnabled && (
          <div>
            <div className="Step-v2">
              <div
                className="Step__Header-v2"
                role="button"
                tabIndex="0"
                onClick={() => showStep(YOUR_ADDRESS_INDEX, expandedSection !== YOUR_ADDRESS_INDEX && enrolledPets?.length > 0)}
                onKeyUp={(e) => {
                  handleKeyUp(e, () => {
                    showStep(YOUR_ADDRESS_INDEX, expandedSection !== YOUR_ADDRESS_INDEX && enrolledPets?.length > 0);
                  });
                }}
              >
                <Icon name={stepsChecked[YOUR_ADDRESS_INDEX] ? 'checked' : 'yourAddress'} />
                <Title>Your Address</Title>
                {stepsChecked[YOUR_ADDRESS_INDEX] && expandedSection !== YOUR_ADDRESS_INDEX
                  && <div className="Step__Edit-v2" aria-label="Edit Your Address Section">Edit</div>}
              </div>

              {expandedSection === YOUR_ADDRESS_INDEX && !isLoading && (
                <div className="Step__Content-v2">
                  <Formik
                    initialValues={address}
                    validateOnMount
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string()
                        .max(50, 'Too Long! Max 50 characters')
                        .required('Required'),
                      lastName: Yup.string()
                        .max(50, 'Too Long! Max 50 characters')
                        .required('Required'),
                      address: Yup.string()
                        .max(50, 'Too Long! Max 50 characters')
                        .required('Required'),
                      street2: Yup.string()
                        .max(50, 'Too Long! Max 50 characters'),
                      city: Yup.string()
                        .max(50, 'Too Long! Max 50 characters')
                        .required('Required'),
                      state: Yup.string()
                        .required('Required'),
                      zip: Yup.string()
                        .matches(zipCodeRegex, 'Must be a valid zipcode')
                        .required('Required'),
                    })}
                    enableReinitialize
                  >
                    {(props) => (
                      <Form>
                        <fieldset>
                          <div>
                            <div>
                              <label htmlFor="firstname">
                                <span>* </span>
                                Your First Name
                              </label>
                              <Field
                                id="firstName"
                                name="firstName"
                                placeholder="Enter First Name"
                                className={props.values.firstName ? 'address' : ''}
                              />
                              <br />
                              {props.errors.firstName && props.touched.firstName && (
                                <Error name="firstName">
                                  {props.error}
                                </Error>
                              )}

                              <label htmlFor="lastName">
                                <span>* </span>
                                Your Last Name
                              </label>
                              <Field
                                id="lastName"
                                name="lastName"
                                placeholder="Enter Last Name"
                                className={props.values.lastName ? 'address' : ''}
                              />
                              <br />
                              {props.errors.lastName && props.touched.lastName && (
                                <Error name="lastName">
                                  {props.error}
                                </Error>
                              )}

                              <label htmlFor="address">
                                <span>* </span>
                                Address
                              </label>
                              <Field
                                id="address"
                                name="address"
                                placeholder="Enter Address"
                                className={props.values.address ? 'address' : ''}
                              />
                              {props.errors.address && props.touched.address && (
                                <Error name="address">
                                  {props.error}
                                </Error>
                              )}

                              <label htmlFor="street2">Unit/Apt #</label>
                              <Field
                                id="street2"
                                name="street2"
                                placeholder="Unit/Apt #"
                                className={props.values.street2 ? 'address' : ''}
                              />
                              {props.errors.street2 && props.touched.street2 && (
                                <Error name="street2">
                                  {props.error}
                                </Error>
                              )}

                              <label htmlFor="city" className="city-label">
                                <span>* </span>
                                City
                              </label>
                              <Field
                                id="city"
                                name="city"
                                placeholder="Enter City"
                                className={props.values.city ? 'address' : ''}
                                data-testid="city"
                              />
                              <br />
                              {props.errors.city && props.touched.city && (
                                <Error name="city">
                                  {props.error}
                                </Error>
                              )}

                              <div className="location">
                                <div>
                                  <label htmlFor="state">
                                    <span>* </span>
                                    State
                                  </label>
                                  <ComboBox
                                    id="state"
                                    name="state"
                                    ariaLabel="state"
                                    // pass the initial value selected value
                                    selectedOption={props.values.state}
                                    options={states.map(
                                      (state) => ({
                                        key: state.id,
                                        value: state.postalAbbreviation,
                                        label: state.label,
                                      }),
                                    )}
                                    onChange={props.setFieldValue}
                                    onBlur={props.setFieldTouched}
                                  />
                                  <br />
                                  {props.errors.state && props.touched.state && (
                                    <Error name="state">
                                      {props.error}
                                    </Error>
                                  )}
                                </div>
                                <div>
                                  <label htmlFor="zip">
                                    <span>* </span>
                                    Zip Code
                                  </label>
                                  <Field
                                    id="zip"
                                    name="zip"
                                    placeholder="Enter Zip Code"
                                    className={props.values.zip ? 'address' : ''}
                                  />
                                  <br />
                                  {props.errors.zip && props.touched.zip && (
                                    <Error name="zip">
                                      {props.error}
                                    </Error>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <Button
                          onClick={() => {
                            dispatch(registrationInfoSlice.actions.setAddressInformedByCustomer(props.values));
                            completeStep(YOUR_ADDRESS_INDEX);
                            trackEvent(adobeAnalyticsTags?.PAYMENT_REVIEW_V2);
                            window.parent.postMessage({ action: 'scrollTop' }, '*');
                          }}
                          label="Enter Payment Information"
                          type="button"
                          className={Object.keys(props.errors)?.length > 0
                            || !props.isValid ? 'invalid-button' : 'active-button'}
                          disabled={Object.keys(props.errors)?.length > 0
                            || !props.isValid}
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="Step-v2">
          <div
            className={useAciPayment ? 'Step__Header-v2-ACI' : 'Step__Header-v2'}
            role="button"
            aria-expanded={expandedSection === PAYMENT_INFORMATION_INDEX ? 'true' : 'false'}
            tabIndex="0"
            onClick={() => { showStep(PAYMENT_INFORMATION_INDEX, expandedSection !== PAYMENT_INFORMATION_INDEX && enrolledPets?.length > 0); trackEvent(adobeAnalyticsTags?.EDIT_CARD_INFO_V2); }}
            onKeyUp={(e) => {
              handleKeyUp(e, () => {
                showStep(PAYMENT_INFORMATION_INDEX,
                  expandedSection !== PAYMENT_INFORMATION_INDEX && enrolledPets?.length > 0);
                trackEvent(adobeAnalyticsTags?.EDIT_CARD_INFO_V2);
              });
            }}
          >
            <Title as="h3" className="no-padding">2. Payment</Title>
            {stepsChecked[PAYMENT_INFORMATION_INDEX] && expandedSection !== PAYMENT_INFORMATION_INDEX
              && <div className="Step__Edit-v2" aria-label="Edit Payment Information Section">Edit</div>}
          </div>

          {expandedSection === PAYMENT_INFORMATION_INDEX && (
            <div className="Step__Content-v2">
              {toggles?.USE_ACI_PAYMENT && errorDuringCreation && (errorModalBody === 'Payment Declined' || errorModalBody === 'Payment Failure')
                && (
                  <div className="expiredErrorMessage flashMessage" data-testid="expiredErrorMessage">
                    <span><Icon name="errorIcon" /></span>
                    <span>
                      {uiText?.declinedPaymentMethodMessageForACI}
                    </span>
                  </div>
                )}
              <PaymentInformationStep
                role="listbox"
                onShowMobileAddNewForm={onShowMobileAddNewForm}
                onDoneStep={selectNewPaymentMethod}
                paymentMethodUpdateErrorInfo={paymentMethodUpdateErrorInfo}
                deepLinkInfo={deepLinkInfo}
                errorAddingPayment={errorAddingPayment}
                setErrorAddingPayment={setErrorAddingPayment}
              />
            </div>
          )}
        </div>

        {storeLocatorEnabled && (
          <StoreLocator
            yourStoreIndex={YOUR_STORE_INDEX}
            showStep={showStep}
            stepNames={stepNames}
            expandedSection={expandedSection}
            enrolledPets={enrolledPets}
            stepsChecked={stepsChecked}
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            completeStep={completeStep}
            ENABLE_UNIFIED_MEMBERSHIP={toggles?.ENABLE_UNIFIED_MEMBERSHIP}
          />
        )}

        <div className="Step-v2">
          <div className={`${!everExpandedFinishSection ? 'Step__Header-v2 border-bottom' : 'Step__Header-v2'}`}>
            <div className="finish-your-purchase-header">
              <Title as="h3" className="no-padding">{storeLocatorEnabled ? '4.' : '3.'} Finish Your Purchase</Title>
            </div>
          </div>
          {everExpandedFinishSection && (
            <div className="Step__Content-v2">
              <FinishYourPurchaseStep
                enrolledPets={enrolledPets}
                termsChecked={termsChecked}
                prevTermsCheckedRef={prevTermsCheckedRef}
                monthlyAgreementChecked={monthlyAgreementChecked}
                prevMonthlyAgreementCheckedRef={prevMonthlyAgreementCheckedRef}
                setMonthlyAgreementChecked={setMonthlyAgreementChecked}
                totalTax={totalTax}
                membershipTotalPrice={membershipTotalPrice}
                membershipTotalPriceWithoutTax={membershipTotalPriceWithoutTax}
                submitEnabled={submitEnabled}
                showTax={showTax}
                createMemberships={createMemberships}
                setTermsChecked={setTermsChecked}
                isLoading={isLoading}
                selectedStore={selectedStore}
                discountInfo={discountInfo}
                isAffiliate={hasAffiliateDiscount}
                toggles={toggles}
                paymentTenure={paymentTenure}
                disclaimerMessage={disclaimerMessage}
                wsConnectionId={wsConnectionId}
                petCostsByMembershipId={petCostsByMembershipId}
                paymentAuthCCInfo={paymentAuthCCInfo}
                wcsUserId={wcsUserId}
                isMonthlyCommitment={isMonthlyCommitment}
              />
            </div>
          )}
        </div>
        {checkErrorModel()
          && (
            <ErrorModal
              title={errorModalTitle}
              message={errorModalBody}
              show
            >
              <button
                className="btn btn-primary retry-button"
                onClick={() => {
                  setCalledCreateMembership(false);
                  dispatch(registrationInfoSlice.actions.setOnMembershipCreateError({ error: false }));
                }}
              >
                Ok
              </button>
              <button
                className="btn btn-primary retry-button"
                onClick={() => {
                  dispatch(navigationHistory.actions.goToInitialDecision());
                }}
              >
                Cancel Enrollment
              </button>
            </ErrorModal>
          )}
        <NeedHelp
          uiText={uiText}
        />
        {/** this confirmation modal will popup once showRetryModal is enabled based on architectural flag. */}
        {toggles?.ENABLEVCVERSION2 && webSocketNotification?.status_code === 500 && (
          <ConfirmationModal
            title="oops!"
            show={showRetryModal}
            onAction={() => {
              dispatch(registrationInfoSlice.actions.clearSelectedNotification({}));
              showStep(PAYMENT_INFORMATION_INDEX, expandedSection !== PAYMENT_INFORMATION_INDEX && enrolledPets?.length > 0);
              setShowRetryModal(false);
              setEverExpandedFinishSection(false);
              setSocketId(true);
            }}
            onHide={hideRetryModal}
            primaryButtonLabel="Retry"
            secondaryButtonLabel="Back To Home"
            navigateTo={navigateToDashBoard}
            enableVersion={toggles?.ENABLEVCVERSION2}
          >
            <div>
              <p className={toggles?.ENABLEVCVERSION2 ? 'modal-body-paragraph' : ''}>We've encountered an issue while processing your payment and cannot create your membership</p>
              <p className={toggles?.ENABLEVCVERSION2 ? 'modal-body-paragraph' : ''}>Please try again later.</p>
            </div>
          </ConfirmationModal>
        )}

      </div>

      {/*
      // Note: Once we have a clarity we will enable this block
      {webSocketNotification?.message && (
        <ToastContainer autoClose={2000} />
      )}
    */}

    </div>
  );
}

export default CompleteYourPurchase;

import React, { useState } from 'react';
import Slide from 'react-slick';

import { handleKeyUp } from 'utils';

import { AffiliateDiscountType } from 'utils/constGlobal';

import ConfirmationModal from 'components-v2/shared/ConfirmationModal';
import Icon from 'components-v2/shared/Icon/Icon';
import { Text, Title } from 'components-v2/shared/Elements';
import Subtotal from '../FinishYourPurchaseStep/Subtotal';

import './pet-cards.css';

function NextArrow({ onClick }) {
  return (
    <div className={`${onClick ? '' : 'hide-arrow'} slide-arrow-right`} onClick={onClick}>
      <Icon name="icon-carousel-right" className="icon-carousel-right" size="25" />
    </div>
  );
}

function PrevArrow({ onClick }) {
  return (
    <div className={`${onClick ? '' : 'hide-arrow'} slide-arrow-left`} onClick={onClick}>
      <Icon name="icon-carousel-right" className="icon-carousel-right" size="25" />
    </div>
  );
}
function iconDisplay(pet) {
  return (pet?.type === 'Small Animal' ? (<Icon name="smallAnimal" />) : (<Icon name={pet?.type?.toLowerCase()} />));
}

const settings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: true,
        dots: true,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const PetCards = ({
  enrolledPets,
  enrollmentInfo,
  removeProCare,
  affiliateDiscount,
  isAffiliate,
  toggles,
  paymentTenure,
}) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [petToRemove, setPetToRemove] = useState(null);
  const tenure = toggles?.ANNUAL_PAID_UPFRONT_ONLINE && paymentTenure === 'yearly' ? '/yr' : '/mo';
  const ariaLabelPaymentTenure = paymentTenure === 'yearly' ? 'yearly' : 'monthly';


  function discountName(name) {
    if (name === AffiliateDiscountType.AFFILIATE_DISCOUNT_NAME) {
      return AffiliateDiscountType.AFFILIATE_REFERRAL_NAME;
    }
    return name;
  }

  const getPetDiscountAriaLabel = (amt, term, discounted) => amt + term + discounted;

  const discLength = enrolledPets[0]?.petCost?.discounts?.length;

  const classBasedOnDiscount = () => {
    if (toggles?.ENABLE_UNIFIED_MEMBERSHIP && discLength === 2) {
      return 'multiple_discount';
    } else if (toggles?.ENABLE_UNIFIED_MEMBERSHIP && discLength === 1) {
      return 'single_discount';
    } else if (toggles?.ENABLE_UNIFIED_MEMBERSHIP && discLength === 0) {
      return 'no_discount';
    } else {
      return '';
    }
  };

  return (
    <div className={`PetCards-v2${toggles?.ENABLE_UNIFIED_MEMBERSHIP ? ' PetCards-v2_UM' : ''}`}>
      {showRemoveModal
        && (
          <ConfirmationModal
            title="Are you sure?"
            show={showRemoveModal}
            onHide={() => setShowRemoveModal(false)}
            onAction={() => {
              removeProCare(petToRemove);
              setShowRemoveModal(false);
            }}
          />
        )}
      <Slide {...settings}>
        {enrolledPets.map(
          (pet) => (
            <div className={`CardReviewList-v2 ${classBasedOnDiscount()}`} key={pet.petMasterId}>
              <div className="CardReviewList__Container-v2">
                <div className="CardReviewList__Item-v2">
                  <div className="CardReviewList__Row-v2">
                    <div className="CardReviewList__PetName-v2" tabIndex="0" aria-label={pet.name}>
                      {iconDisplay(pet)}
                      <Title
                        aria-hidden="true"
                        content={pet.name}
                      />
                    </div>
                    <div className="CardReviewList__PetInfo-v2">
                      <div
                        className="CardReviewList__Price-v2"
                        tabIndex="0"
                        aria-label={`${parseFloat(pet?.petCost?.preDiscountPrice)?.toFixed(2)} ${ariaLabelPaymentTenure} for the plan price`}
                      >
                        {`$${parseFloat(pet?.petCost?.preDiscountPrice)?.toFixed(2)}`}
                        <span>{tenure}</span>
                      </div>
                    </div>
                  </div>
                  <div className="CardReviewList__Row-v2" tabIndex={0}>
                    <Text>Annual Commitment</Text>
                    {toggles?.ANNUAL_PAID_UPFRONT_ONLINE && (paymentTenure === 'yearly') ? null : <Text aria-label="For 12 months">x 12 months</Text>}
                  </div>
                </div>

                <div className="CardReviewList__Item-v2 Item-2">
                  <div className={toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'CardReviewList__Row-v2 row_UM' : 'CardReviewList__Row-v2'}>
                    {toggles?.ENABLE_UNIFIED_MEMBERSHIP
                      ? <Text className={toggles?.ANNUAL_PAID_UPFRONT_ONLINE && (paymentTenure === 'yearly') ? 'weight UM' : 'weight'} tabIndex={0}>Vital Care Premier {pet.type === 'Small Animal' ? <br /> : ''} {pet.type === 'Small Animal' ? 'Small Pet' : pet.type} Plan</Text>
                      : <Text className={toggles?.ANNUAL_PAID_UPFRONT_ONLINE && (paymentTenure === 'yearly') ? 'weight UM' : 'weight'} tabIndex={0}>Vital Care {pet.type === 'Small Animal' ? 'Small Pet' : pet.type} Plan</Text>}
                    <div
                      className="CardReviewList__RemoveButton-v2"
                      aria-label={`Remove ${pet.name} from purchase`}
                      role="button"
                      tabIndex="0"
                      onClick={() => {
                        setShowRemoveModal(true);
                        setPetToRemove(pet);
                      }}
                      onKeyUp={(e) => handleKeyUp(e, () => {
                        setShowRemoveModal(true);
                        setPetToRemove(pet);
                      })}
                    >
                      Remove
                    </div>
                  </div>
                </div>
              </div>

              <div className="CardReviewList__Subtotal-v2">
                {pet?.petCost?.discounts?.map(
                  (discount, index) => (
                    discount?.amount > 0 && (
                      <div className={toggles?.ENABLE_UNIFIED_MEMBERSHIP ? 'CardReviewList__MultiPetSavings-v2 CardReviewList__MultiPetSavings-v2_UM' : 'CardReviewList__MultiPetSavings-v2'} key={discount.displayName} tabIndex={0}>
                        <span aria-label="multiple savings">{discountName(discount.displayName)}</span>
                        <span aria-label={getPetDiscountAriaLabel(parseFloat(discount.amount).toFixed(2), paymentTenure === 'yearly' ? 'per year' : 'per month', 'discounted')}>
                          -$
                          {parseFloat(discount.amount).toFixed(2)}{paymentTenure === 'yearly' ? tenure : null}
                          <div className="sr-only" aria-label="Discounted">Discounted</div>
                        </span>
                      </div>
                    )
                  ),
                )}
                {
                  !isAffiliate && (
                    <Subtotal
                      value={parseFloat(pet?.petCost?.subtotal)}
                      preDiscountValue={parseFloat(pet?.petCost?.preDiscountPrice)}
                      dataName="card-subtotal"
                      tenure={tenure}
                    />
                  )
                }

              </div>
            </div>
          ),
        )}
      </Slide>

      {!isAffiliate ? (
        <>
          <div className="EstimatedTotal-v2">
            {enrolledPets.map(
              (pet) => (
                <div key={pet?.id} className="EstimatedTotal__PetInfo-v2">
                  {toggles?.ENABLE_UNIFIED_MEMBERSHIP
                    ? <Text tabIndex={0}>{pet?.name}'s Vital Care Premier</Text>
                    : <Text tabIndex={0}>{pet?.name}'s Vital Care</Text>}
                  <Text className={(toggles?.ANNUAL_PAID_UPFRONT_ONLINE && tenure === '/yr') ? 'price_UM priceWeight' : 'Month-priceWeight'} tabIndex={0} aria-label={`$ ${pet?.petCost?.subtotal} ${tenure === '/yr' ? 'per year' : 'per month'}`}>${pet?.petCost?.subtotal}{tenure}</Text>
                </div>
              ),
            )}
          </div>
          <div className="EstimatedTotal__PetInfo-v2">
            <Title
              content="Estimated Total:"
              as="h3"
              tabIndex={0}
            />
            <Title
              content={toggles?.ANNUAL_PAID_UPFRONT_ONLINE && (paymentTenure === 'yearly') ? `$${enrollmentInfo?.totalPriceWithoutTax?.toFixed(2)}` : `$${enrollmentInfo?.totalPriceWithoutTax?.toFixed(2)}/mo`}
              as="h3"
              tabIndex={0}
              aria-label={toggles?.ANNUAL_PAID_UPFRONT_ONLINE && (paymentTenure === 'yearly') ? `$${enrollmentInfo?.totalPriceWithoutTax?.toFixed(2)} per year` : `$${enrollmentInfo?.totalPriceWithoutTax?.toFixed(2)}per month`}
            />
          </div>
        </>
      ) : (
        <>
          <div className="EstimatedTotal-v2">
            <Text className="weight">Discounted Amount (first 3 months)</Text>
            {enrolledPets.map(
              (pet) => (
                <div key={pet?.id} className="EstimatedTotal__PetInfo-v2">
                  <Text>{pet?.name}'s Vital Care</Text>
                  <Text>${(pet?.petCost?.subtotal)}/mo</Text>
                </div>
              ),
            )}
          </div>
          <div className="EstimatedTotal-v2">
            <Text className="weight">Regular Amount (after discount period)</Text>
            {enrolledPets.map(
              (pet) => (
                <div key={pet?.id} className="EstimatedTotal__PetInfo-v2">
                  <Text>{pet?.name}'s Vital Care</Text>
                  <Text>${(parseFloat(pet?.petCost?.subtotal) + parseFloat(affiliateDiscount))?.toFixed(2)}/mo</Text>
                </div>
              ),
            )}
          </div>
          <div className="EstimatedTotal__PetInfo-v2">
            <Title
              content="Today’s Estimated Total:"
              as="h3"
            />
            <Title
              content={`$${enrollmentInfo?.totalPriceWithoutTax?.toFixed(2)}/mo`}
              as="h3"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PetCards;
